<div class="promo-code-entry">
    <a (click)="toggleShowPromoCodeEntry()" class="promo-code-button" [ngClass]="showPromoCodeEntry ? 'promo-code-open' : 'promo-code-closed'">I have a Promo Code</a>
</div>

<!-- PROMO DROPDOWN -->
<div [hidden]="!showPromoCodeEntry" class="dropdown-promo">
    <div class="dropdown-promo-form">
        <div class="dropdown-promo-code">
            <label for="promotionalCode" class="dropdown-promo-text">Promo Code:</label>
            <input type="string" [(ngModel)]="promotionalCode" (keyup.enter)="applyPromoCode()" name="promotionalCode" #promotionalCodeElement
                id="promotionalCode" placeholder="Enter Promo Code" class="dropdown-promo-input" />
        </div>

        <div class="dropdown-promo-buttons">
            <button name="apply" [disabled]="promotionalCode == '' || !isPromoCodeValid()" (click)="applyPromoCode()" class="dropdown-promo-button dropdown-promo-button-apply">Apply</button>
            <button name="cancel" (click)="toggleShowPromoCodeEntry()" class="dropdown-promo-button dropdown-promo-button-cancel">Cancel</button>
        </div>
    </div>
</div>
<!-- /PROMO DROPDOWN -->