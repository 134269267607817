<!-- <div class="esign-container" [ngClass]="{'form-products-esign': useDarkStyles}"> -->
<div class="esign-container">
    <!-- code input -->
    <div class="form-esign-code" *ngIf="verifyPDFOpened && !codeIsValid()">
        <label for="esignCode" class="esign-input-label">
          Document Code
        </label>
      <div class="esign-text">
        To confirm that you have the ability to view your account disclosures please open
          <u><a href="/assets/esigncompliance.pdf" target="_blank" (click)="handlelEsignCodePDFClick($event)">this document</a></u>
        and enter the code inside below.
      </div>
      <div class="form-items -form-flex form-esign-code-input">
        <input type="text" pattern="^\d{0,6}" [textMask]="{mask: CODE_MASK}" [(ngModel)]="codeEntered" name="esignCode" id="esignCode" placeholder="Enter Document Code" autocomplete="off" class="form-input">
      </div>
    </div>

    <!-- checkbox-->
    <div class="form-esign-checkbox" *ngIf="!verifyPDFOpened || codeIsValid()">
      <div class="form-items form-checkbox form-checkbox-multiline">
        <input type="checkbox" required [(ngModel)]="acceptedChecked" id="esign" name="esign" autocomplete="off" />
        <label for="esign" class="esign-disclosure-label">
          <div class="form-checkbox-info">
            <p>{{labelWording}} <a href="{{disclosureURL}}" target="_blank" (click)="handlelEsignDisclosurePDFClick($event)">{{disclosureDescription}}</a></p>
          </div>
        </label>
      </div>
    </div>
</div>
