import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Util } from '../common/util';

@Component({
  selector: 'csi-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss']
})
export class EsignComponent implements OnInit {
  @Input() disclosureURL: string;
  @Input() disclosureDescription: string;
  @Input() verifyPDFOpened: boolean;
  @Input() useDarkStyles = false;
  @Output() esignValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly ESIGN_CODE = 638762;
  readonly CODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  codeEntered = '';

  private _checked = false;
  get acceptedChecked(): boolean {
    return this._checked;
  }
  set acceptedChecked(value: boolean) {
    this._checked = value;
    this.esignValidChanged.emit(value);
  }

  constructor() { }

  ngOnInit() { }

  codeIsValid() {
    return parseInt(this.codeEntered, 10) === this.ESIGN_CODE;
  }

  handlelEsignCodePDFClick(event) {
    Util.notifyDigitalForWindowOpen(event.srcElement.href);
  }

  handlelEsignDisclosurePDFClick(event) {
    Util.notifyDigitalForWindowOpen(event.srcElement.href);
  }
}
