export interface ProgressState {
    currentStep: Step;
}

export enum Step {
    SignUp = 1,
    PersonaPrimaryApplicantConsent,
    PersonaPrimaryApplicant,
    SelectProducts,
    CustomizeProducts,
    PrimaryApplicantInformation,
    VerifyPrimaryApplicantInformation,
    PersonaSecondaryApplicantConsent,
    PersonaSecondaryApplicant,
    SecondaryApplicantInformation,
    VerifySecondaryApplicantInformation,
    AcceptDisclosures,
    FundingSource,
    DigitalUserVerification,
    // ReviewAndSubmit,
    // ConfirmatinoEmail,
    // MicroDeposits,
    Completion
}

export const initialProgressState = {
    currentStep: Step.SignUp
};
