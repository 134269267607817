import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState, Step } from '../state-management/state/progress.state';
import { SelectedProductsState, Product, Field, SelectedProductsUtil } from '../state-management/state/selected-products.state';
import { ProductsState } from '../state-management/state/products.state';

import { SelectedProductsActionTypes } from '../state-management/actions/selected-products.actions';
import { ProductsActionTypes } from '../state-management/actions/products.actions';
import { InstitutionState, Institution, initialInstitutionState } from '../state-management/state/institution.state';
import { combineLatest } from 'rxjs';
import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { SessionActionTypes } from '../state-management/actions/session.actions';

@Component({
    moduleId: module.id,
    templateUrl: './features.component.html',
    styleUrls: ['features.component.scss']
})
export class FeaturesComponent implements OnDestroy {
    hasPromoCode = false;
    useNewUiForBank = false;

    public currentStep: Step;
    public skipCustomizeProductsScreenFlag = false;
    public pageName = 'features';
    public selectedProducts: any[];
    public isLoading = true;
    public comments = '';
    public unsubscribe: Subject<void> = new Subject<void>();
    public isPanelVisible = false;

    constructor(
        private store: Store<CombinedState>,
        private router: Router,
        private institutionStore: Store<InstitutionState>
    ) {

        this.store.dispatch({ type: SessionActionTypes.APPLY_BUSINESS_RULES });
        combineLatest(
            this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)),
            this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)),
            this.store.select('productsStoreReducer').pipe(takeUntil(this.unsubscribe)),
            (progressState: ProgressState, institutionState: InstitutionState, productsState: ProductsState) =>
                ({ progressState, institutionState, productsState })).subscribe(values => {
                    this.isLoading = values.productsState.isLoading;
                    this.currentStep = values.progressState.currentStep;
                    this.skipCustomizeProductsScreenFlag = values.institutionState.institution.skipCustomizeProductsScreen;
                    this.useNewUiForBank = values.institutionState.institution.useNewUiForBank;

                    // update the wizard panel
                    if (this.currentStep < Step.CustomizeProducts) {
                        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.CustomizeProducts });
                    } else if (this.currentStep > Step.CustomizeProducts) {
                        this.navigateNext(values.institutionState.institution.isBusiness);
                        return;
                    } else if (values.productsState.featuresSelected && this.currentStep === Step.CustomizeProducts) {
                        this.navigateNext(values.institutionState.institution.isBusiness);

                        return;
                    }

                    // commented this for 246274 fix
                    // if we dont have products with ids yet...
                    // if (!this.selectedProducts
                    //     || !this.selectedProducts.length
                    //     || !this.selectedProducts[0].id) {
                    //     // get them and only return if we don't have them...
                    //     this.store.dispatch({ type: SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS });
                    // }


                }
                );

        this.store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SelectedProductsState) => {
                // check to see if we have gotten the updated products from the server
                if (!(info.selectedProducts.length && info.selectedProducts[0].id)) {
                    return;
                }

                this.selectedProducts = info.selectedProducts;

                this.hasPromoCode = this.selectedProducts.some(
                    product => product.promotionalCodeMask !== '' && product.promotionalCodeMask !== null
                );

                this.defaultAccountAliases(this.selectedProducts);
                // Check Bank Settings to see if Customize Products Screen should be skipped
                if (this.skipCustomizeProductsScreenFlag) {
                    this.store.dispatch({
                        type: ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES,
                        payload: this.selectedProducts
                    });
                    return;
                }
            });


        this.isLoading = false;
    }

    private static isFieldValid(field: Field): boolean {
        return FeaturesComponent.isFieldRequiredAndValid(field) ||
            FeaturesComponent.isFieldOptionalAndBlank(field) ||
            FeaturesComponent.isFieldOptionalAndValid(field);
    }

    private static isFieldRequiredAndValid(field: Field): boolean {
        return field.required && !!field.response && field.response.length > 0 && FeaturesComponent.isFieldFormattedCorrectly(field);
    }

    public static isFieldOptionalAndBlank(field: Field): boolean {
        return !field.required && (!field.response || field.response.length === 0);
    }

    private static isFieldOptionalAndValid(field: Field): boolean {
        return !field.required && this.isFieldFormattedCorrectly(field);
    }

    private static isFieldFormattedCorrectly(field: Field): boolean {
        if (!field.validationPattern) {
            return true;
        }

        return (new RegExp(field.validationPattern)).test(field.response);
    }

    navigateNext(isBusiness: boolean) {
        if (isBusiness) {
            this.router.navigate(['business-information']);
        } else {
            this.router.navigate(['personal-information']);
        }
    }

    defaultAccountAliases(selectedProducts: Product[]) {
        for (let i = 0; i < selectedProducts.length; i++) {
            selectedProducts[i].productAlias = '';
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public selectFeatures() {
        this.selectedProducts[0].userComments = this.comments;
        this.store.dispatch({
            type: ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES,
            payload: this.selectedProducts
        });
    }

    public canSubmitProductQuestions() {
        return this.selectedProducts !== undefined && this.selectedProducts.every(
            product => product.questionGroups !== undefined && product.questionGroups.every(
                questionGroup => questionGroup.elements.every(
                    row => row.every(
                        element => {
                            return element.field == null || FeaturesComponent.isFieldValid(element.field);
                        }
                    )
                )
            )
        );
    }
}
