import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, retryWhen } from 'rxjs/operators';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { initialInstitutionState } from 'src/app/state-management/state/institution.state';

@Injectable()
export class CanSignOnDigitallyGuard implements CanActivate {
  institutionSub: Subscription;
  canSignInDigitally: boolean = undefined;
  constructor(
    private router: Router,
    private store: Store<CombinedState>
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.institutionSub = this.store.select('institutionStoreReducer')
    .subscribe(info => {
      if (info.institution === initialInstitutionState.institution) {
        return;
      }

      this.canSignInDigitally = !!info.institution.digitalBankingBrandId;

      if (!this.canSignInDigitally) {
        this.router.navigate(['home']);
      }
    })
    return true;
  }


}
