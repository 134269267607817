import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { ChallengeDepositState, initialChallengeDepositState } from '../state/challenge-deposit.state';
import { ChallengeDepositActionTypes } from '../actions/challenge-deposit.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const challengeDepositStoreReducer: ActionReducer<ChallengeDepositState> = reducer;

export function reducer(state = initialChallengeDepositState, action: NuFundAction) {
    switch (action.type) {
        case ChallengeDepositActionTypes.TRY_CHALLANGE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true, challengeDepositAttempt: action.payload });
        }

        case ChallengeDepositActionTypes.TRY_CHALLANGE_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isVerified: true, isLoading: false, isError: false });
        }

        case ChallengeDepositActionTypes.TRY_CHALLANGE_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error, isVerified: false });
        }

        default: {
            return state;
        }
    }
}
