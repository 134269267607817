import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Step, ProgressState } from '../state-management/state/progress.state';
import { Product, SelectedProductsState, SelectedProductsUtil } from '../state-management/state/selected-products.state';

import { SessionState } from '../state-management/state/session.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';
import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProductsActionTypes } from '../state-management/actions/products.actions';
import { ModalService } from '../modal/modal.service';
import { InstitutionState } from '../state-management/state/institution.state';
import { Bundle, ProductsState } from '../state-management/state/products.state';
import { take, takeUntil } from 'rxjs/operators';
import { CombinedState } from '../state-management/state/combined.state';
import { HttpErrorResponse} from '@angular/common/http';

@Component({
    moduleId: module.id,
    templateUrl: './digital-user.component.html',
    styleUrls: ['digital-user.component.scss']
})
export class DigitalUserComponent implements OnDestroy {
    public pageName = 'digitalUser';
    public currentStep: Step;
    public isLoading = true;
    public unsubscribe: Subject<void> = new Subject<void>();
    public isPanelVisible = false;

    askForExistingCredentials = true;
    username: '';
    password: '';
    confirmPassword: '';
    skipAccountAssociation = false;
    private autoSubmitted = false;
    private selectedProducts: Product[] = [];
    private selectedBundles: Bundle[] = [];
    hasDigitalFunding = false;
    institutionLoading = false;
    useNewUiForBank = false;

    constructor(
        private store: Store<CombinedState>,
        private router: Router,
        private modalService: ModalService<boolean>,
    ) {

        this.store.select('selectedProductsStoreReducer')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SelectedProductsState) => {
            this.selectedProducts = info.selectedProducts;
            this.selectedBundles = info.selectedBundles;
            this.hasDigitalFunding = SelectedProductsUtil.hasDigitalFunding(info);
        });

        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            // update the wizard panel
            this.currentStep = info.currentStep;
            if (this.currentStep > Step.DigitalUserVerification) {
                this.router.navigate(['completion']);
            } else if (this.currentStep < Step.DigitalUserVerification) {
                this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.DigitalUserVerification });
            }
        });


        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {


            if (info.digitalBankingSSOKey && !this.autoSubmitted) {
                this.autoSubmitted = true;
                this.askForExistingCredentials = true;
                this.next();
            }

            if (info.digitalUserStatus !== null) {
                if (info.digitalUserStatus.isExistingDigitalUser) {
                    // existing user, may or may not know his userid through sso
                    if (info.digitalUserStatus.isSingleSignOnUser) {
                        // if the new code is used, this will mean we know their userid and won't need to prompt for passwords and can go on
                        if (info.digitalUserStatus.isKnownUserId) {
                            if (!this.autoSubmitted) {
                                this.autoSubmitted = true;
                                this.next();
                            }
                        } else {
                            // they did sso and we don't know their ID (backwards compatibility)
                            this.askForExistingCredentials = true;
                        }
                    } else {
                        // existing user but they didn't do sso so we don't know their id
                        this.askForExistingCredentials = true;
                    }
                } else {
                    // digital bank, but they don't have a user yet so they need to be able to set up one
                    this.askForExistingCredentials = false;
                }
            }


            // we need to wait to load institution so we have the isResume flag set
            if (!this.institutionLoading) {
                this.institutionLoading = true;
                this.store.select('institutionStoreReducer').pipe(take(1)).subscribe((institutionState: InstitutionState) => {
                    this.useNewUiForBank = institutionState.institution.useNewUiForBank;

                    if (institutionState.institution.digitalBankingBrandId === 0) {
                        if (info.isResume) {
                            this.next();
                        } else {
                            this.sendEmailAndNavigateToCompletion();
                        }
                    } else {
                        this.store.dispatch({ type: SessionActionTypes.GET_DIGITAL_USER_EXISTS });
                    }
                });
            }

            if (info.accountsCreated) {
                this.sendEmailAndNavigateToCompletion();
                return;
            }
            this.isLoading = info.isLoading;

            if (info.isError) {
                let errorMessage = 'We were unable to associate your accounts.  Please try again later.';

                if (info.error.status === 401) {
                    errorMessage = 'Invalid username or password.';
                } else if (info.error.status === 409) {
                    errorMessage = 'The username already exists.  Please try again with a different username.';
                } else if (info.error.status === 400 && info.error.json().bridgeExceptionDetail) {
                    errorMessage = info.error.json().bridgeExceptionDetail;
                }

                this.modalService.presentError(
                    <HttpErrorResponse> {
                        error: errorMessage
                    }, false, 'Error', 'Ok')
                    .subscribe(null, null, () => { });
            }
        });
    }

    private sendEmailAndNavigateToCompletion() {
        this.store.dispatch({ type: ProductsActionTypes.REQUEST_CONFIRMATION_EMAIL });
        this.router.navigate(['completion']);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get allowSkipAccountAssocciation() {
        if (this.hasDigitalFunding) {
            return false;
        }

        // otherwise you can
        return true;
    }

    public next() {
        
        if (!this.skipAccountAssociation) {
            this.store.dispatch({
                type: SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS,
                payload: {
                    userName: this.username,
                    password: this.password,
                    existingUser: this.askForExistingCredentials
                }
            });
        } else {
            this.sendEmailAndNavigateToCompletion();
        }
    }

    private isUndefinedNullOrEmpty(myString: string) {
        return myString === null || typeof myString === 'undefined' || myString === '';
    }

    private isPasswordValid(password: string) {
        // 8 characters, a digit, a non-word character (includes emojis ☕), lowercase and uppsercase
        return /^(?=.{8,}$)(?=.*\d)(?=.*\W)(?=.*[a-z])(?=.*[A-Z]).*/.test(password);
    }

    get passwordErrors() {
        const errors = [];

        // don't check for password requirements for existing users
        if (this.askForExistingCredentials) {
            return errors;
        }

        if (!/.{8,}/.test(this.password)) {
            errors.push('be at least 8 characters long.');
        }

        if (!/\d+/.test(this.password)) {
            errors.push('contain at least one number.');
        }

        if (!/[a-z]+/.test(this.password)) {
            errors.push('contain at least one lower case letter.');
        }

        if (!/[A-Z]+/.test(this.password)) {
            errors.push('contain at least one upper case letter.');
        }

        if (!/\W+/.test(this.password)) {
            errors.push('contain at least one special character.');
        }

        return errors;
    }

    canContinue() {
        return !this.isLoading &&
            !this.isUndefinedNullOrEmpty(this.username) &&
            !this.isUndefinedNullOrEmpty(this.password) &&
            (
                this.askForExistingCredentials ||
                this.password === this.confirmPassword &&
                this.isPasswordValid(this.password)
            ) ||
            this.skipAccountAssociation;
    }
}
