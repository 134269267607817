import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { combineLatest } from 'rxjs-compat/operator/combineLatest';
import { map } from 'rxjs/operators';
import { IdvBusinessRequest, IdvRequest, IdvResponse } from '../models/idv.model';


import { Applicant } from '../state-management/state/applicants.state';
import { ApplicantIDCollectionStatus } from '../persona-id-collection/persona-id-collection.component';

@Injectable()
export class VerificationService {
    /**
     *
     */
    constructor(public http: HttpClient) {
    }

    public saveBusinessApplicants(applicants: Applicant[], continueSession): Observable<any> {
        return this.http.post<any>('BusinessApplicant/' + continueSession, applicants);
    }

    // returns nothing
    public verify(applicant: Applicant): Observable<any> {
        return this.http.post<any>('Applicant/Verification/', applicant);
    }

    public addNewApplicant(): Observable<any> {
        return this.http.post<any>('Applicant/', {});
    }

    public removeApplicant(applicant: Applicant): Observable<any> {
        return this.http.delete<any>('Applicant/' + applicant.id);
    }

    public submitIdentificationDocument(applicant: Applicant) {
        return this.http.post<any>('Applicant/Capture', applicant);
    }

    public continueToIdvFlow(idvRequest: IdvRequest): Observable<IdvResponse> {
        return this.http.post<IdvResponse>('WatchDogIDV/IDScreening', idvRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/IDScreening'))
        );
    }
    public continueToIdvFlowWithStepUp(idvRequest: IdvRequest): Observable<IdvResponse> {
        return this.http.post<IdvResponse>('WatchDogIDV/IDScreeningStepUp', idvRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/IDScreeningStepUp'))
        );
    }

    public submitIdvOtp(idvRequest: IdvRequest): Observable<IdvResponse> {
        return this.http.post<IdvResponse>('WatchDogIDV/IDScreeningOTP', idvRequest);
    }

    public resendIdvOtp(idvRequest: IdvRequest, isStepUp: boolean): Observable<IdvResponse> {
        const request = isStepUp
        ? this.http.post<IdvResponse>('WatchDogIDV/IDScreeningStepUp', idvRequest)
        : this.http.post<IdvResponse>('WatchDogIDV/IDScreening', idvRequest);

        return request.pipe(
            map(r => handleIdvResponseError(r, 'Watchdog-Resend'))
        );
    }
    public skipIdvOtp(idvRequest: IdvRequest): Observable<IdvResponse> {
        return this.http.post<IdvResponse>('WatchDogIDV/IDScreeningSkipOTP', idvRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/IDScreeningSkipOTP'))
        );
    }

    public submitIdvKiq(idvRequest: IdvRequest): Observable<IdvResponse> {
        return this.http.post<IdvResponse>('WatchDogIDV/IDScreeningKIQ', idvRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/IDScreeningKIQ'))
        );
    }
    
    /** does the splitting up of the call here based on the number of signers */
    public submitBusinessIdv(idvRequest: IdvBusinessRequest): Observable<IdvResponse> {

        if (idvRequest.applicants.length < 3) {
            return this.http.post<IdvResponse>('WatchDogIDV/BusinessPrincipalIDScreening', idvRequest).pipe(
                map(r => handleIdvResponseError(r, 'WatchDogIDV/BusinessPrincipalIDScreening'))
            );
        }

        const idvSecondRequest = {...idvRequest, applicants: idvRequest.applicants.slice(2)};
        idvRequest = {...idvRequest, applicants: idvRequest.applicants.slice(0,2)}

        const idvBusinessRequest = this.http.post<IdvResponse>('WatchDogIDV/BusinessPrincipalIDScreening', idvRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/BusinessPrincipalIDScreening'))
        );

        const idvPrincipleRequest = this.http.post<IdvResponse>('WatchDogIDV/PrincipalIDScreening', idvSecondRequest).pipe(
            map(r => handleIdvResponseError(r, 'WatchDogIDV/PrincipalIDScreening'))
        );
        return forkJoin({
            one: idvBusinessRequest,
            two: idvPrincipleRequest
        }).pipe(map((res: {one: IdvResponse, two: IdvResponse}) => {
            const idvResponse = <IdvResponse> {
                statusCode: res.one.statusCode > res.two.statusCode ? res.one.statusCode : res.two.statusCode,
                otpRequest: res.one.otpRequest,
                kiqQuestions: res.one.kiqQuestions,
                clientReferenceId: res.one.clientReferenceId,
                sessionId: res.one.sessionId,
                score: res.one.score,
                exceptionMessage: res.one.exceptionMessage + "\n----\n" + res.two.exceptionMessage
            }

            return idvResponse
        }))
    }
    
    public saveApplicant(applicant: Applicant, personaStatus : ApplicantIDCollectionStatus) {
        return this.http.post<any>('Applicant/SaveApplicant?status=' + personaStatus, applicant);
    }

}

/**
 * Need to handle some idv responses this way since errors in IDV service will be handled in the API as a 200 with some properties set.
 * @param idvResponse Response object from API.
 * @param url Endpoint accessed in the http method above.
 * @throws HttpResponseError if `idvResponse.statusCode >= 400` 
 * @returns parameter `idvResponse` if there is no need to error out.
 */
function handleIdvResponseError(idvResponse: IdvResponse, url: string): IdvResponse {
    if (idvResponse?.statusCode >= 400) {
        const errorMessage = (idvResponse as any).exceptionMessage;
        window.open('/verification-error/' + errorMessage, '_self');
        throw new HttpErrorResponse({
            status: idvResponse.statusCode,
            error: errorMessage,
            url: url,
            statusText: errorMessage
        })
    }

    return idvResponse;
}
