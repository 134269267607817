import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdvComponent } from './idv.component';
import { IdvRoutingModule } from './idv-routing.module';
import { OtpComponent } from './otp/otp.component';
import { KiqComponent } from './kiq/kiq.component';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { ProgressModule } from '../progress/progress.module';
import { LoadingModule } from '../loading/loading.module';
import { FormsModule } from '@angular/forms';
import { IdvLayoutService } from './service/idv-layout.service';
import { IdvLoadingService } from './service/idv-loading.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IdvRoutingModule,
    PageHeaderModule,
    PageFooterModule,
    ProgressModule,
    LoadingModule,
  ],
  declarations: [
    IdvComponent,
    OtpComponent,
    KiqComponent
  ],
  providers: [
    IdvLayoutService,
    IdvLoadingService
  ]
})
export class IdvModule { }
