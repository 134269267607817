
export interface OutOfWalletState {
    applicantOutOfWalletState: Level3State[];
    currentApplicantIndex: number;
    isLoading: boolean;
    isError: boolean;
    error: any;
}

export interface Level3State {
    questions: Level3Question[];
    answers: number[];
    questionsPassed: boolean;
    questionsFailed: boolean;
}

export class Level3Question {
    possibleAnswers: string[];
    questionText: string;
    questionType: string;
    relativeOrder: number;
}

export const defaultApplicantOutOfWalletState = {
    questions: [],
    answers: [],
    questionsPassed: false,
    questionsFailed: false
};

export const defaultApplicantOutOfWallet: Level3State[] = [
    Object.assign({}, defaultApplicantOutOfWalletState),
    Object.assign({}, defaultApplicantOutOfWalletState)
];


export const initialOutOfWalletState = {
    applicantOutOfWalletState: defaultApplicantOutOfWallet,
    currentApplicantIndex: 0,
    isLoading: false,
    isError: false,
    error: null
};
