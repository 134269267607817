<section>
    <div class="container">
        <div>
            <!-- idology -->
            <div class="id-options-container" *ngIf="!sessionState.isSSO && !institution.disableIdentityVerificationScanning">
                <a href="#" (click)="toggleShowIdFill()" class="id-option-fillout">
                    <h3>Fill Out Using Your ID</h3>
                    <p>Quickly and securely scan your Identification to auto-fill some of this application.</p>
                </a>

                <div class="idology-container" *ngIf="!sessionState.isSSO && applicant.showIdFill && !institution.disableIdentityVerificationScanning">
                    <div class="idology-body">
                        <!-- step 1 -->
                        <fieldset class="form-fieldset-id-step1">
                            <legend class="subhead">Step 1: Select Your ID Type</legend>
                            <div class="form-items form-applicant-idtype">
                                <label for="identificationType">Identification Type</label>
                                <select [(ngModel)]="applicantForm.value.identificationType" id="identificationType" class="form-select">
                                    <option *ngFor="let identificationType of institution.identificationTypes" [value]="identificationType.id">{{identificationType.description}}</option>
                                </select>
                            </div>
                        </fieldset>
                        <!-- /step 1 -->
                        <!-- step 2 -->
                        <fieldset class="form-fieldset-id-step2">
                            <legend class="subhead">Step 2: Add ID Images</legend>
                            <!-- images -->
                            <div class="form-applicant-idimages">
                                <!-- front -->
                                <div class="form-items form-applicant-idimage-front">
                                    <label for="frontImage">Front</label>
                                    <!-- upload area -->
                                    <div class="form-idupload-area">
                                        <input type="file" accept="image/*" (change)="imageListener($event, true)" id="frontImage">

                                        <div class="form-idupload-overlay form-idupload-default" [ngClass]="{'form-idupload-success' : applicantForm.value.frontImage != ''}">
                                            <div class="form-idupload-content no-file">
                                                <img src="./assets/images/icon-id-front.png" alt="ID Front" class="icon-upload">
                                                <h5 class="text-upload">Add Image</h5>
                                            </div>
                                            <div class="form-idupload-content yes-file">
                                                <img [attr.src]="getIDSRC(true)" alt="ID Front" class="image-upload">
                                            </div>
                                            <div class="form-idupload-check"></div>

                                        </div>
                                    </div>
                                    <!-- /upload area -->
                                </div>
                                <!-- /front -->

                                <!-- back -->
                                <!-- 7 is passport and doesn't require back image -->
                                <div class="form-items form-applicant-idimage-back" *ngIf="applicantForm.value.identificationType != 7">
                                    <label for="backImage">Back</label>
                                    <!-- upload area -->
                                    <div class="form-idupload-area">
                                        <input type="file" accept="image/*" (change)="imageListener($event, false)" id="backImage">

                                        <div class="form-idupload-overlay form-idupload-default" [ngClass]="{'form-idupload-success' : applicantForm.value.backImage != ''}">
                                            <!-- <div class="form-idupload-overlay form-idupload-default -form-idupload-success"> -->
                                            <div class="form-idupload-content">
                                                <img src="./assets/images/icon-id-back.png" alt="ID Back" class="icon-upload">
                                                <h5 class="text-upload">Add Image</h5>
                                            </div>
                                            <div class="form-idupload-content yes-file">
                                                <img [attr.src]="getIDSRC(false)" alt="ID Back" class="image-upload">
                                            </div>
                                            <div class="form-idupload-check"></div>
                                        </div>
                                    </div>
                                    <!-- /upload area -->
                                </div>
                                <!-- /back -->
                            </div>
                            <!-- /images -->
                        </fieldset>
                        <!-- /step 2 -->
                    </div>

                    <!-- options: submit -->
                    <div class="idology-options">
                        <button [disabled]="applicantForm.value.frontImage == '' || (applicantForm.value.backImage == '' && applicantForm.value.identificationType != 7) || applicantForm.value.identificationType == ''"
                            class="submit-id-button" (click)="submitIdImages(applicantForm.value)">Submit Identification</button>
                    </div>

                    <!-- tips -->
                    <fieldset class="form-fieldset-id-tips">
                        <legend class="subhead">ID Scanning Tips</legend>
                        <ol class="form-fieldset-id-tips-list">
                            <li class="form-fieldset-id-tips-list-item">Place the document on a dark background that contrasts with the document.</li>
                            <li class="form-fieldset-id-tips-list-item">Confirm that all four corners of the document are clearly visible.</li>
                            <li class="form-fieldset-id-tips-list-item">Ensure that the background can be seen on all sides of the image.</li>
                            <li class="form-fieldset-id-tips-list-item">Ensure that the image is in sharp focus and taken in a well lit room with no glare on the image.</li>
                        </ol>
                    </fieldset>
                </div>
            </div>


            <!-- /idology -->

            <form [formGroup]="applicantForm" class="form-applicant-info" autocomplete="off">

                <!-- applicant -->
                <fieldset class="form-fieldset-applicant">
                    <div class="form-input-container">
                        <div class="form-items form-applicant-firstname">
                            <label for="firstName" class="required">First Name</label>

                            <div class="requiredContainer">
                                <input type="text" required formControlName="firstName" id="firstName" class="form-input">
                                <div *ngIf="formErrors.firstName; else firstNameValid" class="indicator-error"></div>
                                <ng-template #firstNameValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-middleinitial">
                            <label for="middleInitial">M.I.</label>
                            <input type="text" formControlName="middleInitial" id="middleInitial" class="form-input">
                        </div>
                        <div class="form-items form-applicant-lastname">
                            <label for="lastName" class="required">Last Name</label>

                            <div class="requiredContainer">
                                <input type="text" required formControlName="lastName" id="lastName" class="form-input">
                                <div *ngIf="formErrors.lastName; else lastNameValid" class="indicator-error"></div>
                                <ng-template #lastNameValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-phonenumber">
                            <label for="phoneNumber" class="required">Phone Number</label>

                            <div class="requiredContainer">
                                <input type="tel" [textMask]="{mask: textMasks.phoneMask}" formControlName="phoneNumber" id="phoneNumber"
                                    class="form-input">
                                <div *ngIf="formErrors.phoneNumber; else phoneNumberValid" class="indicator-error"></div>
                                <ng-template #phoneNumberValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="!sessionState.isSSO" class="form-items form-applicant-phonetype">
                            <label for="phoneType" >Phone Type</label>
                            <select formControlName="phoneType" id="phoneType"
                                class="form-input">
                                <option *ngFor="let name of phoneTypeNames" [value]="phoneTypes[name]">
                                    {{name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-items">
                        <div class="form-input-container">
                            <div class="mobile-margin form-applicant-emailaddress">
                                <label for="emailAddress" class="required">Email Address</label>

                                <div class="requiredContainer">
                                    <input type="emailAddress" required autocomplete="off" formControlName="emailAddress"
                                        autocapitalize="none" id="emailAddress" class="form-input">
                                    <div *ngIf="formErrors && formErrors.emailAddress; else emailAddressValid" class="indicator-error"></div>
                                    <ng-template #emailAddressValid>
                                        <div class="indicator-valid">
                                            <div class="icon icon-check"></div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="mobile-margin form-applicant-verifyemailaddress">
                                <label for="verifyEmailAddress" class="required">Verify Email Address:</label>

                                <div class="requiredContainer">
                                    <input type="emailAddress" (paste)="$event.preventDefault()" required autocomplete="off"
                                        formControlName="verifyEmailAddress" autocapitalize="none" id="verifyEmailAddress" class="form-input">
                                    <div *ngIf="applicantForm.hasError('emailMatches'); else verifyEmailAddressValid" class="indicator-error"></div>
                                    <ng-template #verifyEmailAddressValid>
                                        <div class="indicator-valid">
                                            <div class="icon icon-check"></div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <!-- combined email input alert -->
                        <div class="form-input-container">
                            <div *ngIf="applicantIndex == 0 && applicantForm.get('emailAddress').touched" class="alert alert-plain alert-warning">
                                All status updates and notifications for this application will be sent to this email address.
                                <!-- Changes to this field will change where status updates and notifications will be sent for this application. -->
                            </div>
                        </div>
                        <!-- /combined email input alert -->
                    </div>

                </fieldset>

                <!-- suggested address info -->
                <fieldset class="form-fieldset-addressvalidation" [hidden]="!addressSuggestion">
                    <legend class="subhead">Address Suggestion</legend>
                    <div class="form-validation-container">

                        <!-- entered -->
                        <div class="form-items form-radio form-radio-address-selector form-radio-address-entered" [ngClass]="{'selected-address': !useSuggestedAddress}">
                            <input type="radio" id="addressEntered" [value]="false" [(ngModel)]="useSuggestedAddress"
                                [ngModelOptions]="{standalone: true}" [checked]='!useSuggestedAddress' name="chosenAddress">
                            <label for="addressEntered" class="form-validation-label">
                                <div class="form-validation-address">
                                    <div class="form-validation-subhead"><strong>You Entered:</strong><!--{{useSuggestedAddress}}--></div>
                                    <div class="form-validation-address1">{{address.address1}}</div>
                                    <div class="form-validation-address2">{{address.address2}}</div>
                                    <div class="form-validation-citystatezip">{{address.city}}, {{address.state}} {{address.zipCode}}</div>
                                </div>
                            </label>
                            <button (click)="addressSuggestion=null; useSuggestedAddress=false" class="form-validation-button-edit">Edit Address</button>
                        </div>

                        <!-- suggested -->
                        <div *ngIf="addressSuggestion" class="form-items form-radio form-radio-address-selector form-radio-address-suggested" [ngClass]="{'selected-address': useSuggestedAddress}">
                            <input type="radio" id="addressSuggestion" [value]="true" [(ngModel)]="useSuggestedAddress"
                                [ngModelOptions]="{standalone: true}" name="chosenAddress">
                            <label for="addressSuggestion" class="form-validation-label">
                                <div class="form-validation-address">
                                    <div class="form-validation-subhead"><strong>Suggested Address:</strong></div>
                                    <div class="form-validation-address1">{{addressSuggestion.address1}}</div>
                                    <div class="form-validation-address2">{{addressSuggestion.address2}}</div>
                                    <div class="form-validation-citystatezip">{{addressSuggestion.city}}, {{addressSuggestion.state}} {{addressSuggestion.zipCode}}</div>
                                </div>
                            </label>
                        </div>
                    </div>
                </fieldset>

                <!-- current address info -->
                <fieldset class="form-fieldset-currentaddress" [hidden]="addressSuggestion">
                    <legend class="subhead">Current Address</legend>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-address1">
                            <label for="address1" class="required">Street Address 1
                                <em>(Cannot be a PO Box)</em>
                            </label>

                            <div class="requiredContainer">
                                <input type="text" (ngModelChange)="handleAddressChange($event)" required
                                    formControlName="address1" id="address1" class="form-input">
                                <div *ngIf="formErrors.address1 && applicantForm.get('address1').touched" class="alert alert-danger">
                                    {{ formErrors.address1 }}
                                </div>
                                <div *ngIf="formErrors.address1; else address1Valid" class="indicator-error"></div>
                                <ng-template #address1Valid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-address2">
                            <label for="address2">Street Address 2</label>
                            <input type="text" (ngModelChange)="handleAddressChange($event)" formControlName="address2"
                                id="address2" class="form-input">
                            <div *ngIf="formErrors.address2 && applicantForm.get('address2').touched" class="alert alert-danger">
                                {{ formErrors.address2 }}
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-city">
                            <label for="city" class="required">City</label>

                            <div class="requiredContainer">
                                <input type="text" (ngModelChange)="handleAddressChange($event)" required formControlName="city"
                                    id="city" class="form-input">
                                <div *ngIf="formErrors.city; else cityValid" class="indicator-error"></div>
                                <ng-template #cityValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-state">
                            <label for="state" class="required no-wrap">State</label>

                            <div class="requiredContainer requiredSelect">
                                <select (ngModelChange)="handleAddressChange($event)" formControlName="state"
                                    id="state" class="form-select">
                                    <option style="display:none" value=""></option>
                                    <option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
                                </select>
                                <div *ngIf="formErrors.state; else stateValid" class="indicator-error"></div>
                                <ng-template #stateValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-zipcode">
                            <label for="zipCode" class="required">Zip Code</label>

                            <div class="requiredContainer">
                                <input [readonly]="applicantIndex == 0" (ngModelChange)="handleAddressChange($event)"
                                    required formControlName="zipCode" id="zipcode" class="form-input">
                                <div *ngIf="formErrors.zipCode; else zipCodeValid" class="indicator-error"></div>
                                <ng-template #zipCodeValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                </fieldset>

                <!-- corrections info -->
                <fieldset class="form-fieldset-corrections" *ngIf="applicantIndex == 0 && applicant.isSSO">
                    <legend class="subhead">Corrections</legend>
                    <div class="form-input-container">
                        <div class="form-items form-checkbox">
                            <input [(ngModel)]="personalInfoIncorrect" [ngModelOptions]="{standalone: true}" type="checkbox" id="personalInfoIncorrect">
                            <label for="personalInfoIncorrect">The above information is incorrect or has changed</label>
                        </div>
                    </div>

                    <div class="form-input-container" [hidden]="!personalInfoIncorrect">
                        <div class="mobile-margin form-applicant-emailaddress">
                            <label for="contactPhoneNumber" class="required">Please contact me at the following phone number</label>

                            <div class="requiredContainer">
                                <input type="tel" [textMask]="{mask: textMasks.phoneMask}" formControlName="contactPhoneNumber" id="contactPhoneNumber"
                                    class="form-input">
                            </div>
                        </div>
                    </div>
                </fieldset>

                <!-- mailing address info -->
                <fieldset class="form-fieldset-mailingaddress" [hidden]="applicantIndex == 0 && applicant.isSSO">
                    <legend class="subhead">Mailing Address</legend>
                    <div class="form-input-container">
                        <div class="form-items form-checkbox">
                            <input [(ngModel)]="mailingAddressSameAsCurrentAddress" [ngModelOptions]="{standalone: true}" type="checkbox" id="mailingAddressSameAsCurrentAddress"
                                (click)="toggleMailingAddressSameAsCurrentAddress($event)">
                            <label for="mailingAddressSameAsCurrentAddress">Same as Current Address</label>
                        </div>
                    </div>

                    <div class="form-input-container" [hidden]="mailingAddressSameAsCurrentAddress">
                        <div class="form-items form-applicant-mailingaddress1">
                            <label for="mailingAddress1">Mailing Address 1</label>
                            <input type="text" formControlName="mailingAddress1" id="mailingAddress1" class="form-input">
                            <div *ngIf="formErrors.mailingAddress1 && applicantForm.get('mailingAddress1').touched" class="alert alert-danger">
                                {{ formErrors.mailingAddress1 }}
                            </div>
                        </div>
                        <div class="form-items form-applicant-mailingaddress2">
                            <label for="mailingAddress2">Mailing Address 2</label>
                            <input type="text" formControlName="mailingAddress2" id="mailingAddress2" class="form-input">
                            <div *ngIf="formErrors.mailingAddress2 && applicantForm.get('mailingAddress2').touched" class="alert alert-danger">
                                {{ formErrors.mailingAddress2 }}
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container" [hidden]="mailingAddressSameAsCurrentAddress">
                        <div class="form-items form-applicant-mailingcity">
                            <label for="mailingCity">Mailing City</label>
                            <input type="text" formControlName="mailingCity" id="mailingCity" class="form-input">
                        </div>
                        <div class="form-items form-applicant-mailingstate">
                            <label for="mailingState">Mailing State</label>
                            <select formControlName="mailingState" id="mailingState" class="form-select">
                                <option style="display:none" value=""></option>
                                <option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
                            </select>
                        </div>
                        <div class="form-items form-applicant-zipcode">
                            <label for="mailingZipCode">Mailing Zip Code</label>
                            <input type="number" formControlName="mailingZipCode" id="mailingZipCode" class="form-input">
                        </div>
                    </div>

                </fieldset>

                <!-- identity info -->
                <fieldset class="form-fieldset-identity" *ngIf="showIdentityFields">
                    <legend class="subhead">Identity Information</legend>
                    <div class="form-input-container" *ngIf="citizenTypeRequired">
                        <div class="form-items form-applicant-citizen">
                            <label for="citizenType" class="required">Are you a U.S. citizen or Resident Alien</label>

                            <div class="requiredContainer requiredSelect">
                                <select formControlName="citizenType" id="citizenType" class="form-select">
                                    <option style="display:none" value=""></option>
                                    <option [value]="0">I am a U.S. Citizen living in the U.S.</option>
                                    <option [value]="2.1">I am a Resident Alien living in the U.S. with a Green Card</option>
                                    <option [value]="2.2">I am a Resident Alien living in the U.S. who passes the Substantial Presence Test</option>
                                    <option [value]="8">I am a Non-Resident Alien living in the U.S.</option>
                                </select>

                                <div *ngIf="formErrors.citizenType && applicantForm.get('citizenType').touched" class="alert alert-danger">
                                    {{ formErrors.citizenType }}
                                </div>

                                <div *ngIf="formErrors.citizenType; else citizenTypeValid" class="indicator-error"></div>
                                <ng-template #citizenTypeValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-taxidentificationnumber" *ngIf="applicantHasSSN || !citizenTypeRequired">
                            <label for="taxIdentificationNumber" class="required">Social Security Number</label>

                            <div class="requiredContainer">
                                <input type="text" required formControlName="taxIdentificationNumber"
                                    id="taxIdentificationNumber" [textMask]="{mask: textMasks.ssnMask, guide: true}" pattern="\d{3}-\d{2}-\d{4}" class="form-input">
                                <div *ngIf="formErrors.taxIdentificationNumber; else taxIdentificationNumberValid" class="indicator-error"></div>
                                <ng-template #taxIdentificationNumberValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                        <div class="form-items form-applicant-taxidentificationnumber" *ngIf="applicantHasGreenCard">
                            <label for="greenCardNumber" class="required">Green Card Number</label>

                            <div class="requiredContainer">
                                <input type="text" formControlName="greenCardNumber" id="greenCardNumber" class="form-input">
                            </div>
                        </div>

                        <div class="form-items form-applicant-dateofbirth">
                            <label for="dateOfBirth" class="required">Date of Birth</label>
                            <div class="requiredContainer requiredDate">

                                <input type="text" name="dateOfBirth" required class="form-control form-input form-input-date"
                                    placeholder="mm/dd/yyyy" id="dateOfBirth" [textMask]="{mask: textMasks.dateMask}" formControlName="dateOfBirth">

                                <div *ngIf="formErrors.dateOfBirth && applicantForm.get('dateOfBirth').touched" class="alert alert-danger">
                                    {{ formErrors.dateOfBirth }}
                                </div>

                                <div *ngIf="formErrors.dateOfBirth; else dateOfBirthValid" class="indicator-error"></div>
                                <ng-template #dateOfBirthValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-identificationtype">
                            <label for="identificationType" class="required">ID Type</label>

                            <div class="requiredContainer requiredSelect">
                                <select formControlName="identificationType" id="identificationType" class="form-select">
                                    <option *ngFor="let identificationType of institution.identificationTypes" [value]="identificationType.id">{{identificationType.description}}</option>
                                </select>
                                <div *ngIf="formErrors.identificationType; else identificationTypeValid" class="indicator-error"></div>
                                <ng-template #identificationTypeValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-identificationnumber">
                            <label for="identificationNumber" class="required">ID Number</label>

                            <div class="requiredContainer">
                                <input oninput="this.value = this.value.toUpperCase()" type="text" formControlName="identificationNumber" id="identificationNumber"
                                    class="form-input">
                                <div *ngIf="formErrors.identificationNumber && applicantForm.get('identificationNumber').touched" class="alert alert-danger">
                                    {{ formErrors.identificationNumber }}
                                </div>
                                <div *ngIf="formErrors.identificationNumber; else identificationNumberValid" class="indicator-error"></div>
                                <ng-template #identificationNumberValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-identificationstate">
                            <label for="identificationState" class="required no-wrap">State Issued</label>

                            <div class="requiredContainer requiredSelect">
                                <select formControlName="identificationState" id="identificationState" class="form-select">
                                    <option style="display:none" value=""></option>
                                    <option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
                                </select>
                                <div *ngIf="formErrors.identificationState; else identificationStateValid" class="indicator-error"></div>
                                <ng-template #identificationStateValid>
                                    <div class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-identificationissuedate">
                            <label for="identificationIssueDate" [ngClass]="{'required' : issueDateRequired}">Issue Date</label>

                            <div class="requiredContainer requiredDate">
                                <input type="text" name="identificationIssueDate" class="form-control form-input form-input-date"
                                    placeholder="mm/dd/yyyy" formControlName="identificationIssueDate" [textMask]="{mask: textMasks.dateMask}" id="identificationIssueDate">
                                <div *ngIf="formErrors.identificationIssueDate && applicantForm.get('identificationIssueDate').touched" class="alert alert-danger">
                                    {{ formErrors.identificationIssueDate }}
                                </div>

                                <div *ngIf="formErrors.identificationIssueDate; else identificationIssueDateValid" class="indicator-error"></div>
                                <ng-template #identificationIssueDateValid>
                                    <div *ngIf="issueDateRequired" class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-identificationexpirationdate">
                            <label for="identificationExpirationDate" [ngClass]="{'required' : expirationDateRequired}">Expiration Date</label>

                            <div class="requiredContainer requiredDate">

                                <input type="text" name="identificationExpirationDate" class="form-control form-input form-input-date"
                                    placeholder="mm/dd/yyyy" formControlName="identificationExpirationDate" [textMask]="{mask: textMasks.dateMask}" id="identificationExpirationDate">
                                <div *ngIf="formErrors.identificationExpirationDate && applicantForm.get('identificationExpirationDate').touched" class="alert alert-danger">
                                    {{ formErrors.identificationExpirationDate }}
                                </div>

                                <div *ngIf="formErrors.identificationExpirationDate; else identificationExpirationDateValid" class="indicator-error"></div>
                                <ng-template #identificationExpirationDateValid>
                                    <div *ngIf="expirationDateRequired" class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                </fieldset>


                <!-- employer info -->
                <fieldset class="form-fieldset-employer" *ngIf="applicantIndex != 0 || !sessionState.isSSO">
                    <legend class="subhead">Employer Information</legend>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-employername">
                            <label for="employerName" [ngClass]="{'required' : employerNameRequired}">Employer Name</label>

                            <div class="requiredContainer">
                                <input type="text" formControlName="employerName" id="employerName" class="form-input">
                                <div *ngIf="formErrors.employerName && applicantForm.get('employerName').touched" class="alert alert-danger">
                                    {{ formErrors.employerName }}
                                </div>
                                <div *ngIf="formErrors.employerName; else employerNameValid" class="indicator-error"></div>
                                <ng-template #employerNameValid>
                                    <div *ngIf="employerNameRequired" class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-items form-applicant-employerphone">
                            <label for="employerPhone" [ngClass]="{'required' : employerPhoneRequired}">Employer Phone</label>

                            <div class="requiredContainer">
                                <input type="tel" [textMask]="{mask: textMasks.phoneMask}" formControlName="employerPhone" id="employerPhone"
                                    class="form-input">
                                <div *ngIf="formErrors.employerPhone; else employerPhoneValid" class="indicator-error"></div>
                                <ng-template #employerPhoneValid>
                                    <div *ngIf="employerPhoneRequired" class="indicator-valid">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="form-input-container">
                        <div class="form-items form-applicant-employeroccupationtitle">
                            <label for="employerOccupationTitle" [ngClass]="{'required' : employerOccupationRequired}">Occupation/Title</label>

                            <div class="requiredContainer">
                                <input type="text" formControlName="employerOccupationTitle" id="employerOccupationTitle"
                                    class="form-input">
                                <div *ngIf="formErrors.employerOccupationTitle && applicantForm.get('employerOccupationTitle').touched" class="alert alert-danger">
                                    {{ formErrors.employerOccupationTitle }}
                                </div>
                                <div *ngIf="formErrors.employerOccupationTitle; else employerOccupationTitleValid" class="indicator-error"></div>
                                <ng-template #employerOccupationTitleValid>
                                    <div class="indicator-valid" *ngIf="employerOccupationRequired">
                                        <div class="icon icon-check"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                </fieldset>

                <!-- additional questions -->
                <fieldset class="form-fieldset-additionalquestions" 
                *ngIf="
                    applicant.applicationQuestions.length 
                    && (!sessionState.isSSO || applicantIndex != 0)
                    && institution.isIdentitySolutionCip">
                    <legend class="subead">Additional Questions</legend>
                    <div class="form-input-container" formArrayName="applicationQuestions">
                        <div class="form-items form-applicant-additionalquestion" [formGroupName]="i" *ngFor="let question of applicant.applicationQuestions; let i = index;">
                            <!-- non-security questions -->
                            <ng-container *ngIf="!question.useTranslate">
                                <label for="applicationQuestions_{{question.questionId}}" class="required">{{question.questionText}}</label>

                                <div class="requiredContainer">
                                    <input type="text" formControlName="answerText" id="applicationQuestions_{{question.questionId}}" class="form-input">
                                    <div *ngIf="formErrors.applicationQuestions[i] && applicantForm.get('applicationQuestions').controls[i].touched" class="alert alert-danger">
                                        {{ formErrors.applicationQuestions[i] }}
                                    </div>
                                    <div *ngIf="formErrors.applicationQuestions[i]" class="indicator-error"></div>
                                    <div *ngIf="!formErrors.applicationQuestions[i] 
                                        && applicantForm.get('applicationQuestions').controls[i].valid
                                        && (applicantForm.get('applicationQuestions').controls[i].touched 
                                        || applicantForm.get('applicationQuestions').controls[i].dirty)" class="indicator-valid">   
                                        <div class="icon icon-check"></div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- security question -->
                            <ng-container *ngIf="question.useTranslate">
                                <label for="securityQuestionsQuestion_{{question.questionId}}" class="required">Select a Security Question and Answer</label>
                                <!-- question -->
                                <div class="requiredContainer requiredSelect">
                                    <select type="text" formControlName="securityQuestion" id="securityQuestionsQuestion_{{question.questionId}}" class="form-select form-size-sm form-applicant-securityquestion">
                                        <option [ngValue]="null" disabled selected>SELECT A SECURITY QUESTION</option>
                                        <option *ngFor="let securityQuestion of institution.securityQuestions" [ngValue]="securityQuestion">{{securityQuestion.questionText}}</option>
                                    </select>

                                    <div *ngIf="formErrors.applicationQuestions[i]" class="indicator-error"></div>
                                    <div *ngIf="!formErrors.applicationQuestions[i]  
                                    && applicantForm.get('applicationQuestions').controls[i].valid
                                    && (applicantForm.get('applicationQuestions').controls[i].touched 
                                    || applicantForm.get('applicationQuestions').controls[i].dirty)" class="indicator-valid"> 
                                        <div class="icon icon-check"></div>
                                    </div>
                                </div>
                                <!-- answer -->
                                <div class="requiredContainer">
                                    <input type="text" formControlName="answerText" id="applicationQuestions_{{question.questionId}}" class="form-input">
                                    <div *ngIf="formErrors.applicationQuestions[i] && applicantForm.get('applicationQuestions').controls[i].touched" class="alert alert-danger">
                                        Both a question and an answer must be chosen.
                                    </div>

                                    <div *ngIf="formErrors.applicationQuestions[i]" class="indicator-error"></div>
                                    <div *ngIf="!formErrors.applicationQuestions[i]  
                                    && applicantForm.get('applicationQuestions').controls[i].valid
                                    && (applicantForm.get('applicationQuestions').controls[i].touched 
                                    || applicantForm.get('applicationQuestions').controls[i].dirty)" class="indicator-valid"> 
                                        <div class="icon icon-check"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>

    </div>
</section>
