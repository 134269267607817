import { FormControl, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { Applicant } from 'src/app/state-management/state/applicants.state';
import { Product } from 'src/app/state-management/state/products.state';

export class FormValidators {
    static validateIdentificationIssueDate(c: FormControl) {
        const testDate = new Date(c.value);
        const now = new Date();

        if(c.value.length && testDate) {
            return !FormValidators.isDateTooOld(100, testDate.getFullYear()) && testDate < now ? null : {
            invalidDate: {
                valid: false
            }
        };
        }
    }

    static isDateTooOld(maxYearsAgo: number, testYear: number) {
        const year = new Date().getFullYear();

        return (year - testYear > maxYearsAgo) ? true : false;
    }

    static emailMatches(firstKey: string, confirmKey: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const first = group.controls[firstKey];
            const confirm = group.controls[confirmKey];

            return (first.value.toLowerCase() === confirm.value.toLowerCase()) ? null : {
                emailMatches: {
                    valid: false
                }
            };
        };
    }

    static validateGreenCardNumber(applicant: Applicant) {
        return (control: AbstractControl) => {
            // TODO: don't use this, it doesn't work, but it is a start
            return applicant.citizenType === '2.1' && control.value === '' ? {
                required: {
                    valid: false
                }
             } : null;
        };
    }

    static validateProductAgeRangeCheck(products: Product[], applicantIndex: number) :ValidatorFn {
        if (applicantIndex !== 0) {
            return (control: AbstractControl) => {
                return null;
            };
        }

        return (control: AbstractControl) => {
            const customerAge = Math.abs((new Date((new Date()).getTime() - (new Date(control.value)).getTime())).getUTCFullYear() - 1970);

            const isTooYoung = !products.every((product: Product) => {
                // there is no minimum age so they are good for this one
                if (product.minimumAge === null || product.minimumAge === 0) {
                    return true;
                } else {
                    // get the customer's age
                    if (customerAge < product.minimumAge) {
                        // they are too young
                        return false;
                    } else {
                        // they are old enough
                        return true;
                    }
                }
            });

            return isTooYoung ? { productAgeCheck: { value: control.value, valid: false } } : null;
        };
    }

    // the rest of these are control specific and don't require extra params

    static validateEmail(c: FormControl) {
        // Got RegExp from https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
        // tslint:disable-next-line:max-line-length
        const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

        return EMAIL_REGEXP.test(c.value) ? null : {
            validateEmail: {
                valid: false
            }
        };
    }

    static validatePhoneNumber(c: FormControl) {
        if (c.value === null || c.value === undefined || c.value === '' || !/\d/g.test(c.value) || c.value.match(/\d/g).length !== 10) {
            // is not valid phone number
            return {
                validatePhoneNumber: {
                    valid: false
                }
            };
        } else {
            return null;
        }
    }

    static validateNotPOBox(c: FormControl) {
        const address = c.value;
        // tslint:disable-next-line:max-line-length
        const postOfficeBoxMatch = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

        if (address.match(postOfficeBoxMatch)) {
            // is POBox
            return {
                validateNotPOBox: {
                    valid: false
                }
            };
        } else {
            return null;
        }
    }

    static validateDateOfBirth(c: FormControl) {
        const testDate = new Date(c.value);
        const now = new Date();

        // are you less than 150 years old and not born in the future?
        if (!FormValidators.isDateTooOld(150, testDate.getFullYear()) && testDate < now) {
            // successful check
            return null;
        } else {
            // they are too old or not yet born
            return {
                invalidDate: {
                    valid: false
                }
            };
        }
    }

    static validateIdentificationExpirationDate(c: FormControl) {
        const testDate = new Date(c.value);
        const now = new Date();

        // check valid format of date first
        if (c.value.length && isNaN(testDate.valueOf())) {
            return (!isNaN(testDate.valueOf())) ? null : {
                invalidDate: {
                    valid: false
                }
            };
        }

        // then check expiration if format is valid
        if(c.value.length ){
             return (testDate > now) ? null : {
            expiredDate: {
                valid: false
            }
        };
        }

    }
}
