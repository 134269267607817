<div class="product-outer-container" [class.product-selected]="isProductSelected">
    <!-- icons container -->
    <ul class="product-icons-container">
        <li class="product-icon-item product-icon-promo" *ngIf="product.hasPromoCode">
            <!-- current version -->
            <span class="icon icon-certificate"></span>

            <!-- RESKIN version-->
            <div class="product-icon-promo-badge">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="product-promo-icon"><path class="product-promo-icon-star" d="M9.71 12L6 9.491 2.29 12l1.119-4.495L0 4.582l4.4-.283L6-.001l1.6 4.3 4.4.283-3.41 2.923z" fill="#fff"/></svg>Promo
            </div>
        </li>
    </ul>
    <!-- /icons container -->

    <!-- inner content -->
    <div class="product-inner-container">
        <!-- image style -->
        <img src="{{product.descriptionImageURI}}" class="product-type-image" *ngIf="product.isImageProduct">
        <!-- /image style -->

        <!-- text style -->
        <div class="product-item-details" *ngIf="!product.isImageProduct" #productDetails>
            <div class="product-item-header">
                <h5>{{ product.productName }}</h5>
                <h6>{{ product.applicationCode }}</h6>
            </div>
            <div class="product-item-description" [innerHTML]="product.description"></div>
        </div>
        <!-- /text style -->
        
        <!-- buttons -->
        <ul class="product-buttons">
            <li *ngIf="isMoreInfoNeeded" class="product-button">
                <a *ngIf="!isProductSelected" (click)="showMoreInfo($event, product)" class="product-button-link">More Info</a>
                <a *ngIf="isProductSelected" (click)="showMoreInfoRemove($event, product)" class="product-button-link">More Info</a>
            </li>
            <li class="product-button">
                <a *ngIf="!isProductSelected" (click)="addProduct()" class="product-button-link">Add Product</a>
                <a *ngIf="isProductSelected" (click)="onProductRemove()" class="product-button-link">Remove Product</a>
            </li>

        </ul>
        <!-- /buttons -->

    </div>

</div>
