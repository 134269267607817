import { Component, Input } from '@angular/core';
import { QuestionGroup } from '../../state-management/state/selected-products.state';

@Component({
    moduleId: module.id,
    selector: 'product-questions',
    templateUrl: './product-questions.component.html',
    styleUrls: ['product-questions.component.scss']
})
export class ProductQuestionsComponent {
    @Input() questionGroups: QuestionGroup[];

    constructor() { }
}