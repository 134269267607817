import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product, Bundle } from '../../state-management/state/products.state';
import { productsToBundles } from '../bundel-util';

@Component({
    moduleId: module.id,
    selector: 'products-selector',
    templateUrl: './products-selector.component.html',
    styleUrls: ['products-selector.component.scss']
})
export class ProductsSelectorComponent implements OnInit {
    @Input() products: Product[];
    @Input() instantAddCode: string;
    @Output() addProduct: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() removeProduct: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() addBundle: EventEmitter<Bundle> = new EventEmitter<Bundle>();
    @Output() removeBundle: EventEmitter<Bundle> = new EventEmitter<Bundle>();

    bundles: Bundle[];
    private _preSelectedProducts: Product[] = [];

    ngOnInit() {
        this.bundles = productsToBundles(this.products);

        if (this.instantAddCode !== '' && this.preSelectedProducts.length) {
            this.preSelectedProducts.forEach(preSelectedProduct => {
                preSelectedProduct.autoAdded = true;
                setTimeout(() => this.onProductAdded(preSelectedProduct));
            });
        }
    }

    get preSelectedProducts() {
        const codes = this.instantAddCode.split(',');
        if (this._preSelectedProducts.length === 0 && this.instantAddCode !== '') {
            this._preSelectedProducts = this.products.filter(
                product =>
                    this.instantAddCode !== null &&
                    product.instantAddKey !== null &&
                    codes.some((code) =>  product.instantAddKey.toLocaleLowerCase() === code.toLocaleLowerCase())
            );
        }
        return this._preSelectedProducts;
    }

    onBundleAdded(bundle: Bundle) {
        this.addBundle.emit(bundle);
    }
    onBundleRemove(bundle: Bundle) {
        this.removeBundle.emit(bundle);
    }

    onProductAdded(product: Product) {
        this.addProduct.emit(product);
    }
    onProductRemove(product: Product) {
        this.removeProduct.emit(product);
    }
}
