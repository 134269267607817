import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ChallengeDepositActionTypes, TryChallengeFailureAction } from '../actions/challenge-deposit.actions';
import { ChallengeDepositService } from '../../services/challenge-deposit.service';
import { of } from 'rxjs';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ChallengeDepositEffects {

    @Effect()
    appGetInfo$ = this.action$.pipe(
        ofType(ChallengeDepositActionTypes.TRY_CHALLANGE),
        switchMap((action: NuFundAction) => {
            return this.service.tryChallengeDeposit(action.payload).pipe(
                map((response: any) => {
                    // The InstitutionService successfully completed the Http request
                    return { type: ChallengeDepositActionTypes.TRY_CHALLANGE_SUCCESS };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new TryChallengeFailureAction({ error: response }));
                })
            )
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: ChallengeDepositService) { }
}
