import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { SessionState } from '../../state-management/state/session.state';
import { SessionActionTypes } from '../../state-management/actions/session.actions';
import { InstitutionState } from '../../state-management/state/institution.state';

import { Applicant, ApplicantsState, initialApplicantsState } from '../../state-management/state/applicants.state';
import { ApplicantsActionsTypes } from '../../state-management/actions/applicants.actions';

import { AuthenticationHeaderManager } from '../../authentication-header-manager';

import { ProgressActionTypes } from '../../state-management/actions/progress.actions';
import { ProgressState, Step } from '../../state-management/state/progress.state';

import { Util } from 'src/app/common/util';
import { TextMasks } from 'src/app/business-information/business-validators';
import { Settings } from 'src/app/settings';
import { CombinedState } from 'src/app/state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { isPromoCodeValid } from 'src/app/common/functions';

@Component({
    moduleId: module.id,
    templateUrl: './new-user.component.html',
    styleUrls: ['new-user.component.scss']
})
export class NewUserComponent implements OnInit , OnDestroy {
    public isLoading: boolean;
    public unsubscribe: Subject<void> = new Subject<void>();
    public welcomeDisclosureId = '';
    public welcomeDisclosureDescription = '';
    public enablePersonaIdScanning: boolean;
    esignValid = false;
    requireDemonstratedEsignConsent = false;
    requireEsignConsentFromSingleSignOn = false;
    useNewUiForBank = false;

    textMasks: TextMasks = new TextMasks();

    public applicantsState: ApplicantsState = JSON.parse(JSON.stringify(initialApplicantsState));
    public newUser: Applicant = this.applicantsState.applicants[0];
    public promoCode = '';

    private readonly blankRouteMask = 'none';
    instantAddCode = '';
    skipProductSelection = false;
    isSSO = false;

    constructor(
        private applicantsStore: Store<ApplicantsState>,
        private store: Store<CombinedState>,
        private router: Router,
        private route: ActivatedRoute
    ) {
        
    }
    ngOnInit(){
        this.newUser.applicationQuestions = [];

        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SignUp });
        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.isLoading = info.isLoading;
            this.isSSO = info.isSSO;

            if (info.confirmationCode && info.confirmationCode.length) {
                this.applicantsStore.dispatch({ type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT, payload: info });
                if (this.enablePersonaIdScanning){    
                    this.router.navigate(['personaconsent']);
                }
                else{
                    this.router.navigate(['products']);
                }
            }
        });

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            if (params.firstName !== undefined && params.firstName !== this.blankRouteMask) {
                this.newUser.firstName = params.firstName;
            }

            if (params.lastName !== undefined && params.lastName !== this.blankRouteMask) {
                this.newUser.lastName = params.lastName;
            }

            if (params.emailAddress !== undefined && params.emailAddress !== this.blankRouteMask) {
                this.newUser.emailAddress = params.emailAddress;
            }

            if (params.phoneNumber !== undefined && params.phoneNumber !== this.blankRouteMask) {
                this.newUser.phoneNumber = params.phoneNumber;
            }

            if (params.zipCode !== undefined && params.zipCode !== this.blankRouteMask) {
                this.newUser.zipCode = params.zipCode;
            }

            if (params.instantAddCode !== undefined && params.instantAddCode !== this.blankRouteMask) {
                if (params.instantAddCode !== undefined) {
                    this.instantAddCode = params.instantAddCode;
                }

                if (params.skipProductSelection) {
                    this.skipProductSelection = params.skipProductSelection !== '';
                }

                this.store.dispatch({
                    type: SessionActionTypes.UPDATE_INSTANT_ADD_CODE,
                    payload: {
                        instantAddCode: this.instantAddCode,
                        skipProductSelection: this.skipProductSelection,
                    }
                })
            }
        });

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.isLoading = info.isLoading;
            this.welcomeDisclosureId = info.institution.welcomeDisclosureId;
            this.welcomeDisclosureDescription = info.institution.welcomeDisclosureDescription;
            this.requireDemonstratedEsignConsent = info.institution.requireDemonstratedEsignConsent;
            this.requireEsignConsentFromSingleSignOn = info.institution.requireEsignConsentFromSingleSignOn;
            this.useNewUiForBank = info.institution.useNewUiForBank;
            this.enablePersonaIdScanning = info.institution.enablePersonaIdScanning;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public signUpNewUser(): void {
        if (this.promoCode !== '') {
            this.applicantsState.promotionalCodes.push(this.promoCode);
        }

        this.store.dispatch({
            type: SessionActionTypes.ESTABLISH_SESSION,
            payload: Object.assign(
                {
                    instantAddCode: this.instantAddCode,
                    skipProductSelection: this.skipProductSelection
                },
                this.applicantsState
            )
        });         
    }

    public getEsignLink(): string {
        return Settings.getAPIBaseURL() + 'Disclosures/' + encodeURIComponent(this.welcomeDisclosureId) + '/' +
            Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    }

    public getEsignDescription(): string {
        return (this.welcomeDisclosureDescription);
    }

    public isEsignRequired(): boolean {
        return this.welcomeDisclosureId.length > 0 &&
        !this.isSSO ||
        this.isSSO &&
        this.requireEsignConsentFromSingleSignOn;
    }

    public validateDateOfBirth(): boolean {
        if(this.enablePersonaIdScanning){
            return true;
        }
        
        const testDate = new Date(this.newUser.dateOfBirth);
        const now = new Date();

        // are you less than 150 years old and not born in the future?
        if (!this.isDateTooOld(150, testDate.getFullYear()) && testDate < now) {
            // successful check
            return true;
        } else {
              // they are too old or not yet born
              return false;
        }
    }

    isDateTooOld(maxYearsAgo: number, testYear: number) {
        const year = new Date().getFullYear();

        return (year - testYear > maxYearsAgo) ? true : false;
    }

    public isPromoCodeValid():boolean {
        return isPromoCodeValid(this.promoCode);
    }
}
