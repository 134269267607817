import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { ProductsState, initialProductsState } from '../state/products.state';
import { ProductsActionTypes } from '../actions/products.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { productsToBundles } from '../../products/bundel-util';
import { NuFundAction } from 'src/app/common/nufund-action';

export const productsStoreReducer: ActionReducer<ProductsState> = reducer;
export function reducer(state = initialProductsState, action: NuFundAction) {

    switch (action.type) {
        case ProductsActionTypes.GET_PRODUCTS: {
            return Object.assign({}, state, { isLoading: true });
        }

        case ProductsActionTypes.GET_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.payload.products,
                bundles: productsToBundles(action.payload.products),
                isLoading: false,
                isError: false,
                productsLoaded: true
            });
        }

        case ProductsActionTypes.GET_PRODUCTS_FAILURE: {
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS: {
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, { isLoading: true, isError: false, error: null, productsSelected: true });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FAILURE: {
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error, productsSelected: false });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES: {
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES_SUCCESS: {
            return Object.assign({}, state, { isLoading: false, isError: false, error: null, featuresSelected: true });
        }

        case ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES_FAILURE: {
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES: {
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES_SUCCESS: {
            return Object.assign({}, state, { isLoading: false, isError: false, error: null, disclosuresSubmitted: true });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES_FAILURE: {
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING: {
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING_SUCCESS: {
            return Object.assign({}, state, { isLoading: false, isError: false, error: null, fundingSubmitted: true });
        }

        case ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING_FAILURE: {
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS_SUCCESS: {
            return Object.assign({}, state, { isLoading: false, isError: false, error: null, filesSubmitted: true });
        }

        case ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS_FAILURE: {            
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }


        case SessionActionTypes.LOG_OUT: {
            const newState = Object.assign({}, initialProductsState);
            newState.products = [];
            newState.disclosures = [];
            newState.selectedFundingMethods = [];

            return newState;
        }

        default: {
            return state;
        }
    }
}
