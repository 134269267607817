import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FeaturesRoutingModule } from './features-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { ProgressModule } from '../progress/progress.module';
import { FeatureItemModule } from './feature-item/feature-item.module';
import { OrderByModule } from '../common/order-by-pipe.module';
import { TextMaskModule } from 'angular2-text-mask';

import { FeaturesComponent } from './features.component';
import { ProductQuestionsComponent } from './product-questions/product-questions.component';
import { ProductQuestionElementComponent } from './product-questions/product-question-element/product-question-element.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        FeaturesComponent,
        ProductQuestionsComponent,
        ProductQuestionElementComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        FeaturesRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        FeatureItemModule,
        OrderByModule,
        TextMaskModule,
        LoadingModule
    ]
})
export class FeaturesModule { }
