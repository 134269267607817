import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonaIdCollectionComponent } from './persona-id-collection.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';

const routes: Routes = [
  { 
    path: 'personaIdCollection',
    component: PersonaIdCollectionComponent,
    canActivate: [
      CanActivateSessionGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonaIdCollectionRoutingModule { }
