import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { idvFindRouteToNavigateTo, truncateInstitutionName } from 'src/app/common/functions';
import { IdvRequest, KiqAnswer, KiqQuestion } from 'src/app/models/idv.model';
import { ApplicantsActionsTypes } from 'src/app/state-management/actions/applicants.actions';
import { ApplicantsState } from 'src/app/state-management/state/applicants.state';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { InstitutionState } from 'src/app/state-management/state/institution.state';
import { IdvLayoutService } from '../service/idv-layout.service';
import { IdvLoadingService } from '../service/idv-loading.service';

@Component({
  selector: 'app-kiq',
  templateUrl: './kiq.component.html',
  styleUrls: ['./kiq.component.scss']
})
/**
 * In this component
 * @one accept a precondition of having the questions
 * @two allow the user to submit the questions
 * @three if the answers are incorrect, redirect them to an error page. 
 */
export class KiqComponent implements OnInit, OnDestroy {

  private _isFirstLoad: boolean = true;
  /** Manages subscriptions in the component to prevent observable based memory leaks */
  private readonly _unsubscribe$ = new Subject();
  /** Shows if the component is waiting on data. */
  public isLoading: boolean = false;
  /** The institution state that comes out of the redux store */
  private _institutionState: InstitutionState = null;
  /** The applicant state that comes out of the redux store */
  private _applicantsState: ApplicantsState = null;
  /** A formatted answers array in the form the API is expecting. The index is the question index, the number value is the answer value. */
  private answersArray: { answer: number, isPristine: boolean }[] = [];

  /** The kiqQuestions on the applicants state. For use in the template. */
  public get questions(): KiqQuestion[] { return this._applicantsState.idvResponse.kiqQuestions ?? null; }
  constructor(
    private router: Router,
    private store: Store<CombinedState>,
    private idvLayoutService: IdvLayoutService,
    private idvLoadingService: IdvLoadingService,
  ) {

    this.idvLayoutService.setDisabled(true);

    this.idvLoadingService.isLoading$
    .pipe(takeUntil(this._unsubscribe$))
    .subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });

    // subscribe to the applicants store. this is where the events of this page emit to, so routing logic happens here.
    this.store.select('applicantsStoreReducer')
    .pipe(takeUntil(this._unsubscribe$))
    .subscribe((applicantsState: ApplicantsState) => {
      this._applicantsState = applicantsState;

      // gate for initialization
      if (this.questions.length) {
        if (!this.answersArray.length) {
          this.answersArray = new Array(this.questions.length);
          this.answersArray.fill({answer: 0, isPristine: true});
        }

        // finish loading after the page has been properly initialized
        this.idvLoadingService.finishedLoading();

        return;
      }

      //handle positive response logic.
      if (applicantsState.idvResponse.exceptionMessage === 'ACCEPT') {
        // if we are currently navigating, then we have already made a decision. abort.
        if (this.router.getCurrentNavigation()) {
          return;
        }

        const routeToNavigateTo = idvFindRouteToNavigateTo(applicantsState);

        if (routeToNavigateTo.includes("personal-information")) {
          this.store.dispatch({
            type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT,
            payload: 1
          });

        }
        this.router.navigate(routeToNavigateTo);

        return;

      }
    })
    
    // subscription that gets information about the bank details
    this.store.select('institutionStoreReducer')
    .pipe(takeUntil(this._unsubscribe$))
    .subscribe((institutionState: InstitutionState) => {
      this._institutionState = institutionState;
    });

    // listens to the idv.component's button click.
    this.idvLayoutService.buttonClick$
    .pipe(takeUntil(this._unsubscribe$))
    .subscribe(() => {
      if (this._isFirstLoad) {
        this._isFirstLoad = false;
        return;
      }

      // if the component has instantiated...
      if(this.questions) {
        // if all the questions have been answered...
        if (!this.answersArray.map(a => a.isPristine).includes(true)) {
          this.idvLoadingService.startLoading();
          // submit the request
          this.store.dispatch({
            type: ApplicantsActionsTypes.SUBMIT_KIQ_QUESTIONS,
            payload: <IdvRequest>{
              applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
              clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
              idvSessionId: this._applicantsState.idvResponse.sessionId,
              kiqAnswers: this.answersArray.map(a => a.answer),
              bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
              oneTimePasscode: null,
            }
          });
        }
      }
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
  /**
   * A method that gets the object keys for the answer objects associated with certain questions.
   * Used by the template for its `ngFor` for answers.
   * @param questionText the identifier to search through the array 
   * @returns an array of numbers which the template uses as primary keys.
   */
  public getAnswerKeysByQuestionText(questionText: string): string[] {
    return  Object.keys(
      this.questions.filter(question => question.questionText === questionText)[0].questionChoices
    );
  } 

  /**
   * A lookup function which takes in the lookup for the questions array and the answers object and returns the answer text.
   * Used by the template to display the answer text.
   * @param questionText The primary key to look through the questions array
   * @param answerId The primary key to look through the answers object 
   * @returns The answer text associated with the two lookup keys
   */
  public getAnswerTextById(questionText: string, answerId: string): string {
    const answerObj = this.questions
      .filter((question: KiqQuestion) => question.questionText === questionText)
      .map((question: KiqQuestion) => question.questionChoices)
      [0]
    const answerText = Object.keys(answerObj).filter((id: string) => id === answerId)[0]
    return answerObj[answerText];
  }

  /**
   * Updates the `answersArray` using the `questionText` to find the index in the `answersArray`. Updates the array with the value of `answerId` 
   * @template setter
   * @param questionText The primary key to look through the questions array to get the index out of it
   * @param answerId The value we intend to update the `answersArray` with
   * @returns N/A
   */
  addSelectedAnswer(questionText: string, answerId: string): void {
    const questionIndex = this.questions.map(q => q.questionText).indexOf(questionText)

    this.answersArray[questionIndex] = {
      answer: parseInt(answerId),
      isPristine: false
    };
    const userAnsweredAllQuestion: boolean = !this.answersArray.map(a => a.isPristine).includes(true);
    if (userAnsweredAllQuestion) {
      // emit to the enable the button
      this.idvLayoutService.setDisabled(false);
    }
    return;
  }

}
