import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ModalService } from '../modal/modal.service';

import { ChallengeDepositActionTypes } from '../state-management/actions/challenge-deposit.actions';
import { ChallengeDepositState, ChallengeDepositAttempt } from '../state-management/state/challenge-deposit.state';
import { InstitutionState } from '../state-management/state/institution.state';
import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './challenge-deposit.component.html',
    styleUrls: ['challenge-deposit.component.scss']
})
export class ChallengeDepositComponent implements OnDestroy {
    public pageName = 'challengeDeposit';
    public challengeDepositAttempt: ChallengeDepositAttempt = {
        confirmationCode: '',
        amount1: '',
        amount2: ''
    };
    public unsubscribe: Subject<void> = new Subject<void>();
    public isLoading: boolean;
    public confirmationCodePrefilled = false;
    public previousValue= 0;

    public validationSuccessful = false;
    pixelId = 0;
    pageEventName = 'Deposit';

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<boolean>,
        private route: ActivatedRoute,
    ) {
        this.store.select('challengeDepositStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: ChallengeDepositState) => {
            this.isLoading = info.isLoading;
            if (info.isError && !info.isLoading) {
                // catch NOT FOUND which is usually a result of a user entering an incorrect confirmation code
                console.log(info.error.status);
                if (info.error.status === 404) {
                    info.error.statusText = 'We are unable to process your request. Please contact us for further information.';
                }
                this.modalService.presentError(info.error, false, 'Error', 'Ok').subscribe(null, null, () => { });
            } else {
                this.validationSuccessful = info.isVerified;
            }
        });

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            this.challengeDepositAttempt.confirmationCode = params.confirmationCode;
            if (this.challengeDepositAttempt.confirmationCode !== undefined && this.challengeDepositAttempt.confirmationCode.length !== 0) {
                this.confirmationCodePrefilled = true;
            }
        });

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.pixelId = info.institution.pixelId;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    canSubmit() {
        /** Checks a challenge deposit amount is greater than 0
         * @params cd - a string value for a currency
         * @returns false for falsy, non parseable values of cd. returns cd > 0
         */
        let challengeDepositIsValid = (cd:string): boolean => {
            if (!cd) return false;
            let cdValue: number = parseFloat(cd);
            if (isNaN(cdValue)) return false;
            return cdValue > 0;
        }

        return challengeDepositIsValid(this.challengeDepositAttempt.amount1) &&
            challengeDepositIsValid(this.challengeDepositAttempt.amount2) &&
            this.challengeDepositAttempt.confirmationCode &&
            this.challengeDepositAttempt.confirmationCode.length > 0 &&
            !this.isLoading;
    }

    submitChallengeDeposit() {
        this.store.dispatch({ type: ChallengeDepositActionTypes.TRY_CHALLANGE, payload: this.challengeDepositAttempt });
    }
    formatAmount(event,challengeDepositAmount){
        if(event && (event.keyCode===13 || event.type=='blur')){
            if(event.target.value % 1!=0){
                event.target.value = event.target.value;
           }
           else if((event.target.value >0) && (event.target.value < 100 )){
               event.target.value= event.target.value/100;
           }
           if(challengeDepositAmount === 'challengeDepositAmount2'){
              this.challengeDepositAttempt.amount2 = event.target.value;
           }
           else if(challengeDepositAmount === 'challengeDepositAmount1'){
              this.challengeDepositAttempt.amount1 = event.target.value;
           }
           
        }
        
    }
    limitDigits(event){
        
        if(event.data=='.' && this.previousValue >=1){
            event.target.value = event.target.value.slice(0,1);
        }
        if( event.target.value %1!=0 && event.target.value.charAt(0)=='.'){
            event.target.value = event.target.value.slice(0,3);
        }
        else if(event.target.value %1!=0 && event.target.value <1 ){
            event.target.value = event.target.value.slice(0,4);
        }
        else if((event.target.value.length > 2 && event.target.value != 0.0) ){
            event.target.value = event.target.value.slice(0, 2);
        }
        this.previousValue=event.target.value;
    }
    
}
