export interface ValidationErrorMessage {
    required: string;
    [index: string]: string;
}

export interface ValidationErrors {
    firstName: ValidationErrorMessage | string;
    lastName: ValidationErrorMessage | string;
    phoneNumber: ValidationErrorMessage | string;
    emailAddress: ValidationErrorMessage | string;
    verifyEmailAddress: ValidationErrorMessage | string;
    address1: ValidationErrorMessage | string;
    address2: ValidationErrorMessage | string;
    mailingAddress1: ValidationErrorMessage | string;
    mailingAddress2: ValidationErrorMessage | string;
    city: ValidationErrorMessage | string;
    state: ValidationErrorMessage | string;
    zipCode: ValidationErrorMessage | string;
    taxIdentificationNumber: ValidationErrorMessage | string;
    dateOfBirth: ValidationErrorMessage | string;
    identificationType: ValidationErrorMessage | string;
    identificationNumber: ValidationErrorMessage | string;
    identificationState: ValidationErrorMessage | string;
    identificationIssueDate: ValidationErrorMessage | string;
    identificationExpirationDate: ValidationErrorMessage | string;
    employerName: ValidationErrorMessage | string;
    employerPhone: ValidationErrorMessage | string;
    employerOccupationTitle: ValidationErrorMessage | string;
    citizenType: ValidationErrorMessage | string;
    greenCardNumber: ValidationErrorMessage | string;
    applicationQuestions: ValidationErrorMessage | string | [ValidationErrorMessage | string] | [];

    frontImage?: ValidationErrorMessage | string;
    backImage?: ValidationErrorMessage | string;
    contactPhoneNumber:ValidationErrorMessage | string;
}

export const defaultValidiationErrors: ValidationErrors = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    verifyEmailAddress: '',
    address1: '',
    address2: '',
    mailingAddress1: '',
    mailingAddress2: '',
    city: '',
    state: '',
    zipCode: '',
    taxIdentificationNumber: '',
    dateOfBirth: '',
    identificationType: '',
    identificationNumber: '',
    identificationState: '',
    identificationIssueDate: '',
    identificationExpirationDate: '',
    employerName: '',
    employerPhone: '',
    employerOccupationTitle: '',
    citizenType: '',
    greenCardNumber: '',
    frontImage: '',
    backImage: '',
    contactPhoneNumber:'',
    applicationQuestions: []
};

export const validationMessages: ValidationErrors = {
    firstName: {
        required: 'First Name is required.',
        minlength: 'First Name must be at least 4 characters long.',
        maxlength: 'First Name cannot be more than 24 characters long.'
    },
    lastName: {
        required: 'Last Name is required.'
    },
    phoneNumber: {
        required: 'Phone Number is required.',
        validatePhoneNumber: 'Phone number must be valid and correctly formatted.'
    },
    emailAddress: {
        required: 'Email Address is required.',
        validateEmail: 'Email address must be valid and correctly formatted.'
    },
    verifyEmailAddress: {
        required: 'Email Address is required.',
        validateEmail: 'Email address must be valid and correctly formatted.'
    },
    address1: {
        required: 'Address is required.',
        validateNotPOBox: 'Please enter a Physical Address',
        maxlength: 'Address must be 40 characters or less.'
    },
    address2: {
        required: 'Address2 is required.',
        maxlength: 'Address must be 40 characters or less.'
    },
    mailingAddress1: {
        required: 'Address2 is required.',
        maxlength: 'Address must be 40 characters or less.'
    },
    mailingAddress2: {
        required: 'Address2 is required.',
        maxlength: 'Address must be 40 characters or less.'
    },
    city: {
        required: 'City is required.'
    },
    state: {
        required: 'State is required.'
    },
    zipCode: {
        required: 'Zip Code is required.',
        minlength: 'Zip Code must be at least 5 digits.',
        maxlength: 'Zip Code cannot be more than 5 digits.'
    },
    dateOfBirth: {
        invalidDate: 'Date of Birth does not appear to be correct.',
        required: 'Date of birth is required.',
        productAgeCheck: 'One of the products you have selected is age restricted and you do not meet the minimum age.'
    },
    taxIdentificationNumber: {
        required: 'SSN/TIN is required.',
        minlength: 'SSN/TIN must be at least 9 digits.',
        maxlength: 'SSN/TIN cannot be more than 11 digits.',
        invalidNumber: 'SSN/TIN must be at least 9 digits.'
    },
    identificationType: {
        required: 'ID Type is required.',
    },
    identificationNumber: {
        required: 'ID Number is required.',
        maxlength: 'ID Number cannot be more than 20 characters.'
    },
    identificationState: {
        required: 'ID State is required.',
    },
    identificationIssueDate: {
        invalidDate: 'ID Issue Date does not appear to be correct.',
        required: 'ID Issue Date is required.'
    },
    identificationExpirationDate: {
        invalidDate: 'ID Expiration Date does not appear to be correct.',
        expiredDate: 'ID Expiration Date is expired.',
        required: 'ID Expiration Date is required.'
    },
    employerName: {
        required: 'Employer Name is required.',
        maxlength: 'Employer Name must be 25 characters or less.'
    },
    employerPhone: {
        required: 'Employer Phone is required.'
    },
    employerOccupationTitle: {
        required: 'Occupation/Title is required.',
        maxlength: 'Occupation/Title must be 25 characters or less.'
    },
    citizenType: {
        required: 'Citizenship status is required.',
        pattern: 'We are unable to continue your application online.  Please contact us to complete your registration.'
    },
    greenCardNumber: {
        required: 'Green card number is required.'
    },
    applicationQuestions: {
        required: 'Information is required in this field.',
        maxlength: 'Security answers must be 20 characters or less.'
    },
    contactPhoneNumber: {
        required: 'Information is required in this field.',
    }
};
