import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import {
    OutOfWalletState, initialOutOfWalletState, defaultApplicantOutOfWallet, defaultApplicantOutOfWalletState
} from '../state/out-of-wallet.state';
import { OutOfWalletActionTypes } from '../actions/out-of-wallet.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const outOfWalletStoreReducer: ActionReducer<OutOfWalletState> = reducer;

export function reducer(state = initialOutOfWalletState, action: NuFundAction) {
    switch (action.type) {
        case OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true, isError: false });
        }

        case OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state, { isLoading: false, isError: false, error: null });
            newState.applicantOutOfWalletState[newState.currentApplicantIndex].questions = action.payload.questions;

            return newState;
        }

        case OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true, isError: false });
        }

        case OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state, { isLoading: false, isError: false, error: null });
            newState.applicantOutOfWalletState[newState.currentApplicantIndex].questionsPassed = true;

            return newState;
        }

        case OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
            newState.applicantOutOfWalletState[newState.currentApplicantIndex].questionsFailed = true;

            return newState;
        }


        case OutOfWalletActionTypes.SET_CURRENT_APPLICANT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { currentApplicantIndex: action.payload });
        }

        case SessionActionTypes.LOG_OUT: {
            if (isDevMode()) {
                console.log('{outOfWalletStoreReducer} ' + action.type + ' action being handled!');
            }

            const newState = Object.assign({}, initialOutOfWalletState);
            newState.applicantOutOfWalletState = Object.assign([], defaultApplicantOutOfWallet);

            for (let i = 0; i < newState.applicantOutOfWalletState.length; i++) {
                newState.applicantOutOfWalletState[i] = Object.assign({}, defaultApplicantOutOfWalletState);
                newState.applicantOutOfWalletState[i].questions = [];
                newState.applicantOutOfWalletState[i].answers = [];
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}
