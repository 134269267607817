<div *ngIf="!editable" class="businessname-header">Resume application for <strong>{{ business.lastName }}</strong></div>
<form [formGroup]="businessForm" class="form-applicant-info" autocomplete="off" *ngIf="editable">

  <!-- general info -->
  <fieldset class="form-fieldset-applicant">

    <legend class="subhead">Business Information</legend>

    <div class="form-input-container">
      <div class="form-items form-applicant-businessname">
        <label for="lastName" class="required">Legal Business Name</label>
        <div class="requiredContainer">
          <input type="text" required formControlName="lastName" id="lastName" [(ngModel)]="business.lastName" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.lastName"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-datestarted">
        <label for="dateStarted" class="required">Date Business Started</label>
        <div class="requiredContainer requiredDate">
          <input type="text" required class="form-control form-input form-input-date"
            placeholder="mm/dd/yyyy" id="dateStarted" [(ngModel)]="business.dateStarted" [textMask]="{mask: textMasks.dateMask}" formControlName="dateStarted">
          <csi-validation-indicator [control]="businessForm.controls.dateStarted"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-businesstype">
        <label for="businessType" class="required">Business Type</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="businessType" id="businessType" [(ngModel)]="business.businessType" class="form-select">
            <option style="display:none" value=""></option>
            <option *ngFor="let businessType of businessTypes" [value]="businessType.value">{{businessType.description}}</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.businessType"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-businessprofit">
        <label for="isNonProfit" class="required">Non-Profit / Profit</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="isNonProfit" id="isNonProfit" [(ngModel)]="business.isNonProfit" class="form-select">
            <option style="display:none" value=""></option>
            <option [value]="false">For-Profit</option>
            <option [value]="true">Non-Profit</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.isNonProfit"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-industrytype">
        <label for="industryType" class="required">Industry Type</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="industryType" id="industryType" [(ngModel)]="business.industryType" class="form-select">
            <option style="display:none" value=""></option>
            <option *ngFor="let industryType of industryOptions" [value]="industryType.value">{{industryType.description}}</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.industryType"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-businessid">
        <label for="taxIdentificationNumber" class="required">Tax ID</label>
         <div class="requiredContainer">
            <input type="text" required formControlName="taxIdentificationNumber" id="taxIdentificationNumber" [(ngModel)]="business.taxIdentificationNumber" class="form-input">
            <csi-validation-indicator [control]="businessForm.controls.taxIdentificationNumber"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-address1">
        <label for="address1" class="required">Street Address 1 <em>Cannot be a PO Box</em></label>
        <div class="requiredContainer">
          <input type="text" required formControlName="address1" id="address1" [(ngModel)]="business.address1" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.address1"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-address2">
        <label for="address2">Street Address 2</label>
        <input type="text" formControlName="address2" id="address2" [(ngModel)]="business.address2" class="form-input">
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-city">
        <label for="city" class="required">City</label>
        <div class="requiredContainer">
          <input type="text" required formControlName="city" id="city" [(ngModel)]="business.city" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.city"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-state">
        <label for="state" class="required no-wrap">State</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="state" id="state" [(ngModel)]="business.state" class="form-select">
              <option style="display:none" value=""></option>
              <option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.state"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-zipcode">
        <label for="zipCode" class="required">Zip Code</label>
        <!-- <div class="requiredContainer"> -->
          <input type="text" formControlName="zipCode" readonly id="zipCode" [(ngModel)]="business.zipCode" class="form-input">
        <!-- </div> -->
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-url">
        <label for="website">Business Website</label>
        <input type="text" formControlName="website" id="website" [(ngModel)]="business.website" class="form-input">
      </div>
    </div>
  </fieldset>
  <!-- general info -->

  <!-- contact info -->
  <fieldset class="form-fieldset-applicant">

    <legend class="subhead">Business Contact Information</legend>

    <div class="form-input-container">
      <div class="form-items form-applicant-phonenumber">
        <label for="phoneNumber" class="required">Business Phone</label>
        <div class="requiredContainer">
          <input type="tel" [textMask]="{mask: textMasks.phoneMask}" formControlName="phoneNumber" id="phoneNumber" [(ngModel)]="business.phoneNumber" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.phoneNumber"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-emailaddress">
        <label for="emailAddress" class="required">Business Email Address</label>
        <div class="requiredContainer">
          <input type="email" required autocomplete="off" formControlName="emailAddress" autocapitalize="none" id="emailAddress" [(ngModel)]="business.emailAddress" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.emailAddress"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-verifyemailaddress">
        <label for="verifyEmailAddress" class="required">Confirm Business Email Address</label>
        <div class="requiredContainer">
          <input type="email" required autocomplete="off" formControlName="verifyEmailAddress" autocapitalize="none" id="verifyEmailAddress" [(ngModel)]="business.verifyEmailAddress" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.verifyEmailAddress"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-contactmethod">
        <label for="methodOfContact" class="required">Preferred method of contact</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="methodOfContact" id="methodOfContact" [(ngModel)]="business.methodOfContact" class="form-select">
            <option style="display:none" value=""></option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.methodOfContact"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-contacttime">
        <label for="contactTime" class="required">Preferred contact timeframe</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="contactTime" id="contactTime" [(ngModel)]="business.contactTime" class="form-select">
            <option style="display:none" value=""></option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
            <option value="evening">Evening</option>
            <option value="anytime">Anytime</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.contactTime"></csi-validation-indicator>
        </div>
      </div>
    </div>
  </fieldset>
  <!-- /contact info -->

  <!-- business solutions -->
  <fieldset class="form-fieldset-applicant">

    <legend class="subhead">Business Solutions</legend>

    <div class="form-input-container">
      <div class="form-items form-applicant-solution-left form-applicant-checkswritten">
        <label for="checksWritten" class="initial-case required">On average, how many checks will you write per month?</label>
        <div class="requiredContainer">
          <input type="number" required formControlName="checksWritten" id="checksWritten" [(ngModel)]="business.checksWritten" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.checksWritten"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-solution-right form-applicant-checksdeposited">
        <label for="checksDeposited" class="initial-case required">How many paper checks will you deposit per month?</label>
        <div class="requiredContainer">
          <input type="number" required formControlName="checksDeposited" id="checksDeposited" [(ngModel)]="business.checksDeposited" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.checksDeposited"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-solution-left form-applicant-averagebalance">
        <label for="averageAccountBalance" class="initial-case required">What will your average monthly account balance be?</label>
        <div class="requiredContainer">
          <input type="number" required formControlName="averageAccountBalance" id="averageAccountBalance" [(ngModel)]="business.averageAccountBalance" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.averageAccountBalance"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-solution-right form-applicant-employeecount">
        <label for="employeeCount" class="initial-case required">How many employees work for your business?</label>
        <div class="requiredContainer">
          <input type="number" required formControlName="employeeCount" id="employeeCount" [(ngModel)]="business.employeeCount" class="form-input">
          <csi-validation-indicator [control]="businessForm.controls.employeeCount"></csi-validation-indicator>
        </div>
      </div>
    </div>

    <div class="form-input-container">
      <div class="form-items form-applicant-solution-left form-applicant-creditpayments">
        <label for="isAcceptingCreditPayments" class="initial-case required">Will you accept MasterCard and Visa payments?</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="isAcceptingCreditPayments" id="isAcceptingCreditPayments" [(ngModel)]="business.isAcceptingCreditPayments" class="form-select">
            <option style="display:none" value=""></option>
            <option [value]="true">Yes</option>
            <option [value]="false">No</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.isAcceptingCreditPayments"></csi-validation-indicator>
        </div>
      </div>

      <div class="form-items form-applicant-solution-right form-applicant-checkpayments">
        <label for="isAcceptingCheckPayments" class="initial-case required">Will you accept check payments?</label>
        <div class="requiredContainer requiredSelect">
          <select formControlName="isAcceptingCheckPayments" id="isAcceptingCheckPayments" [(ngModel)]="business.isAcceptingCheckPayments" class="form-select">
            <option style="display:none" value=""></option>
            <option [value]="true">Yes</option>
            <option [value]="false">No</option>
          </select>
          <csi-validation-indicator [control]="businessForm.controls.isAcceptingCheckPayments"></csi-validation-indicator>
        </div>
      </div>
    </div>
  </fieldset>
  <!-- /business solutions -->

</form>