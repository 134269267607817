import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OutOfWalletComponent } from './out-of-wallet.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';
import { OutOfWalletQuestionComponent } from './out-of-wallet-question/out-of-wallet-question.component';

const routes: Routes = [
    {
        path: 'out-of-wallet/:applicant',
        component: OutOfWalletComponent,
        canActivate: [
            CanActivateSessionGuard
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class OutOfWalletRoutingModule { }