import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormValidators } from './form-validators';
import { ModalService } from '../../modal/modal.service';

import { Store } from '@ngrx/store';

import { ApplicantsActionsTypes } from '../../state-management/actions/applicants.actions';
import { ApplicantsState, Applicant, PhoneType } from '../../state-management/state/applicants.state';
import { SelectedProductsState, Product } from '../../state-management/state/selected-products.state';
import { SessionState } from '../../state-management/state/session.state';

import { InstitutionState, Institution, ApplicationQuestion } from '../../state-management/state/institution.state';

import { StateOption, allStates } from './states';
import { Util } from 'src/app/common/util';
import { validationMessages, defaultValidiationErrors } from './validation-messages';
import { FormControlFactory } from './form-control-factory';
import { TextMasks } from 'src/app/business-information/business-validators';
import { USPSService } from 'src/app/services/usps.service';
import { Address } from 'src/app/models/address';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UspsResponse } from 'src/app/models/address-validate-response';
import { CombinedState } from 'src/app/state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { IdvStrategy } from 'src/app/models/idv.model';
import { isIdvFlow } from 'src/app/common/functions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    moduleId: module.id,
    selector: 'applicant',
    templateUrl: './applicant.component.html',
    styleUrls: ['applicant.component.scss']
})
export class ApplicantComponent implements OnChanges, OnInit, OnDestroy {
    private _applicant: Applicant;
    get applicant() {
        return this._applicant;
    }
    @Input('applicant')
    set applicant(value: Applicant) {
        // this loops over all the applicant questions and re-applies the "use translate"
        // property that is lost when we pull data from the API.  without it, the UI won't render
        // the questions properly after a license upload
        for (const question of value.applicationQuestions) {
            for (const insitutionQuestion of this.institution.applicationQuestions) {
                if (insitutionQuestion.questionId === question.questionId && typeof question.useTranslate === 'undefined') {
                    question.useTranslate = insitutionQuestion.useTranslate;
                    break;
                }
            }
        }
        this._applicant = value;
    }

    // Optionally required fields
    @Input() employerNameRequired: boolean;
    @Input() employerOccupationRequired: boolean;
    @Input() employerPhoneRequired: boolean;
    @Input() expirationDateRequired: boolean;
    @Input() issueDateRequired: boolean;
    @Input() citizenTypeRequired: boolean;

    private applIndex: number;
    get applicantIndex() {
        return this.applIndex;
    }
    @Input('applicantIndex')
    set applicantIndex(value: number) {
        this.applIndex = value;
    }

    @Output() formValidStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() applicantChanged: EventEmitter<Applicant> = new EventEmitter<Applicant>();

    institution: Institution;
    sessionState: SessionState;
    applicantForm: FormGroup;
    mailingAddressSameAsCurrentAddress = true;
    tempMailingAddress = {
        mailingAddress1: '',
        mailingAddress2: '',
        mailingCity: '',
        mailingState: '',
        mailingZipCode: ''
    };
    pendingAddressValidation = false;
    useSuggestedAddress = false;
    addressSuggestion: Address;

    unsubscribe: Subject<any> = new Subject<any>();
    selectedProducts: Product[];

    textMasks: TextMasks = new TextMasks();

    // import the (probably empty) default errors to set up for future string concatination
    formErrors: ValidationErrors = Object.assign({}, defaultValidiationErrors);

    // import large list of validation messages from external file
    validationMessages: ValidationErrors = validationMessages;

    // import the list of all states used in the state picker
    states: StateOption[] = allStates;

    address$: Observable<Address>;
    addressSubject = new Subject<Address>();

    phoneTypes = PhoneType;
    phoneTypeNames: string[];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<CombinedState>,
        private applicantsStore: Store<ApplicantsState>,
        private formBuilder: FormBuilder,
        private modalService: ModalService<Address>,
        private UspsService: USPSService,
    ) {
        this.phoneTypeNames = Object.keys(this.phoneTypes)
        this.address$ = this.addressSubject.pipe(debounceTime(1000));

        this.address$.subscribe(address => {
            this.uspsValidateAddress(address);
        });

        // pull in institution data so we can set the questions up
        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: InstitutionState) => {
                this.institution = info.institution;
                this.tryToSetapplicationQuestions();
            }
            );

        // get session info so we can know if this is a SSO session
        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SessionState) => {
                this.sessionState = info;
                this.mailingAddressSameAsCurrentAddress = !info.isSSO;
            }
            );

        // get the products so we can know things like age requirements
        this.store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SelectedProductsState) => this.selectedProducts = info.selectedProducts);

        // watch for the applicant to change globally so we can set it on our form
        this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((applicantState => {
                // if (this.applicantIndex !== applicantState.currentApplicantIndex) {
                    this.applicantIndex = applicantState.currentApplicantIndex;
                    this._handleApplicantSwitch(applicantState);
                // }
            })
            );


    }
    private _handleApplicantSwitch(applicantState): void {
        // if the data required is there, sync theng model to the form if it is updated (usually because of autofill)
        if (applicantState && this.applicantForm && !isNaN(this.applicantIndex)) {
            this.applicant = {...applicantState.applicants[this.applicantIndex] as Applicant};

            /** The object that we pass into `applicantForm.setValue` to update the form */
            let formValue = this.applicantForm.value;
            
            formValue = Object.keys(this.applicantForm.controls).reduce((p,c ) => {
                // console.log("r", r);
                // console.log("this.applicantForm.value[r]", this.applicantForm.value[r]);
                let temp = {};
                temp[c] = this.applicantForm.value[c];
                return {...p, ...temp};
            }, {})

            // update the non question fields
            Object.keys(formValue).forEach(k => {
                    formValue[k] = this.applicant[k];
            })

            // handle application questions
            const applicantHasQuestions: boolean = this.institution.isIdentitySolutionCip && !this.institution.isBusiness && !this.applicant.isSSO;

            if (applicantHasQuestions) {
                
                formValue.applicationQuestions.forEach((aq: ApplicationQuestion, i:number) => {
                    // need to ensure that every question has the appropriate fields to pass into the setvalue method
                    // this involves adding and removing fields from the object.
                    aq = aq.fieldValue ? {...aq} : {...aq, fieldValue: ''};
                    aq = aq.answerText ? {...aq} : {...aq, answerText: ''};
                    
                    const hasSecurityQuestions = ((this.applicantForm.controls['applicationQuestions'] as FormArray).controls[i] as FormGroup).controls.hasOwnProperty('securityQuestion')
                    if (hasSecurityQuestions) {
                        aq = aq.securityQuestion ? {...aq} : {...aq, securityQuestion: null};
                    }
                    delete aq.displayOrder;
                    delete formValue.applicationQuestions[i].displayOrder;

                    // update the formValue object for questions
                    Object.keys(aq).forEach((k: string) => {
                        if (aq.hasOwnProperty(k)){
                            formValue.applicationQuestions[i][k] = this.applicant.applicationQuestions[i][k] ?? '';
                        }
                    })
                })
            }

            this.applicantForm.setValue(formValue, {onlySelf: true});

            this.applicantForm.updateValueAndValidity();

            this.institution.applicationQuestions = this.applicant.applicationQuestions;

            this.onValueChanged();
        }
    }
    ngOnChanges(c) {
        // this.onValueChanged();
        this.handleApplicantSwitch();
    }

    ngOnInit() {
        // This checked the form for validation errors on each change.  It would be a good idea to rate limit this.
        this._applicant.isSSO = this.sessionState.isSSO;

        this.applicantForm.valueChanges
            .subscribe(newValue => {
                this.onValueChanged(newValue)
            });

        // Check the form for validation errors before any changes are made.
        this.onValueChanged();
        //TODO: Errors are being calculated wrong in a subsidary of the method above

        this.handleApplicantSwitch();

    }

    ngOnDestroy() {
        // unsubscribe to subscriptions
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }


    private handleApplicantSwitch() {
        // This loads up all the models and validators in to form controls
        if (!this.applicantForm)
            this.setupFormAndValidation();

        // default the questions
        this.tryToSetapplicationQuestions();
    }

    onValueChanged(data?: any) {
        // if the form isn't loaded yet just exit
        if (!this.applicantForm) {
            return;
        }


        // sync up the model with the form value
        if (data) {
            this.syncFormValueToApplicantModel(data);
        }

        // loop over all the errors and put them in the right place
        this.concatinateErrors();

        // hide the ID scanning modal after successful return
        if (this.applicant.idResultsFilled) {
            this.applicant.showIdFill = false;
        }

        // since the form has been updated, we need to update change detection, I think??
        this.changeDetectorRef.detectChanges();

        // account for address lookup
        let tempApplicant: Applicant = this.applicant;
        if (this.useSuggestedAddress) {
            tempApplicant = Object.assign(tempApplicant, this.addressSuggestion);
        }

        // is anyone listening to this on the output of the control??
        this.applicantChanged.emit(tempApplicant);
    }

    syncFormValueToApplicantModel(formData: any) {
        for (const formValueKey of Object.keys(formData)) {
            // if the key exists in the applicant model and it is different
            if (formValueKey in this.applicant && this.applicant[formValueKey] !== formData[formValueKey]) {
                // set it to the form value
                if (formValueKey !== 'applicationQuestions') {
                    this.applicant[formValueKey] = formData[formValueKey];
                } else {
                    for (let i = 0; i < formData.applicationQuestions.length; i++) {
                        const applicantQuestion = this.applicant[formValueKey][i];
                        const questionFormField = formData[formValueKey][i];
                        const securityQuestion = questionFormField.securityQuestion ? questionFormField.securityQuestion : null;

                        applicantQuestion.answerText = questionFormField.answerText;

                        if (securityQuestion !== null) {
                            this.applicant[formValueKey][i].questionText = securityQuestion.questionText;
                            this.applicant[formValueKey][i].fieldValue = securityQuestion.fieldValue;
                        } else {
                            this.applicant[formValueKey][i].questionText = questionFormField.questionText;
                            this.applicant[formValueKey][i].fieldValue = null;
                        }
                    }
                }
            }
        }
        this.applicantForm.markAllAsTouched();
        this.changeDetectorRef.detectChanges();
    }

    /** This method builds the object for the template to render the appropriate error message. */
    concatinateErrors() {
        let formValid = true;

        // Check each field for validation errors and build the error message.
        for (const fieldKey of Object.keys(this.formErrors)) {
            // clear previous error, questions can be an array of errors
            this.formErrors[fieldKey] = fieldKey !== 'applicationQuestions' ? '' : [];
            // get a refrence to the form control
            const control: AbstractControl = this.applicantForm.get(fieldKey);

            // if the control is invalid and not disabled
            if (control && !control.valid && !control.disabled) {
                // invalidate the entire form
                formValid = false;
                if (this.institution.idvStrategy in IdvStrategy) {
                    if (fieldKey != 'applicationQuestions') {
                        this.concatinateSingleErrors(fieldKey, control);
                    }
                } else {
                    if (control instanceof FormArray) {
                        this.concatinateQuestionErrors(control);
                    } else {
                        this.concatinateSingleErrors(fieldKey, control);
                    }
                }
            }
        }

        if (this.applicantForm.errors !== null) {
            formValid = false;
        }

        // if the form its self thinks it is invalid, emit so
        this.formValidStateChanged.emit(formValid);
    }

    /** This adds the error message for every field except for the applicant questions */
    concatinateSingleErrors(fieldKey: string, control: AbstractControl) {
        // loop over all the errors
        if (!control) {
            console.log('break')
        }
        for (const errorKey of Object.keys(control.errors)) {
            // append them to the list of all errors for this field
            this.formErrors[fieldKey] += this.validationMessages[fieldKey][errorKey] + ' ';
        }
    }

    /** The method adds the form errors for of the applicant questions */
    concatinateQuestionErrors(control: FormArray) {
        // loop over all the questions
        for (let i = 0; i < control.controls.length; i++) {
            // get the current control group
            const controls = (control.controls[i] as FormGroup).controls;
            const targetControl = controls.answerText;
            const targetSecurityControl = controls.securityQuestion ? controls.securityQuestion : null;
            // if the group isn't valid
            if (!targetControl.valid && !targetControl.disabled || targetSecurityControl !== null && !targetSecurityControl.valid) {
                // append the proper error.  there is only really one so I don't bother with a nested loop

                for (const errorKey of Object.keys(targetControl.errors)) {
                    this.formErrors.applicationQuestions[i] = this.validationMessages.applicationQuestions[errorKey] + ' ';
                }

            }
        }
    }

    setupFormAndValidation() {
        const applicantQuestions = this.institution.applicationQuestions;
        // build generic model/requirement controls for the entire form
        const applicantFormControlsConfig = FormControlFactory.getFormControls(
            this.sessionState.isSSO,
            this.applicantIndex,
            this.applicant,
            this.selectedProducts,
            applicantQuestions,
            {
                employerNameRequired: this.employerNameRequired,
                employerOccupationRequired: this.employerOccupationRequired,
                employerPhoneRequired: this.employerPhoneRequired,
                expirationDateRequired: this.expirationDateRequired,
                issueDateRequired: this.issueDateRequired,
                citizenTypeRequired: this.citizenTypeRequired
            },
            isIdvFlow(this.institution)
        );

        // This is the form builder that creates each control binding with values and validators
        this.applicantForm = this.formBuilder.group({...applicantFormControlsConfig});

        this.applicantForm.setValidators(FormValidators.emailMatches('emailAddress', 'verifyEmailAddress'));

        this.applicantForm.updateValueAndValidity();

    }

    tryToSetapplicationQuestions() {
        // set the questions and default answers on the model
        if (this.applicant !== undefined && this.institution.applicationQuestions && this.applicant.applicationQuestions.length === 0) {
            for (let i = 0; i < this.institution.applicationQuestions.length; i++) {
                this.applicant.applicationQuestions[i] = Object.assign({}, this.institution.applicationQuestions[i]);
            }
        }
    }

    handleAddressChange(event) {
        if (this.institution.uspsAddressValidation && this.isAddressValid) {
            this.addressSubject.next(this.address);
        } else {
            // set the key in the model to the new value
            if (this.mailingAddressSameAsCurrentAddress) {
                this.syncAddresses();
            }
        }
    }

    get address(): Address {
        return {
            address1: this.applicantForm.get('address1').value,
            address2: this.applicantForm.get('address2').value,
            city: this.applicantForm.get('city').value,
            state: this.applicantForm.get('state').value,
            zipCode: this.applicantForm.get('zipCode').value
            
        };
    }

    set address(address: Address) {
        this.applicantForm.get('address1').setValue(address.address1);
        this.applicantForm.get('address2').setValue(address.address2);
        this.applicantForm.get('city').setValue(address.city);
        this.applicantForm.get('state').setValue(address.state);
        // this.applicantForm.get('zipCode').setValue(address.zipCode);
    }

    setAddressFormValue(address: Address) {
        address.address1 = address.address1 !== undefined ? address.address1 : '';
        address.address2 = address.address1 !== undefined ? address.address2 : '';
        address.city = address.address1 !== undefined ? address.city : '';
        address.state = address.address1 !== undefined ? address.state : '';
        address.zipCode = address.address1 !== undefined ? address.zipCode : '';

        this.applicantForm.get('address1').setValue(address.address1);
        this.applicantForm.get('address2').setValue(address.address2);
        this.applicantForm.get('city').setValue(address.city);
        this.applicantForm.get('state').setValue(address.state);
        this.applicantForm.get('zipCode').setValue(address.zipCode);
    }

    get isAddressValid() {
        return this.applicantForm.get('address1').valid &&
            this.applicantForm.get('address2').valid &&
            this.applicantForm.get('city').valid &&
            this.applicantForm.get('state').valid &&
            this.applicantForm.get('zipCode').valid;
    }

    syncAddresses() {
        this.applicant.mailingAddress1 = this.applicant.address1;
        this.applicant.mailingAddress2 = this.applicant.address2;
        this.applicant.mailingCity =this.applicant.city;
        this.applicant.mailingState = this.applicant.state;
        this.applicant.mailingZipCode = this.applicant.zipCode;
    }

    toggleMailingAddressSameAsCurrentAddress(event) {
        const mailingAddress1 = this.applicantForm.get('mailingAddress1');
        const mailingAddress2 = this.applicantForm.get('mailingAddress2');
        const mailingCity = this.applicantForm.get('mailingCity');
        const mailingState = this.applicantForm.get('mailingState');
        const mailingZipCode = this.applicantForm.get('mailingZipCode');

        // if the are supposed to be the same
        if (event.srcElement.checked) {
            // sync up the mailing and physical address
            mailingAddress1.setValue('');
            mailingAddress2.setValue('');
            mailingCity.setValue('');
            mailingState.setValue('');
            mailingZipCode.setValue('');

            // disable the inputs
            mailingAddress1.disable();
            mailingAddress2.disable();
            mailingCity.disable();
            mailingZipCode.disable();
        } else {
            // enable the inputs
            mailingAddress1.enable();
            mailingAddress2.enable();
            mailingCity.enable();
            mailingZipCode.enable();
        }
    }

    imageListener(fileEvent: any, isFront: boolean) {
        const maxSize = 2048;

        const file = fileEvent.target.files[0];
        // if they didn't upload an image
        if (file !== undefined && !file.type.match(/image.*/)) {
             this.modalService.presentError(
                <HttpErrorResponse> {
                    error: 'Only images may be uploaded as identification documents.'
                }, null, 'Image Validation Error', 'Ok').subscribe(null, null, () => { });
        } else {
            const fileReader = new FileReader();
            // when data is loaded from the read below
            fileReader.addEventListener('load', (readerEvent) => {
                const image = new Image();
                image.addEventListener('load', () => {
                    if (isFront) {
                        // set the base64 value to the form front value
                        this.applicantForm.value.frontImage = Util.resizeImageToBase64JPEG(image, maxSize);
                    } else {
                        // set the base64 value to the form back value
                        this.applicantForm.value.backImage = Util.resizeImageToBase64JPEG(image, maxSize);
                    }
                });
                image.src = (readerEvent.target as any).result;
            });
            // read the image uploaded
            fileReader.readAsDataURL(file);
        }
    }

    submitIdImages(applicant: Applicant) {
        // send the images to Idology
        this.applicantsStore.dispatch({ type: ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT, payload: applicant });
    }

    getIDSRC(isFront: boolean) {
        // update the preview image
        if (isFront) {
            if (this.applicantForm.value.frontImage !== '') {
                return this.prefixImageURIIfNeeded(this.applicantForm.value.frontImage);
            } else {
                return './assets/images/icon-id-front.png';
            }
        } else {
            if (this.applicantForm.value.backImage !== '') {
                return this.prefixImageURIIfNeeded(this.applicantForm.value.backImage);
            } else {
                return './assets/images/icon-id-back.png';
            }
        }
    }

    private prefixImageURIIfNeeded(imageURI: string) {
        return /data\:image\/jpeg\;base64\,/.test(imageURI) ?
            imageURI : 'data:image/jpeg;base64,' + imageURI;
    }

    get applicantHasSSN() {
        return !(
            // don't bother for sso applicant 0
            this.showIdentityFields && (
                this.applicantForm.controls.citizenType.value !== '0' &&
                this.applicantForm.controls.citizenType.value !== '2.1' &&
                this.applicantForm.controls.citizenType.value !== '2.2' ||
                this.applicantForm.controls.citizenType.value === undefined ||
                this.applicantForm.controls.citizenType.value === null ||
                this.applicantForm.controls.citizenType.value === ''
            )
        );
    }

    get applicantHasGreenCard() {
        return this.applicantForm.get('citizenType').value === '2.1';
    }

    get applicantHasSubstantialPresence() {
        return this.applicantForm.get('citizenType').value === '2.2';
    }

    toggleShowIdFill() {
        // expand or collapse the id area
        this.applicant.showIdFill = !this.applicant.showIdFill;
        return false;
    }

    get showIdentityFields() {
        return this.applicantIndex !== 0 || !this.sessionState.isSSO;
    }

    get needsNewAddressValidation() {
        return !this.addressSuggestion ||
            this.addressSuggestion &&
            this.addressSuggestion.address1 !== this.address.address1 ||
            this.addressSuggestion.address2 !== this.address.address2 ||
            this.addressSuggestion.city !== this.address.city ||
            this.addressSuggestion.state !== this.address.state;
    }

    private uspsValidateAddress(address: Address) {
        // if this is the first suggestion or a new suggestion
        if (this.needsNewAddressValidation) {
            // look it up with usps
            this.UspsService.validateAddress(address).subscribe(response => {
                this.handleUspsResponse(response);
            }, (error) => {
                console.error(error);
                // it blew up so let them go
                if (this.mailingAddressSameAsCurrentAddress) {
                    this.syncAddresses();
                }
                this.concatinateErrors();
            }, () => {
                this.concatinateErrors();
            });
        }
    }

    private handleUspsResponse(response: UspsResponse) {
        if (response.address1 !== '' && response.zip5 !== '') {
            this.addressSuggestion = {
                address1: response.address1,
                address2: response.address2,
                city: response.city,
                state: response.state,
                zipCode: response.zip5
               
            };

            this.useSuggestedAddress = true;

            // this.address = this.addressSuggestion;
        } else {
            this.addressSuggestion = null;
            this.useSuggestedAddress = false;
        }
    }
}
