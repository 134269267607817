import { Product, Bundle } from '../state-management/state/products.state';

export function productsToBundles(products: Product[]) {
    const bundleDictionary = products.reduce(
        (bundleAccumulator, product) => {
            const key = product.bundleId ? product.bundleId : product.productId;
            const isBundle = product.bundleId ? true : false;

            if (!bundleAccumulator[key]) {
                bundleAccumulator[key] = new Bundle({
                    products: [],
                    name: isBundle ? product.bundleName : product.productName,
                    hasPromoCode: false,
                    isBundle: false
                });
            }

            bundleAccumulator[key].products.push(new Product(product));
            bundleAccumulator[key].isBundle = product.bundleId !== null;
            bundleAccumulator[key].hasPromoCode = bundleAccumulator[key].products.some(
                (currentProduct: Product) => currentProduct.hasPromoCode
            );

            return bundleAccumulator;
        }, { }
    );

    return Object.keys(bundleDictionary).map(key => bundleDictionary[key]);
}

export function bundlesToProducts(bundles: Bundle[]) {
    const products: Product[] = [];

    for (const bundle of bundles) {
        for (const product of bundle.products) {
            products.push(new Product(product));
        }
    }

    return products;
}
