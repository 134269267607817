import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Applicant } from 'src/app/state-management/state/applicants.state';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { IdentificationType } from 'src/app/state-management/state/institution.state';
import { BusinessValidators, TextMasks } from '../business-validators';
import { StateOption, allStates } from 'src/app/personal-information/applicant/states';

@Component({
    selector: 'csi-signer-applicant',
    templateUrl: './signer-applicant.component.html',
    styleUrls: ['./signer-applicant.component.scss']
})
export class SignerApplicantComponent implements OnInit {
    @Input() signer: Applicant;
    @Output() signerChange: EventEmitter<Applicant> = new EventEmitter<Applicant>();

    @Input() ordinal = 1;
    @Input() deleteable = true;
    @Input() editable = true;

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() delete: EventEmitter<Applicant> = new EventEmitter<Applicant>();
    @Input() identificationTypes: IdentificationType[];

    signerForm: FormGroup;
    textMasks = new TextMasks();
    states: StateOption[] = allStates;
    expanded = true;

    constructor() { }

    ngOnInit() {
        this.signerForm = this.getSignerFormGroup();
        this.signerForm.statusChanges.subscribe(result => {
            this.valid.emit(result === 'VALID');
        });

        if (!this.editable) {
            this.expanded = false;
        }
    }

    private getSignerFormGroup() {
        return new FormGroup({
            firstName: new FormControl(this.signer.firstName, [Validators.required]),
            lastName: new FormControl(this.signer.lastName, [Validators.required]),
            address1: new FormControl(this.signer.address1, [Validators.required]),
            address2: new FormControl(this.signer.address2),
            city: new FormControl(this.signer.city, [Validators.required]),
            state: new FormControl(this.signer.state, [Validators.required]),
            zipCode: new FormControl(this.signer.zipCode, [Validators.required, Validators.minLength(5)]),
            taxIdentificationNumber: new FormControl(this.signer.taxIdentificationNumber, [Validators.required]),
            identificationType: new FormControl(this.signer.identificationType, [Validators.required]),
            identificationNumber: new FormControl(this.signer.identificationNumber, [Validators.required]),
            identificationState: new FormControl(this.signer.identificationState, [Validators.required]),
            identificationIssueDate: new FormControl(this.signer.identificationIssueDate, [
                Validators.required,
                BusinessValidators.date,
                BusinessValidators.pastDate
            ]),
            identificationExpirationDate: new FormControl(this.signer.identificationExpirationDate, [
                Validators.required,
                BusinessValidators.date,
                BusinessValidators.futureDate
            ]),
            phoneNumber: new FormControl(this.signer.phoneNumber, [Validators.required, BusinessValidators.phoneNumber]),
            dateOfBirth: new FormControl(this.signer.dateOfBirth, [
                Validators.required,
                BusinessValidators.date,
                BusinessValidators.pastDate
            ])
        });
    }

    toggleExpand() {
        this.expanded = this.editable ? !this.expanded : false;
    }

    deleteClick() {
        this.delete.emit(this.signer);
    }
}
