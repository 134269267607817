import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Applicant } from 'src/app/state-management/state/applicants.state';
import { ApplicationQuestion, Institution } from 'src/app/state-management/state/institution.state';

@Injectable({
  providedIn: 'root'
})
export class ApplicantLayoutService {

    applicantForm:FormGroup;
    applicant:Applicant;
    institution:Institution;
    /** This subject represents the internal state of the observable and lets the class encapsulate the behavior of the public facing observable. */
    private _buttonClick$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    /** Public facing observable that children subscribe to. Emits when the layout has recieved a click event. */
    public buttonClick$ = this._buttonClick$.asObservable();

    /** This subject represents the state of the `diabled` state of the layout `Continue` button. Is private to enforce encapsulation. */
    private _buttonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /** The public facing observable to emit to the layout page about the `disabled` state. */
    public buttonDisabled$ = this._buttonDisabled$.asObservable();


    /** Empty constructor in case we ever need to inject dependencies here. */
    constructor() {
    }

    /** Emits the `buttonClick$` observable to its subscribers. */
    public clickButton(): void {
        this._buttonClick$.next();
    }

    /** Emits the `buttonDisabled$` observable to the `idv.component.ts` */
    public setDisabled(disabld: boolean): void {
        this._buttonDisabled$.next(disabld);
    }

    public getformValidStatus():boolean {
        return this.applicantForm.valid
    }
    public SetFormData(formData:FormGroup, applicant:Applicant, institution:Institution) {


          this.applicantForm = formData;
          this.applicant =applicant;
          this.institution = institution
    }

    public getTransformedFormdata() : Applicant {

            for (const formValueKey of Object.keys(this.applicantForm.value)) {
                // if the key exists in the applicant model and it is different
                if (formValueKey in this.applicant &&
                    this.applicant[formValueKey] !== this.applicantForm.value[formValueKey]) {
                    // set it to the form value
                    if (formValueKey !== 'applicationQuestions') {
                        this.applicant[formValueKey] = this.applicantForm.value[formValueKey];
                    } else {


             if(this.institution.applicationQuestions.length) {


               const len = (this.applicantForm.controls['applicationQuestions'] as FormArray).length;
               const formArray = (this.applicantForm.controls['applicationQuestions'] as FormArray)


                     for(let i=0; i < len; i++) {
                        let applicationQuestions:ApplicationQuestion = this.applicant.applicationQuestions[i];
                        const temp = formArray;
                      applicationQuestions.answerText = formArray.controls[i].get('answerText').value
                        const hasSeurityQuestions = (formArray.controls[i] as FormGroup).controls.hasOwnProperty('securityQuestion');
                        if (hasSeurityQuestions) {
                            if(formArray.controls[i].get('securityQuestion') && formArray.controls[i].get('securityQuestion').value) {
                                 this.applicant['applicationQuestions'][i].questionText = formArray.controls[i].get('securityQuestion').value['questionText'];
                            this.applicant['applicationQuestions'][i].fieldValue = formArray.controls[i].get('securityQuestion').value['fieldValue'];
                            }

                        } else {
                            this.applicant['applicationQuestions'][i].questionText = this.applicant.applicationQuestions[i].questionText;
                            this.applicant['applicationQuestions'][i].fieldValue = null;
                        }

                    }
             }

            }
            }
        }

        return this.applicant;
        }
}
