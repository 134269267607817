
export interface RoutingLookupState {
    suggestions: Suggestion[];
    isLoading: boolean;
    isError: boolean;
    error: any;
}

export interface Suggestion {
    routingNumber: number;
    institutionName: string;
}

export const initialRoutingLookupState = {
    isLoading: false,
    isError: false,
    error: null,
    suggestions: []
}