import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { merge, of, Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Address} from 'src/app/models/address';
import { ApplicantComponent } from 'src/app/personal-information/applicant/applicant.component';
import { FormValidators } from 'src/app/personal-information/applicant/form-validators';
import { allStates, StateOption } from 'src/app/personal-information/applicant/states';
import { Applicant, ApplicantsState, PhoneType } from 'src/app/state-management/state/applicants.state';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { SessionState } from 'src/app/state-management/state/session.state';
import { ApplicantLayoutService } from 'src/new-applicant/services/applicant-layout.service';
import { TextMasks } from 'src/app/business-information/business-validators';
import { defaultValidiationErrors, validationMessages } from 'src/app/personal-information/applicant/validation-messages';
import { ApplicationQuestion, Institution, InstitutionState } from 'src/app/state-management/state/institution.state';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ModalService } from 'src/app/modal/modal.service';
import { ApplicantsActionsTypes } from 'src/app/state-management/actions/applicants.actions';
import { Util } from 'src/app/common/util';
import { USPSService } from 'src/app/services/usps.service';
import { UspsResponse } from 'src/app/models/address-validate-response';
import { SelectedProductsState } from 'src/app/state-management/state/selected-products.state';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
    selector: 'app-applicant-form',
    templateUrl: './applicant-form.component.html',
    styleUrls: ['./applicant-form.component.scss']
})
export class ApplicantFormComponent implements OnInit,OnDestroy {

    applicantForm: FormGroup
    applicantState: ApplicantsState;
    applicantIndex = 0
    phoneTypes = PhoneType;
    phoneTypeNames: string[];
    institution: Institution;
    unsubscribe: Subject<any> = new Subject<any>();

    states: StateOption[] = allStates;
    addressSuggestion:Address;
    sessionState: SessionState
    mailingAddressSameAsCurrentAddress = true

    textMasks: TextMasks = new TextMasks();
    applicant: Applicant
    // import the (probably empty) default errors to set up for future string concatination
    formErrors: ValidationErrors = Object.assign({}, defaultValidiationErrors);

    // import large list of validation messages from external file
    validationMessages: ValidationErrors = validationMessages;
enteredAddress1 :string ;
enteredAddress2 :string ;
enteredcity :string ;
enteredstate :string ;
enteredzipCode :string ;
    address$: Observable<Address>;
    addressSubject = new Subject<Address>();
    useSuggestedAddress = false;
    PreviousApplicantIndex =-1;
    selectedProducts =[]
    suggestedAddress: Address;
  securityQuestionsLists=[]
    

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private applicantLayoutService: ApplicantLayoutService,
        private formBuilder: FormBuilder,
        private store: Store<CombinedState>,
        private applicantsStore: Store<ApplicantsState>,
        private modalService: ModalService<Boolean>,
        private UspsService: USPSService
    ) { }

    ngOnInit() {

        this.store.select("applicantsStoreReducer").pipe(takeUntil(this.unsubscribe)).subscribe((applicantState) => {
            this.applicantState = applicantState;
            this.applicantIndex = applicantState.currentApplicantIndex;
            this.applicant = this.applicantState.applicants[this.applicantIndex];
            const isLoading =  this.applicant.isLoading;
            const isaddorremoveApplicant = this.applicant.isAddingApplicant;
            const canContinue = this.applicant['canContinue'];
            if (this.applicant['idResultsFilled']) {
                this.applicant.showIdFill = false;
            }
            if (applicantState.applicants[applicantState.currentApplicantIndex].isError) {
                if (applicantState.canContinue) {
                    this.modalService.presentError(
                        applicantState.applicants[applicantState.currentApplicantIndex].error, true, 'Verification Error', 'Ok'
                    ).pipe(takeUntil(this.unsubscribe)).subscribe(null, null, () => { });
                }
            }
            if (this.applicantForm &&
                (this.applicant['autoFill'] || this.PreviousApplicantIndex != this.applicantIndex ||
                 this.applicant['idResultsFilled'])) {
                this.PreviousApplicantIndex = this.applicantIndex;
                if( this.applicant['idResultsFilled']) {
                    this.formatSSN();
                }
                this.setValueForForm(this.applicantState.applicants[this.applicantIndex])
            }
            if(this.applicantForm && (isaddorremoveApplicant || isLoading)) {
                this.applicantLayoutService.setDisabled(false);
            } else if(this.applicantForm){
                this.applicantLayoutService.setDisabled(this.applicantForm.valid);
            }
        });
        
        // get session info so we can know if this is a SSO session
        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((state: SessionState) => {
                this.sessionState = state;
                this.mailingAddressSameAsCurrentAddress = !state.isSSO

            });


        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).
            subscribe((data: InstitutionState) => {
                this.institution = data.institution;
                if(!this.sessionState.isSSO || this.applicantIndex!=0)
                this.setapplicationQuestions();
               // this.useNewUiForBank = data.institution.useNewUiForBank;
            });

        // get the products so we can know things like age requirements
        this.store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe))
        .subscribe((info: SelectedProductsState) => this.selectedProducts = info.selectedProducts);

        //initiliaze the form
        this.initForm();

        this.applicantForm.valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe(newValue =>  this.onApplicantFormValueChanged(newValue));

        this.onApplicantFormValueChanged();

        this.phoneTypeNames = Object.keys(this.phoneTypes);
        this.address$ = this.addressSubject.pipe(debounceTime(1000));
        
        this.address$.subscribe(address=> {
            this.uspsValidateAddress(address);
        });     
    }
    setSecurityQuestions(){
     
       this.applicant.applicationQuestions.forEach((val,index) => {
       
        this.securityQuestionsLists[index] = this.institution.securityQuestions;
        
       })
    }

    formatSSN() {

       const getOnlyNumber = (this.applicant.taxIdentificationNumber.toString()).replace(/\D+/g, '');

        if(getOnlyNumber){
            let number = getOnlyNumber.replace(
                /(\d{3})(\d{2})(\d{4})/, "$1-$2-$3"
            );
            this.applicant.taxIdentificationNumber = number;
        }

    }
    ngAfterViewInit() {
            setTimeout(()=> {
                if (this.sessionState.isSSO && this.applicantIndex === 0) {
                this.applicantForm.disable();
                this.applicantForm.controls['emailAddress'].enable();
                this.applicantForm.controls['verifyEmailAddress'].enable();
                this.applicantForm.controls['contactPhoneNumber'].enable();
                }
            })
    }

    setapplicationQuestions() {
        // set the questions and default answers on the model
        if (this.applicant !== undefined && this.institution.applicationQuestions.length) {

            if (this.applicant.applicationQuestions.length === 0) {

                const institutionQuestions = this.institution.applicationQuestions.length;
                for (let i = 0; i < institutionQuestions; i++) {
                    this.applicant.applicationQuestions[i] = Object.assign({}, this.institution.applicationQuestions[i]);
                }
            } else if (this.applicant.applicationQuestions.length) {

                const institutionQuestions = this.institution.applicationQuestions;
                const applicantQuestions = this.applicant.applicationQuestions;

                for (let i = 0; i < applicantQuestions.length; i++) {

                    if (applicantQuestions[i].useTranslate || applicantQuestions[i].fieldValue) {

                        let securityQuestion ;
                         this.institution.securityQuestions.forEach(value =>
                           {
                            if(applicantQuestions[i].fieldValue != null &&
                                value.fieldValue === applicantQuestions[i].fieldValue)
                                securityQuestion = value;

                            });

                            securityQuestion != undefined ? applicantQuestions[i]['securityQuestion'] = securityQuestion:
                            applicantQuestions[i]['securityQuestion'] =  this.institution.securityQuestions[i];
                    }
                }
            }
        }
        this.setSecurityQuestions();
    }

    // in case to remove additional questions
    removeAdditionalQuestions() {

        if (!this.applicantState.applicants[this.applicantIndex]['applicationQuestions'].length ||
        this.sessionState.isSSO) {

            const formApplicationsControl = this.applicantForm.controls['applicationQuestions']['controls'];
            if (formApplicationsControl.length) {
                const length = formApplicationsControl.length;
                for (let i = length-1; i >=0 ; i--) {

                    (this.applicantForm.controls['applicationQuestions'] as FormArray).removeAt(i);
                }
            }
         }
    }

    addAdditionalQuestions() {
        const applicantQuestions = this.applicant.applicationQuestions;

        if(applicantQuestions.length) {
            (this.applicantForm.controls["applicationQuestions"] as FormArray).clear();
            const formArray = this.applicantForm.get('applicationQuestions') as FormArray;

            for (let i = 0; i < applicantQuestions.length; i++) {
                if (applicantQuestions[i].useTranslate || applicantQuestions[i].fieldValue) {
                    (formArray).push(this.getFormControls(true));
                } else {
                    (formArray).push(this.getFormControls(false));
                }
               // this.applicantForm.updateValueAndValidity();
            }
        }

    }

    /**
     * select form controls based on useTranslate flag
     */
    getFormControls(useTranslate: boolean): FormGroup {

        let applicantQuestionsForm: FormGroup
        if (!useTranslate) {
            applicantQuestionsForm = this.formBuilder.group({
                answerText: ['', [Validators.required, Validators.maxLength(25)]] })
        } else {
            applicantQuestionsForm = this.formBuilder.group({
                securityQuestion: [null, Validators.required],
                answerText: ['', [Validators.required, Validators.maxLength(20)]]
            });
        }
        return applicantQuestionsForm
    }

    toggleContactPhoneValidator(event, data) {

       if(event.target.checked) {
        this.applicantForm.controls["contactPhoneNumber"].setValidators(Validators.required);
       } else {
        this.applicantForm.controls["contactPhoneNumber"].clearValidators();
       }
       this.applicantForm.controls["contactPhoneNumber"].updateValueAndValidity();

    }
   
    securityQuestionChoose(e,i){
        
         let requiredSequ =this.applicantForm.value.applicationQuestions[i].securityQuestion;
       
        this.securityQuestionsLists.forEach((val,index) =>{
            this.securityQuestionsLists[i] = this.institution.securityQuestions;
            if(index != i){
                 this.securityQuestionsLists[index] = this.securityQuestionsLists[index].filter(v => v.questionText != requiredSequ.questionText);
                
            }


        })

    }
    onApplicantFormValueChanged(value?) {
         for (const fieldKey of Object.keys(this.formErrors)) {
                const control: AbstractControl = this.applicantForm.get(fieldKey);
                this.formErrors[fieldKey] = fieldKey !== 'applicationQuestions' ? '' : [];

                if (fieldKey === 'applicationQuestions' && control['controls'].length) {

                    const questionGroup = control['controls']

                    for (let i = 0; i < questionGroup.length; i++) {

                        const questioncontrols = (questionGroup[i] as FormGroup).controls;
                        const targetControl = questioncontrols.answerText;
                        const targetSecurityControl = questioncontrols.securityQuestion ? questioncontrols.securityQuestion : null;
                        // if the group isn't valid
                        if ((!targetControl.valid && !targetControl.disabled || targetSecurityControl !== null &&
                             !targetSecurityControl.valid) &&( targetControl.errors || targetSecurityControl.errors)) {

                                if(targetControl.errors){
                                    for (const errorKey of Object.keys(targetControl.errors)) {
                                        this.formErrors.applicationQuestions[i] =(this.validationMessages.applicationQuestions[errorKey] + ' ');
                                    }
                                } else if(targetSecurityControl.errors) {
                                    for (const errorKey of Object.keys(targetSecurityControl.errors)) {
                                        this.formErrors.applicationQuestions[i] =(this.validationMessages.applicationQuestions[errorKey] + ' ');
                                    }
                                }
                        }
                    }

                } else if (control && !control.valid && !control.disabled) {

                    this.concatinateSingleErrors(fieldKey, control)
                }

               // this.changeDetectorRef.detectChanges();
            }
       setTimeout(() => {
        this.applicantLayoutService.setDisabled(this.applicantForm.valid);
        this.applicantLayoutService.SetFormData(this.applicantForm, this.applicant, this.institution);
        
       })
    }

    /** This adds the error message for every field except for the applicant questions */
    concatinateSingleErrors(fieldKey: string, control: AbstractControl) {
        if (!control) {
            console.log('break')
        }
        for (const errorKey of Object.keys(control.errors)) {
            // append them to the list of all errors for this field
            this.formErrors[fieldKey] += this.validationMessages[fieldKey][errorKey] + ' ';
        }

    }

    checkForAdditionalQuestions(){

        if((this.sessionState.isSSO && this.applicantIndex!=0) || this.applicant['idResultsFilled']) {
                this.setapplicationQuestions();
                this.addAdditionalQuestions();
        }
    }

    setValueForForm(applicant: Applicant) {

        //check whether to add or remove addtional questions
        //set timeout is used for change detection
        this.checkForAdditionalQuestions();

            setTimeout(() =>

            {this.applicantForm.patchValue(Object.assign({},this.applicantState.applicants[this.applicantIndex]));
           
            

            if((this.sessionState.isSSO && this.applicantIndex!=0) || this.applicant['idResultsFilled'] ) {

            this.onApplicantFormValueChanged();
            this.setValidators();

            }});
            if(this.applicantForm.controls["greenCardNumber"] &&
            this.applicantForm.controls['citizenType'].value === '2.1') {
                this.applicantForm.controls["greenCardNumber"].markAsUntouched({onlySelf:true});
                this.applicantForm.controls["greenCardNumber"].clearValidators();
                this.applicantForm.controls['greenCardNumber'].updateValueAndValidity();
            };
        if (this.applicantState.currentApplicantIndex >= 1 && this.sessionState.isSSO) {
            setTimeout(() => {
                this.applicantForm.enable();
                this.applicantForm.updateValueAndValidity();
            });
        }
        setTimeout(() => {
            this.applicantLayoutService.setDisabled(this.applicantForm.valid);
        })

    }

    initForm() {
        this.applicantForm = this.formBuilder.
            group(Object.assign({}, this.applicantState.applicants[this.applicantIndex],
                { applicationQuestions: this.formBuilder.array([]) }));

        this.PreviousApplicantIndex = this.applicantIndex;
        if(!this.institution.collectUSResidency ||
            (this.institution.collectUSResidency &&
                this.applicantForm.controls['citizenType'].value !== '2.1')){
            this.applicantForm.controls["greenCardNumber"].disable();
        }
        this.addAdditionalQuestions();
        this.setValidators();
    }

    /**
     *   this method is in progress
     */
     setInstitutionEmployeeValidators(primaryValue, secondaryValue, formControl, isCustomValidator, validateField?){


        if((this.institution[primaryValue] && this.applicantIndex === 0) || this.institution[secondaryValue] && this.applicantIndex != 0 ) {
            if(isCustomValidator)
           this.applicantForm.controls[formControl].setValidators([Validators.required, FormValidators[validateField]]);
           else
           this.applicantForm.controls[formControl].setValidators([Validators.required, Validators.maxLength(25)]);
        }else {
            if(isCustomValidator)
            this.applicantForm.controls[formControl].setValidators(FormValidators[validateField]);
            else
            this.applicantForm.controls[formControl].setValidators(Validators.maxLength(25));
            this.applicantForm.controls[formControl].updateOn['blur']
        }
    }

     //identificationExpirationDate
    setValidators() {
        if(!this.institution.enablePersonaIdScanning && this.applicantIndex === 0){
        this.applicantForm.setValidators(
            [Validators.required , FormValidators.emailMatches('emailAddress', 'verifyEmailAddress'),
            ]);     
        this.applicantForm.controls["emailAddress"].setValidators([Validators.required, Validators.email]);
        this.applicantForm.controls["verifyEmailAddress"].setValidators([Validators.required, Validators.email]);
        }
        this.applicantForm.controls["phoneNumber"].setValidators([Validators.required,FormValidators.validatePhoneNumber]);
        this.applicantForm.controls["identificationNumber"].setValidators([Validators.required,Validators.maxLength(20)]);
        this.applicantForm.controls["taxIdentificationNumber"].setValidators([Validators.required,Validators.minLength(11),Validators.maxLength(13)]);
        this.applicantForm.controls["dateOfBirth"].setValidators([Validators.required,FormValidators.validateDateOfBirth,
        FormValidators.validateProductAgeRangeCheck(this.selectedProducts,this.applicantIndex)]);
        this.applicantForm.controls["address1"].setValidators([Validators.required,FormValidators.validateNotPOBox, Validators.maxLength(40)]);
        this.applicantForm.controls["address2"].setValidators(Validators.maxLength(40));
        this.applicantForm.controls["mailingAddress1"].setValidators(Validators.maxLength(40));
        this.applicantForm.controls["zipCode"].setValidators([Validators.required, Validators.pattern(/\d{5}(-\d{4})?/)]);
        this.setInstitutionEmployeeValidators('primaryIssueDate', 'secondaryIssueDate' ,'identificationIssueDate', true, 'validateIdentificationIssueDate' );

        this.setInstitutionEmployeeValidators('primaryExpirationDateRequired', 'secondaryExpirationDateRequired' ,'identificationExpirationDate',true,'validateIdentificationExpirationDate');

        this.setInstitutionEmployeeValidators('primaryEmployer', 'secondaryEmployer' ,'employerName',false);

        this.setInstitutionEmployeeValidators('primaryEmployerOccupationRequired', 'secondaryEmployerOccupationRequired' ,'employerOccupationTitle', false );

        if((this.institution['primaryEmployerPhone'] && this.applicantIndex === 0) || (this.institution['secondaryEmployerPhone'] && this.applicantIndex != 0 )){
            this.applicantForm.controls["employerPhone"].setValidators([Validators.required]);
        }else {
            this.applicantForm.controls["employerPhone"].setValidators([]);
        }
        if(this.institution.collectUSResidency){
            this.applicantForm.controls["citizenType"].setValidators([Validators.required, Validators.pattern(/[^8]+/)]);
        }
        setTimeout(() => this.applicantForm.updateValueAndValidity());
    }

    handleAddressChange(event) {
        if (this.institution.uspsAddressValidation && this.isAddressValid) {
            this.addressSubject.next(this.address);
        } else {
            // set the key in the model to the new value
            if (this.mailingAddressSameAsCurrentAddress) {
                this.syncAddresses();
            }
        }
    }
    get isAddressValid() {
        return this.applicantForm.get('address1').valid &&
            this.applicantForm.get('address2').valid &&
            this.applicantForm.get('city').valid &&
            this.applicantForm.get('state').valid &&
            this.applicantForm.get('zipCode').valid;
    }

    syncAddresses() {
        this.applicantForm.get('mailingAddress1').setValue(this.address.address1);
        this.applicantForm.get('mailingAddress2').setValue(this.address.address2);
        this.applicantForm.get('mailingCity').setValue(this.address.city);
        this.applicantForm.get('mailingState').setValue(this.address.state);
        this.applicantForm.get('mailingZipCode').setValue(this.address.zipCode);
    }
    get address(): Address {
        
            return {
                address1: this.applicantForm.get('address1').value,
                address2: this.applicantForm.get('address2').value,
                city: this.applicantForm.get('city').value,
                state: this.applicantForm.get('state').value,
                zipCode: this.applicantForm.get('zipCode').value,
                
            };
       
       
    }
    

    set address(address: Address) {
       
        this.applicantForm.get('address1').setValue(address.address1);
        this.applicantForm.get('address2').setValue(address.address2);
        this.applicantForm.get('city').setValue(address.city);
        this.applicantForm.get('state').setValue(address.state);
        this.applicantForm.get('zipCode').setValue(address.zipCode);
    }

    toggleMailingAddressSameAsCurrentAddress(event) {
        const mailingAddress1 = this.applicantForm.get('mailingAddress1');
        const mailingAddress2 = this.applicantForm.get('mailingAddress2');
        const mailingCity = this.applicantForm.get('mailingCity');
        const mailingState = this.applicantForm.get('mailingState');
        const mailingZipCode = this.applicantForm.get('mailingZipCode');

        // if the are supposed to be the same
        if (event.srcElement.checked) {
            // sync up the mailing and physical address
            this.syncAddresses();
        } else {
            mailingAddress1.setValue('');
            mailingAddress2.setValue('');
            mailingCity.setValue('');
            mailingState.setValue('');
            mailingZipCode.setValue('');
        }
    }

    get needsNewAddressValidation() {
        return !this.addressSuggestion ||
            this.addressSuggestion &&
            this.addressSuggestion.address1 !== this.address.address1 ||
            this.addressSuggestion.address2 !== this.address.address2 ||
            this.addressSuggestion.city !== this.address.city ||
            this.addressSuggestion.state !== this.address.state;
    }
    private uspsValidateAddress(address: Address) {
       // if this is the first suggestion or a new suggestion
         if (this.needsNewAddressValidation) {
            // look it up with usps
            
            this.enteredAddress1=address.address1;
            this.enteredAddress2=address.address2;
            this.enteredcity=address.city;
            this.enteredstate=address.state;
            this.enteredzipCode=address.zipCode;
           this.UspsService.validateAddress(address).subscribe(response => {
                 this.suggestedAddress = {
                    address1: response.address1,
                    address2: response.address2,
                    city: response.city,
                    state: response.state,
                    zipCode: response.zip5
                    
                };
               
                this.handleUspsResponse(this.suggestedAddress);
            }, (error) => {
               // this.handleUspsResponse(this.response);
                console.error(error);
                // it blew up so let them go
                if (this.mailingAddressSameAsCurrentAddress) {
                    this.syncAddresses();
                }
               // this.concatinateErrors();
            }, () => {
               // this.concatinateErrors();
            });
        }
    }

    addressChange(e:any){
        
        
        this.useSuggestedAddress = false;
        const {name,value,checked} = e.target;
        
        if(name == "chosenAddress" && checked ==true ){
            this.applicantForm.get('address1').setValue(this.enteredAddress1);
        this.applicantForm.get('address2').setValue(this.enteredAddress2);
        this.applicantForm.get('city').setValue(this.enteredcity);
        this.applicantForm.get('state').setValue(this.enteredstate);
         this.applicantForm.get('zipCode').setValue(this.enteredzipCode);

        } 
       
    }
     addressChangesuggested(e){
        this.useSuggestedAddress = true;
        const {name,value,checked} = e.target;
        if(name == "chosenAddress" && checked ==true){
            this.applicantForm.controls.address1.setValue(this.suggestedAddress.address1);
           this.applicantForm.controls.address2.setValue(this.suggestedAddress.address2);
           this.applicantForm.controls.city.setValue(this.suggestedAddress.city);
           this.applicantForm.controls.state.setValue(this.suggestedAddress.state);
           this.applicantForm.controls.zipCode.setValue(this.suggestedAddress.zipCode);

          
              
        }
    }

    private handleUspsResponse(response) {
        if (response.address1 !== '' && response.zip5 !== '') {
            this.addressSuggestion = {
                address1: response.address1,
                address2: response.address2,
                city: response.city,
                state: response.state,
                zipCode: response.zip5
                
            };
            //this.useSuggestedAddress = true;
        } else {
            this.addressSuggestion = null;
            this.useSuggestedAddress = false;
           
        } 
    }
    get showEmailFields(){
        
        if (this.institution.enablePersonaIdScanning && this.applicant.isSSO){
            return true;
        }else if (this.institution.enablePersonaIdScanning && this.applicantIndex === 0){
            return false;
        }
        return true;
    }

    get showIdentityFields() {
        if(this.institution.enablePersonaIdScanning && !this.institution.collectUSResidency){
            return false;
        }
        return this.applicantIndex !== 0 || !this.sessionState.isSSO;
    }
    get applicantHasSSN() {
        return !(
            // don't bother for sso applicant 0
            this.showIdentityFields && (
                this.applicantForm.controls.citizenType.value !== '0' &&
                this.applicantForm.controls.citizenType.value !== '2.1' &&
                this.applicantForm.controls.citizenType.value !== '2.2' ||
                this.applicantForm.controls.citizenType.value === undefined ||
                this.applicantForm.controls.citizenType.value === null ||
                this.applicantForm.controls.citizenType.value === ''
            )
        );
    }

    citizenChangeHandler(event) {

    setTimeout(() => {

        if( event && event.target.value == 2.1){
            this.applicantForm.controls["greenCardNumber"].enable();
            this.applicantForm.controls["greenCardNumber"].setValidators(Validators.required);
         } else {
            this.applicantForm.controls["greenCardNumber"].markAsUntouched({onlySelf:true});
            this.applicantForm.controls["greenCardNumber"].clearValidators();
            this.applicantForm.controls["greenCardNumber"].disable();
         }
         this.applicantForm.controls["greenCardNumber"].updateValueAndValidity();

    } ) ;
    }


    get applicantHasGreenCard() {
            return this.applicantForm.get('citizenType').value === '2.1';
    }
    toggleShowIdFill() {
        // expand or collapse the id area
        this.applicant.showIdFill = !this.applicant.showIdFill;
        return false;
    }

    imageListener(fileEvent: any, isFront: boolean) {
        const maxSize = 2048;

        const file = fileEvent.target.files[0];
        // if they didn't upload an image
        if (file !== undefined && !file.type.match(/image.*/)) {
            this.modalService.presentError(new HttpErrorResponse({
                error: 'Only images may be uploaded as identification documents.'
            }), null, 'Image Validation Error', 'Ok').subscribe(null, null, () => { });
        } else {
            const fileReader = new FileReader();
            // when data is loaded from the read below
            fileReader.addEventListener('load', (readerEvent) => {
                const image = new Image();
                image.addEventListener('load', () => {
                    if (isFront) {
                        // set the base64 value to the form front value
                        this.applicantForm.value.frontImage = this.applicant.frontImage = Util.resizeImageToBase64JPEG(image, maxSize);
                    } else {
                        // set the base64 value to the form back value
                        this.applicantForm.value.backImage = this.applicant.backImage= Util.resizeImageToBase64JPEG(image, maxSize);
                    }
                });
                image.src = (readerEvent.target as any).result;
            });
            // read the image uploaded
            fileReader.readAsDataURL(file);
        }
    }

    /**
     *  to change the additional questions data format before sending to the api
     */

    submitIdImages(applicant: Applicant) {
        // send the images to Idology
        const currentApplicant = this.applicantLayoutService.getTransformedFormdata();
        this.applicantLayoutService.setDisabled(false);
        this.applicantsStore.dispatch({ type: ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT, payload: currentApplicant});
    }
    getIDSRC(isFront: boolean) {
        // update the preview image
        if (isFront) {
            if (this.applicantForm.value.frontImage !== '') {
                return this.prefixImageURIIfNeeded(this.applicantForm.value.frontImage);
            } else {
                return './assets/images/icon-id-front.png';
            }
        } else {
            if (this.applicantForm.value.backImage !== '') {
                return this.prefixImageURIIfNeeded(this.applicantForm.value.backImage);
            } else {
                return './assets/images/icon-id-back.png';
            }
        }
    }
    private prefixImageURIIfNeeded(imageURI: string) {
        return /data\:image\/jpeg\;base64\,/.test(imageURI) ?
            imageURI : 'data:image/jpeg;base64,' + imageURI;
    }


    checkForFlagErrors(formConrolName, error1, error2?): boolean {

        let valid = false;
        if (error2 && error2.length) {
            if (this.applicantForm.get(formConrolName).value.length &&
                this.formErrors[formConrolName].trim() === this.validationMessages[formConrolName][error2].trim() ||
                this.formErrors[formConrolName].trim() === this.validationMessages[formConrolName][error1].trim()) {
                valid = true;
            }
        } else if (this.applicantForm.get(formConrolName).value.length &&
            this.formErrors[formConrolName].trim() === (this.validationMessages[formConrolName][error1].trim())) {
            valid = true;
        }
        return valid;
    }


    setInstitutionFlagValidators(primaryFlag, secondrayFlag, formConrolName, error1, error2?): boolean {

        let valid = false;

        if (this.applicantIndex === 0) {
            if (!this.institution[primaryFlag]) {

                valid = this.checkForFlagErrors(formConrolName, error1, error2);
            } else {
                if (this.formErrors[formConrolName]) {
                    valid = true;
                }
            }
        } else if (this.applicantIndex === 1) {

            if (!this.institution[secondrayFlag]) {

                valid = this.checkForFlagErrors(formConrolName, error1, error2);
            } else {
                if (this.formErrors[formConrolName]) {
                    valid = true;
                }
            }
        }

        return valid
    }
    ngOnDestroy(): void {
        console.log(this, 'on destroy ')
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
