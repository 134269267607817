import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { HomeRoutingModule } from './home-routing.module';

import { TextMaskModule } from 'angular2-text-mask';

import { HomeComponent } from './home.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ReturningUserComponent } from './returning-user/returning-user.component';
import { EsignModule } from '../esign/esign.module';
import { NewBusinessComponent } from './new-business/new-business.component';
import { DigitalUserHomeComponent } from './digital-user-home/digital-user-home.component';
import { CanSignOnDigitallyGuard } from './guard/can-digital-sign-on-guard.service';
import { HomePromoCodeComponent } from './home-promo-code/home-promo-code.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HomeRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        TextMaskModule,
        EsignModule,
        ReactiveFormsModule
    ],
    declarations: [
        HomeComponent,
        NewUserComponent,
        NewBusinessComponent,
        ReturningUserComponent,
        DigitalUserHomeComponent,
        HomePromoCodeComponent
    ],
    providers: [
        CanSignOnDigitallyGuard
    ]
})
export class HomeModule { }
