import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject} from 'rxjs';

import { ModalService } from '../modal/modal.service';
import { AuthenticationHeaderManager } from '../authentication-header-manager';

import { Store } from '@ngrx/store';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState, Step } from '../state-management/state/progress.state';
import { SelectedProductsState, Product } from '../state-management/state/selected-products.state';
import { SessionState } from '../state-management/state/session.state';
import { InstitutionState } from '../state-management/state/institution.state';

import { ProductsState } from '../state-management/state/products.state';
import { ProductsActionTypes } from '../state-management/actions/products.actions';

import { SessionNotificationService } from '../services/session-notification.service';

import { SessionActionTypes } from '../state-management/actions/session.actions';
import { Util } from '../common/util';
import { Settings } from '../settings';
import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './completion.component.html',
    styleUrls: ['completion.component.scss']
})
export class CompletionComponent implements OnDestroy, OnInit {
    public pageName = 'finalized';
    public selectedProducts: Product[];
    public confirmationCode: string;
    public isLoading = false;
    public unsubscribe: Subject<any> = new Subject<any>();
    public institutionState: InstitutionState;

    useNewUiForBank = false;

    private confirmationSessionTimeout: any;
    private templateTimeout: number;
    digitalURL = '';
    digitalBankingSSOKey=''
    pixelId = 0;
    pageEventName = 'Confirmation';
    isSSO = false;

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<boolean>,
        private router: Router,
        private sessionNotificationService: SessionNotificationService,
    ) {

        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            // update the wizard panel
            if (info.currentStep < Step.Completion) {
                this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.Completion });
            }
        });

        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.confirmationCode = info.confirmationCode;
            this.isSSO = info.isSSO;
        });

        this.store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SelectedProductsState) => {
                if (info.isError && info.error.status === 412) {
                    this.store.dispatch({
                        type: SessionActionTypes.LOG_OUT,
                        payload: {
                            bypassSendingEmail: true
                        }
                    });
                    this.router.navigate(['home', 'This application is unable to be completed online.  Contact us for more information.']);
                } else {
                    this.selectedProducts = info.selectedProducts;
                }
            });
    }

    get cards() {
        return this.selectedProducts.filter(product => this.isCard(product));
    }

    get accounts() {
        return this.selectedProducts.filter(product => !this.isCard(product));
    }

    private isCard(product: Product) {
        return product.applicationCode === 'CRD';
    }

    ngOnInit() {
        // don't show the signout notifications since they are done with their session now
        this.sessionNotificationService.stopTimers();

        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {

            if(info.digitalBankingSSOKey) {
                this.digitalBankingSSOKey = info.digitalBankingSSOKey;
            }
        })

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institutionState = info;
            this.pixelId = info.institution.pixelId;
            this.digitalURL = info.institution.digitalURL;
            this.templateTimeout = this.institutionState.institution.sessionTimeoutMilliseconds;
            this.useNewUiForBank = info.institution.useNewUiForBank;
            // this is a special timer for kicking the user out from the confirmation screen.
            this.startSessionEndTimer(this.templateTimeout);
        });
    }

    openDigitalBanking() {
        window.open(this.digitalURL, '_blank');
        return false;
    }

    private startSessionEndTimer(timeout: number) {
        this.confirmationSessionTimeout = setTimeout(() => {
            this.store.dispatch({
                type: SessionActionTypes.LOG_OUT,
                payload: {
                    bypassSendingEmail: true
                }
            });
            this.router.navigate(['home', 'Session has timed out.']);
        }, timeout);
    }

    private stopSessionEndTimer() {
        clearTimeout(this.confirmationSessionTimeout);
    }

    private restartSessionEndTimer(timeout: number) {
        this.stopSessionEndTimer();
        this.startSessionEndTimer(timeout);
    }

    public openDiscloresWindow() {
        window.open(this.getDisclosuresLink(), '_blank');
        return false;
    }

    public resendEmail() {
        this.restartSessionEndTimer(this.templateTimeout);

        this.store.dispatch({ type: ProductsActionTypes.REQUEST_CONFIRMATION_EMAIL });

        this.modalService.openModal('Confirmation Sent', 'The confirmation email has been sent.', [
            {
                label: 'Ok',
                result: true,
                closesModal: true,
                class: 'modal-button'
            }
        ]).pipe(takeUntil(this.unsubscribe)).subscribe();

        return false;
    }

    public getDisclosuresLink() {
        this.restartSessionEndTimer(this.templateTimeout);
        return Settings.getAPIBaseURL() + 'Disclosures/Combined/' +
            Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    returnToLogin() {
        this.router.navigate(['home']);
    }

    get showDigitalLink(): boolean {

    // SSO session and no digittalBankingKey
    if(this.isSSO && !this.digitalBankingSSOKey) {
        return false;
    }
    // digital
    if(this.isSSO && this.digitalBankingSSOKey){
        this.digitalURL = this.digitalBankingSSOKey;
        return true
    }
    if(!this.isSSO && this.digitalURL){
        return true
    }
    return false;

}
}
