<section class="home-form-digitalsignon">
  <form *ngIf="loginForm" [formGroup]="loginForm" class="form-home" >
    <div class="form-home-container">
      <div class="form-home-content">
        <div class="form-items form-home-username">
          <input formControlName="userName" label="Username" class="form-input">
          <label for="userName" class="form-label">
            Username
          </label>
        </div>
        <div class="form-items form-home-password">
          <input formControlName="password" label="Password" type="password" class="form-input">
          <label for="password" class="form-label">
            Password
          </label>
        </div>
      </div>
      
      <div *ngIf="isInvalidCredentials" class="form-required-text center">
        Your username or password are incorrect
      </div>
  
      <csi-esign
        [verifyPDFOpened]="requireDemonstratedEsignConsent"
        [disclosureURL]="getEsignLink()"
        [disclosureDescription]="getEsignDescription()"
        (esignValidChanged)="hasESigned = !!$event ? $event : false"></csi-esign>
      <div class="form-home-content submit-btn">
        <div class="form-items form-home-buttons">
          <button type="submit" name="signUp" class="login-form-buttonsubmit"
            (click)="submit()"
            [disabled]="!hasESigned || isLoading || !loginForm.valid || disableContinue">
            Continue
          </button>
        </div>
      </div>
    </div>
  </form>
</section>

