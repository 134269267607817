import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { InstitutionActionTypes, GetInstutionSuccessAction, GetInstutionFailureAction } from '../actions/institution.actions';
import { InstitutionService } from '../../services/institution.service';
import { Institution } from '../state/institution.state';

import { NuFundAction } from 'src/app/common/nufund-action';
import { RevationStatus } from '../state/revation-status';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class InstitutionEffects {

    @Effect()
    appGetInfo$ = this.action$.pipe(ofType(InstitutionActionTypes.GET_INSTITUTION)).pipe(
        switchMap((action: NuFundAction) => {
            return this.service.getInstitution().pipe(
                map((info: any) => {
                    // The InstitutionService successfully completed the Http request
                    return new GetInstutionSuccessAction({ institution: info });
                }),
                catchError((response: any) => {
                    console.error("response", response);
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new GetInstutionFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    getRevationInfo$ = this.action$
        .pipe(ofType(InstitutionActionTypes.GET_REVATION_STATUS)).pipe(
            switchMap((action: NuFundAction) => {
                return this.service.getRevationStatus(action.payload.revationStatusURL).pipe(
                    map((info: RevationStatus) => {
                        return {
                            type: InstitutionActionTypes.GET_REVATION_STATUS_SUCCESS,
                            payload: info
                        };
                    })
                )
            })
        )
    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: InstitutionService) { }
}
