export interface IndustryType {
    value: string;
    description: string;
}

export const industryTypes: IndustryType[] = [
    { value: '111000', description: 'Agriculture and Forestry' },
    { value: '114000', description: 'Fishing and Hunting' },
    { value: '210000', description: 'Mining and Oil/Gas Extraction' },
    { value: '220000', description: 'Utilities' },
    { value: '230000', description: 'Construction' },
    { value: '310000', description: 'Manufacturing' },
    { value: '420000', description: 'Wholesale Trade' },
    { value: '440000', description: 'Retail Trade' },
    { value: '480000', description: 'Transportation and Warehousing' },
    { value: '510000', description: 'Information' },
    { value: '520000', description: 'Finance and Insurance' },
    { value: '530000', description: 'Real Estate and Rental/Leasing' },
    { value: '540000', description: 'Professional/Scientific Services' },
    { value: '540000', description: 'Technical Services' },
    { value: '550000', description: 'Company/Enterprise Management' },
    { value: '561000', description: 'Administrative/Support Services' },
    { value: '562000', description: 'Waste Mgmt/Remediation Services' },
    { value: '610000', description: 'Educational Services' },
    { value: '620000', description: 'Health Care and Social Assistance' },
    { value: '710000', description: 'Arts, Entertainment and Recreation' },
    { value: '720000', description: 'Accommodation and Food Services' },
    { value: '810000', description: 'Other Services' },
    { value: '920000', description: 'Public Administration' },
];
