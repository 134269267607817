<section class="modal-mask" *ngIf="showModal()">
    <div class="modal {{modalService.classOverride}}">
        <div class="modal-content">
            <div class="modal-header">
                <h1>{{ modalService.title }}</h1>
                <!--<h6>Sub heading</h6>-->
            </div>

            <div *ngIf="!isMultiBody" class="modal-body" [innerHTML]="getBody()"></div>

            <ng-container *ngIf="isMultiBody" class="modal-body">
                <ul *ngFor="let body of getBody()" class="modal-body-bundle">
                    <li [innerHTML]="body" class="modal-body-bundle-item"></li>
                </ul>
            </ng-container>

        </div>

        <div class="modal-controls">
            <div *ngFor="let button of getButtons()" class="modal-button">
                <a (click)="getClickAction(button)" name="{{ button.label }}" class="{{button.class}}">
                    {{ button.label }}
                </a>
            </div>
        </div>
    </div>
</section>