<section class="home-form-returninguser">
    <label class="home-header">
        <h1 class="home-form-header">Resume Saved Session</h1>
        <div class="home-text-returning">
            <p>Thank you for returning to complete the application process.</p>
            <p>Please enter your email address and confirmation code below. When you select the Continue button, you will be able to complete your application.</p>
            <p>If you do not have your confirmation code, enter your email address and select &ldquo;I forgot my confirmation code&rdquo; to have it sent to you.</p>
        </div>
    </label>


    <form (ngSubmit)="resumeSession()" #returningUserForm="ngForm" class="form-home">
        <div class="form-home-container">
            <div class="form-home-content">
                <div class="form-items form-home-email">
                    <input type="emailAddress" required [(ngModel)]="returningUser.emailAddress" name="emailAddress" id="emailAddress" class="form-input" autocapitalize="none" />
                    <label for="emailAddress" class="form-label">
                        Email Address
                    </label>
                </div>
            </div>

            <div class="form-home-content">
                <div class="form-items form-home-code">
                    <input type="text" [(ngModel)]="returningUser.confirmationCode" name="confirmationCode" id="confirmationCode" class="form-input" />
                    <label for="confirmationCode" class="form-label">
                        Confirmation Code
                    </label>
                </div>
            </div>

            <div class="form-home-content">
                <div class="form-items form-home-buttons">
                    <button tyle="submit" [disabled]="!returningUserForm.form.valid || !returningUser.confirmationCode || isLoading" name="continue"
                        class="login-form-buttonsubmit">
                        Continue
                    </button>
                   
                    <button type="button" name="forgot" [disabled]="isLoading || !returningUser.emailAddress" (click)="forgotSessionId()" class="login-form-buttonforgot">
                        I forgot my confirmation code
                    </button>
                </div>
            </div>
        </div>
    </form>
</section>