import { Institution } from '../state/institution.state';
import { NuFundAction } from 'src/app/common/nufund-action';

export const InstitutionActionTypes = {
    GET_INSTITUTION: '[Institution] GET_INSTITUTION',
    GET_INSTITUTION_SUCCESS: '[Institution] GET_INSTITUTION_SUCCESS',
    GET_INSTITUTION_FAILURE: '[Institution] GET_INSTITUTION_FAILURE',

    GET_REVATION_STATUS: '[Institution] GET_REVATION_STATUS',
    GET_REVATION_STATUS_SUCCESS: '[Institution] GET_REVATION_STATUS_SUCCESS'
};

export class GetInstutionSuccessAction implements NuFundAction {
    type = InstitutionActionTypes.GET_INSTITUTION_SUCCESS;
    constructor(public payload: { institution: Institution }) { }
}

export class GetInstutionFailureAction implements NuFundAction {
    type = InstitutionActionTypes.GET_INSTITUTION_FAILURE;
    constructor(public payload: { error: any }) { }
}
