<div class="form-items">
    <label *ngIf="element.label" class="{{element.class}}" [ngClass]="{'required': element?.field?.required}">{{element.label}}</label>
    <div *ngIf="element.field" [ngClass]="{'requiredContainer': element.field?.required, 'requiredSelect': element.field?.required && fieldHasOptions(element.field) }">

        <!-- Text Input -->
        <div *ngIf="!fieldHasOptions(element.field)">
            <!-- input field -->
            <input type="{{ getFieldType(element) }}" class="form-input" [(ngModel)]="element.field.response" [value]="element.field.default"
                [name]="elementName" #formControlModel="ngModel" [required]="element.field.required" [pattern]="getPattern(element)"
                [textMask]="getTextMask(element)" />

            <!-- validation error message -->
            <div *ngIf="formControlModel.touched && formControlModel.invalid && element.field.invalidInputMessage" class="alert alert-danger">
                {{ element.field.invalidInputMessage }}
            </div>

            <!-- validation indicators -->
            <div class="indicator-error" *ngIf="formControlModel.touched && formControlModel.invalid"></div>

            <div class="indicator-valid" *ngIf="formControlModel.touched && !formControlModel.invalid && element.field.required">
                <div class="icon icon-check"></div>
            </div>

        </div>

        <!-- Select Box -->
        <div *ngIf="fieldHasOptions(element.field)">
            <!-- select field -->
            <select class="form-select" [(ngModel)]="element.field.response" #formControlModel="ngModel" [required]="element.field.required"
                [name]="elementName">
                <option style="display:none" [ngValue]="null"></option>
                <option *ngFor="let option of element.field.options" [selected]="element.field.default && option.value === element.field.default"
                    [ngValue]="option.value">{{ option.display }}</option>
            </select>

            <!-- validation indicators -->
            <div class="indicator-error" *ngIf="formControlModel.touched && formControlModel.invalid"></div>

            <div class="indicator-valid" *ngIf="formControlModel.touched && !formControlModel.invalid && element.field.required">
                <div class="icon icon-check"></div>
            </div>

        </div>
    </div>
</div>