<div class="container">
    <!-- <header>
        <div class="error-branding">
            <img *ngIf="institutionState.institution.logo" src="data:image/jpeg;base64,{{ institutionState.institution.logo }}" />
        </div>
    </header> -->

    <main class="error-content">
        <img src="./assets/images/pagemissing@2x.png" alt="Oops!" class="error-image">
        <h2 class="error-title">Page Not Found</h2>
        <div class="error-text">
            <p>You&rsquo;ve reached this page in error or the resource you selected could not be located.</p>
        </div>
        <button (click)="returnToLogin()" class="error-button">Return to the Welcome Screen</button>
    </main>
</div>