import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { SessionState, initialSessionState } from '../state/session.state';
import { SessionActionTypes } from '../actions/session.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const sessionStoreReducer: ActionReducer<SessionState> = reducer;

export function reducer(state = initialSessionState, action: NuFundAction) {
    switch (action.type) {
        
        case SessionActionTypes.ESTABLISH_SESSION: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, {
                instantAddCode: action.payload.instantAddCode,
                skipProductSelection: action.payload.skipProductSelection,
                isLoading: true,
                isError: false
            });
        }

        case SessionActionTypes.ESTABLISH_SESSION_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, action.payload.applicants[0], {
                isLoading: false,
                isError: false,
                error: null,
                confirmationCode: action.payload.confirmationCode,
                nuFundStatus: action.payload.nuFundStatus
            });
        }

        case SessionActionTypes.ESTABLISH_SESSION_FAILURE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.message });
        }

        case SessionActionTypes.UPDATE_INSTANT_ADD_CODE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return {
                ...state,
                instantAddCode: action.payload.instantAddCode,
                skipProductSelection: action.payload.skipProductSelection,
            }
        }

        case SessionActionTypes.LOG_OUT: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, initialSessionState, { isLoading: false, isError: false, error: null });
        }

        case SessionActionTypes.RETRIEVE_FORGOTTEN_CONFIRMATION_CODE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state);
        }

        case SessionActionTypes.RESUME_SESSION: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case SessionActionTypes.RESUME_SESSION_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, action.payload.resumeData.applicants[0], {
                isLoading: false,
                isError: false,
                error: null,
                isResume: true,
                confirmationCode: action.payload.resumeData.confirmationCode,
                nuFundStatus: action.payload.resumeData.nuFundStatus,
                totalApplicants: action.payload.resumeData.applicants.length,
                secondaryApplicantZipCode: action.payload.resumeData.applicants.length > 1 ? action.payload.resumeData.applicants[1].zipCode : undefined
            });
        }

        case SessionActionTypes.DIGITAL_SIGN_ON_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const settingsObj: any = {
                isSSO: true,
                isLoading: true,
                isError: false,
                error: null
            };
            // if (action.payload && action.payload.applicants && action.payload.applicants.length) {
            //     action.payload.applicants = action.payload.applicants
            //         .map(applicant => Object.assign({}, applicant, {isVerified: true}))
            // }
            return Object.assign({}, state, action.payload, settingsObj);
        }

        case SessionActionTypes.DIGITAL_SIGN_ON_FAILURE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: true, isError: true, error: action.payload });
        }

        case SessionActionTypes.ESTABLISH_SSO_SESSION: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case SessionActionTypes.ESTABLISH_SSO_SESSION_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign(
                { },
                state,
                action.payload.resumeData.applicants[0],
                {
                    isLoading: false,
                    isError: false,
                    error: null,
                    isSSO: true,
                    confirmationCode: action.payload.resumeData.confirmationCode,
                    nuFundStatus: action.payload.resumeData.nuFundStatus
                }
            );
        }

        // set isLoading to true so the page stays loading while it navigates to completion
        case SessionActionTypes.GET_DIGITAL_USER_EXISTS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        case SessionActionTypes.GET_DIGITAL_USER_EXISTS_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, action.payload, {
                // setting this boolean to isSSO. This is necessary because we want to hide the digital association for sso users and so the loading should be true when sso is true.
                isLoading: state.isSSO, 
                isError: action.payload.error !== null && action.payload.error !== undefined,
                error: action.payload.error,
                digitalUserStatus: action.payload
            });
        }

        case SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        // set isLoading to true so the page stays loading while it navigates to completion
        case SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, action.payload, {
                isLoading: true, isError: false, error: null, accountsCreated: true
            });
        }

        case SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS_FAILURE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, action.payload, {
                isLoading: false, isError: true, error: action.payload
            });
        }

        case SessionActionTypes.APPLY_BUSINESS_RULES_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state);
        }

        case SessionActionTypes.ADD_HISTORY_ENTRY: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: true, isError: false, error: null });
        }

        // set isLoading to true so the page stays loading while it navigates to completion
        case SessionActionTypes.ADD_HISTORY_ENTRY_SUCCESS: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, {isLoading: true, isError: false, error: null});
        }

        case SessionActionTypes.ADD_HISTORY_ENTRY_FAILURE: {
            if (action && action.type && isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, {isLoading: false, isError: true, error: action.payload});
        }

        default: {
            return state;
        }
    }
}
