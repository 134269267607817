import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { PromoCodeState, initialPromoCodeState } from '../state/promo-code.state';
import { PromoCodeActionTypes } from '../actions/promo-code.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const promoCodeStoreReducer: ActionReducer<PromoCodeState> = reducer;

export function reducer(state = initialPromoCodeState, action: NuFundAction) {
    switch (action.type) {
        case PromoCodeActionTypes.APPLY_PROMO_CODE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true });
        }

        case PromoCodeActionTypes.APPLY_PROMO_CODE_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        default: {
            return state;
        }
    }
}
