
export interface SessionState {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
    promotionalCode: string;
    instantAddCode: string;
    dateOfBirth: string;
    skipProductSelection: boolean;

    secondaryApplicantZipCode: string;

    nuFundStatus: string;
    totalApplicants: number;
    totalVerifiedApplicants: number;

    confirmationCode: string;
    isLoading: boolean;
    isError: boolean;
    error: any;
    /**
     * Dictates how the user proceeds through the app and often is used to skip steps in the flow.
     * @important Can ONLY be set by grabbing an SSO session from Digital Banking OR by proceeding through the app
     *      by selecting "Use My Digital Banking Login" on the home page. (See `this.digitalBankingSSOKey` for more details)
     */
    isSSO: boolean;
    digitalUserStatus: DigitalUserStatus;
    accountsCreated: boolean;
    isResume: boolean;
    /** 
     * Is set to true if they come into the app normally, then select "Use My Digital Banking Login" to proceed 
     * @note This can ONLY be true if `this.isSSO` is also true.
     */
    digitalBankingSSOKey:string;
}

export interface DigitalUserStatus {
    isKnownUserId: boolean;
    isSingleSignOnUser: boolean;
    isExistingDigitalUser: boolean;
    error: any;
 }

export const initialSessionState: SessionState = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zipCode: '',
    promotionalCode: '',
    dateOfBirth: '',

    secondaryApplicantZipCode: '',

    nuFundStatus: '',
    totalApplicants: 1,
    totalVerifiedApplicants: 0,

    confirmationCode: '',
    instantAddCode: '',
    skipProductSelection: false,
    isLoading: false,
    isError: false,
    error: null,
    isSSO: false,
    digitalUserStatus: null,
    accountsCreated: false,
    isResume: false,
    digitalBankingSSOKey: ''
};

export class ReturningUser {
    emailAddress: string;
    confirmationCode: string;
}

export class DigitalAccountCredentials {
    existingUser: boolean;
    userName: string;
    password: string;
}

export class HistoryEntry {
    entryType: string;
    message: string;
}
