import { NuFundAction } from 'src/app/common/nufund-action';
import { Product } from '../state/selected-products.state';

export const SelectedProductsActionTypes = {
    GET_SELECTED_PRODUCTS: '[SelectedProducts] GET_SELECTED_PRODUCTS',
    INCREMENT_SELECTED_PRODUCT: '[SelectedProducts] INCREMENT_SELECTED_PRODUCT',
    INCREMENT_SELECTED_BUNDLE: '[SelectedProducts] INCREMENT_SELECTED_BUNDLE',
    DECREMENT_SELECTED_PRODUCT: '[SelectedProducts] REMOVE_SELECTED_PRODUCT',
    DECREMENT_SELECTED_BUNDLE: '[SelectedProducts] REMOVE_SELECTED_BUNDLE',
    REMOVE_ALL_SELECTED_PRODUCTS: '[SelectedProducts] REMOVE_ALL_SELECTED_PRODUCTS',
    REMOVE_ALL_SELECTED_PRODUCTS_BY_PRODUCT: '[SelectedProducts] REMOVE_ALL_SELECTED_PRODUCTS_BY_PRODUCT',
    SET_SELECTED_PRODUCTS: '[SelectedProducts] SET_SELECTED_PRODUCTS',
    GET_SELECTED_PRODUCTS_SUCCESS: '[SelectedProducts] GET_SELECTED_PRODUCTS_SUCCESS',

    GET_SERVER_SELECTED_PRODUCTS: '[SelectedProducts] GET_SELECTED_PRODUCTS',
    GET_SERVER_SELECTED_PRODUCTS_SUCCESS: '[SelectedProducts] GET_SERVER_SELECTED_PRODUCTS_SUCCESS',
    GET_SERVER_SELECTED_PRODUCTS_FAILURE: '[SelectedProducts] GET_SERVER_SELECTED_PRODUCTS_FAILURE',
    REHYDRATE: '[SelectedProducts] REHYDRATE'
};

export class GetSelectedProductsSuccessAction implements NuFundAction {
    type = SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS_SUCCESS;
    constructor(public payload: { products: Product[] }) { }
}

export class GetSelectedProductsFailureAction implements NuFundAction {
    type = SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS_FAILURE;
    constructor(public payload: { error: any }) { }
}

