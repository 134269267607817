import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


import { ReturningUser, DigitalAccountCredentials, DigitalUserStatus, HistoryEntry } from '../state-management/state/session.state';
import { Applicant, ApplicantsState, defaultApplicant } from '../state-management/state/applicants.state';
import { HttpClient } from '@angular/common/http';
import { Session } from '../models/session.model';
import { initialApplicantsState } from '../state-management/state/applicants.state';
import { map } from 'rxjs/operators';
@Injectable()
export class SessionService {

    constructor(public http: HttpClient) {
    }

    public establishSession(applicant: Applicant): Observable<Session> {
        return this.http.post<Session>('Session', applicant);
    }

    public resumeSession(returningUser: ReturningUser): Observable<any> {
        return this.http.get<any>('Session/' + returningUser.confirmationCode + '/' + returningUser.emailAddress + '/');
    }

    // returns nothing
    public forgotConfirmationCode(emailAddress: string): Observable<any> {
        return this.http.get<any>('Session/' + emailAddress + '/');
    }

    public establishSSOSession(code: string): Observable<any> {
        return this.http.get<any>('Session/establish/sso/' + code + '/');
    }

    public sessionSignout(bypassSendingEmail: boolean): Observable<any> {
        return this.http.post<any>('Session/logout/' + bypassSendingEmail, {});
    }

    establishDigitalUserSession(userName: string, password: string, isBusiness: boolean): Observable<Session> {
        return this.http.post<Session>('session/EstablishDigitalSession', {username: userName, password: password, isBusiness: isBusiness});
    }

    digitalUserExistsForSession(): Observable<any> {
        return this.http.get<any>('users/digital');
    }

    digitalUserStatus(): Observable<DigitalUserStatus> {
        return this.http.get<DigitalUserStatus>('users/digital/status');
    }

    addDigitalAccounts(digitalAccountCredentials: DigitalAccountCredentials): Observable<any> {
        return this.http.post<any>('userAccounts/' + digitalAccountCredentials.existingUser, digitalAccountCredentials);
    }

    applyBusinessRules(): Observable<any> {
        return this.http.post('session/ApplyBusinessRules', {});
    }

    addHistoryEntry(historyEntry: HistoryEntry): Observable<any> {
        return this.http.post('session/History', historyEntry);
    }
}