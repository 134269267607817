import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ProductsActionTypes, GetProductsSuccessAction, GetProductsFailureAction, SubmitProductsFailureAction,
    SubmitSelectedProductsFeaturesFailureAction, SubmitProductsDisclosuresFailureAction,
    SubmitProductsFundingFailureAction } from '../actions/products.actions';
import { SelectedProductsActionTypes, GetSelectedProductsFailureAction } from '../actions/selected-products.actions';
import { ProductsService } from '../../services/products.service';
import { Product } from '../state/products.state';

import { Router } from '@angular/router';
import { bundlesToProducts } from '../../products/bundel-util';
import { combineLatest, forkJoin, merge, of } from 'rxjs';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ProductsEffects {

    @Effect()
    appGetInfo$ = this.action$.pipe(
        ofType(ProductsActionTypes.GET_PRODUCTS),
        switchMap((action: NuFundAction) => {
            let instantAddCodes = [];

            if (action.payload && action.payload.instantAddCodes) {
                instantAddCodes = action.payload.instantAddCodes;
            }
            return this.service.getProducts(instantAddCodes).pipe(
                map((info: Product[]) => {
                    const productsModels = info.map(product => new Product(product));
                    return new GetProductsSuccessAction({ products: productsModels });
                }),
                catchError((response: any) => {
                    return of(new GetProductsFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appSubmitSelectedProducts$ = this.action$.pipe(
        ofType(ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS),
        switchMap((action: NuFundAction) => {
            const products = action.payload.products.concat(bundlesToProducts(action.payload.bundles));

            return this.service.postSelectedProducts(products).pipe(
                map(() => {
                    return { type: ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_SUCCESS };
                }),
                catchError((response: any) => {
                    return of(new SubmitProductsFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appSubmitSelectedProductsFeatures$ = this.action$.pipe(
        ofType(ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES),
        switchMap((action: NuFundAction) => {
            return this.service.postSelectedProductFeatures(action.payload).pipe(
                map(() => {

                    this.router.navigateByUrl('personal-information');
                    return { type: ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES_SUCCESS };
                }),
                catchError((response: any) => {
                    return of(new SubmitSelectedProductsFeaturesFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appSubmitProductsDisclosuresany$ = this.action$.pipe(
        ofType(ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES),
        switchMap((action: NuFundAction) => {
            return this.service.postProductDisclosureResults(action.payload).pipe(
                map(() => {
                    return { type: ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES_SUCCESS };
                }),
                catchError((response: any) => {
                    return of(new SubmitProductsDisclosuresFailureAction({ error: response }));
                })     
            )
        })
    )

    @Effect()
    appSubmitProductsFundingany$ = this.action$.pipe(
        ofType(ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING),
        switchMap((action: NuFundAction) => {
            return this.service.postFundingProducts(action.payload).pipe(
                map(() => {
                    return { type: ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING_SUCCESS };
                }),
                catchError((response: any) => {
                    return of(new SubmitProductsFundingFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appRequestConfirmation$ = this.action$.pipe(
        ofType(ProductsActionTypes.REQUEST_CONFIRMATION_EMAIL),
        switchMap((action: NuFundAction) => {
            return this.service.requestConfirmationEmail().pipe(
                map(() => {
                    return { type: ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING_SUCCESS };
                }),
                catchError((response: any) => {
                    return of(new SubmitProductsFundingFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appGetSelectedProducts$ = this.action$.pipe(
        ofType(SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS),
        switchMap((action: NuFundAction) => {
            return this.service.getSelectedProducts().pipe(
                map((result) => {
                    return { type: SelectedProductsActionTypes.SET_SELECTED_PRODUCTS, payload: result };
                }),
                catchError((response: any) => {
                    return of(new GetSelectedProductsFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appUploadBusinessIncorpDocs$ = this.action$.pipe(
        ofType(ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS),
        switchMap((action: NuFundAction) => {
            const fileCalls$ = forkJoin(...action.payload.map(x => this.service.uploadBusinessIncorpDocs(x)));
            return fileCalls$.pipe(
                map((result) => {
                    return { type: ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS_SUCCESS, payload: result };
                }),
                catchError((response: any) => {
                    return of({type : ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS_FAILURE, payload: response});
                })
            )
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: ProductsService, private router: Router) { }
}
