import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { idvFindRouteToNavigateTo, isIdvFlow, truncateInstitutionName } from 'src/app/common/functions';
import { IdvRequest, IdvStrategy } from 'src/app/models/idv.model';
import { ApplicantsActionsTypes } from 'src/app/state-management/actions/applicants.actions';
import { ApplicantsState } from 'src/app/state-management/state/applicants.state';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { InstitutionState } from 'src/app/state-management/state/institution.state';
import { IdvLayoutService } from '../service/idv-layout.service';
import { IdvLoadingService } from '../service/idv-loading.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})

export class OtpComponent implements OnInit, OnDestroy {

  /** Manages subscriptions in the component to prevent observable based memory leaks */
  private readonly _unsubscribe$ = new Subject();
  /** Shows if the component is waiting on data. */
  public isLoading: boolean = false;
  /** The institution state that comes out of the redux store */
  private _institutionState: InstitutionState = null;
  /** The applicant state that comes out of the redux store */
  private _applicantsState: ApplicantsState = null;

  /** The otp string that we will send to the API at the end of the page.
   * It also manages the `disabled` state of the `Submit` button in the template.
   */
  public otp: string = '';
  /** The amount of times the user has attempted to resend the OTP */
  public currentTry = 0;

  /** Indicates to the view if there was an error in the last OTP submission. */
  public wasError: boolean = false;
  /** The maximum times they can attempt to resend the OTP */
  public readonly MAX_NUMBER_OF_RETRIES: number = 3;


  constructor(
    private router: Router,
    private store: Store<CombinedState>,
    private idvLayoutService: IdvLayoutService,
    private idvLoadingService: IdvLoadingService,
  ) {

    this.idvLoadingService.isLoading$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
    this.store.select('institutionStoreReducer')
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((institutionState: InstitutionState) => {
        this._institutionState = institutionState;
      });

    this.store.select('applicantsStoreReducer')
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((applicantsState: ApplicantsState) => {

        this._applicantsState = applicantsState;

        // is initial load, skip logic
        if (!this.currentTry) {
          return;
        }
        // if there was an error processing the response, assume bad passcode.
        if (applicantsState?.idvResponse?.statusCode >= 400) {
          // bad request/response.
          this.wasError = true;
          return;
        } else {
          if (applicantsState.idvResponse.kiqQuestions.length) {
              this.router.navigate(['idv/kiq']);
              return;
          }

          this.wasError = false;
          // response was good and passcode was correct 
          if (applicantsState.idvResponse.exceptionMessage === 'ACCEPT') {
            this.ngOnDestroy();
            
            // if we are currently navigating, then we have already made a decision. abort.
            if (this.router.getCurrentNavigation()) {
              return;
            }

            const routeToNavigateTo = idvFindRouteToNavigateTo(applicantsState);

            if (routeToNavigateTo.includes("personal-information")) {
              this.store.dispatch({
                type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT,
                payload: 1
              });

            }
            this.router.navigate(routeToNavigateTo);

            return;
          }

          if (applicantsState.idvResponse.exceptionMessage === 'NODECISION') {
            if (this.currentTry > this.MAX_NUMBER_OF_RETRIES) {
              this.store.dispatch({
                type: ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE,
                payload: <IdvRequest>{
                  applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
                  clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
                  idvSessionId: this._applicantsState.idvResponse.sessionId,
                  kiqAnswers: null,
                  bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
                  oneTimePasscode: null,
                }
              })
              // this.router.navigate(['idv/kiq']);
              return;
            }
          }
        }
      });

    this.idvLayoutService.buttonClick$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        // Initial Load
        if (!this.currentTry) {
          this.currentTry++;
          return;
        } else {
          this.idvLoadingService.startLoading();
        }
        // After initial load, but before the max number of retries has been met
        if (this.currentTry <= this.MAX_NUMBER_OF_RETRIES) {
          this.currentTry++;
          // updates the store, the next line of execution in this file will be in the applicantsStoreReducer subscribe
          this.store.dispatch({
            type: ApplicantsActionsTypes.SUBMIT_OTP_CODE,
            payload: <IdvRequest>{
              applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
              clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
              idvSessionId: this._applicantsState.idvResponse.sessionId,
              kiqAnswers: null,
              bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
              oneTimePasscode: this.otp,
            }
          })
          return;
        }

        // We want the user to have to click one time and acknowledge the screen that they have 
        //  attempted above the maximum allowance of tries.
        if (this.currentTry > this.MAX_NUMBER_OF_RETRIES) {
          this.store.dispatch({
            type: ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE,
            payload: <IdvRequest>{
              applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
              clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
              idvSessionId: this._applicantsState.idvResponse.sessionId,
              kiqAnswers: null,
              bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
              oneTimePasscode: null,
            }
          })
          return;
        }
      })

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /** Send the Otp to the API to check validity */
  public sendOtpRequest(): void {

  }

  /** Attempt to resend the OTP to the user's phone if they haven't met the retry allowance */
  public didNotReceiveCode(): void {
    if (this.currentTry <= this.MAX_NUMBER_OF_RETRIES) {
      this.store.dispatch({
        type: ApplicantsActionsTypes.DID_NOT_RECEIVE_CODE,
        payload: {
          isStepUp: this._institutionState.institution.idvStrategy === IdvStrategy.IdScreeningWithStepUp,
          idvRequest: <IdvRequest>{
            applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
            clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
            idvSessionId: this._applicantsState.idvResponse.sessionId,
            kiqAnswers: null,
            bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
            oneTimePasscode: null,
          }
        }
      })

      this.currentTry++;
    }
    return;
  }

  /** User cannont receive OTP for some reason, move the user to the KIQ questions */
  public canNotReceiveCode(): void {
    this.store.dispatch({
      type: ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE,
      payload: <IdvRequest>{
        applicant: this._applicantsState.applicants[this._applicantsState.currentApplicantIndex],
        clientReferenceId: this._applicantsState.idvResponse.clientReferenceId,
        idvSessionId: this._applicantsState.idvResponse.sessionId,
        kiqAnswers: null,
        bankName: truncateInstitutionName(this._institutionState.institution.institutionName),
        oneTimePasscode: null,
      }
    });

    this.idvLoadingService.startLoading();

    // this.router.navigate('')
    return;
  }

}
