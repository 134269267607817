import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateSessionGuard } from 'src/app/services/can-activate-session-guard.service';

import { NewApplicantComponent } from './new-applicant.component';

const routes: Routes = [
    {
        path: 'personal-information',
        component: NewApplicantComponent,
        canActivate: [
            CanActivateSessionGuard
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class NewApplicantRoutingModule { }
