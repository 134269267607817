import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RoutingLookupActionTypes, RoutingLookupFailureAction } from '../actions/routing-lookup.actions';
import { RoutingLookupService } from '../../services/routing-lookup.service';
import { NuFundAction } from 'src/app/common/nufund-action';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class RoutingLookupEffects {

    @Effect()
    appApplyPromoCode$ = this.action$.pipe(
        ofType(RoutingLookupActionTypes.SUBMIT_SUGGESTION),
        switchMap((action: NuFundAction) => {
            return this.service.lookupRoutingNumber(action.payload).pipe(
                map((result) => {
                    return { type: RoutingLookupActionTypes.SUBMIT_SUGGESTION_SUCCESS, payload: result };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new RoutingLookupFailureAction({ error: response }));
                })
            )
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: RoutingLookupService) { }
}
