import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { tap, timeout } from 'rxjs/operators';
import { Settings } from '../settings';
import { AuthenticationHeaderManager } from '../authentication-header-manager';
import { Identity } from '../models/identity';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
/** The goal of this class is to set the theme css in app load. This should be called as soon as possible */
export class CssService {
    constructor(
        @Inject(DOCUMENT) private document,
        private http: HttpClient,
        ) {
        
    }
    /** 
     * Sets the css on the page to what the institution has it set up as in admin.
     * @sideeffect Sets the css tag before emitting to observers.
     * @returns The css that comes from the api.
    */
    public getCss(): Observable<any> {
        return this.http.get<any>('css', {
            responseType: 'text'
        } as any).pipe(
            tap(x => {

                const style = this.document.getElementById('theme-style');
                style.innerHTML = x;
                return x;
            })
        )
    }
}