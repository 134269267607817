import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductBundleComponent } from './product-bundle.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ProductBundleComponent
  ],
  exports: [
    ProductBundleComponent
  ]
})
export class ProductBundleModule { }
