import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'csi-validation-indicator',
    templateUrl: './validation-indicator.component.html',
    styleUrls: ['./validation-indicator.component.scss'],
})
export class ValidationIndicatorComponent {
    @Input() control: FormControl;

    get isIndicatedValid() {
        return this.control.valid;
    }

    get isInvalid() {
        return this.control.invalid && (this.control.touched || this.control.dirty);
    }

    get firstError() {
        if (this.isInvalid && this.control.errors) {
            const firstError = Object.entries(this.control.errors)[0];
            if (firstError[0] === 'required') {
                return 'This is required.';
            }
            if (typeof firstError[1] === 'string') {
                return firstError[1];
            } else if (typeof firstError[1] === 'object') {
                if (firstError[1].requiredPattern) { // the text format module
                    return 'This value is invalid.';
                } else if (firstError[1].minlength) { // value too short
                    return 'This value is too short.';
                } else if (firstError[1].maxLength) { // value too long
                    return 'This value is too long.';
                } else {
                    console.log(this.control.errors);
                    return 'Invalid';
                }
            } else {
                console.log(this.control.errors);
                return 'This is invalid.';
            }
        }
    }
}
