
export interface ChallengeDepositState {
    challengeDepositAttempt: ChallengeDepositAttempt;
    isLoading: boolean;
    isError: boolean;
    error: any;
    isVerified: boolean;
}

export interface ChallengeDepositAttempt {
    amount1: string;
    amount2: string;
    confirmationCode: string;
}

export const initialChallengeDepositState = {
    challengeDepositAttempt: {
        amount1: '',
        amount2: '',
        confirmationCode: ''
    },
    isLoading: false,
    isError: false,
    error: null,
    isVerified: false
};
