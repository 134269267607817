<main id="view-digitalUser">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content digitalUser-content">

        <!-- xl container -->
        <div class="xl-container">
            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title" *ngIf="!isLoading"></page-header>

                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>

            <csi-loading [isLoading]="isLoading"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
            <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div *ngIf="!isLoading" class="page-body-content">
                <section class="digitalUser-body">
                    <!-- applicant -->
                    <fieldset class="form-fieldset-dbinfo">
    
                        <!-- header -->
                        <!-- existing db user -->
                        <div class="linkaccount-header" *ngIf="askForExistingCredentials && allowSkipAccountAssocciation">
                            <h1 class="linkaccount-subhead">Existing Digital Banking User</h1>
                            <p>It looks like you already have a Digital Banking account with us. Please provide your Digital Banking <strong>username</strong> and <strong>password</strong>, and we'll link these new account(s) to your profile.</p>
                        </div>
    
                        <!-- not db user -->
                        <div class="linkaccount-header" *ngIf="!askForExistingCredentials && allowSkipAccountAssocciation">
                            <h1 class="linkaccount-subhead">Sign Up for Digital Banking</h1>
                            <p>If you would like to enroll in Digital Banking, our online banking application, please choose a <strong>username</strong> and <strong>password</strong> to begin the setup process.</p>
                        </div>

                        <!-- db funded account - existing db user -->
                        <div class="linkaccount-header" *ngIf="askForExistingCredentials && !allowSkipAccountAssocciation">
                            <h1 class="linkaccount-subhead">Enter Your Digital Banking Login</h1>
                            <p>A product you selected is being funded with your digital banking account. Please provide the <strong>username</strong> and <strong>password</strong> associated with your Digital Banking account to complete this process.</p>
                            <p><small>If you do not remember your login information, please use the Digital Banking application to reset your password.</small></p>
                        </div>

                        <!-- db funded account - not db user -->
                        <div class="linkaccount-header" *ngIf="!askForExistingCredentials && !allowSkipAccountAssocciation">
                            <h1 class="linkaccount-subhead">Create A Digital Banking Account</h1>
                            <p>A product you selected will need to be funded within Digital Banking, our online banking application, after it has been approved. It looks like you don't currently have a Digital Banking account. Not a problem! We can help you set one up.</p>
                            <p>To get started creating your Digital Banking account, please choose a <strong>username</strong> and <strong>password</strong>. You will use this information to sign in to Digital Banking where you'll be able complete the funding process.</p>
                        </div>

                        <!-- /header -->

                        <!-- body -->
                        <div class="linkaccount-body">
                            <!-- associate: YES -->
                            <div class="linkaccount-associate" [class.linkaccount-associate-dbfunded]="(askForExistingCredentials && !allowSkipAccountAssocciation) || (!askForExistingCredentials && !allowSkipAccountAssocciation)">
                                <div class="linkaccount-associate-yes" [class.linkaccount-associate-active]="!skipAccountAssociation && allowSkipAccountAssocciation">
                                    <div class="form-input-container">
                                        <!-- ASSOCIATION -->
                                        <div class="form-radio form-db-associate">
                                            <input type="radio" #skipAccountAssociationInputFalse [value]="false" [(ngModel)]="skipAccountAssociation" name="skipAccountAssociationInput" id="skipAccountAssociationInputFalse">

                                            <!-- associate: existing db user -->
                                            <label *ngIf="askForExistingCredentials && allowSkipAccountAssocciation" for="skipAccountAssociationInputFalse">
                                                <strong *ngIf="useNewUiForBank" class="linkaccount-associate-subhead">Link My Account(s)</strong>
                                                I want to associate my new account with my Digital Banking account
                                            </label>

                                            <!-- associate: not db user -->
                                            <label *ngIf="!askForExistingCredentials && allowSkipAccountAssocciation" for="skipAccountAssociationInputFalse">
                                                <strong *ngIf="useNewUiForBank" class="linkaccount-associate-subhead">Create a Digital Banking Account</strong>
                                                Enter a username and password to use for your new Digital Banking account
                                            </label>

                                            <!-- associate: db funded account - existing db user -->
                                            <label *ngIf="askForExistingCredentials && !allowSkipAccountAssocciation" for="skipAccountAssociationInputFalse">
                                                <strong *ngIf="useNewUiForBank" class="linkaccount-associate-subhead">Enter Your Digital Banking Login</strong>
                                                Please enter your Digital Banking account username and password to fund your account(s)
                                            </label>

                                            <!-- associate: db funded account - not db user -->
                                            <label *ngIf="!askForExistingCredentials && !allowSkipAccountAssocciation" for="skipAccountAssociationInputFalse">
                                                <strong *ngIf="useNewUiForBank" class="linkaccount-associate-subhead">Create a Digital Banking Account</strong>
                                                Enter a username and password to use for your new Digital Banking account
                                            </label>

                                        </div>
                                        <!-- /ASSOCIATION -->
                                    </div>

                                    <!-- form -->
                                    <div class="form-input-container form-items form-db-credentials" [class.form-skipassociation]="skipAccountAssociation">
                                        <!-- USERNAME -->
                                        <div class="mobile-margin form-db-username">
                                            <label for="usernameInput" class="required">
                                                Username
                                            </label>
                                            <div class="requiredContainer">
                                                <input [disabled]="skipAccountAssociation" type="text" #usernameInput="ngModel" required [(ngModel)]="username" name="usernameInput" class="form-input">
                                                <!-- valid alert -->
                                                <div *ngIf="usernameInput.invalid && (usernameInput.dirty || usernameInput.touched)" class="form-source-valid">
                                                    <div class="alert alert-danger">
                                                        Username is Required
                                                    </div>
                                                </div>
                                                <!-- /valid alert -->
                                            </div>
                                        </div>
                                        <!-- /USERNAME -->
                
                                        <!-- PASSWORDS -->
                                        <div class="form-db-passwords">
                                            <!-- pass -->
                                            <div class="form-db-pass">
                                                <label for="passwordInput" class="required">
                                                    Password
                                                </label>
                                                <div class="requiredContainer">
                                                    <input [disabled]="skipAccountAssociation" type="password" #passwordInput="ngModel" required [(ngModel)]="password" name="passwordInput" class="form-input">
                                                    <!-- valid alert -->
                                                    <div *ngIf="passwordErrors.length && (passwordInput.dirty || passwordInput.touched)" class="form-source-valid">
                                                        <div class="alert alert-danger">
                                                            The password must:
                                                            <div *ngFor="let error of passwordErrors">
                                                                {{ error }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- /valid alert -->
                                                </div>
                                            </div>
                                            <!-- /pass -->
                
                                            <!-- confirm pass -->
                                            <div class="form-db-confirmpass" *ngIf="!askForExistingCredentials">
                                                <label for="confirmPasswordInput" class="required">
                                                    Confirm Password
                                                </label>
                                                <div class="requiredContainer">
                                                    <input [disabled]="skipAccountAssociation" type="password" #confirmPasswordInput="ngModel" required [(ngModel)]="confirmPassword" name="confirmPasswordInput" class="form-input">
                                                    <!-- valid alert -->
                                                    <div *ngIf="confirmPasswordInput.invalid && (confirmPasswordInput.dirty || confirmPasswordInput.touched) || confirmPasswordInput.value !== passwordInput.value" class="form-source-valid">
                                                        <div class="alert alert-danger">
                                                            Your passwords must match
                                                        </div>
                                                    </div>
                                                    <!-- /valid alert -->
                                                </div>
                                            </div>
                                            <!-- /confirm pass -->
                                        </div>
                                        <!-- /PASSWORDS -->
                                    </div>
                                    <!-- /form -->
                                </div>
                            </div>
                            <!-- /associate: YES -->

                            <!-- associate: NO -->
                            <!-- do not show if product *requires* db funding -->
                            <div class="linkaccount-associate" *ngIf="allowSkipAccountAssocciation">
                                <div class="linkaccount-associate-no" [class.linkaccount-associate-active]="skipAccountAssociation">
                                    <div class="form-input-container">
                                        <!-- ASSOCIATION -->
                                        <div class="form-radio form-db-associate">
                                            <input type="radio" #skipAccountAssociationInputTrue [value]="true" [(ngModel)]="skipAccountAssociation" name="skipAccountAssociationInput" id="skipAccountAssociationInputTrue">
                                            <label for="skipAccountAssociationInputTrue">
                                                <strong *ngIf="useNewUiForBank" class="linkaccount-associate-subhead">Not Right Now</strong>
                                                I do not want to associate my new account information with a user at this time
                                            </label>
                                        </div>
                                        <!-- /ASSOCIATION -->
                                    </div>
                                </div>
                            </div>
                            <!-- associate: NO -->
                        </div>
                        <!-- /body -->

                    </fieldset>
                </section>
            </div>

            <page-footer [pageName]="pageName" *ngIf="!isLoading" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!canContinue()" (click)="next()" data-continue-page="digitalUser" class="next-button">Continue</button>
    </div>
    <!-- /"continue" button -->

</main>