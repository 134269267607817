import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SessionActionTypes, EstablishSessionSuccessAction, EstablishSessionFailureAction,
    RetrieveForgottenConfirmationCodeFailureAction, ResumeSessionSuccessAction,
    EstablishSSOSessionSuccessAction, 
    AddHistoryEntryFailureAction} from '../actions/session.actions';
import { SessionService } from '../../services/session.service';
import { ReturningUser, DigitalAccountCredentials, DigitalUserStatus } from '../state/session.state';
import { Applicant } from '../state/applicants.state';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CombinedState } from '../state/combined.state';
import { Store } from '@ngrx/store';
import {GetSelectedProductsFailureAction, SelectedProductsActionTypes } from '../actions/selected-products.actions';

@Injectable()
export class SessionEffects {

    @Effect()
    appEstablishSession$ = this.action$.pipe(
        ofType(SessionActionTypes.ESTABLISH_SESSION),
        switchMap((action: NuFundAction) => {
            return this.service.establishSession(action.payload as Applicant).pipe(
                map((info: any) => {
                    // The login successfully completed the Http request
                    return new EstablishSessionSuccessAction(info as ReturningUser);
                }),
                catchError((response: any) => {
                    // The login failed somewhere while making the Http request
                    console.log("response", response);
                    return of(new EstablishSessionFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    app$ = this.action$.pipe(
        ofType(SessionActionTypes.DIGITAL_SIGN_ON),
        switchMap((action: NuFundAction) => {
            return this.service.establishDigitalUserSession(action.payload.userName, action.payload.password, action.payload.isBusiness).pipe(
                map((response) => {
                    return {type: SessionActionTypes.DIGITAL_SIGN_ON_SUCCESS, payload: response};
                }),
                catchError(e => {
                    // There was an error in signout, but we will continue as if it worked
                    return of({ type: SessionActionTypes.DIGITAL_SIGN_ON_FAILURE, payload: e });
                })
            )
        })
    )

    @Effect()
    appBusinessRules$ = this.action$.pipe(
        ofType(SessionActionTypes.APPLY_BUSINESS_RULES),
        switchMap((action: NuFundAction) => {
            return this.service.applyBusinessRules().pipe(
                map((response) => {
                    return { type: SelectedProductsActionTypes.SET_SELECTED_PRODUCTS, payload: response };
                    // return {type: SessionActionTypes.APPLY_BUSINESS_RULES_SUCCESS, payload: response};
                }),
                catchError(error => {
                    // There was an error in signout, but we will continue as if it worked

                    return of(new GetSelectedProductsFailureAction({ error: error }));
                   // return of({ type: SessionActionTypes.APPLY_BUSINESS_RULES_FAILURE, payload: e });
                })
            )
        })
    )

    @Effect()
    appResumeSession$ = this.action$.pipe(
        ofType(SessionActionTypes.RESUME_SESSION),
        switchMap((action: NuFundAction) => {
            return this.service.resumeSession(action.payload).pipe(
                map((response) => {
                    // list of states that should not call GET/products/selected
                    const SELECTED_PRODUCT_BLACKLIST = [
                        'Established Session', // we are on the products page
                        'Confirmation Package Sent' // we are on the confirmation page
                    ];

                    // check that the current state should call /products/selected
                    // commented out as part of 246274 fix as ApplyBusinessRuleV2 will be called in componet level
                    if (!SELECTED_PRODUCT_BLACKLIST.includes(response.nuFundStatus)) {
                        this.store.dispatch({ type: SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS });
                    }
                    return new ResumeSessionSuccessAction({ resumeData: response });
                }),
                catchError((response: any) => {
                    // The resume failed somewhere while making the Http request
                    return of(new EstablishSessionFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appGetForgottenConfirmation$ = this.action$.pipe(
        ofType(SessionActionTypes.RETRIEVE_FORGOTTEN_CONFIRMATION_CODE),
        switchMap((action: NuFundAction) => {
            return this.service.forgotConfirmationCode(action.payload).pipe(
                map((info: any) => {
                    // The forgot confirmation code successfully completed the Http request
                    return { type: SessionActionTypes.RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_SUCCESS };
                }),
                catchError((response: any) => {
                    // The forgot confirmation code failed somewhere while making the Http request
                    return of(new RetrieveForgottenConfirmationCodeFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appEstablishSSOSession$ = this.action$.pipe(
        ofType(SessionActionTypes.ESTABLISH_SSO_SESSION),
        switchMap((action: NuFundAction) => {
            return this.service.establishSSOSession(action.payload).pipe(
                map((response) => {
                    // The forgot confirmation code successfully completed the Http request
                    return new EstablishSSOSessionSuccessAction({ resumeData: response });
                }),
                catchError((response: any) => {
                    // The forgot confirmation code failed somewhere while making the Http request
                    return of(new EstablishSessionFailureAction({ error: response }));
                })
            );
        })
    )


    @Effect()
    appSessionSignOut$ = this.action$.pipe(
        ofType(SessionActionTypes.LOG_OUT),
        switchMap((action: NuFundAction) => {
            let bypass = false;

            if (action.payload) {
                bypass = action.payload.bypassSendingEmail;
            }
            return this.service.sessionSignout(bypass).pipe(
                map(() => {
                    // The user signed out successfully
                    return { type: SessionActionTypes.LOG_OUT_SUCCESS, payload: true };
                }),
                catchError(() => {
                    // There was an error in signout, but we will continue as if it worked
                    return of({ type: SessionActionTypes.LOG_OUT_SUCCESS, payload: false });
                })
            )
        })
    )


    @Effect()
    appSessionDigitalUserExists$ = this.action$.pipe(
        ofType(SessionActionTypes.GET_DIGITAL_USER_EXISTS),
        switchMap((action: NuFundAction) => {
            return this.service.digitalUserStatus().pipe(
                map((response: any) => {
                    // The user existed
                    return {
                        type: SessionActionTypes.GET_DIGITAL_USER_EXISTS_SUCCESS,
                        payload: Object.assign(
                            { error: null },
                            response
                        ) as DigitalUserStatus
                    };
                }),
                catchError((err) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of({
                        type: SessionActionTypes.GET_DIGITAL_USER_EXISTS_SUCCESS,
                        payload: {
                            isExistingDigitalUser: false,
                            isSingleSignOnUser: false,
                            error: err
                        } as DigitalUserStatus
                    });
                })
            )
        })
    )

    @Effect()
    appSessionAddDigitalAccounts$ = this.action$.pipe(
        ofType(SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS),
        switchMap((action: NuFundAction) => {
            return this.service.addDigitalAccounts(action.payload as DigitalAccountCredentials)
            .pipe(
                map(() => {
                    // The user was created
                    return { type: SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS_SUCCESS };
                }),
                catchError((err) => {
                    // The user was not created
                    return of({ type: SessionActionTypes.SUBMIT_DIGITAL_CREDENTIALS_FAILURE, payload: err });
                })
            )
        })
    )

    @Effect()
    appAddHistoryEntry$ = this.action$.pipe(
        ofType(SessionActionTypes.ADD_HISTORY_ENTRY),
        switchMap((action: NuFundAction) => {
            return this.service.addHistoryEntry(action.payload).pipe(              
                map((response) => {
                    return { type: SessionActionTypes.ADD_HISTORY_ENTRY_SUCCESS, payload: response };
                    // return {type: SessionActionTypes.APPLY_BUSINESS_RULES_SUCCESS, payload: response};
                }),
                catchError((response: any) => {
                    // The forgot confirmation code failed somewhere while making the Http request
                    return of(new AddHistoryEntryFailureAction({ error: response }));
                })
            );
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: SessionService, private router: Router, private store: Store<CombinedState>) { }
}
