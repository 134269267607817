import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RevationChatComponent } from './revation-chat.component';

@NgModule({
  declarations: [RevationChatComponent],
  exports: [RevationChatComponent],
  imports: [
    CommonModule
  ]
})
export class RevationChatModule { }
