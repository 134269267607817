import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProgressModule } from '../progress/progress.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { OutOfWalletRoutingModule } from './out-of-wallet-routing.module';
import { OutOfWalletQuestionComponent } from './out-of-wallet-question/out-of-wallet-question.component';

import { FormatOutOfWalletQuestion } from './out-of-wallet-question/format-out-of-wallet-question.pipe';

import { OutOfWalletComponent } from './out-of-wallet.component';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        OutOfWalletComponent,
        OutOfWalletQuestionComponent,
        FormatOutOfWalletQuestion
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        OutOfWalletRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        FacebookPixelModule,
        LoadingModule
    ]
})
export class OutOfWalletModule { }
