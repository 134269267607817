import { environment } from "src/environments/environment";

export class Settings {
    public static getAPIBaseURL() {
        switch (window.location.host) {
            case 'localhost:4201':
                return 'https://uatapi.csinufund.com/api/';
            case 'testk8s.csinufund.com':
            case 'testaks.csinufund.com':
            case 'test.csinufund.com':
            case 'testindividual.csinufund.com':
            case 'meridiantest.csinufund.com':
            case 'test.csinufund.com:4200':
            case 'localhost:4200':
            case 'localhost:8080':
            case 'staging.csinufund.com':
                if (environment.local) {
                    return 'http://localhost:3236/api/';
                } else {
                    return 'https://testapi.csinufund.com/api/';
                }
            case 'releasetest.csinufund.com':
                return 'https://releaseapi.csinufund.com/api/';
            case 'devopenandfund.csiweb.com:4100':
            case 'devhsa.csiweb.com:4200':
                return 'http://devapi.csinufund.com/api/';
            default:
                return 'https://api.csinufund.com/api/';
        }
    }

    public static get baseApimUrl() {
        switch (window.location.host) {
            case 'testk8s.csinufund.com':
            case 'testaks.csinufund.com':
            case 'test.csinufund.com':
            case 'testindividual.csinufund.com':
            case 'meridiantest.csinufund.com':
            case 'test.csinufund.com:4200':
            case 'localhost:4200':
            case 'localhost:8080':
            case 'releasetest.csinufund.com':
            case 'staging.csinufund.com':
                return 'https://devapim.csibridge.com/';
            case 'devopenandfund.csiweb.com:4100':
            case 'devhsa.csiweb.com:4200':
                return 'https://devapim.csibridge.com/';
            default: // TODO: prod url goes here!
                /*
                OLD PROD APIM VALUES
                return 'https://devapim.csibridge.com/'; 
                return 'https://csiapimprod01.azure-api.net/';
                */
                return 'https://api.csiweb.cloud/';
        }
    }
    public static get bridgeV3ApiUrl() {
        switch (window.location.host) {
            case 'localhost:4200':
            case 'localhost:8080':
            case 'test.csinufund.com':
            case 'testindividual.csinufund.com':
                return 'https://testbridge.csiweb.com/0_3/';
            default:
                return 'https://bridge.csiweb.com/0_3/'
        }
    }
}
