import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalService } from '../modal/modal.service';

import { Subscription, combineLatest } from 'rxjs';
import { InstitutionState } from '../state-management/state/institution.state';
import { Store } from '@ngrx/store';
import { CombinedState } from '../state-management/state/combined.state';

@Component({
    moduleId: module.id,
    templateUrl: './home.component.html',
    styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnDestroy, OnInit {
    pageName = 'home';
    modalSub: Subscription;
    isPanelVisible = false;
    showBusinessView = false;
    routedPromoCode = '';
    useNewUiForBank = false;
    shouldShowDigitalSignOn = false;
    isNew: boolean;

    constructor(
        private store: Store<CombinedState>,
        private route: ActivatedRoute,
        private router: Router,
        private modal: ModalService<boolean>) {
    }

    ngOnInit() {
        combineLatest(
            this.store.select('institutionStoreReducer'),
            this.route.firstChild ? this.route.firstChild.params : this.route.params,
            (institutionState, params) => ({institutionState, params})
        ).subscribe(pair => {
            
            const regex = new RegExp("/home/(.+)");
            let activeRoute = regex.exec(this.router.url)[1];
            this.isNew = activeRoute === 'newUser' || activeRoute === 'newBusiness' || activeRoute === 'digitalSignIn';
            this.showBusinessView = pair.institutionState.institution.isBusiness;
            this.useNewUiForBank = pair.institutionState.institution.useNewUiForBank;
            this.shouldShowDigitalSignOn = !!pair.institutionState.institution.digitalBankingBrandId;
            this.routedPromoCode = !pair.params.promoCode ? '' : pair.params.promoCode;
            if (pair.params.reason !== undefined) {
                this.modalSub = this.modal.openModal('Session Ended', pair.params.reason, [{
                    label: 'Ok',
                    result: true,
                    closesModal: true,
                    class: 'modal-button'
                }], 'modal-alert').subscribe(() => this.router.navigate(['/home', this.showBusinessView ? 'newBusiness' : 'newUser']));
            } else {
                if (this.router.url.indexOf('/home/newBusiness') === -1 && this.showBusinessView) {
                    this.router.navigate(['/home', 'newBusiness']);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.modalSub !== undefined) {
            this.modalSub.unsubscribe();
        }
    }
}
