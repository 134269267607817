import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ApplicantsState } from '../state-management/state/applicants.state';
import { CombinedState } from '../state-management/state/combined.state';
import { Step } from '../state-management/state/progress.state';
import { IdvLayoutService } from './service/idv-layout.service';
import { IdvLoadingService } from './service/idv-loading.service';

@Component({
  selector: 'app-idv',
  templateUrl: './idv.component.html',
  styleUrls: ['./idv.component.scss']
})
export class IdvComponent implements OnInit {
  useNewUiForBank: boolean;
  isLoading = false;
  isDisabled = true;
  applicantsState: ApplicantsState;
  private readonly unsubscribe$ = new Subject()

  constructor(
    private store: Store<CombinedState>,
    private router: Router,
    private idvLayoutService: IdvLayoutService,
    private idvLoadingService: IdvLoadingService
  ) {

    this.store.select('applicantsStoreReducer')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(applicantsState => {
      this.applicantsState = applicantsState;
      this.idvLoadingService.finishedLoading()
    })

    // control progress state and handle routing logic here.
    this.store.select('progressStoreReducer')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(progressState => {
      //routing logic:
      // if there is only one applicant ...
      if (this.applicantsState.applicants.length === 1) {
        // ... and the progress step is not yet correct state
        if (progressState.currentStep < Step.VerifyPrimaryApplicantInformation) {
          // correct it!
          store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.VerifyPrimaryApplicantInformation });
        } else if (progressState.currentStep > Step.VerifyPrimaryApplicantInformation) {
          // if there is only one applicant and the step is beyond verify, skip this page. 
          this.router.navigate(['disclosures']);
        }
        return;
      }

      // if there are two applicants ...
      if (this.applicantsState.applicants.length === 2) {
        // ... and this is the first applicant + the step is before verify firt applicant
        if (this.applicantsState.currentApplicantIndex === 0 && progressState.currentStep < Step.VerifyPrimaryApplicantInformation) {
          // the the progress state correctly
          store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.VerifyPrimaryApplicantInformation });
        }

        // ... and the is the second applicant + the step is before verify second applicant
        if (this.applicantsState.currentApplicantIndex === 1 && progressState.currentStep < Step.VerifySecondaryApplicantInformation) {
          // the the progress state correctly
          store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.VerifySecondaryApplicantInformation });
        }
        
        // if we are beyond the verify step for both applicants:
        if (progressState.currentStep > Step.VerifySecondaryApplicantInformation) {
          // navigate to disclosures and let that component handle the progress state.
          this.router.navigate(['disclosures']);
        }

        return;
      }
    })

    this.store.select('institutionStoreReducer')
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(institutionState => {
      this.useNewUiForBank = institutionState.institution.useNewUiForBank;
      this.useNewUiForBank = true;
    })

    this.idvLayoutService.buttonDisabled$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((isDisabled: boolean) => {
      this.isDisabled = isDisabled;
    })
    
    this.idvLoadingService.isLoading$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  continueClick() {
    this.idvLayoutService.clickButton();
  }

}
