import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalService } from '../../../modal/modal.service';
import { Bundle } from '../../../state-management/state/products.state';
import { take } from 'rxjs/operators';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { Store } from '@ngrx/store';

@Component({
    moduleId: module.id,
    selector: 'product-bundle',
    templateUrl: './product-bundle.component.html',
    styleUrls: ['product-bundle.component.scss']
})
export class ProductBundleComponent implements OnInit {
    @Input() bundle: Bundle = null;
    @Output() bundleAdded: EventEmitter<Bundle> = new EventEmitter<Bundle>();
    @Output() bundleRemove: EventEmitter<Bundle> = new EventEmitter<Bundle>();
    @ViewChild('productDetails') productDetailsElement: ElementRef;

    bundleHasPromoCode = false;
    isMoreInfoNeeded = false;
    currentProductBundleIndex = 0;
    isProductSelected = false;

    constructor(private modalService: ModalService<Bundle>,
        private store: Store<CombinedState>  ) { }

    ngOnInit() {
        this.store.select("selectedProductsStoreReducer").subscribe(info => {
            this.isProductSelected = info.selectedBundles
            .map(b => b.products[0].bundleId)
            .includes(this.bundle.products[0].bundleId);
        })

        this.setIsMoreInfoNeeded();
        const guid = this.newGuid();
        for (const product of this.bundle.products) {
            product.relatedAccountId = guid;
        }
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }

    get hasPromoCode() {
        return this.bundle.products.some(product => product.hasPromoCode);
    }

    incrementProduct(index: number) {
        this.currentProductBundleIndex = (this.currentProductBundleIndex + index) % this.bundle.products.length;
    }

    showMoreInfo(event: MouseEvent) {
        const body = this.bundle.products.map(
            product => product.descriptionWithoutImage
        );

        this.modalService.openModal(this.bundle.name, body, [
            {
                label: 'Add This Bundle',
                result: this.bundle,
                closesModal: true,
                class: 'modal-button'
            },
            {
                label: 'Close',
                result: null,
                closesModal: true,
                class: 'modal-error-button'
            }
        ]).pipe(
            take(1)
        ).subscribe(bundleResult => {
            if (bundleResult !== null) {
                this.bundleAdded.emit(bundleResult);
                this.isProductSelected = true;
            }
        });

        // don't click through to select the product
        event.stopPropagation();
    }

    showMoreInfoRemove(event: MouseEvent) {
        const body = this.bundle.products.map(
            product => product.descriptionWithoutImage
        );

        this.modalService.openModal(this.bundle.name, body, [
            {
                label: 'Remove This Bundle',
                result: this.bundle,
                closesModal: true,
                class: 'modal-button'
            },
            {
                label: 'Close',
                result: null,
                closesModal: true,
                class: 'modal-error-button'
            }
        ]).pipe(
            take(1)
        ).subscribe(bundleResult => {
            if (bundleResult !== null) {
                this.bundleRemove.emit(bundleResult);
                this.isProductSelected = false;
            }
        });

        // don't click through to select the product
        event.stopPropagation();
    }

    addBundle() {
        this.bundleAdded.emit(this.bundle);
    }

    onBundleRemove() {
        this.bundleRemove.emit(this.bundle);
    }

    setIsMoreInfoNeeded() {
        // if there is an image, always so the button
        for (const product of this.bundle.products) {
            if (product.isImageProduct && product.description !== '') {
                this.isMoreInfoNeeded = true;
                return;
            }
        }

        this.isMoreInfoNeeded = (
            this.productDetailsElement &&
            this.productDetailsElement.nativeElement.scrollHeight > this.productDetailsElement.nativeElement.offsetHeight
        );
    }
}
