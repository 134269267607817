import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { settings } from 'cluster';
import { empty, Observable, pipe } from 'rxjs';
import { map, mergeMap, share, timeout } from 'rxjs/operators';
import { DocumentDetail } from '../models/document-detail.model';
import { Settings } from '../settings';

import { Product } from '../state-management/state/products.state';
import { IdentityService } from './identity.service';

@Injectable()
export class ProductsService  {
    public products: Product[] = [];

    constructor(public http:HttpClient, public identityService:IdentityService) {

    }

    getProducts(instantAddCodes?: string[]): Observable<Product[]> {

        // explode the instant add codes
        let instantAddCodesParamters = '';
        if (instantAddCodes && instantAddCodes.length) {
            instantAddCodesParamters = '?instantAddCodes=' + instantAddCodes.join('&instantAddCodes=');
        } else {
            instantAddCodesParamters = '';
        }

        const productsObservable = this.http.get<Product[]>('Products' + instantAddCodesParamters).pipe(share());
        productsObservable.subscribe((result) => {
            this.products = result;
        });

        return productsObservable;
    }

    // returns nothing
    postSelectedProductFeatures(selectedProducts: Product[]): Observable<any> {
        return this.http.post<any>('Products/Features', selectedProducts);
    }

    postSelectedProducts(selectedProducts: Product[]): Observable<any> {
        return this.http.post<any>('Products', selectedProducts);
    }

    postFundingProducts(fundingProducts: Product[]): Observable<any> {
        return this.http.post<any>('Products/Funding', fundingProducts);
    }

    // returns nothing
    postProductDisclosureResults(products: Product[]): Observable<any> {
        return this.http.post<any>('Disclosures', products);
    }

    applyPromoCode(promotionalCode: string): Observable<any> {
        return this.http.put<any>('Promotion/' + promotionalCode, {});
    }

    getSelectedProducts(): Observable<Product[]> {
        const productsObservable = this.http.get<Product[]>('Products/Selected').pipe(share());
        productsObservable.subscribe((result) => {
            this.products = result as Product[];
        });

        return productsObservable;
    }

    uploadBusinessIncorpDocs(filedata:DocumentDetail): Observable<any>{
        return this.identityService.getBearerToken().pipe(
            mergeMap(identity => {
                return this.http.post<any>(Settings.bridgeV3ApiUrl + 'DocumentDelivery/0_1/api/DocumentDelivery/PDFFile' ,filedata, {
                    headers: IdentityService.identityToHttpHeaders(identity)
                })
            })
        );
    }

    // returns nothing
    public requestConfirmationEmail(): Observable<any> {
        return this.http.post<any>('Confirmation', {});
    }
}
