import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalService } from '../../../modal/modal.service';
import { Product } from '../../../state-management/state/products.state';
import { take } from 'rxjs/operators';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { Store } from '@ngrx/store';

@Component({
    moduleId: module.id,
    selector: 'product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
    @Input() product: Product = null;
    @Output() productAdded: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() productRemove: EventEmitter<Product> = new EventEmitter<Product>();
    @ViewChild('productDetails') productDetailsElement: ElementRef;
    isMoreInfoNeeded = false;
    isProductSelected = false;

    constructor(private modalService: ModalService<Product>,
        private store: Store<CombinedState>  ) { }

    ngOnInit() {
        this.store.select("selectedProductsStoreReducer").subscribe(info => {
            this.isProductSelected = info.selectedProducts
            .map(s => s.productId).includes(this.product.productId);
        })
        this.product.relatedAccountId = this.newGuid();
        // normally we wouldn't use a setTimeout to avoid async view updates but in this case the view really is updating its self.
        setTimeout(() => {
            this.isMoreInfoNeeded = (
                this.product.isImageProduct && this.product.description !== ''
            ) ||  (
                this.productDetailsElement &&
                this.productDetailsElement.nativeElement.scrollHeight > this.productDetailsElement.nativeElement.offsetHeight
            );
        }, 0);
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }

    showMoreInfo(event: MouseEvent, product: Product) {
        this.modalService.openModal(product.productName, product.descriptionWithoutImage, [
            {
                label: 'Add This Product',
                result: product,
                closesModal: true,
                class: 'modal-button'
            },
            {
                label: 'Close',
                result: null,
                closesModal: true,
                class: 'modal-error-button'
            }
        ]).pipe(take(1)).subscribe(productResult => {
            if (productResult !== null) {
                this.productAdded.emit(productResult);
                this.isProductSelected = true;
            }
        });

        // don't click through to select the product
        event.stopPropagation();
    }

    showMoreInfoRemove(event: MouseEvent, product: Product) {
        this.modalService.openModal(product.productName, product.descriptionWithoutImage, [
            {
                label: 'Remove This Product',
                result: product,
                closesModal: true,
                class: 'modal-button'
            },
            {
                label: 'Close',
                result: null,
                closesModal: true,
                class: 'modal-error-button'
            }
        ]).pipe(take(1)).subscribe(productResult => {
            if (productResult !== null) {
                this.productRemove.emit(productResult);
                this.isProductSelected = false;
            }
        });

        // don't click through to select the product
        event.stopPropagation();
    }

    addProduct() {
        this.productAdded.emit(this.product);
    }

    onProductRemove() {
        this.productRemove.emit(this.product);
    }

}
