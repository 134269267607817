import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
/** The goal of this class is to indicate the loading status of the Idv portions of this app. */
export class IdvLoadingService {
    /** This subject represents when the UI is waiting on something, like the API, so it can disable/enable elements. */
    private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /** Public facing observable that the layout subscribes to. Emits when the UI is waiting on a call. */
    public isLoading$ = this._isLoading$.asObservable();
    /** Empty constructor in case we ever need to inject dependencies here. */
    constructor() {
    }

    /** Emits true to the `isLoading$` subscribers to indicate the UI is waiting on data. */
    public startLoading(): void {
        this._isLoading$.next(true);
    }
    
    /** Emits false to the `isLoading$` subscribers to indicate the UI has finished waiting on data. */
    public finishedLoading(): void {
        this._isLoading$.next(false);
    }
}
