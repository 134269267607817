import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';

import { ApplicantComponent } from './applicant.component';

@NgModule({
    imports: [ReactiveFormsModule, CommonModule, FormsModule, TextMaskModule],
    declarations: [ApplicantComponent],
    exports: [ApplicantComponent],
    providers: [DatePipe]
})
export class ApplicantModule { }
