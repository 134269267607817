<fieldset *ngIf="question" class="verify-item">
    <div class="verify-question">
        {{question.questionText}}
    </div>

    <ul class="verify-answers">
        <li *ngFor="let currentAnswer of question.possibleAnswers; let i = index" class="verify-answer">
            <div class="form-radio">
                <input type="radio" [(ngModel)]="answer" name="{{name}}_answer" id="answer_{{name}}_{{i}}" [value]="i" (change)="setAnswer(i)">
                <label for="answer_{{name}}_{{i}}">{{currentAnswer.answerText | formatOutOfWalletQuestion}}</label>
            </div>
        </li>
    </ul>
</fieldset>