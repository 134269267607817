import { Product } from '../state/products.state';
import { NuFundAction } from 'src/app/common/nufund-action';

export const ProductsActionTypes = {
    GET_PRODUCTS: '[Products] GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: '[Products] GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: '[Products] GET_PRODUCTS_FAILURE',

    SUBMIT_SELECTED_PRODUCTS: '[Products] SUBMIT_SELECTED_PRODUCTS',
    SUBMIT_SELECTED_PRODUCTS_SUCCESS: '[Products] SUBMIT_SELECTED_PRODUCTS_SUCCESS',
    SUBMIT_SELECTED_PRODUCTS_FAILURE: '[Products] SUBMIT_SELECTED_PRODUCTS_FAILURE',

    /**
     * Navigate to personal-information in the effects on success
     * @payload Products[] with an `id`
     */
    SUBMIT_SELECTED_PRODUCTS_FEATURES: '[Products] SUBMIT_SELECTED_PRODUCTS_FEATURES',
    SUBMIT_SELECTED_PRODUCTS_FEATURES_SUCCESS: '[Products] SUBMIT_SELECTED_PRODUCTS_FEATURES_SUCCESS',
    SUBMIT_SELECTED_PRODUCTS_FEATURES_FAILURE: '[Products] SUBMIT_SELECTED_PRODUCTS_FEATURES_FAILURE',

    SUBMIT_PRODUCTS_DISCLOSURES: '[Products] SUBMIT_PRODUCTS_DISCLOSURES',
    SUBMIT_PRODUCTS_DISCLOSURES_SUCCESS: '[Products] SUBMIT_PRODUCTS_DISCLOSURES_SUCCESS',
    SUBMIT_PRODUCTS_DISCLOSURES_FAILURE: '[Products] SUBMIT_PRODUCTS_DISCLOSURES_FAILURE',

    SUBMIT_PRODUCTS_FUNDING: '[Products] SUBMIT_PRODUCTS_FUNDING',
    SUBMIT_PRODUCTS_FUNDING_SUCCESS: '[Products] SUBMIT_PRODUCTS_FUNDING_SUCCESS',
    SUBMIT_PRODUCTS_FUNDING_FAILURE: '[Products] SUBMIT_PRODUCTS_FUNDING_FAILURE',

    SET_PRODUCTS_READY_FOR_INITIAL_LOAD: '[Products] SET_PRODUCTS_READY_FOR_INITIAL_LOAD',

    REQUEST_CONFIRMATION_EMAIL: '[Products] REQUEST_CONFIRMATION_EMAIL_SUCCESS',

    /** Used for uploading documents on the disclosure page
     * @payload document-detail.model.ts[]
     */
    SUBMIT_BUSINESS_INCORP_DOCS: '[Products] SUBMIT_BUSINESS_INCORP_DOCS',
    SUBMIT_BUSINESS_INCORP_DOCS_SUCCESS: '[Products] SUBMIT_BUSINESS_INCORP_DOCS_SUCCESS',
    SUBMIT_BUSINESS_INCORP_DOCS_FAILURE: '[Products] SUBMIT_BUSINESS_INCORP_DOCS_FAILURE'

};

export class GetProductsSuccessAction implements NuFundAction {
    type = ProductsActionTypes.GET_PRODUCTS_SUCCESS;
    constructor(public payload: { products: Product[] }) { }
}

export class GetProductsFailureAction implements NuFundAction {
    type = ProductsActionTypes.GET_PRODUCTS_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class SubmitProductsFailureAction implements NuFundAction {
    type = ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class SubmitSelectedProductsFeaturesFailureAction implements NuFundAction {
    type = ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS_FEATURES_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class SubmitProductsDisclosuresFailureAction implements NuFundAction {
    type = ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class SubmitProductsFundingFailureAction implements NuFundAction {
    type = ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING_FAILURE;
    constructor(public payload: { error: any }) { }
}
