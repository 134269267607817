import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ModalService } from '../modal/modal.service';

import { Store } from '@ngrx/store';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState, Step } from '../state-management/state/progress.state';
import { SelectedProductsState, Product, SelectedProductsUtil } from '../state-management/state/selected-products.state';
import { initialFundingSourceState, SelectedFundingMethods, fundingSources } from '../state-management/state/products.state';
import { ProductsState } from '../state-management/state/products.state';
import { ProductsActionTypes } from '../state-management/actions/products.actions';

import { RoutingLookupActionTypes } from '../state-management/actions/routing-lookup.actions';
import { RoutingLookupState } from '../state-management/state/routing-lookup.state';

import { AuthenticationHeaderManager } from '../authentication-header-manager';
import { Util } from '../common/util';
import { InstitutionState } from '../state-management/state/institution.state';
import { Settings } from '../settings';
import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './funding.component.html',
    styleUrls: ['funding.component.scss']
})
export class FundingComponent implements OnDestroy {
    public pageName = 'funding';
    public currentStep: Step;
    public selectedProducts: Product[];
    public isLoading = true;
    public fundingSources = fundingSources;
    public unsubscribe: Subject<void> = new Subject<void>();
    public isPanelVisible = false;
    public achAccepted = false;
    private skippableFundingMethodNames = ['Employer HSA'];
    institutionName  = '';
    institutionRoutingNumber = '';
    _onUs = false;
    hasDigitalFunding = false;
    useNewUiForBank = false;

    get onUs() {
        return this._onUs;
    }
    set onUs(value: boolean) {
        this._onUs = value;
        if (this._onUs) {
            this.ACHFundingSource.routingNumber = this.institutionRoutingNumber;
            this.ACHFundingSource.bankName = this.institutionName;
        } else {
            this.ACHFundingSource.routingNumber = '';
            this.ACHFundingSource.bankName = '';
        }
    }
    public ACHFundingSource: SelectedFundingMethods = initialFundingSourceState;

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<boolean>,
        private router: Router,
    ) {

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institutionName  = info.institution.institutionName;
            this.institutionRoutingNumber = info.institution.routingNumber;
            this.useNewUiForBank = info.institution.useNewUiForBank;
        });

        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            // update the wizard panel
            this.currentStep = info.currentStep;
            if (this.currentStep > Step.FundingSource) {
                this.router.navigate(['digitaluser']);
            } else if (this.currentStep < Step.FundingSource) {
                this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.FundingSource });
            }
        });

        this.store.select('routingLookupStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: RoutingLookupState) => {
            if (info.suggestions && info.suggestions.length && !info.isLoading && !info.isError) {
                this.ACHFundingSource.bankName = info.suggestions[0].institutionName;
            }
        });

        this.store.select('productsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProductsState) => {
            this.isLoading = info.isLoading;
            if (info.isError) {
                this.modalService.presentError(info.error, true, 'Error saving funding choices');
            } else {
                if (info.fundingSubmitted && this.currentStep === Step.FundingSource) {
                    this.router.navigate(['digitaluser']);
                }
            }
        });

        this.store.select('selectedProductsStoreReducer')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((info: SelectedProductsState) => {
            this.selectedProducts = info.selectedProducts;
            this.hasDigitalFunding = SelectedProductsUtil.hasDigitalFunding(info);
            this.setupSelectedFundingModel();
        });

        this.ACHFundingSource.bankName = '';
    }

    private fundingMethodIsDigital(fundingMethod: any) {
        return fundingMethod.type === 'CSI Digital Banking';
    }

    private getDigitalFundingMethod() {
        for (const product of this.selectedProducts) {
            for (const fundingMethod of product.availableFundingMethods) {
                if (this.fundingMethodIsDigital(fundingMethod)) {
                    return fundingMethod;
                }
            }
        }
        return null;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public isAnyProductACH() {
        for (const product of this.selectedProducts) {
            if (product.selectedFundingMethods[0].type === this.fundingSources.ACH) {
                return true;
            }
        }
        return false;
    }

    public translateFundingDescription(description: string) {
        if (description === 'ACH') {
            return 'Account Transfer';
        }
        return description;
    }

    handleACHAccountNumberChange(event) {
        const nonDigitExpression = /\D+/g;
        event.target.value = event.target.value.replace(nonDigitExpression, '');
        this.ACHFundingSource.accountNumber = this.ACHFundingSource.accountNumber.replace(nonDigitExpression, '');
        this.resetACHAuthorization();
    }

    resetACHAuthorization() {
        this.achAccepted = false;
    }

    public getACHTotal() {
        let total = 0;
        for (const product of this.selectedProducts) {
            if (product.selectedFundingMethods[0].type === this.fundingSources.ACH && !isNaN(Number(product.openAmount))) {
                total += Number(product.openAmount);
            }
        }
        return parseFloat(total.toString()).toFixed(2);
    }

    public processRoutingChange(event) {
        // every time the r&t is modified, blank the name
        this.ACHFundingSource.bankName = '';

        // don't bother looking up our own r&t
        if (this.onUs) {
            return;
        }

        const nonDigitExpression = /\D+/g;
        event.target.value = event.target.value.replace(nonDigitExpression, '');
        this.ACHFundingSource.routingNumber = this.ACHFundingSource.routingNumber.replace(nonDigitExpression, '');

        if (this.ACHFundingSource.routingNumber.length === 9) {
            this.store.dispatch({ type: RoutingLookupActionTypes.SUBMIT_SUGGESTION, payload: this.ACHFundingSource.routingNumber });
        }

        this.resetACHAuthorization();
    }

    public setupSelectedFundingModel() {
        // if any product is digital fundable, skip funding and fund them all digitally
        if (this.hasDigitalFunding) {
            const digitalFundingMethod = this.getDigitalFundingMethod();
            if (digitalFundingMethod !== null) {
                for (const product of this.selectedProducts) {
                    product.selectedFundingMethods[0] = Object.assign({}, digitalFundingMethod);
                    product.openAmount = '0';
                }
                this.isLoading = true;
                this.next();
                return;
            }
        }

        // i fonly 1 product and 1 funding method and that funding method is HSA then set it and go
        if (this.selectedProducts.length === 1 &&
        this.selectedProducts[0].availableFundingMethods.length === 1 &&
        this.skippableFundingMethodNames.some(
            (fundingMethodName) => this.selectedProducts[0].availableFundingMethods[0].description === fundingMethodName)
        ) {
            this.selectedProducts[0].selectedFundingMethods[0] = Object.assign({}, initialFundingSourceState);
            this.selectedProducts[0].selectedFundingMethods[0].type = this.selectedProducts[0].availableFundingMethods[0].type;
            this.selectedProducts[0].openAmount = parseFloat(this.selectedProducts[0].minimumDeposit.toString()).toFixed(2);
            this.next();
        } else {
            // loop over all the products
            for (const product of this.selectedProducts) {
                if (product.selectedFundingMethods.length === 0) {
                    product.selectedFundingMethods[0] = Object.assign({}, initialFundingSourceState);
                }
                // if only one funding method then select it by default
                if (product.availableFundingMethods.length === 1) {
                    product.selectedFundingMethods[0].type =
                    product.availableFundingMethods[0].type;
                    // if the one funding method is an HSA then set its default open amount to the minimum
                    if (this.skippableFundingMethodNames.some(
                        (fundingMethodName) =>
                        product.availableFundingMethods[0].description === fundingMethodName
                        )
                    ) {
                        product.openAmount = parseFloat(product.minimumDeposit.toString()).toFixed(2);
                    }
                }
            }
        }
    }

    public getFundingHeading(product: Product): string {
        let fundingHeading = '';
        fundingHeading += product.productName + '<span class=\'funding-minmax\'>(Minimum $' + product.minimumDeposit;

        if (product.productAlias != null) {
            if (product.maximumDeposit == null || isNaN(product.maximumDeposit)) {
                fundingHeading += ')</span><div class=\'funding-head-alias\'><span>Nickname:</span>' + product.productAlias + '</div>';
            } else {
                fundingHeading += ' / Maximum $' + product.maximumDeposit + ')</span><div class=\'funding-head-alias\'><span>Nickname:</span>' +
                    product.productAlias + '</div>';
            }
        }
        else {
            if (product.maximumDeposit == null || isNaN(product.maximumDeposit)) {
                fundingHeading += ')</span>';
            } else {
                fundingHeading += ' / Maximum $' + product.maximumDeposit + ')</span>';
            }
        }
        return fundingHeading;
    }

    getProductMaximumFunding(product: Product): number {
        return (product.maximumDeposit === null || isNaN(Number(product.maximumDeposit))) ? Infinity : product.maximumDeposit;
    }

    formatOpenAmount(product: Product): number {
        product.openAmount = parseFloat(product.openAmount).toFixed(2);
        return Number(product.openAmount);
    }

    public canContinue(): boolean {
        for (const product of this.selectedProducts) {
            if (!this.fundingSourceIsValid(product)) {
                return false;
            }
        }

        return true;
    }

    getACHPDFURL() {
        return Settings.getAPIBaseURL() + 'Disclosures/ach/' +
            Util.getHostName() + '/session/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader()) +
            '/funding/' + this.getACHTotal() + '/type/' + this.ACHFundingSource.accountType + '/aba/' +
            this.ACHFundingSource.routingNumber + '/account/' + this.ACHFundingSource.accountNumber;
    }

    public isACHInformationValid() {
        return this.ACHFundingSource.accountType !== '' && // it has an account type
            this.ACHFundingSource.bankName !== '' && this.ACHFundingSource.bankName !== null && // it has a bank name
            this.ACHFundingSource.routingNumber !== '' && this.ACHFundingSource.routingNumber !== null && // it has a routing number
            this.ACHFundingSource.accountNumber !== '' && this.ACHFundingSource.accountNumber !== null; // it has an account number
    }

    public fundingAmountIsValid(product: Product) {
        return !isNaN(Number(product.openAmount)) && // a number is in the amount
            Number(product.openAmount) >= product.minimumDeposit && // it is above the minimum
            Number(product.openAmount) <= this.getProductMaximumFunding(product); // the entered value is less the maximum
    }

    public fundingSourceIsValid(product: Product) {
        // if there aren't any funding methods, it is valid
        if (!product.availableFundingMethods || product.availableFundingMethods.length === 0) {
            return true;
        }

        if (product.selectedFundingMethods[0].type === this.fundingSources.Check ||
            product.selectedFundingMethods[0].type === this.fundingSources.Other) {
            return this.fundingAmountIsValid(product); // value is between the minimum and maximum
        } else if (product.selectedFundingMethods[0].type === this.fundingSources.ACH) { // ach
            return this.fundingAmountIsValid(product) &&  // value is between the minimum and maximum
                this.isACHInformationValid() && this.achAccepted; // ACH info is valid
        } else { // not yet set
            return false;
        }
    }

    public normalizeACHFunding() {
        for (const product of this.selectedProducts) {
            if (product.selectedFundingMethods[0].type === this.fundingSources.ACH) {
                product.selectedFundingMethods[0].accountType = this.ACHFundingSource.accountType;
                product.selectedFundingMethods[0].bankName = this.ACHFundingSource.bankName;
                product.selectedFundingMethods[0].routingNumber = this.ACHFundingSource.routingNumber;
                product.selectedFundingMethods[0].accountNumber = this.ACHFundingSource.accountNumber;

            }
        }
    }

    public next() {
        this.normalizeACHFunding();
        this.store.dispatch({ type: ProductsActionTypes.SUBMIT_PRODUCTS_FUNDING, payload: this.selectedProducts });
    }
}
