export class ScriptInject {
    private static localWindow = window as any;
    private static localDocument = ScriptInject.localWindow.document;
    private static scriptTagType = 'script';

    static injectScript(url: string, async = true) {
        const newScript = ScriptInject.localDocument.createElement(ScriptInject.scriptTagType);
        const firstScript = ScriptInject.localDocument.getElementsByTagName(ScriptInject.scriptTagType)[0];
        newScript.src = url;
        newScript.async = async;
        firstScript.parentNode.insertBefore(newScript, firstScript);
    }

    static injectScriptCode(jsCode: string, async = true) {
        const newScript = ScriptInject.localDocument.createElement(ScriptInject.scriptTagType);
        newScript.innerHTML = jsCode;
        newScript.async = async;
        ScriptInject.localDocument.head.appendChild(newScript);
    }
}
