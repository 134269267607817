import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/app/modal/modal.service';
import { ApplicantsActionsTypes } from 'src/app/state-management/actions/applicants.actions';
import { Applicant, ApplicantsState } from 'src/app/state-management/state/applicants.state';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { Institution, InstitutionState } from 'src/app/state-management/state/institution.state';
import { ProgressState, Step } from 'src/app/state-management/state/progress.state';
import { SessionState } from 'src/app/state-management/state/session.state';
import { ApplicantLayoutService } from './services/applicant-layout.service';
import { isIdvFlow, truncateInstitutionName } from 'src/app/common/functions';
import { IdvRequest, IdvStrategy } from 'src/app/models/idv.model';
import { ProgressActionTypes } from 'src/app/state-management/actions/progress.actions';


@Component({
    selector: "app-new-applicant",
    templateUrl: "./new-applicant.component.html",
    styleUrls: ["./new-applicant.component.scss"],
})
export class NewApplicantComponent implements OnInit {
    pageName = 'personalInformation';
    unsubscribe: Subject<any> = new Subject<any>();
    applicantState: ApplicantsState;
    institutionState: InstitutionState;
    institution: Institution;
    progressState: ProgressState;
    continueButtonEnable = false;
    isEnabled: boolean = true;
    sessionState: SessionState
    currentStep: Step;
    applicantIndex = 0;
    isLoading = false;
    isAddingApplicant = false;

    //institution
    useNewUiForBank = false;

    constructor(
        private applicantLayoutService: ApplicantLayoutService,
        private store: Store<CombinedState>,
        private router: Router,
        private modalService: ModalService<boolean>
    ) { }

    get applicant() {
        return this.applicantState.applicants[this.applicantIndex];
    }

    ngOnInit() {

        this.store
            .select("applicantsStoreReducer")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((applicantState) => {
                this.applicantState = applicantState;
                this.applicantIndex = applicantState.currentApplicantIndex;
                this.isLoading = applicantState.applicants[applicantState.currentApplicantIndex].isLoading;
                this.store.dispatch({ type: ProgressActionTypes.GET_PROGRESS });
                this.isAddingApplicant = applicantState.applicants[applicantState.currentApplicantIndex].isAddingApplicant;

                if (applicantState.applicants[applicantState.currentApplicantIndex].isVerified &&
                    (this.currentStep === Step.PrimaryApplicantInformation || this.currentStep === Step.SecondaryApplicantInformation)) {

                    if (applicantState.applicants[applicantState.currentApplicantIndex].isVerified &&
                        (this.currentStep === Step.PrimaryApplicantInformation || this.currentStep === Step.SecondaryApplicantInformation)
                    ) {

                        if (this.sessionState.isSSO && applicantState.currentApplicantIndex === 0) {

                            if (applicantState.applicants.length > 1) {
                                if(this.institution.enablePersonaIdScanning){
                                    this.store.dispatch({ type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT, payload: 1 });
                                    this.router.navigate(['personaconsent']);
                                }
                                else{
                                    this.store.dispatch({ type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT, payload: 1 });
                                }
                            } else {
                                this.router.navigate(['disclosures']);
                            }
                        }
                        else if (this.sessionState) {
                            this.router.navigate(['out-of-wallet', this.applicantState.currentApplicantIndex]);
                        }
                    }


                }

                if(!this.isAddingApplicant && !this.isLoading) {
                     this.isEnabled = true;
                }
            });


            this.store.select('progressStoreReducer').
            pipe(takeUntil(this.unsubscribe)).
            subscribe((progressState: ProgressState) => {

                this.currentStep = progressState.currentStep;
                this.progressState = progressState;
                //starts
                if (
                    this.applicantState.currentApplicantIndex === 0 &&
                    this.currentStep < Step.VerifyPrimaryApplicantInformation &&
                    this.currentStep !== Step.SignUp && this.currentStep !== Step.PrimaryApplicantInformation
                ) {
                    this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PrimaryApplicantInformation });
                } else if (
                    this.applicantState.currentApplicantIndex === 1 &&
                    this.currentStep < Step.SecondaryApplicantInformation &&
                    this.currentStep !== Step.SignUp &&
                    this.currentStep !== Step.SecondaryApplicantInformation
                ) {
                    this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SecondaryApplicantInformation });
                } else if (this.currentStep >= Step.AcceptDisclosures && !this.applicant['idResultsFilled']) {
                    this.router.navigate(['disclosures']);
                }
            });

        // get session info so we can know if this is a SSO session
        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((state: SessionState) => {
                this.sessionState = state;

            });
        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((data: InstitutionState) => {
                this.institutionState = data;
                this.institution = data.institution;
                this.useNewUiForBank = data.institution.useNewUiForBank;

            });

        this.applicantLayoutService.buttonDisabled$.pipe(takeUntil(this.unsubscribe)).
        subscribe(data => {
            this.continueButtonEnable = data;
            //this.isEnabled = data;
        });
    }


    addApplicant() {
        this.store.dispatch({ type: ApplicantsActionsTypes.ADD_APPLICANT });
        this.applicantLayoutService.setDisabled(false);
        this.isEnabled = false;
        return false;
    }

    removeApplicant(index: number) {
        this.applicantLayoutService.setDisabled(false);
        this.isEnabled = false;
        this.applicantState.applicants.forEach((applicant, currentIndex) =>
            applicant.index = currentIndex);

        if (this.applicantState.currentApplicantIndex === index) {
            this.modalService.openModal(
                'Confirm removal',
                'Are you sure you want to remove this person?  It will not be possible to add them back after this.',
                [
                    {
                        label: 'Remove this person',
                        result: true,
                        closesModal: true,
                        class: 'modal-button'
                    },
                    {
                        label: 'Cancel',
                        result: false,
                        closesModal: true,
                        class: 'modal-error-button'
                    }
                ], 'modal-alert').pipe(takeUntil(this.unsubscribe)).subscribe(buttonResult => {
                    if (buttonResult) {
                        this.store.dispatch({ type: ApplicantsActionsTypes.REMOVE_APPLICANT, payload: this.applicantState.applicants[index] });
                        this.router.navigate(['disclosures']);
                    } else{
                        this.applicantLayoutService.setDisabled(this.applicantLayoutService.getformValidStatus());
                        this.isEnabled = true;
                    }
                });
        } else {
            this.store.dispatch({ type: ApplicantsActionsTypes.REMOVE_APPLICANT, payload: this.applicantState.applicants[index] });
        }
        return false;
    }

    get showAdminAutoFill() {
        return this.institution.allowAutoFill && (!this.sessionState.isSSO || this.applicantIndex > 0);
    }

    onAutofillClick() {
        if (isIdvFlow(this.institution)) {
            this.store.dispatch({ type: ApplicantsActionsTypes.LOAD_DEMO_EXPERIANAPPLICANT, payload: this.institution });

        } else {
            this.store.dispatch({ type: ApplicantsActionsTypes.LOAD_DEMO_APPLICANT, payload: this.institution });

        }
        return false;
    }

    submitCurrentApplicant() {

        const applicant = this.applicantLayoutService.getTransformedFormdata();
        applicant.index = this.applicantState.currentApplicantIndex;
        if (applicant.citizenType === '2.1' ||
        applicant.citizenType === '2.2') {
            applicant.citizenType = '2';
        }

        if (!this.institution.collectUSResidency) {
            this.applicant.citizenType = '0';
        }

        if (this.sessionState.isSSO && this.applicantIndex === 0) {
            // there is logic in the api method to snuff out sso requests
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_APPLICANT,
                payload: applicant
            });
            // short circuit the rest
            return;
        }
        // Watchdog IDV applicant validation
        if (isIdvFlow(this.institution)) {
            const idvRequest: IdvRequest = <IdvRequest>{
                applicant: applicant,
                clientReferenceId: null,
                idvSessionId: null,
                kiqAnswers: null,
                bankName: truncateInstitutionName(this.institution.institutionName),
                oneTimePasscode: null,
            }
            if (this.institution.idvStrategy === IdvStrategy.IdScreening) {
                this.store.dispatch({
                    type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW,
                    payload: { ...idvRequest, currentApplicantIndex: this.applicantIndex, length: this.applicantState.applicants.length }
                });
            }
            if (this.institution.idvStrategy === IdvStrategy.IdScreeningWithStepUp) {
                this.store.dispatch({
                    type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW_WITH_STEP_UP,
                    payload: idvRequest
                });
            }
        } else {
            // Normal KBA validation validation
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_APPLICANT,
                payload: applicant
            });
        }
    }

    ngOnDestroy(): void {
        console.log('on destroy - New-Applicant')
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
