import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';


import { Settings } from './settings';
import { Store } from '@ngrx/store';
import { SessionState } from './state-management/state/session.state';
import { InstitutionState } from './state-management/state/institution.state';
import { SessionActionTypes } from './state-management/actions/session.actions';
import { ProgressState, Step } from './state-management/state/progress.state';
import { SessionNotificationService } from './services/session-notification.service';
import { AuthenticationHeaderManager } from './authentication-header-manager';

import { share, catchError, tap, publishReplay, retry } from 'rxjs/operators';
import { CombinedState } from './state-management/state/combined.state';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    // URLs that we won't try and send sessionid headers with
    public readonly nonSessionURLs: RegExp[] = [
        /Session$/,
        /Institution$/,
        /css$/,
        /Session\/(?!logout|ping)/
    ];

    public progressState: ProgressState;
    public sessionState: SessionState;
    public institutionState: InstitutionState;

    public readonly demoAPIs: string[] = ['testapi.csinufund.com', 'devapi.csinufund.com', 'localapi.csinufund.com'];
    public isDemoAPI: boolean;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {




        // SET URL
        let url = req.url.match(/https:\/\/.+$/)    // matching to see if we are passing in an already formatted url
            ? req.url                               // if it is formatted, don't do anything
            : Settings.getAPIBaseURL() + req.url;   // otherwise format it.

        // Wasn't being used anywhere. Keeping this in case there is something we need from it.
        this.isDemoAPI = this.demoAPIs.some(
            currentValue => Settings.getAPIBaseURL().indexOf(currentValue) > -1
        );

        let hostName = window.location.origin;

        if (environment.dev) {
            if (environment.isBusiness) {
                hostName = 'https://test.csinufund.com';
            } else {
                hostName = 'https://testindividual.csinufund.com';
            }
        }

        // SET HEADERS
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
            'Access-Control-Allow-Origin': 'access-control-allow-origin,cache-control,content-type,expires,pragma,sessionid',
            'HostName': hostName
        });

        if (req.headers.get('Authorization')) {
            headers = headers.set(
                'Authorization',
                req.headers.get('Authorization')
            );
        }

        // REFRESH THE SESSION
        if (!this.nonSessionURLs.some(nonSessionURL => nonSessionURL.test(req.url))
            && !/\/api\/(?:Session\/logout|Challenge)/.test(req.url)) {
            this.sessionNotificationService.restartTimers(
                this.institutionState.institution.sessionTimeoutMilliseconds,
                this.institutionState.institution.sessionTimeoutMilliseconds - 60000,
                this.sessionState.confirmationCode
            );
            if (AuthenticationHeaderManager.getAuthenticationHeader()) {
                headers = headers.set(
                    AuthenticationHeaderManager.authenticationHeaderName,
                    AuthenticationHeaderManager.getAuthenticationHeader()
                );
            }
        }


        // BEGIN HANDLING
        let newRequest = req.clone({
            url: url,
            headers: headers
        })


        return next.handle(newRequest).pipe(
            // retry(3),
            tap((event: HttpEvent<any>) => {
                // if we want to modify the respons before sending it out to the app, this is where we'd do that.
                if (event instanceof HttpResponse) {
                    // Update the SessionId in localStorage
                    // Make an exception for ApplyBusinessRules. The call does not update the state whatsoever and only causes race conditions when it updates.
                    // Should only update the sessionid when NOT ApplyBusinessRules
                    if (!/ApplyBusinessRules/g.test(url)) {
                        AuthenticationHeaderManager.storeAuthenticationHeader(
                            event.headers.get(AuthenticationHeaderManager.authenticationHeaderName)
                            ?? AuthenticationHeaderManager.getAuthenticationHeader()
                        );
                    }

                }
            }, (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    // handle error
                    this.error(error);
                }
            })
        );
    }

    constructor(
        private sessionNotificationService: SessionNotificationService,
        private store: Store<CombinedState>, private router:Router
    ) {
        this.store.select('progressStoreReducer').subscribe((info: ProgressState) => {
            this.progressState = info;
        });

        this.store.select('institutionStoreReducer').subscribe((info: InstitutionState) => {
            this.institutionState = info;
        });

        this.store.select('sessionStoreReducer').subscribe((info: SessionState) => {
            this.sessionState = info;
        });
    }

    error(error: HttpErrorResponse): void {
        if (this.progressState.currentStep === Step.VerifyPrimaryApplicantInformation ||
            this.progressState.currentStep === Step.VerifySecondaryApplicantInformation) {
            const errorObject = error.error;
            let errorMessage =
                errorObject.bridgeExceptionDetail !== undefined &&
                    errorObject.bridgeExceptionDetail.message !== undefined ?
                    errorObject.bridgeExceptionDetail.message :
                    errorObject.message;

            errorMessage = errorMessage === undefined ? '' : errorMessage;

            this.router.navigate(['/verification-error' ,  errorMessage]);
            return;
        }

        if (error.status === 401) {
            if (this.progressState.currentStep === Step.DigitalUserVerification) {
                return;
            }

            this.store.dispatch({
                type: SessionActionTypes.LOG_OUT,
                payload: { bypassSendingEmail: error.url.indexOf('OutOfWallet') !== -1 }
            });
            try {
                this.router.navigate(['/home' , error.error.message]);
            } catch (e) {
                // this is probably the 401 on business information saving
                // window.open('/home/', '_self');
            }
        }
    }

    complete(): void { }
}
