import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { CombinedState } from '../state-management/state/combined.state';
import { InstitutionState } from '../state-management/state/institution.state';
import { Step, ProgressState } from '../state-management/state/progress.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';
import { HistoryEntry } from '../state-management/state/session.state';
import { ApplicantsState, initialApplicantsState } from '../state-management/state/applicants.state';

@Component({
  selector: 'app-persona-consent',
  templateUrl: './persona-consent.component.html',
  styleUrls: ['./persona-consent.component.scss']
})
export class PersonaConsentComponent implements OnInit, OnDestroy {
  useNewUiForBank = false;

  public currentStep: Step;
  public skipCustomizeProductsScreenFlag = false;
  public pageName = 'persona';
  public isLoading = true;
  public comments = '';
  public unsubscribe: Subject<void> = new Subject<void>();
  public isPanelVisible = false;
  public currentApplicant = 0;
  private currentApplicantID = '';
  private currentDate: Date = new Date();

  constructor(
      private store: Store<CombinedState>,
      private router: Router
  ) {
  }
  ngOnInit(): void {
    this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((applicantsState: ApplicantsState) => {
      this.currentApplicant = applicantsState.currentApplicantIndex;  
      this.currentApplicantID = applicantsState.applicants[this.currentApplicant].id; //save down the ID for Lauire.
    });

    this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((progressState: ProgressState) => {
      this.currentStep = progressState.currentStep;
      // update the wizard panel
      if (this.currentApplicant === 0 && this.currentStep < Step.PersonaPrimaryApplicantConsent) {
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaPrimaryApplicantConsent });
      } else if (this.currentApplicant === 1 && this.currentStep < Step.PersonaSecondaryApplicantConsent){
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaSecondaryApplicantConsent });
      } else if (this.currentApplicant === 0 && this.currentStep === Step.SelectProducts){
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaPrimaryApplicantConsent });
      } else if (this.currentApplicant === 1 && this.currentStep === Step.SecondaryApplicantInformation){
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaSecondaryApplicantConsent });
      }
    });
    this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((institutionState: InstitutionState) =>
    {
      this.useNewUiForBank = institutionState.institution.useNewUiForBank;
    });

    this.isLoading = false;
  }

  navigateNext() {
    this.store.dispatch({ type: SessionActionTypes.ADD_HISTORY_ENTRY, payload: <HistoryEntry>{entryType: 'Consent to ID Collection', message: 'Applicant: ' + this.currentApplicantID + ' consented to online Id Collection at ' + this.currentDate.toISOString()}});
    this.router.navigate(['personaIdCollection']);
  }

  ngOnDestroy() {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }
}
