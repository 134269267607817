import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';

import { Institution } from '../state-management/state/institution.state';
import { RevationStatus } from '../state-management/state/revation-status';

@Injectable()
export class InstitutionService {

    private noInterceptHttp: HttpClient;
    constructor(
        private httpBackend: HttpBackend,
        private http: HttpClient) {
            this.noInterceptHttp = new HttpClient(this.httpBackend);
    }

    getInstitution(): Observable<Institution> {
        return this.http.get<Institution>('Institution');
    }

    getRevationStatus(revationStatusURL: string): Observable<RevationStatus> {
        // I use the raw HTTP client here so that it doesn't go through the intercept and pick up all the headers
        return this.noInterceptHttp.get<RevationStatus>(revationStatusURL);
        // return from(
        //     fetch(revationStatusURL).then(response => response.json())
        // );
    }
}
