import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { merge, Observable, pipe, Subject, zip } from 'rxjs';

import { Store } from '@ngrx/store';

import { AuthenticationHeaderManager } from '../authentication-header-manager';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProductsActionTypes } from '../state-management/actions/products.actions';

import { ProgressState, Step } from '../state-management/state/progress.state';
import { SelectedProductsState, Product } from '../state-management/state/selected-products.state';
import { ProductsState } from '../state-management/state/products.state';
import { Applicant, ApplicantsState, BusinessApplicant } from '../state-management/state/applicants.state';
import { InstitutionState } from '../state-management/state/institution.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';

import { Disclosure } from '../state-management/state/products.state';

import { Util } from '../common/util';
import { ModalService } from '../modal/modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Settings } from '../settings';
import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';
import { SelectedProductsActionTypes } from '../state-management/actions/selected-products.actions';
import { SessionState } from '../state-management/state/session.state';
import { BusinessFile } from '../models/business-files.model';
import { FILE } from 'dns';
import { DocumentDetail } from '../models/document-detail.model';
import { basename } from 'path';
import { mergeAll } from 'rxjs-compat/operator/mergeAll';

@Component({
    moduleId: module.id,
    templateUrl: './disclosures.component.html',
    styleUrls: ['disclosures.component.scss']
})
export class DisclosuresComponent implements OnDestroy {
    pageName = 'disclosureAcceptance';
    currentStep: Step;
    selectedProducts: Product[];
    isLoading;
    unsubscribe: Subject<void> = new Subject<void>();
    isPanelVisible = false;
    numberOfApplicants = 0;
    pixelId = 0;
    pageEventName = 'Validate';
    useNewUiForBank = false;
    isBuisness = false;
    /** An array of buiness incorpiraton files to be uploaded to centerdoc */
    files: BusinessFile[] = [];
    /** Used to flag a file as to large then dsiplay an error to the user */
    isFileTooBig = false;
    /** Used to hide the X file button for a Sole Proprietor buisness */
    isSoleProprietor = false;
    /** The applicant to be used for uploading buisness incorpuraton documents */
    applicant: Applicant;

    constructor(
        private store: Store<CombinedState>,
        private router: Router,
        private modalService: ModalService<boolean>,
        private sanitizer: DomSanitizer) {
        
        this.store.dispatch({ type: SessionActionTypes.APPLY_BUSINESS_RULES});
        
        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            // update the wizard panel
            this.currentStep = info.currentStep;
            if (this.currentStep > Step.AcceptDisclosures) {
                this.router.navigate(['funding']);
            } else if (this.currentStep < Step.AcceptDisclosures) {
                this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.AcceptDisclosures });
            }
        });

        this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ApplicantsState) => {
            this.applicant = info.applicants[0];
        })

        this.store.select('productsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProductsState) => {
            if(info.isError){
                this.isLoading = info.isLoading;
                return;
            }
            if (info.disclosuresSubmitted && this.currentStep === Step.AcceptDisclosures) {
                if(this.isBuisness){
                    if(this.files.length === 0){
                        this.isLoading = info,this.isLoading;
                        this.router.navigate(['funding']);
                    }
                    if(info.filesSubmitted){
                        this.isLoading = info.isLoading;
                        this.router.navigate(['funding']);
                    }
                } else{
                    this.isLoading = info.isLoading;
                    this.router.navigate(['funding']);
                }
            }
        });

        this.store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe))
            .subscribe((info: SelectedProductsState) => {
                this.selectedProducts = info.selectedProducts;
            });

        this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ApplicantsState) => {
            this.numberOfApplicants = info.applicants.length;
            this.isSoleProprietor = info.applicants[0].businessType === 'S';
        });

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.pixelId = info.institution.pixelId;
            this.useNewUiForBank = info.institution.useNewUiForBank;
            this.isBuisness = info.institution.isBusiness;
        });
        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {

        });



    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    acceptDisclosure(disclosure: Disclosure) {
        disclosure.isAccepted = true;
        return false;
    }

    modalDisclosure(disclosure: Disclosure) {
        this.modalService.openModal(
            '',
            this.getDisclosureIframe(disclosure), [
            {
                label: 'Accept Disclosure',
                result: true,
                closesModal: true,
                class: 'modal-button'
            },
            {
                label: 'Close Disclosure',
                result: false,
                closesModal: true,
                class: 'modal-button'
            }
        ]).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
            if (result) {
                this.acceptDisclosure(disclosure);
            }
        });
        return false;
    }

    getDisclosureIframe(disclosure: Disclosure) {
        const iframeSrc = this.getDisclosureLink(disclosure);
        return this.sanitizer.bypassSecurityTrustHtml(`<iframe src="${iframeSrc}" frameBorder="0" class="disclosure-iframe"></iframe>`);
    }

    getDisclosureLink(disclosure: Disclosure) {
        return Settings.getAPIBaseURL() + 'Disclosures/' + encodeURIComponent(disclosure.disclosureId) + '/' +
            Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    }

    canContinue() {
        for (const product of this.selectedProducts) {
            for (const disclosure of product.disclosures) {
                if (
                    disclosure.acceptanceRequired &&
                    disclosure.disclosureResponse === null ||
                    !disclosure.isAccepted
                ) {
                    return false;
                }

                for (const question of disclosure.questions) {
                    // all questions must be answered
                    if (!question.answer) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    disclosuresExist() {
        let disclosureFound = false;
        this.selectedProducts.forEach(product => {
            if (product.disclosures != null && product.disclosures.length > 0 && disclosureFound === false) {
                disclosureFound = true;
            }
        });
        return disclosureFound;
    }

    selectFundingSources() {
        this.isLoading = true;

        if (this.isBuisness && this.files.length > 0) {
            const stringObs = this.files.map(f => this.fileToObservable(f));
            zip(...stringObs).subscribe((base64StrArr: {base64: string, fileType: string}[]) => {
                let documentDetails = base64StrArr.map(
                    s => this.selectedProducts.filter(sp => sp.applicationCode !== 'CRD')
                            .map(sp => {
                                return <DocumentDetail>{
                                    taxId: parseInt(this.applicant.taxIdentificationNumber.replace(/-/g, '')),
                                    name: this.applicant.firstName + this.applicant.lastName,
                                    accountNumber: parseInt(sp.accountNumber),
                                    subAccountNumber: parseInt(sp.applicationCode),
                                    description: sp.productName,
                                    formName: s.fileType,
                                    file: /.+;base64,(.+)/.exec(s.base64) [1]
                                }
                        })
                )// map to DocumentDetail

                // Flatten the array
                documentDetails = Array.prototype.concat.apply([], documentDetails);
                this.store.dispatch({ type: ProductsActionTypes.SUBMIT_BUSINESS_INCORP_DOCS, payload: documentDetails })
            })

        }
        
        
        this.store.dispatch({ type: ProductsActionTypes.SUBMIT_PRODUCTS_DISCLOSURES, payload: this.selectedProducts });
    }

    handlelDisclosurePDFClick(event) {
        Util.notifyDigitalForWindowOpen(event.srcElement.href);
    }

    /**
     * Gets you the index of the file type you are looking for in files[].
     * @param fileType The type of buisness file you are trying to upload
     * @returns The index in the file array for that file
     */
    public getFileIndex(fileType: string): number {
        return this.files.map(x => x.fileType).indexOf(fileType);
    }

    //https://www.techiediaries.com/angular-formdata/
    /**
     * Uploads a file to files[].
     * If the type of file already exists it sill over write it with the new file.
     * If the file size is to large to upload the isFileTooBig flag will be set to true.
     * @param event A Buisness File
     * @param fileType The type of buisnes file being uploaded
     * @returns
     */
    public onFileChange(event: Event, fileType : string): void {
        const MAXFILESIZE = 104857600;
        const FILE = (event.target as HTMLInputElement).files[0];
        if (FILE.size > MAXFILESIZE){
            this.isFileTooBig = true;
            return;
        }
        const FILEINDEX = this.files.map(x => x.fileType).indexOf(fileType);
        //IF FILEINDEX is -1 Add file to array otherwise replace file at index with new file.
        if(FILEINDEX === -1){
            this.files.push(<BusinessFile>{
                fileType : fileType,
                file : FILE
            });
        } else {
            this.files[FILEINDEX].file = FILE;
        }
    }
    /**
     * Removes a file type from files[]
     * @param event
     * @param fileType The file type to be removed
     * @param elementRef
     */
    public onFileDelete(event : Event, fileType: string, elementRef : HTMLInputElement): void {
        event.stopPropagation();
        this.files.splice(this.getFileIndex(fileType), 1);
        elementRef.value = null;
    }

    /**
     * Converts a BusinessFile object to an object containing the base64 string and the type of file. Only converts one at a time.
     * @param file BusinessFile object obtained from the view.
     * @returns Base64 file string and the file type as an observable.
     * @error returns empty string and the file type as an observable.
     */
    private fileToObservable (file: BusinessFile): Observable<{base64: string, fileType: string}> {
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.readAsDataURL(file.file)
            reader.onload = () => {
                subscriber.next({
                    base64: reader.result as string,
                    fileType: file.fileType
                })
                subscriber.complete();
            }
            reader.onerror = () => {
                subscriber.next({
                    base64: '',
                    fileType: file.fileType
                })
                subscriber.complete()
            }
        })
    }
}

