import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { SessionState, ReturningUser } from '../../state-management/state/session.state';
import { SessionActionTypes } from '../../state-management/actions/session.actions';
import { ModalService } from '../../modal/modal.service';

import { CombinedState } from 'src/app/state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { InstitutionState } from 'src/app/state-management/state/institution.state';
import { Applicant } from 'src/app/state-management/state/applicants.state';

@Component({
    moduleId: module.id,
    templateUrl: './returning-user.component.html',
    styleUrls: ['returning-user.component.scss']
})
export class ReturningUserComponent implements OnDestroy, OnInit {
    constructor(
        private store: Store<CombinedState>,
        private router: Router,
        private modalService: ModalService<boolean>,
        private route: ActivatedRoute
    ) { }

    public isLoading = false;
    public returningUser: ReturningUser = new ReturningUser();
    public unsubscribe: Subject<void> = new Subject<void>();
    public enablePersonaIdCollection = false;

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit() {
        const confirmationCode = this.route.snapshot.paramMap.get('confirmationCode');
        const emailAddress = this.route.snapshot.paramMap.get('emailAddress');

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((institutionState: InstitutionState) =>
        {
            this.enablePersonaIdCollection = institutionState.institution.enablePersonaIdScanning;
        });

        if (confirmationCode && emailAddress) {
            // this part is probably right, but I'm not going to preload the confirmation code since it may be expired or sensitive
            this.returningUser.emailAddress = emailAddress;

            // submit the info
            this.processResumingSession({emailAddress, confirmationCode});
        }
    }

    public resumeSession(): void {
        this.processResumingSession(this.returningUser);
    }

    private processResumingSession(returningUser: ReturningUser) {
        // trim the confirmation code and email
        returningUser.confirmationCode = returningUser.confirmationCode.trim();
        returningUser.emailAddress = returningUser.emailAddress.trim();

        this.store.dispatch({ type: SessionActionTypes.RESUME_SESSION, payload: returningUser });

        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.isLoading = info.isLoading;

            if(!this.isLoading){
                if (info.isError) {
                    this.modalService.presentError(info.error, true).subscribe(() => this.router.navigate(['/home', 'existingUser']));
                } else {
                    if(info.totalApplicants == 1){
                        if (info.zipCode !== '') {
                            this.router.navigate(['products']);
                        }
                        else if (info.zipCode === '' && this.enablePersonaIdCollection){
                            this.router.navigate(['personaconsent']);
                        }
                    }
                    else{
                        if ((info.zipCode === '' || info.secondaryApplicantZipCode === '') && this.enablePersonaIdCollection){
                            this.router.navigate(['personaconsent']);
                        }
                        else if (info.zipCode !== '') {
                            this.router.navigate(['products']);
                        }
                    }
                }
            }
        });
    }

    forgotSessionId() {
        if (this.returningUser.emailAddress !== undefined && this.returningUser.emailAddress.length !== 0) {
            this.store.dispatch({
                type: SessionActionTypes.RETRIEVE_FORGOTTEN_CONFIRMATION_CODE,
                payload: this.returningUser.emailAddress
            });
            this.modalService.openModal(
                'Reminder sent',
                'If the email address you entered belongs to an existing session, it has been emailed instructions on how to resume.', [
                    {
                        label: 'Ok',
                        result: true,
                        closesModal: true,
                        class: 'modal-button'
                    }
                ]).pipe(takeUntil(this.unsubscribe)).subscribe();
        } else {
            this.modalService.openModal('Enter your email address', 'Enter your email address above to receieve a reminder.', [
                {
                    label: 'Ok',
                    result: true,
                    closesModal: true,
                    class: 'modal-button'
                }
            ]).pipe(takeUntil(this.unsubscribe)).subscribe();
        }
    }
}
