import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'csi-disso',
  template: ''
})
export class DissoComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        const key = params['KEY'];
        this.router.navigate(['sso', key]);
      });
  }
}
