<form [formGroup]="signerForm" class="form-applicant-info" autocomplete="off">

	<fieldset class="form-fieldset-applicant" [ngClass]="expanded ? '' : 'form-fieldset-applicant-collapsed'">

		<legend class="subhead">
			<!-- signer name -->
			<div class="legend-title">
				<strong class="form-applicantant-order">Signer Information {{ordinal}}<span *ngIf="!expanded" class="form-applicant-title-colon">:&nbsp;</span></strong>
				<span *ngIf="!expanded" class="form-applicant-name">{{signer.firstName}} {{signer.lastName}}</span>
			</div>
			<!-- /signer name -->

			<!-- buttons-->
			<div class="legend-buttons">
				<!-- show if editing -->
				<button class="applicant-button applicant-button-delete" *ngIf="deleteable" (click)="deleteClick()">Delete</button>
				<!-- show if collapsed -->
				<button *ngIf="editable" (click)="toggleExpand()" class="applicant-button applicant-button-edit">{{ !expanded ? 'Edit' : 'Collapse' }}</button>
			</div>
			<!-- /buttons-->
		</legend>

		<!-- container to hide/show inputs -->
		<div class="form-inputs-container" *ngIf="expanded" [class.form-inputs-container-expanded]="expanded">
			<!-- main info -->
			<div class="form-input-container">
				<div class="form-items form-applicant-firstname">
					<label for="firstName" class="required">First Name</label>
					<div class="requiredContainer">
						<input type="text" required formControlName="firstName" id="firstName" [(ngModel)]="signer.firstName" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.firstName"></csi-validation-indicator>
					</div>
				</div>

				<div class="form-items form-applicant-lastname">
					<label for="lastName" class="required">Last Name</label>
					<div class="requiredContainer">
						<input type="text" required formControlName="lastName" id="lastName" [(ngModel)]="signer.lastName" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.lastName"></csi-validation-indicator>
					</div>
				</div>
			</div>
			<div class="form-input-container">
				<div class="form-items form-applicant-address1">
					<label for="address1" class="required">Street Address 1 <em>Cannot be a PO Box</em></label>
					<div class="requiredContainer">
						<input type="text" required formControlName="address1" id="address1" [(ngModel)]="signer.address1" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.address1"></csi-validation-indicator>
					</div>
				</div>

				<div class="form-items form-applicant-address2">
					<label for="address2">Street Address 2</label>
					<input type="text" formControlName="address2" id="address2" [(ngModel)]="signer.address2" class="form-input">
				</div>
			</div>
			<div class="form-input-container">
				<div class="form-items form-applicant-city">
					<label for="city" class="required">City</label>
					<div class="requiredContainer">
						<input type="text" required formControlName="city" id="city" [(ngModel)]="signer.city" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.city"></csi-validation-indicator>
					</div>
				</div>

				<div class="form-items form-applicant-state">
					<label for="state" class="required no-wrap">State</label>
					<div class="requiredContainer requiredSelect">
						<select formControlName="state" id="state" [(ngModel)]="signer.state" class="form-select">
								<option style="display:none" value=""></option>
								<option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
						</select>
						<csi-validation-indicator [control]="signerForm.controls.state"></csi-validation-indicator>
					</div>
				</div>

				<div class="form-items form-applicant-zipcode">
					<label for="zipCode" class="required">Zip Code</label>
					<div class="requiredContainer">
						<input type="text" formControlName="zipCode" id="zipCode" [(ngModel)]="signer.zipCode" class="form-input" pattern="^\d{5}$" [textMask]="{mask: textMasks.zipMask}">
						<csi-validation-indicator [control]="signerForm.controls.zipCode"></csi-validation-indicator>
					</div>
				</div>
			</div>
			<!-- /main info -->

			<!-- identity -->
			<div class="form-input-container">
				<div class="form-items form-applicant-taxidentificationnumber">
					<label for="taxIdentificationNumber" class="required">Social Security Number</label>
					<div class="requiredContainer">
						<input type="text" required formControlName="taxIdentificationNumber" id="taxIdentificationNumber" [(ngModel)]="signer.taxIdentificationNumber"
							[textMask]="{mask: textMasks.ssnMask, guide: true}" pattern="\d{3}-\d{2}-\d{4}" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.taxIdentificationNumber"></csi-validation-indicator>
					</div>
				</div>
				<div class="form-items form-applicant-phonenumber">
					<label for="phoneNumber" class="required">Phone Number</label>
					<div class="requiredContainer">
						<input type="tel" [textMask]="{mask: textMasks.phoneMask}" formControlName="phoneNumber" id="phoneNumber" [(ngModel)]="signer.phoneNumber" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.phoneNumber"></csi-validation-indicator>
					</div>
				</div>
				<div class="form-items form-applicant-dateofbirth">
					<label for="dateOfBirth" class="required">Birth Date</label>
					<div class="requiredContainer requiredDate">
						<input type="text" required class="form-control form-input form-input-date" placeholder="mm/dd/yyyy" id="dateOfBirth" [(ngModel)]="signer.dateOfBirth"
							[textMask]="{mask: textMasks.dateMask}" formControlName="dateOfBirth">
						<csi-validation-indicator [control]="signerForm.controls.dateOfBirth"></csi-validation-indicator>
					</div>
				</div>
			</div>
			<div class="form-input-container">
				<div class="form-items form-applicant-identificationtype">
					<label for="identificationType" class="required">Identification Type</label>
					<div class="requiredContainer requiredSelect">
						<select formControlName="identificationType" id="identificationType" [(ngModel)]="signer.identificationType" class="form-select">
							<option style="display:none" value=""></option>
							<option *ngFor="let identificationType of identificationTypes" [value]="identificationType.id">{{identificationType.description}}</option>
						</select>
					</div>
				</div>
				<div class="form-items form-applicant-identificationnumber">
					<label for="identificationNumber" class="required">ID Number</label>
					<div class="requiredContainer">
						<input oninput="this.value = this.value.toUpperCase()" type="text" required formControlName="identificationNumber" id="identificationNumber" [(ngModel)]="signer.identificationNumber" class="form-input">
						<csi-validation-indicator [control]="signerForm.controls.identificationNumber"></csi-validation-indicator>
					</div>
				</div>
				<div class="form-items form-applicant-identificationstate">
					<label for="state" class="required no-wrap">State Issued</label>
					<div class="requiredContainer requiredSelect">
						<select formControlName="identificationState" id="identificationState" [(ngModel)]="signer.identificationState" class="form-select">
								<option style="display:none" value=""></option>
								<option *ngFor="let state of states" [value]="state.value">{{state.label}}</option>
						</select>
						<csi-validation-indicator [control]="signerForm.controls.identificationState"></csi-validation-indicator>
					</div>
				</div>
			</div>
			<div class="form-input-container">
				<div class="form-items form-applicant-identificationissuedate">
					<label for="identificationIssueDate" class="required">ID Issue Date</label>
					<div class="requiredContainer requiredDate">
						<input type="text" required class="form-control form-input form-input-date" placeholder="mm/dd/yyyy" id="identificationIssueDate" [(ngModel)]="signer.identificationIssueDate"
							[textMask]="{mask: textMasks.dateMask}" formControlName="identificationIssueDate">
						<csi-validation-indicator [control]="signerForm.controls.identificationIssueDate"></csi-validation-indicator>
					</div>
				</div>
				<div class="form-items form-applicant-identificationexpirationdate">
					<label for="identificationExpirationDate" class="required">ID Expiration Date</label>
					<div class="requiredContainer requiredDate">
						<input type="text" required class="form-control form-input form-input-date" placeholder="mm/dd/yyyy" id="identificationExpirationDate" [(ngModel)]="signer.identificationExpirationDate"
							[textMask]="{mask: textMasks.dateMask}" formControlName="identificationExpirationDate">
						<csi-validation-indicator [control]="signerForm.controls.identificationExpirationDate"></csi-validation-indicator>
					</div>
				</div>
			</div>
			<!-- /identity -->
		</div>
	</fieldset>
</form>
