import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProductsRoutingModule } from './products-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { ProgressModule } from '../progress/progress.module';
import { ProductsSelectorModule } from './products-selector/products-selector.module';
import { EsignModule } from '../esign/esign.module';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';

import { GroupBy } from '../common/group-by.pipe';

import { ProductsComponent } from './products.component';
import { PromoCodeEntryComponent } from './promo-code-entry/promo-code-entry-component';
import { ProductsCartComponent } from './products-cart/products-cart.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        ProductsComponent,
        PromoCodeEntryComponent,
        ProductsCartComponent,
        GroupBy
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        ProductsRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        ProductsSelectorModule,
        EsignModule,
        FacebookPixelModule,
        LoadingModule
    ]
})
export class ProductsModule { }
