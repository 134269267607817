import { Product as ProductBase, Feature as FeatureBase, Bundle } from './products.state';

export interface SelectedProductsState {
    selectedProducts: Product[];
    selectedBundles: Bundle[];
    userComments: string;
    isError: boolean;
    error: any;
    isLoading: boolean;
}

export class SelectedProductsUtil {
    static ditigalFundingType = 'CSI Digital Banking';
    static hasDigitalFunding = (selectedProductsState: SelectedProductsState) =>
        selectedProductsState.selectedProducts.length &&
        selectedProductsState.selectedProducts.some(
            product => product.availableFundingMethods && product.availableFundingMethods.some(
                fundingMethod => fundingMethod.type === SelectedProductsUtil.ditigalFundingType
            )
        ) ||
        selectedProductsState.selectedBundles.length &&
        selectedProductsState.selectedBundles.some(
            bundle => bundle.products.some(
                product => product.availableFundingMethods && product.availableFundingMethods.some(
                    fundingMethod => fundingMethod.type === SelectedProductsUtil.ditigalFundingType
                )
            )
        )
}

export const initialSelectedProductsState: SelectedProductsState = {
    selectedProducts: [],
    selectedBundles: [],
    userComments: '',
    isError: false,
    error: null,
    isLoading: false
};

export class Product extends ProductBase {
    id: string;
    features: Feature[];
    userComments: string;
    productAlias: string;
    accountNumber: string;
    questionGroups: QuestionGroup[];
    digitalSignatures: string[];
}

export class QuestionGroup {
    formFamily: string;
    elements: Element[][];
}

export class Element {
    label: string;
    field: Field;
    class: string;
}

export class Field {
    required: boolean;
    asteriskMasked: boolean;
    inputMasks: InputMask[];
    options: Option[];
    default: string;
    ddn: number;
    formGui: string;
    response: string;
    invalidInputMessage: string;
    validationPattern: string;
}

export class InputMask {
    isRegEx: boolean;
    value: string;
}

export class Option {
    display: string;
    value: string;
}

export class Feature extends FeatureBase {
    isSelected: boolean;
}
