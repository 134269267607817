import { NuFundAction } from 'src/app/common/nufund-action';

export const PromoCodeActionTypes = {
    APPLY_PROMO_CODE: '[Products] APPLY_PROMO_CODE',
    APPLY_PROMO_CODE_SUCCESS: '[Products] APPLY_PROMO_CODE_SUCCESS',
    APPLY_PROMO_CODE_FAILURE: '[Products] APPLY_PROMO_CODE_FAILURE'
};

export class ApplyPromoCodeFailureAction implements NuFundAction {
    type = PromoCodeActionTypes.APPLY_PROMO_CODE_FAILURE;
    constructor(public payload: { error: any }) { }
}
