import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

import { ModalService } from '../../modal/modal.service';

import { Store } from '@ngrx/store';
import { PromoCodeState } from '../../state-management/state/promo-code.state';
import { PromoCodeActionTypes } from '../../state-management/actions/promo-code.actions';

import { CombinedState } from 'src/app/state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { isPromoCodeValid } from 'src/app/common/functions';

@Component({
    moduleId: module.id,
    selector: 'promo-code-entry',
    templateUrl: './promo-code-entry.component.html',
    styleUrls: ['promo-code-entry.component.scss']
})
export class PromoCodeEntryComponent implements OnDestroy {
    @ViewChild('promotionalCodeElement', { static: true }) promotionalCodeInputElement: ElementRef;

    promotionalCode = '';
    currentPromoCode: string;
    showPromoCodeEntry = false;
    unsubscribe: Subject<void> = new Subject<void>();
    @Input() instantAddCode: string;

    constructor(private store: Store<CombinedState>, private modalService: ModalService<void>) {
        store.select('promoCodeStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: PromoCodeState) => {
            if (info.isError) {
                this.handleLoadingError(info.error);
            } else {
                this.showPromoCodeEntry = false;
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public handleLoadingError(error: any) {
        this.modalService.presentError(error, null, 'Error', 'Ok').pipe(takeUntil(this.unsubscribe)).subscribe(null, null, () => { });
    }

    public applyPromoCode() {
        this.store.dispatch({
            type: PromoCodeActionTypes.APPLY_PROMO_CODE,
            payload: {
                promotionalCode: this.promotionalCode,
                instantAddCodes: this.instantAddCode === '' ? [] : [this.instantAddCode]
            }
        });
    }

    public toggleShowPromoCodeEntry() {
        this.showPromoCodeEntry = !this.showPromoCodeEntry;
        if (this.showPromoCodeEntry) {
            setTimeout(() => this.promotionalCodeInputElement.nativeElement.focus(), 0);
        }
    }

    public isPromoCodeValid(): boolean {
        return isPromoCodeValid(this.promotionalCode);
    }
}
