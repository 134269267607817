import { HttpErrorResponse } from '@angular/common/http';
import { STRING_TYPE } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { Observable, Subscriber } from 'rxjs';
import { Button } from './button';

@Injectable()
export class ModalService<T> {
    public title: string;
    public body: string | string[] | SafeHtml;
    public showModal = false;
    public buttons: Button<T>[];

    public subscriber: Subscriber<T>;
    public classOverride = '';

    public openModal(title: string, body: string | string[] | SafeHtml, buttons: Button<T>[], classOverride?: string): Observable<T> {
        this.buttons = buttons;
        this.title = title;
        this.body = body;
        if (classOverride) {
            this.classOverride = classOverride;
        }

        return new Observable<T>(subscriber => {
            this.subscriber = subscriber;
            this.showModal = true;

            return () => this.showModal = false;
        });
    }

    public presentError(error: HttpErrorResponse, result: T, title?: string, buttonLabel?: string): Observable<T> {
        const errorTitle = title || 'Error';
        let errorBody = 'An unexpected error has occurred.';
        if(error && error.error){
            let errorObject= error.error;
            if (errorObject.message) {
                if (errorObject.number === 404) {
                    errorBody = error.statusText;
                }else{
                    errorBody = errorObject.message;
                }
        }
        else if (errorObject.bridgeExceptionDetail && errorObject.bridgeExceptionDetail.message) {
            errorBody = errorObject.bridgeExceptionDetail.message;
        }
        else{
              if(typeof errorObject === "string") {
                 errorBody = errorObject;}
        } 

        }
        const errorButtons = [{
            label: buttonLabel || 'OK',
            result,
            closesModal: true,
            class: 'modal-error-button'
        }];
        return this.openModal(errorTitle, errorBody, errorButtons, 'modal-alert');
    }

    public click(button: Button<T>) {
        if (button.result) {
            this.subscriber.next(button.result);
        }

        if (button.closesModal) {
            this.subscriber.next(button.result);
            this.closeModal(button);
        }
    }

    public closeModal(button?) {
        if(button){
            this.subscriber.next(button.result);
        }
        this.showModal = false;
        if (this.subscriber) {
            this.subscriber.complete();
        }
    }
}
