import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonaConsentComponent } from './persona-consent.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';

const routes: Routes = [
  { 
    path: 'personaconsent',
    component: PersonaConsentComponent,
    canActivate: [
      CanActivateSessionGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonaConsentRoutingModule { }
