<!-- product: auto-added -->
<div *ngIf="preSelectedProducts.length > 0" class="product-item product-auto-added">
    <div class="product-outer-container">
        <!-- inner content -->
        <div class="product-inner-container">
            <div class="product-item-details" *ngFor="let preSelectedProduct of preSelectedProducts">
                <span class="icon icon-bag-2"></span>
                <div class="product-item-description">The product <strong>{{ preSelectedProduct.productName }}</strong> was automatically added to your cart.</div>
            </div>
        </div>
    </div>
</div>
<!-- /product: auto-added -->

<!-- product -->
<ng-container *ngFor="let bundle of bundles">
    <ng-container *ngIf="!bundle.isBundle">
        <product-item [product]="bundle.products[0]" (productAdded)="onProductAdded($event)" (productRemove)="onProductRemove($event)" class="product-item"></product-item>
    </ng-container>
    <ng-container *ngIf="bundle.isBundle">
        <product-bundle [bundle]="bundle" (bundleAdded)="onBundleAdded($event)" (bundleRemove)="onBundleRemove($event)" class="product-item"></product-bundle>
    </ng-container>
</ng-container>
<!-- product -->