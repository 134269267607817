import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProgressModule } from '../progress/progress.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { PersonalInformationRoutingModule } from './personal-information-routing.module';
import { ApplicantModule } from './applicant/applicant.module';

import { PersonalInformationComponent } from './personal-information.component';
import { LoadingModule } from '../loading/loading.module';
import { ApplicantFormComponent } from 'src/new-applicant/components/applicant-form/applicant-form.component';

@NgModule({
    declarations: [
        PersonalInformationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        PersonalInformationRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        ApplicantModule,
        LoadingModule,
        ReactiveFormsModule
    ]
})
export class PersonalInformationModule { }
