<section *ngIf="!isLoading" class="otp-body">



    <div *ngIf="currentTry <= MAX_NUMBER_OF_RETRIES">
        <div class="page-header-content">
            <p class="page-header-text">To verify your identity we've sent a secure verification code to <strong
                    class="no-break">[ (***) *** - 9876 ]</strong>.<br>
                The code will expire after <strong>[ 5 ] minutes</strong>.</p>
    
            <h1 class="subhead">Enter Your One Time Verification Code</h1>
            <input type="text" [(ngModel)]="otp" id="otp-verification" class="form-input" placeholder="Verification Code">
        </div>
        <!-- message: initial -->
        <div *ngIf="!wasError" class="otp-message">
            <p>Didn’t receive your verification code?</p>
            <p><a (click)="didNotReceiveCode()">Send another code</a> or <a (click)="canNotReceiveCode()">choose another
                    contact verification method</a>.</p>
        </div>
        <!-- /message: initial -->

        <!-- message: error -->
        <div *ngIf="wasError" class="otp-message">
            <p>The code you entered is <strong class="otp-message-error">incorrect</strong>.</p>
            <p><a (click)="didNotReceiveCode()">Send another code</a> or <a (click)="canNotReceiveCode()">choose another
                    contact verification method</a>.</p>
        </div>
        <!-- /message: error -->
    </div>
    <!-- message: total failure -->
    <div *ngIf="currentTry > MAX_NUMBER_OF_RETRIES" class="otp-message">
        <p>The amount of verification passcodes have been exceeded.</p>
        <!-- <button (click)="canNotReceiveCode()" class="form-button form-button-lg">Use a Different Verification Method</button> -->
    </div>
    <!-- /message: total failure -->



    <!-- OLD STUFFS -->
    <!--
    <button (click)="submit()">Submit One-Time Password</button>
    <button (click)="didNotReceiveCode()">I did not receive a password</button>
    <button (click)="canNotReceiveCode()">I can not receive a password</button>
    -->

</section>