<div class="promo-home-component" [hidden]="routedPromoCode !== '' || hidePromoCode">
  <!-- toggle "button" -->
  <div (click)="togglePromoCodeVisible()" class="form-home-promo-toggle">
      <label for="promoCode" class="form-label" [class.promo-input-label-on]="isPromoInputVisible">
          Add Promo Code (Optional)
      </label>
  </div>
  <!-- /toggle "button" -->

  <!-- input -->
  <form [formGroup]="promoForm" class="form-home-promo-input-container" [class.promo-input-container-on]="isPromoInputVisible">
      <input formControlName="promoCode" type="string" [ngModel]="promoCode" name="promoCode" autocomplete="off" class="form-input form-home-promo-input" [class.promo-input-on]="isPromoInputVisible" />
  </form>
  <!-- /input -->
</div>