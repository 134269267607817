import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonaConsentRoutingModule } from './persona-consent-routing.module';
import { PersonaConsentComponent } from './persona-consent.component';
import { ProgressModule } from '../progress/progress.module';
import { LoadingModule } from '../loading/loading.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { PageHeaderModule } from '../page-header/page-header.module';


@NgModule({
  declarations: [
    PersonaConsentComponent
  ],
  imports: [
    CommonModule,
    ProgressModule,
    PersonaConsentRoutingModule,
    PageHeaderModule,
    PageFooterModule,
    LoadingModule
  ]
})
export class PersonaConsentModule { }
