import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Level3Question } from '../state-management/state/out-of-wallet.state';
import { Applicant } from '../state-management/state/applicants.state';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OutOfWalletService{

    constructor(public http: HttpClient) {}
    public getOutOfWalletQuestions(applicant: Applicant): Observable<Level3Question[]> {
        return this.http.get<Level3Question[]>('Applicant/OutOfWallet/' + applicant.id + '/');
    }

    // returns nothing
    public scoreOutOfWalletAnswers(answers: number[], applicant: Applicant): Observable<any> {
        return this.http.post<any>('Applicant/OutOfWallet/' + applicant.id + '/', answers);
    }
}
