import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Applicant } from 'src/app/state-management/state/applicants.state';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { StateOption, allStates } from 'src/app/personal-information/applicant/states';
import { BusinessValidators, TextMasks } from '../business-validators';
import { BusinessType } from 'src/app/state-management/state/institution.state';
import { IndustryType, industryTypes } from '../business-industry-types';

@Component({
    selector: 'csi-business-applicant',
    templateUrl: './business-applicant.component.html',
    styleUrls: ['./business-applicant.component.scss']
})
export class BusinessApplicantComponent implements OnInit {
    @Input() business: Applicant;
    @Input() editable = true;
    @Output() businessChange: EventEmitter<Applicant> = new EventEmitter<Applicant>();

    @Input() businessTypes: BusinessType[] = [];

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    businessForm: FormGroup;
    textMasks = new TextMasks();
    states: StateOption[] = allStates;
    industryOptions: IndustryType[] = industryTypes;
    /** Prevents the TIN field from infinitely updating itself. */
    hasBeenAlteredOnce: boolean = false;

    constructor() { }

    ngOnInit() {
        this.businessForm = this.getBusinessFormGroup();
        this.businessForm.statusChanges.subscribe(result => {
            this.valid.emit(result === 'VALID');
        });

        // Listen to the business type field. If it ever changes to or from sole proprieter, we need to adjust the mask accordingly
        this.businessForm.get("businessType").valueChanges.subscribe(result => {
            const isSoleProprietor = result === 'S'

            // Apply the new validators according to the business type
            if (isSoleProprietor) {
                this.businessForm.get("taxIdentificationNumber").setValidators(Validators.compose([
                    Validators.required,
                    Validators.pattern(/\d{3}-\d{2}-\d{4}/)
                ]))
            } else {
                this.businessForm.get("taxIdentificationNumber").setValidators(Validators.compose([
                    Validators.required,
                    Validators.pattern(/\d{2}-\d{7}/)
                ]))
            }

            // apply the mask
            const tinValue = this.businessForm.get("taxIdentificationNumber").value;
            this._applyMask(tinValue, isSoleProprietor)
        })

        // Apply mask manually
        this.businessForm.get("taxIdentificationNumber").valueChanges.subscribe((result: string) => {
            const isSoleProprietor = this.businessForm.get('businessType').value === 'S'

            // First we are assessing if the input is valid. Remove it and exit if not.
            if (!result) return;

            while (result.includes('-')) {
                result = result.replace('-', '');
            }
            
            // Test if there are any non-digits and remove them.
            if (/\D/.test(result)) {
                // find the place there isnt a digit and splice it
                result = [...result]
                    .filter(c => /\d/.test(c))
                    .reduce((previous: string, current: string) => current === ',' ? previous : previous + current, '');
                this._applyMask(result, isSoleProprietor)
                return;
            }

            // Test if the string is longer than expected.
            if (result.length > 9) {
                result = result.slice(0, 9);
                this._applyMask(result, isSoleProprietor)
                return;
            }

            // apply the mask
            this._applyMask(result, isSoleProprietor)

        })
    }

    /**
     * Takes a string and applies a mask to it, which is set in the taxIdentificationNumber field on the businessForm.
     * @param valueToChange The value which is to be altered by this method.
     * @param isSoleProprietor This value helps determine which mask to apply
     * @returns Nothing. The field transformation and application happens in the method.
     */
    private _applyMask(valueToChange: string, isSoleProprietor: boolean): void {
        // prevent infinite loop
        if (this.hasBeenAlteredOnce) {
            this.hasBeenAlteredOnce = false;
            return;
        }

        // remove the dashes
        while (valueToChange.includes('-')) {
            valueToChange = valueToChange.replace('-', '');
        }

        let valueToSet = '';

        // Set the mask. This logic is cumbersome but it works.
        // Someone smarter than me refactor this based off of some pattern.
        if (isSoleProprietor) {
            if (valueToChange.length === 0) {
                valueToSet = '';
            }
            else if (valueToChange.length <= 3) {
                valueToSet = valueToChange.replace(/(\d{0,3})/, '$1');
            }
            else if (valueToChange.length <= 5) {
                valueToSet = valueToChange.replace(/(\d{0,3})(\d{0,2})/, '$1-$2');
            }
            else {
                valueToSet = valueToChange.replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, '$1-$2-$3');
            }
        } else {
            if (valueToChange.length === 0) {
                valueToSet = '';
            }
            else if (valueToChange.length <= 2) {
                valueToSet = valueToChange.replace(/(\d{0,2})/, '$1')
            }
            else {
                valueToSet = valueToChange.replace(/(\d{0,2})(\d{0,7})/, '$1-$2')
            }

        }
        // Prevent infinite loop
        this.hasBeenAlteredOnce = true;

        // setTimeout is a common workaround for ExpressionChangedAfterItHasBeenCheckedException
        setTimeout(() => {this.businessForm.get('taxIdentificationNumber').setValue(valueToSet)})
    }


    private getBusinessFormGroup() {
        // we refrence this from within the verify email control
        const emailControl = new FormControl(this.business.emailAddress, [Validators.required, BusinessValidators.email]);

        // need to subscribe to the email changes and update this one's validity
        const verifyEmailAddressControl = new FormControl(this.business.emailAddress, [
            Validators.required,
            BusinessValidators.valueMatches(emailControl)
        ]);
        emailControl.valueChanges.subscribe(() => verifyEmailAddressControl.updateValueAndValidity());

        return new FormGroup({
            lastName: new FormControl(this.business.lastName, [Validators.required]),
            dateStarted: new FormControl(this.business.dateStarted, [
                Validators.required,
                BusinessValidators.date,
                BusinessValidators.pastDate
            ]),
            businessType: new FormControl(this.business.businessType, [Validators.required]),
            isNonProfit: new FormControl(this.business.isNonProfit, [Validators.required]),
            industryType: new FormControl(this.business.industryType, [Validators.required]),
            taxIdentificationNumber: new FormControl(this.business.taxIdentificationNumber ?? '', [Validators.required]),
            address1: new FormControl(this.business.address1, [Validators.required]),
            address2: new FormControl(this.business.address2),
            city: new FormControl(this.business.city, [Validators.required]),
            state: new FormControl(this.business.state, [Validators.required]),
            // normally this would be required but they can't really edit it here
            zipCode: new FormControl(this.business.zipCode),
            website: new FormControl(this.business.website),

            phoneNumber: new FormControl(this.business.phoneNumber, [Validators.required, BusinessValidators.phoneNumber]),
            emailAddress: emailControl,
            verifyEmailAddress: verifyEmailAddressControl,
            methodOfContact: new FormControl(this.business.methodOfContact, [Validators.required]),
            contactTime: new FormControl(this.business.contactTime, [Validators.required]),

            checksWritten: new FormControl(this.business.checksWritten, [Validators.required]),
            checksDeposited: new FormControl(this.business.checksDeposited, [Validators.required]),
            averageAccountBalance: new FormControl(this.business.averageAccountBalance, [Validators.required]),
            employeeCount: new FormControl(this.business.employeeCount, [Validators.required]),
            isAcceptingCreditPayments: new FormControl(this.business.isAcceptingCreditPayments, [Validators.required]),
            isAcceptingCheckPayments: new FormControl(this.business.isAcceptingCheckPayments, [Validators.required])
        });
    }
}
