import { IdvStrategy } from 'src/app/models/idv.model';
import { RevationStatus } from './revation-status';

export interface InstitutionState {
    /** 
     * Represents the settings the customer has selected on NufundAdmin. Controls various parts of the app
     * such as the logo, whether certain features are available, and certain texts on the page.
     */
    institution: Institution;
    isLoading: boolean;
    isError: boolean;
    error: any;
}

export const initialInstitutionState: InstitutionState = {
    institution: {
        color: '',
        fontColor: '',
        fontStyle: '',
        hideNuFundTradeMark: false,
        logo: '',
        logoFooter: '',
        pageContent: null,
        applicationQuestions: [],
        securityQuestions: [],
        identificationTypes: [],
        welcomeDisclosureId: '',
        welcomeDisclosureDescription: '',
        allowAutoFill: false,
        disableIdentityVerificationScanning: false,
        enablePersonaIdScanning: false,
        sessionTimeoutMilliseconds: 900000, // 15 minutes
        collectSignature: false,
        collectUSResidency: false,
        requireDemonstratedEsignConsent: false,
        requireEsignConsentFromSingleSignOn: false,
        pixelId: 0,
        digitalBankingBrandId: 0,
        digitalURL: '',
        alphaRankId: '',
        googleTagId: '',
        googleTagManagerId: '',
        allowMultipleApplicants: true,
        routingNumber: '',
        institutionName: '',
        isBusiness: false,
        businessTypes: [],
        revationURL: '',
        revationStatus: null,
        skipCustomizeProductsScreen: false,
        uspsAddressValidation: false,
        useNewUiForBank: false,
        hidePromoCode: false,
        isIdentitySolutionCip: false,
        idvGuid: '',
        idvStrategy: 1,
        personaEnvironmentId: '',
        personaTemplateId: ''
    },
    isLoading: false,
    isError: false,
    error: null,
};

export interface BusinessType {
    value: string;
    description: string;
}

export interface Institution {
    color: string;
    fontColor: string;
    fontStyle: string;
    hideNuFundTradeMark: boolean;
    logo: string;
    logoFooter: string;
    pageContent: {
        [key: string]: {
            htmlHeader: string;
            htmlFooter: string;
            title: string;
        };
    };
    applicationQuestions: ApplicationQuestion[];
    businessTypes: BusinessType[];
    securityQuestions: SecurityQuestion[];
    identificationTypes: IdentificationType[];
    welcomeDisclosureId: string;
    welcomeDisclosureDescription: string;
    allowAutoFill: boolean;
    disableIdentityVerificationScanning: boolean;
    enablePersonaIdScanning: boolean;
    sessionTimeoutMilliseconds: number;
    collectSignature: boolean;
    collectUSResidency: boolean;
    requireDemonstratedEsignConsent: boolean;
    requireEsignConsentFromSingleSignOn: boolean;
    pixelId: number;
    digitalBankingBrandId: number;
    digitalURL: string;
    alphaRankId: string;
    googleTagId: string;
    googleTagManagerId: string;
    allowMultipleApplicants: boolean;
    routingNumber: string;
    /** Bank Name */
    institutionName: string;
    isBusiness?: boolean;
    revationURL?: string;
    revationStatus?: RevationStatus;
    skipCustomizeProductsScreen?: boolean;
    uspsAddressValidation?: boolean;
    useNewUiForBank?: boolean;
    hidePromoCode?: boolean;
    /**This toggles between a bank using CIP or IDV */
    isIdentitySolutionCip?: boolean;
    /**The IDV Guid */
    idvGuid?: string;
    /**Boolean that specifies between CIP and IDV */
    idvStrategy?: IdvStrategy;
    personaEnvironmentId: string;
    personaTemplateId: string;
}

export interface IdentificationType {
    description: string;
    id: number;
}

export interface ApplicationQuestion {
    questionId: string;
    questionText: string;
    answerText: string;
    displayOrder: number;
    useTranslate: boolean;
    securityQuestion?: SecurityQuestion;
    fieldValue: string;
}

export interface SecurityQuestion {
    fieldValue: string;
    questionText: string;
    displayOrder: number;
}
