import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { timeout } from 'rxjs/operators';
import { Settings } from '../settings';
import { AuthenticationHeaderManager } from '../authentication-header-manager';
import { Identity } from '../models/identity';

@Injectable({
    providedIn: 'root',
})
export class IdentityService {
    constructor(private http: HttpClient) { }

    public static identityToHttpHeaders(identiy: Identity) {
        return new HttpHeaders({
            Authorization: `${identiy.key} ${identiy.value}`
        });
    }

    getBearerToken(): Observable<Identity> {
        return this.http.get<Identity>('ApimIdentity', {
            headers: AuthenticationHeaderManager.authenticationHeaders
        })
        .pipe(
            timeout(5000)
        );
    }
}
