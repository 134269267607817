import { Level3Question } from '../state/out-of-wallet.state';
import { NuFundAction } from 'src/app/common/nufund-action';

export const OutOfWalletActionTypes = {
    GET_OUT_OF_WALLET_QUESTIONS: '[OutOfWallet] GET_OUT_OF_WALLET_QUESTIONS',
    GET_OUT_OF_WALLET_QUESTIONS_SUCCESS: '[OutOfWallet] GET_OUT_OF_WALLET_QUESTIONS_SUCCESS',
    GET_OUT_OF_WALLET_QUESTIONS_FAILURE: '[OutOfWallet] GET_OUT_OF_WALLET_QUESTIONS_FAILURE',

    SCORE_OUT_OF_WALLET_ANSWERS: '[OutOfWallet] SCORE_OUT_OF_WALLET_ANSWERS',
    SCORE_OUT_OF_WALLET_ANSWERS_SUCCESS: '[OutOfWallet] SCORE_OUT_OF_WALLET_ANSWERS_SUCCESS',
    SCORE_OUT_OF_WALLET_ANSWERS_FAILURE: '[OutOfWallet] SCORE_OUT_OF_WALLET_ANSWERS_FAILURE',

    SET_CURRENT_APPLICANT: '[OutOfWallet] SET_CURRENT_APPLICANT'
};

export class GetOutOfWalletSuccessAction implements NuFundAction {
    type = OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS_SUCCESS;
    constructor(public payload: { questions: Level3Question[] }) { }
}

export class GetOutOfWalletFailureAction implements NuFundAction {
    type = OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class ScoreOutOfWalletFailureAction implements NuFundAction {
    type = OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS_FAILURE;
    constructor(public payload: { error: any }) { }
}
