export class Util {
    private static localWindow = window as any;

    public static notifyDigitalForWindowOpen(target: string) {
        window.parent.postMessage({
            url: window.location.href,
            event: 'NuFund',
            target
        }, '*');
    }

    public static isLocalView() {
        return /^(?:localhost)|(?:127\.(?:[0-9]{1,3}\.){2}[0-9]{1,3}):?[0-9]*$/.test(Util.localWindow.location.hostname);
    }

    static getHostName() {
        return Util.isLocalView() ? 'test.csinufund.com' : Util.localWindow.location.hostname;
    }

    static resizeImageToBase64JPEG(image: CanvasImageSource, maxSize: number) {
        const canvas = document.createElement('canvas');

        // if they upload a SVG things can get starnge...
        let width: number = image.width instanceof SVGAnimatedLength ? 1000 : image.width;
        let height: number = image.height instanceof SVGAnimatedLength ? 1000 : image.height;

        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;

        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        return canvas.toDataURL('image/jpeg');
    }
}
