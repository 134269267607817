<section class="home-form-newuser">
  <label class="home-header">
    <!-- TODO: RESKIN - custom welcome text needs to go here instead of in page-header component -->
    <h1 *ngIf="!useNewUiForBank" class="home-form-header">Welcome</h1>

    <!-- TODO: RESKIN - update button ("Resume Application") when "Go Here" link is clicked to show active -->
    <!-- <div *ngIf="useNewUiForBank" class="home-header-text">
      <p>We’re very excited to have you! Tell us a little about yourself so we can get started.</p>
      <p>Need to finish signing up for accounts? <a [routerLink]="['/home', 'existingUser']" (click)="isNewView = false">Go here</a>.</p>
    </div> -->
  </label>

  <form [formGroup]="businessForm" (ngSubmit)="signUpNewUser()" class="form-home">
    <div class="form-home-container">

      <!-- business name / zip -->
      <div class="form-home-content">
        <div class="form-items form-home-businessname">
          <input type="text" formControlName="lastName" required name="businessName" id="businessName" class="form-input" />
          <label for="businessName" class="form-label">
            Business Name
            <span>(required)</span>
          </label>
          <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="form-required-text">
            Business Name is required
          </div>
        </div>

        <div class="form-items form-home-zip">
          <input type="text" formControlName="zipCode" name="zipCode" id="zipCode" class="form-input" [required]="true"
            pattern="^\d{5}$" [textMask]="{mask: textMasks.zipMask}" />
          <label for="zipCode" class="form-label">
            Zip Code
            <span>(required)</span>
          </label>
          <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="form-required-text">
            This question is required.
          </div>
        </div>
      </div>
      <!-- /business name / zip -->

      <!-- email -->
      <div class="form-home-content">
        <div class="form-items form-home-email">
          <input type="emailAddress" required formControlName="emailAddress" name="emailAddress" id="emailAddress" class="form-input" autocapitalize="none"
          [required]="true" pattern="^.+@.+\..+$" />
          <label for="emailAddress" class="form-label">
              Email Address
              <span>(required)</span>
          </label>
          <div *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)" class="form-required-text">
              Invalid email address
          </div>
        </div>
      </div>
      <!-- /email -->

      <!-- online gambling -->
      <div class="form-home-content">
        <div class="form-items form-home-gambling">
          <div class="form-items-wide">
            <div class="form-items-wide-label-container">
              <label for="gambling" class="form-label">Do you engage in online gambling? <span>(required)</span></label>
            </div>
            <select id="gambling" name="gambling" formControlName="gambling" class="form-input form-select">
              <option style="display:none" value=""></option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>

          <!-- error: unanswered -->
          <div *ngIf="gambling.errors?.required && (gambling.dirty || gambling.touched)" class="form-required-text">
            This question is required
          </div>
          <!-- error: unable -->
          <div *ngIf="gambling.errors?.mustBeFalse && (gambling.dirty || gambling.touched)" class="form-required-text">
            Sorry, we are unable to open an account for you online at this time
          </div>
        </div>
      </div>
      <!-- /online gambling-->

      <!-- sell extras -->
      <div class="form-home-content">
        <div class="form-items">
          <div class="form-items-wide form-home-extras">
            <div class="form-items-wide-label-container">
              <label for="cashChecks" class="form-label">Will you sell money orders, cash checks, etc? <span>(required)</span></label>
            </div>
            <select id="cashChecks" name="cashChecks" formControlName="cashChecks" class="form-input form-select">
              <option style="display:none" value=""></option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>

          <!-- error: unanswered -->
          <div *ngIf="cashChecks.errors?.required && (cashChecks.dirty || cashChecks.touched)" class="form-required-text">
            This question is required
          </div>
          <!-- error: unable -->
          <div *ngIf="cashChecks.errors?.mustBeFalse && (cashChecks.dirty || cashChecks.touched)" class="form-required-text">
            Sorry, we are unable to open an account for you online at this time
          </div>
        </div>
      </div>
      <!-- /sell extras -->

      <!-- how heard -->
      <div class="form-home-content">
        <div class="form-items form-home-howheard">
          <div class="form-items-wide">
            <div class="form-items-wide-label-container">
              <label for="campaign" class="form-label">How did you hear about us?</label>
            </div>
            <select id="campaign" name="campaign" class="form-input form-select" formControlName="campaign">
              <option style="display:none" value=""></option>
              <option *ngFor="let option of campaignOptions" [value]="option">{{option}}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- /how heard -->

      <!-- where heard -->
      <div class="form-home-content">
        <div *ngIf="businessForm.value.campaign === 'Other'" class="form-items form-home-whereheard">
          <input type="text" id="other" name="other" formControlName="other" class="form-input">
          <label for="other" class="form-label">
            Where did you hear about us?
          </label>
        </div>
      </div>
      <!-- /where heard -->

      <!-- esign -->
      <div class="esign-home-component">
        <csi-esign *ngIf="isEsignRequired()" [verifyPDFOpened]="requireDemonstratedEsignConsent"
          [disclosureURL]="getEsignLink()" [disclosureDescription]="getEsignDescription()"
          (esignValidChanged)="esignValid = ($event || !isEsignRequired())"></csi-esign>
      </div>
      <!-- /esign -->

      <!-- promo -->
      <home-promo-code [(promoCode)]="promoCode"></home-promo-code>
      <!-- <div class="promo-home-component" [hidden]="routedPromoCode !== '' || hidePromoCode">
        <div (click)="isPromoInputVisible = !isPromoInputVisible;" class="form-home-promo-toggle">
          <label for="promoCode" class="form-label" [class.promo-input-label-on]="isPromoInputVisible">
            Add Promo Code (Optional)
          </label>
        </div>
        <div class="form-home-promo-input-container" [class.promo-input-container-on]="isPromoInputVisible">
          <input type="string" formControlName="promoCode" name="promoCode" autocomplete="off"
            class="form-input form-home-promo-input" [class.promo-input-on]="isPromoInputVisible" />
        </div>
      </div> -->
      <!-- /promo -->

      <div class="form-home-content">
        <!-- continue -->
        <div class="form-items form-home-buttons">
          <button type="submit" [disabled]="businessForm.invalid || isLoading || !esignValid || !isPromoCodeValid()" name="signUp"
            class="login-form-buttonsubmit">
            Continue
          </button>
        </div>
      </div>

    </div>
  </form>
</section>
