import { IdvResponse } from 'src/app/models/idv.model';
import { ApplicationQuestion } from './institution.state';

export interface ApplicantsState {
    /** List of applicants on the current application. */
    applicants: Applicant[];
    /** Index in the `applicants` list of the applicant we are currently editing or inspecting on a macro level. */
    currentApplicantIndex: number;
    canContinue: boolean;

    confirmationCode: string;
    nuFundStatus: string;
    promotionalCodes: string[];

    // business banking fields
    gambling ?: boolean;
    cashChecks ?: boolean;
    campaign ?: string;
    other ?: string;
    isBusiness ?: boolean;
    lastDeletedApplicantId ?: string;

    
    /** This is the response from the api. Reflects the state of the IDV according to business rules. */
    idvResponse: IdvResponse;
}

export interface BusinessApplicant {
    dateStarted?: string;
    isNonProfit?: boolean;
    website?: string;
    methodOfContact?: string;
    contactTime?: string;
    checksWritten?: number;
    checksDeposited?: number;
    averageAccountBalance?: number;
    employeeCount?: number;
    isAcceptingCreditPayments?: boolean;
    isAcceptingCheckPayments?: boolean;
    businessType?: string;
    industryType?: string;

    // used to advance the business banking screen if the 0th applicant has been saved to the API
    submitted?: boolean;
    isValid?: boolean;
}

export class Applicant implements BusinessApplicant {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    phoneType: PhoneType;
    zipCode: string;
    promotionalCode: string;

    salutation: string;
    middleInitial: string;
    verifyEmailAddress: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingState: string;
    mailingZipCode: string;
    /** Main Identification Number */
    taxIdentificationNumber: string;
    dateOfBirth: string;
    identificationType: string;
    identificationNumber: string;
    identificationState: string;
    identificationIssueDate: string;
    identificationExpirationDate: string;
    employerName: string;
    employerPhone: string;
    employerOccupationTitle: string;
    digitalSignature: string;
    applicationQuestions: ApplicationQuestion[];
    index: number;
    nuFundSessionId: string;
    showIdFill: boolean;
    frontImage: string;
    backImage: string;
    idResultsFilled: boolean;
    // hasSSN: boolean;
    citizenType: string;
    greenCardNumber: string;

    // business
    dateStarted?: string;
    isNonProfit?: boolean;
    website?: string;
    methodOfContact?: string;
    contactTime?: string;
    checksWritten?: number;
    checksDeposited?: number;
    averageAccountBalance?: number;
    employeeCount?: number;
    isAcceptingCreditPayments?: boolean;
    isAcceptingCheckPayments?: boolean;
    businessType?: string;
    industryType?: string;
    submitted?: boolean;


    isLoading: boolean;
    isError: boolean;
    error: any;
    isVerified: boolean;
    isSSO: boolean;
    isAddingApplicant: boolean;

    contactPhoneNumber: string;

    // used in business banking to disable the editing and isValidation of completed applicants
    completeOnServer: boolean;
    isValid: boolean;
}
export enum PhoneType {
    Other = '',
    Mobile = 'MOBILE',
    Landline = 'LANDLINE',
    IP = 'IP',
    Satelite = 'SATELITE',
    Fax = 'FAX',
}
export const demoApplicant: Applicant = {
    id: '',
    salutation: '',
    firstName: 'LaurieAnn',
    middleInitial: '',
    lastName: 'Radlein',
    emailAddress: 'laurieann.radelin@csiweb.com',
    verifyEmailAddress: 'laurieann.radelin@csiweb.com',
    phoneNumber: '2704427361',
    phoneType: PhoneType.Other,
    address1: '3507 Woodlea Ave',
    address2: '',
    city: 'Baltimore',
    state: 'MO',
    zipCode: '26214',
    mailingAddress1: '3507 Woodlea Ave',
    mailingAddress2: '',
    mailingCity: 'Baltimore',
    mailingState: 'MO',
    mailingZipCode: '26214',
    taxIdentificationNumber: '666-13-5502',
    dateOfBirth: '02/14/1968',
    identificationType: '5',
    identificationNumber: '419949394',
    identificationState: 'MA',
    identificationIssueDate: '01/01/2010',
    identificationExpirationDate: '01/01/2030',
    employerName: 'Computer Services, Inc.',
    employerPhone: '2704427361',
    employerOccupationTitle: 'Product Manager',
    digitalSignature: '',
    applicationQuestions: [],
    index: 0,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: true,
    citizenType: '0',
    greenCardNumber: '',

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false,
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '2704427361',
    completeOnServer: false,
    isValid: false
};

export const demoExperianApplicant: Applicant = {
    id: '',
    salutation: '',
    firstName: 'THOMAS',
    middleInitial: 'J',
    lastName: 'CERICH',
    emailAddress: 'joseph.jordan@experian.com',
    verifyEmailAddress: 'joseph.jordan@experian.com',
    phoneNumber: '5128827556',
    phoneType: PhoneType.Mobile,
    address1: '219 E 15TH ST APT 2',
    address2: '',
    city: 'MISSION',
    state: 'TX',
    zipCode: '78572-4043',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    taxIdentificationNumber: '666-59-2549',
    dateOfBirth: '11/18/1958',
    identificationType: '5',
    identificationNumber: '08187264',
    identificationState: 'TX',
    identificationIssueDate: '04/01/2010',
    identificationExpirationDate: '04/01/2030',
    employerName: 'Computer Services Inc',
    employerPhone: '123-456-7890',
    employerOccupationTitle: 'Lead Clown Juggler',
    digitalSignature: '',
    applicationQuestions: [],
    index: 1,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: true,
    citizenType: '0',
    greenCardNumber: '',

    dateStarted: '03/16/1965',
    isNonProfit: false,
    website: 'https:/?/www.csiweb.com',
    methodOfContact: 'email',
    contactTime: 'morning',
    checksWritten: 1500,
    checksDeposited: 700,
    averageAccountBalance: 5000000,
    employeeCount: 1300,
    isAcceptingCreditPayments: true,
    isAcceptingCheckPayments: true,
    businessType: 'C',
    industryType: '520000',
    submitted: false,

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false, // this is true for idv
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '2704427361',
    completeOnServer: false,
    isValid: false
};

export const demoBusiness: Applicant = {
    id: '',
    salutation: '',
    firstName: '',
    middleInitial: '',
    lastName: 'Computer Services Inc.',
    emailAddress: 'laurieann.radelin@csiweb.com',
    verifyEmailAddress: 'laurieann.radelin@csiweb.com',
    phoneNumber: '8005454274',
    phoneType: PhoneType.Other,
    address1: '3901 Technology Dr.',
    address2: '',
    city: 'Paducah',
    state: 'KY',
    zipCode: '42001',
    mailingAddress1: '3901 Technology Dr.',
    mailingAddress2: '',
    mailingCity: 'Paducah',
    mailingState: 'KY',
    mailingZipCode: '42001',
    taxIdentificationNumber: '123-45-6789',
    dateOfBirth: '',
    identificationType: '',
    identificationNumber: '',
    identificationState: '',
    identificationIssueDate: '',
    identificationExpirationDate: '',
    employerName: '',
    employerPhone: '',
    employerOccupationTitle: '',
    digitalSignature: '',
    applicationQuestions: [],
    index: 0,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: true,
    citizenType: '',
    greenCardNumber: '',

    dateStarted: '03/16/1965',
    isNonProfit: false,
    website: 'https://www.csiweb.com',
    methodOfContact: 'email',
    contactTime: 'morning',
    checksWritten: 1500,
    checksDeposited: 700,
    averageAccountBalance: 5000000,
    employeeCount: 1300,
    isAcceptingCreditPayments: true,
    isAcceptingCheckPayments: true,
    businessType: 'C',
    industryType: '520000',
    submitted: false,

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false,
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '2704427361',
    completeOnServer: false,
    isValid: false
};

export const demoExperianBusiness: Applicant = {
    id: '',
    salutation: '',
    firstName: '',
    middleInitial: '',
    lastName: 'JUNEAU JUMPERS FOUNDATION INC',
    emailAddress: 'laurieann.radelin@csiweb.com',
    verifyEmailAddress: 'laurieann.radelin@csiweb.com',
    phoneNumber: '8005454274',
    phoneType: PhoneType.Other,
    address1: '3192 PIONEER AVE',
    address2: '',
    city: 'JUNEAU',
    state: 'AK',
    zipCode: '99801',
    mailingAddress1: '3901 Technology Dr.',
    mailingAddress2: '',
    mailingCity: 'Paducah',
    mailingState: 'KY',
    mailingZipCode: '42001',
    taxIdentificationNumber: '91-8531021',
    dateOfBirth: '',
    identificationType: '',
    identificationNumber: '',
    identificationState: '',
    identificationIssueDate: '',
    identificationExpirationDate: '',
    employerName: '',
    employerPhone: '',
    employerOccupationTitle: '',
    digitalSignature: '',
    applicationQuestions: [],
    index: 0,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: true,
    citizenType: '0',
    greenCardNumber: '',

    dateStarted: '03/16/1965',
    isNonProfit: false,
    website: 'https:/?/www.csiweb.com',
    methodOfContact: 'email',
    contactTime: 'morning',
    checksWritten: 1500,
    checksDeposited: 700,
    averageAccountBalance: 5000000,
    employeeCount: 1300,
    isAcceptingCreditPayments: true,
    isAcceptingCheckPayments: true,
    businessType: 'C',
    industryType: '520000',
    submitted: false,

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false, // this is true for idv
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '2704427361',
    completeOnServer: false,
    isValid: false
};

export const demoExperianBusinessSigner: Applicant =  {
    id: '',
    salutation: '',
    firstName: 'Olive',
    middleInitial: '',
    lastName: 'Faustino',
    emailAddress: 'joseph.jordan@experian.com',
    verifyEmailAddress: 'joseph.jordan@experian.com',
    phoneNumber: '9075869925',
    phoneType: PhoneType.Mobile,
    address1: '800 F ST APT H5',
    address2: '',
    city: 'JUNEAU',
    state: 'AK',
    zipCode: '99801',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    taxIdentificationNumber: '666-55-1196',
    dateOfBirth: '12/24/1964',
    identificationType: '5',
    identificationNumber: '08187264',
    identificationState: 'PA',
    identificationIssueDate: '04/01/2010',
    identificationExpirationDate: '04/01/2030',
    employerName: 'Computer Services Inc',
    employerPhone: '123-456-7890',
    employerOccupationTitle: 'Lead Clown Juggler',
    digitalSignature: '',
    applicationQuestions: [],
    index: 0,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: null,
    citizenType: '',
    greenCardNumber: '',

    dateStarted: '',
    isNonProfit: null,
    website: '',
    methodOfContact: '',
    contactTime: '',
    checksWritten: null,
    checksDeposited: null,
    averageAccountBalance: null,
    employeeCount: null,
    isAcceptingCreditPayments: null,
    isAcceptingCheckPayments: null,
    businessType: '',
    industryType: '',
    submitted: false,

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false,
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '',
    completeOnServer: false,
    isValid: false
};

export const defaultApplicant: Applicant = {
    id: '',
    salutation: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    emailAddress: '',
    verifyEmailAddress: '',
    phoneNumber: '',
    phoneType: PhoneType.Other,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    taxIdentificationNumber: '',
    dateOfBirth: '',
    identificationType: '5',
    identificationNumber: '',
    identificationState: '',
    identificationIssueDate: '',
    identificationExpirationDate: '',
    employerName: '',
    employerPhone: '',
    employerOccupationTitle: '',
    digitalSignature: '',
    applicationQuestions: [],
    index: 0,
    promotionalCode: '',
    nuFundSessionId: '',
    showIdFill: false,
    frontImage: '',
    backImage: '',
    idResultsFilled: false,
    // hasSSN: null,
    citizenType: '',
    greenCardNumber: '',

    dateStarted: '',
    isNonProfit: null,
    website: '',
    methodOfContact: '',
    contactTime: '',
    checksWritten: null,
    checksDeposited: null,
    averageAccountBalance: null,
    employeeCount: null,
    isAcceptingCreditPayments: null,
    isAcceptingCheckPayments: null,
    businessType: '',
    industryType: '',
    submitted: false,

    isLoading: false,
    isError: false,
    error: null,
    isVerified: false,
    isSSO: false,
    isAddingApplicant: false,

    contactPhoneNumber: '',
    completeOnServer: false,
    isValid: false
};

export const initialApplicantsState: ApplicantsState = {
    applicants: [Object.assign({}, defaultApplicant)],
    currentApplicantIndex: 0,
    canContinue: true,

    confirmationCode: '',
    nuFundStatus: '',
    promotionalCodes: [],

    // business banking fields
    gambling : null,
    cashChecks : null,
    campaign : '',
    other : '',
    isBusiness: false,
    lastDeletedApplicantId: null,

    idvResponse: null,
};
