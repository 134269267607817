import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Suggestion } from '../state-management/state/routing-lookup.state';

@Injectable()
export class RoutingLookupService{
    constructor(public http: HttpClient) {
    }

    lookupRoutingNumber(routingNumber: number): Observable<Suggestion[]> {
        return this.http.get<Suggestion[]>('Institution/Routing/' + routingNumber + '/')
    }
}
