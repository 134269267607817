import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Product, Bundle } from '../../state-management/state/products.state';

@Component({
    moduleId: module.id,
    selector: 'products-cart',
    templateUrl: './products-cart.component.html',
    styleUrls: ['products-cart.component.scss']
})
export class ProductsCartComponent {
    @Output() productRemove: EventEmitter<Product> = new EventEmitter<Product>();
    @Output() bundleRemove: EventEmitter<Bundle> = new EventEmitter<Bundle>();
    @Input() selectedProducts: Product[] = [];
    @Input() selectedBundles: Bundle[] = [];

    showCart = false;
    timeoutId = null;
    isRetracting = false;
    private readonly CART_SHOW_DURATION = 3000;

    open() {
        this.isRetracting = this.showCart = true;
        this.timeoutId = setTimeout(() => this.showCart = false, this.CART_SHOW_DURATION);
    }

    get totalCartItems() {
        return this.selectedBundles.length + this.selectedProducts.length;
    }

    toggleShowCart() {
        this.showCart = !this.showCart;
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }
        if (this.isRetracting) {
            this.isRetracting = false;
        }
    }

    onProductRemove(product: Product) {
        this.productRemove.emit(product);
        this.toggleShowCart();
    }

    onBundleRemove(bundle: Bundle) {
        this.bundleRemove.emit(bundle);
        this.toggleShowCart();
    }
}
