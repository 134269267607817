import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChallengeDepositComponent } from './challenge-deposit.component';

const routes: Routes = [
    {
        path: 'challengeDeposit',
        component: ChallengeDepositComponent
    }, {
        path: 'challengeDeposit/:confirmationCode',
        component: ChallengeDepositComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ChallengeDepositRoutingModule { }
