import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
    ApplicantsActionsTypes, SubmitApplicantSuccessAction, SubmitApplicantFailureAction,
    SubmitIdentificationDocumentFailureAction,
    SaveApplicantSuccessAction,
    SaveApplicantFailureAction
} from '../actions/applicants.actions';
import { VerificationService } from '../../services/verification.service';
import { of } from 'rxjs';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IdvRequest, IdvResponse } from 'src/app/models/idv.model';
import { Store } from '@ngrx/store';
import { CombinedState } from '../state/combined.state';

@Injectable()
export class ApplicantsEffects {
    /**
     *
     */

    @Effect()
    appGetInfo$ = this.action$
        .pipe(
            ofType(ApplicantsActionsTypes.SUBMIT_APPLICANT),
            switchMap((action: NuFundAction) => {
                return this.service.verify(action.payload).pipe(
                    map((info: any) => {
                        // The InstitutionService successfully completed the Http request
                        return new SubmitApplicantSuccessAction({ result: info });
                    }),
                    catchError((response: any) => {
                        // The InstitutionService failed somewhere while making the Http request
                        return of(new SubmitApplicantFailureAction(response));
                    })
                )
            })
        )



    @Effect()
    submitBusinessApplicants$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS),
        switchMap((action: NuFundAction) => {
            return this.service.saveBusinessApplicants(action.payload, action.continueSession).pipe(
                map((info: any) => {
                    // The InstitutionService successfully completed the Http request
                    return { type: ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS_SUCCESS, payload: info };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of({ type: ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS_FAILURE, payload: response });
                })
            )
        })
    )

    @Effect()
    submitIdvFlow$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_IDV_FLOW),
        switchMap((action: NuFundAction) => {
            return this.service.continueToIdvFlow(action.payload).pipe(
                map((info: IdvResponse) => {
                    if (info.exceptionMessage === 'ACCEPT') {
                        const index = action.payload.currentApplicantIndex;
                        const applicantCount = action.payload.length;
                        if (applicantCount === index + 1) {
                            this.router.navigateByUrl('disclosures');
                        } else {

                            this.store.dispatch({ type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT, payload: 1 });
                            this.router.navigateByUrl('personal-information');
                        }
                        
                    }
                    return { type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    submitIdvFlowWithStepUp$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_IDV_FLOW_WITH_STEP_UP),
        switchMap((action: NuFundAction) => {
            return this.service.continueToIdvFlowWithStepUp(action.payload).pipe(
                map((info: IdvResponse) => {
                    
                    this.router.navigateByUrl('idv/otp');
                    return { type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW_WITH_STEP_UP_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    submitOtp$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_OTP_CODE),
        switchMap((action: NuFundAction) => {
            return this.service.submitIdvOtp(action.payload).pipe(
            // return of ({ type: ApplicantsActionsTypes.SUBMIT_OTP_CODE_SUCCESS, payload: action.payload }).pipe(
                map((info: IdvResponse) => {
                    return { type: ApplicantsActionsTypes.SUBMIT_OTP_CODE_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    resendOtp$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.DID_NOT_RECEIVE_CODE),
        switchMap((action: NuFundAction) => {
            return this.service.resendIdvOtp(action.payload.idvRequest, action.payload.isStepUp).pipe(
                map((info: IdvResponse) => {
                    return { type: ApplicantsActionsTypes.DID_NOT_RECEIVE_CODE_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    skipOtp$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE),
        switchMap((action: NuFundAction) => {
            return this.service.skipIdvOtp(action.payload).pipe(
                map((info: IdvResponse) => {
                    return { type: ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    submitKiq$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_KIQ_QUESTIONS),
        switchMap((action: NuFundAction) => {
            // return this.service.submitIdvOtp().pipe(
            return this.service.submitIdvKiq(action.payload).pipe(
                map((info: IdvResponse) => {
                    return { type: ApplicantsActionsTypes.SUBMIT_KIQ_QUESTIONS_SUCCESS, payload: info };
                })
            )
        })
    )

    @Effect()
    submitBusinessIdv$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_BUSINESS_IDV),
        switchMap((action: NuFundAction) => {
            // TODO: map to idvbusinessrequest object   
            return this.service.submitBusinessIdv(action.payload).pipe(
            // return of ({ type: ApplicantsActionsTypes.SUBMIT_OTP_CODE_SUCCESS, payload: action.payload }).pipe(
                map((info: IdvResponse) => {
                    this.router.navigate(['disclosures'])
                    return { type: ApplicantsActionsTypes.SUBMIT_BUSINESS_IDV_SUCCESS, payload: info };
                }),
                catchError(e => of({
                    type: ApplicantsActionsTypes.SUBMIT_BUSINESS_IDV_FAILURE,
                    payload: e
                }))
            )
        })
    )


    @Effect()
    submitIdDocument$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT),
        switchMap((action: NuFundAction) => {
            return this.service.submitIdentificationDocument(action.payload).pipe(
                map((info: any) => {
                    // The InstitutionService successfully completed the Http request
                    return { type: ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT_SUCCESS, payload: info };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new SubmitIdentificationDocumentFailureAction(response));
                })
            )
        })
    )


    @Effect()
    addApplicant$ = this.action$
        .pipe(
            ofType(ApplicantsActionsTypes.ADD_APPLICANT),
            switchMap((action: NuFundAction) => {
                return this.service.addNewApplicant().pipe(
                    map((info: any) => {
                        // The InstitutionService successfully completed the Http request
                        return { type: ApplicantsActionsTypes.ADD_APPLICANT_SUCCESS, payload: info };
                    }),
                    catchError((response: any) => {
                        // The InstitutionService failed somewhere while making the Http request
                        return of(new SubmitApplicantFailureAction(response));
                    })
                )
            })
        )

    @Effect()
    removeApplicant$ = this.action$.pipe(
        ofType(ApplicantsActionsTypes.REMOVE_APPLICANT),
        switchMap((action: NuFundAction) => {

            const observable$ = action.payload.id 
                ? this.service.removeApplicant(action.payload)
                : of (null)
            return observable$.pipe(
                map((info: any) => {
                    // The InstitutionService successfully completed the Http request
                    return { type: ApplicantsActionsTypes.REMOVE_APPLICANT_SUCCESS, payload: action.payload };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new SubmitApplicantFailureAction(response));
                })
            )
        })
    )

    @Effect()
    appSaveInfo$ = this.action$
        .pipe(
            ofType(ApplicantsActionsTypes.SAVE_APPLICANT),
            switchMap((action: NuFundAction) => {
                return this.service.saveApplicant(action.payload.applicant, action.payload.personaStatus).pipe(
                    map((info: any) => {
                        // The InstitutionService successfully completed the Http request
                        return new SaveApplicantSuccessAction({ result: info });
                    }),
                    catchError((response: any) => {
                        // The InstitutionService failed somewhere while making the Http request
                        return of(new SaveApplicantFailureAction(response));
                    })
                )
            })
        )

        // actions$ with the dollar sign is naming convention for things that are observables
        constructor(
            private action$: Actions,
            private router: Router,
            private service: VerificationService,
            private store: Store<CombinedState>,
        ) { }
}
