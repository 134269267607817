import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { SessionState } from '../state-management/state/session.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';

import { InstitutionState } from '../state-management/state/institution.state';

import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './page-not-found.component.html',
    styleUrls: ['page-not-found.component.scss']
})
export class PageNotFoundComponent {
    public institutionState: InstitutionState;
    public unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private store: Store<CombinedState>,
    ) {
        store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institutionState = info;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    returnToLogin() {
        this.store.dispatch({ type: SessionActionTypes.LOG_OUT });
        this.router.navigate(["home"])
    }

}