import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PersonalInformationComponent } from './personal-information.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';

const routes: Routes = [
    {
        path: 'personal-information-old',
        component: PersonalInformationComponent,
        canActivate: [
            CanActivateSessionGuard
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class PersonalInformationRoutingModule { }
