import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgressModule } from '../progress/progress.module';
import { CompletionRoutingModule } from './completion-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';

import { CompletionComponent } from './completion.component';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        CompletionComponent
    ],
    imports: [
        CommonModule,
        CompletionRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        ProgressModule,
        FacebookPixelModule,
        LoadingModule
    ]
})
export class CompletionModule { }
