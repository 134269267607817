
import { IdvStrategy } from "../models/idv.model";
import { ApplicantsState } from "../state-management/state/applicants.state";
import { Institution } from "../state-management/state/institution.state";

/** 
 * @deprecated Used only in `personal-information.component.ts` and `applicant.component.ts` and `business-information.component.ts`
 * 
*/
export const isIdvFlow = (institution: Institution) => !institution.isIdentitySolutionCip;


/**
 * 
 * @param institutionName The bank name.
 * @returns A truncated instution name according to what RCG prepends to our requests
 */
export const truncateInstitutionName = (institutionName: string): string =>
    institutionName.length <= 21
        ? institutionName
        : institutionName.substring(0, 20);

/**
 * Controls the idv routing between the multipe components of the idv flow.
 * @param applicantsState The most recent applicant state from the store
 * @returns A `string[]` to be passed into `this.router.navigate(string[])`
 */
export function idvFindRouteToNavigateTo(applicantsState: ApplicantsState): string[] {
    // ROUTING LOGIC: 
    let routeToNavigateTo: string[] = [];
    // if there is only one applicant...
    if (applicantsState.applicants.length === 1) {
        // continue to disclosures
        routeToNavigateTo = ['disclosures']
    // if there are multiple applicants...
    } else {
        // ... and we have only done the process for the first
        if (applicantsState.currentApplicantIndex === 0) {
            // ... circle back around and repeat the process for the next applicant
            routeToNavigateTo = ['personal-information'];
            // ... and we have done the process for the second applicant
        } else {
            // ... continue to disclosures
            routeToNavigateTo = ['disclosures']
        }
    }

    return routeToNavigateTo;
}

/** Gets the Ip address and returns it as a string.
 * @note Uses fetch and promises to make the call to bypass the interceptor. Use `await`. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await
*/
export async function getIpAddressAsync(): Promise<string> {
    const response = await fetch('https://api.ipify.org?format=json')
    const json  = await response.json()
    return await json.ip;
}

export function trimPhoneNumber(phoneNumber: string ) {
    const unFormatRegex = /\d{10}/
    if (unFormatRegex.test(phoneNumber)) {
        return phoneNumber
    }

    const formatRegex = /\((\d{3})\) ?(\d{3})-(\d{4})/
    const a = formatRegex.exec(phoneNumber).slice(1).reduce((prev, curr) => prev+curr, '')
    return a;
    // return phoneNumber
}

/** The only valid characters for Promo Codes are `a-z`, `A-Z` and `0-9` */
export function isPromoCodeValid(promoCode: string): boolean {
    const specialCharacterRegex = /[~`!#$%\^&*+=\-\[\]\\';,/{}\(\)|\\":<>\? @_\.]/g;
    const test =  !specialCharacterRegex.test(promoCode);
    return test;
}