<main id="view-idv">
  <!-- mobile menu -->
  <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
    <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
      <span class="mobile-menu-bars"></span>
    </div>
  </div>
  <!-- /mobile menu -->

  <section class="inner-main-content idv-content">

    <!-- xl container -->
    <div class="xl-container">
      <div class="page-header-content">
        <page-header pageName="personaInformation" class="page-header-logo-title"></page-header>
        <!-- <csi-facebook-pixel *ngIf="pixelId" [pixelId]="pixelId" [pageEventName]="pageEventName"></csi-facebook-pixel> -->

        <!-- RESKIN: NAV -->
        <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
          <wizard-progress></wizard-progress>
        </div>
        <!-- /RESKIN: NAV -->
      </div>

      <csi-loading [isLoading]="isLoading"></csi-loading>

      <div [class.loading]="isLoading" class="page-body-content">

        <router-outlet></router-outlet>

      </div>

      <page-footer class="page-footer-content"></page-footer>


    </div>
    <!-- /xl container -->

  </section>

  <!-- "continue" button -->
  <!-- should this button be left out of html? is this being handled somewhere else? -->
  <div class="next-button-container">
    <!-- <button [disabled]="!canContinue()" (click)="next()" data-continue-page="funding" class="next-button">Continue</button> -->
    <button (click)="continueClick()" [disabled]="isLoading || isDisabled" class="next-button">Continue</button>
  </div>
  <!-- /"continue button -->

</main>