import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { SessionState } from '../state-management/state/session.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';
import { ApplicantsState } from '../state-management/state/applicants.state';
import { ApplicantsActionsTypes } from '../state-management/actions/applicants.actions';

import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    template: ''
})
export class SSOComponent implements OnDestroy, OnInit {
    public paramSub: Subscription;
    public unsubscribe: Subject<any> = new Subject<any>();

    public code: string;
    constructor(
        private store: Store<CombinedState>,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.paramSub = this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            this.loadSSOSessionData(params.code);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public loadSSOSessionData(code: string): void {
        if (code === undefined || code.length < 10) {
            window.open('/home/' + 'Invalid session code provided', '_self');
        }
        this.store.dispatch({ type: SessionActionTypes.ESTABLISH_SSO_SESSION, payload: code });

        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.store.dispatch({ type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT, payload: info });

            if (info.confirmationCode && info.confirmationCode.length && info.isSSO) {
                this.router.navigate(['products']);
            } else {
                // window.open('/home/' + "Invalid session", "_self");
            }
        });
    }
}

