import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';

import { SelectedProductsState, initialSelectedProductsState } from '../state/selected-products.state';

import { SelectedProductsActionTypes } from '../actions/selected-products.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { Bundle } from '../state/products.state';
import { Product } from '../state/selected-products.state';
import { NuFundAction } from 'src/app/common/nufund-action';

export const selectedProductsStoreReducer: ActionReducer<SelectedProductsState> = reducer;

export function reducer(state = initialSelectedProductsState as SelectedProductsState, action: NuFundAction) {
    switch (action.type) {

        case SelectedProductsActionTypes.SET_SELECTED_PRODUCTS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            state.selectedProducts = action.payload;

            return Object.assign({}, state);
        }

        case SelectedProductsActionTypes.INCREMENT_SELECTED_PRODUCT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state);

            newState.selectedProducts = JSON.parse(JSON.stringify(newState.selectedProducts)); // renew selected products array
            newState.selectedProducts.push(Object.assign({}, new Product(action.payload)));

            return newState;
        }

        case SelectedProductsActionTypes.INCREMENT_SELECTED_BUNDLE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state);
            newState.selectedBundles = JSON.parse(JSON.stringify(newState.selectedBundles)); // renew selected bundles array
            newState.selectedBundles.push(Object.assign({}, new Bundle(action.payload)));

            return newState;
        }

        case SelectedProductsActionTypes.DECREMENT_SELECTED_PRODUCT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state);
            newState.selectedProducts = newState.selectedProducts.slice(); // renew selected products array
            let itemIndex = -1;
            for (const index in newState.selectedProducts) {
                if (newState.selectedProducts[index].productId === action.payload.productId) {
                    itemIndex = +index;
                }
            }
            if (itemIndex !== -1) {
                newState.selectedProducts.splice(itemIndex, 1);
            }

            return newState;
        }

        case SelectedProductsActionTypes.DECREMENT_SELECTED_BUNDLE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state);
            newState.selectedBundles = newState.selectedBundles.slice(); // renew selected products array
            let itemIndex = -1;
            for (const index in newState.selectedBundles) {
                if (newState.selectedBundles[index].products[0].bundleId === action.payload.products[0].bundleId) {
                    itemIndex = +index;
                }
            }
            if (itemIndex !== -1) {
                newState.selectedBundles.splice(itemIndex, 1);
            }

            return newState;
        }

        case SelectedProductsActionTypes.REMOVE_ALL_SELECTED_PRODUCTS_BY_PRODUCT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = Object.assign({}, state);
            newState.selectedProducts = newState.selectedProducts.slice(); // renew selected products array
            const indexes = [];
            for (const index in newState.selectedProducts) {
                if (newState.selectedProducts[index].productId === action.payload.productId) {
                    indexes.push(+index);
                }
            }

            for (let i = indexes.length - 1; i >= 0; i--) {
                newState.selectedProducts.splice(indexes[i], 1);
            }

            return newState;
        }

        case SelectedProductsActionTypes.REMOVE_ALL_SELECTED_PRODUCTS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return initialSelectedProductsState;
        }

        case SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }


        case SessionActionTypes.LOG_OUT: {
            if (isDevMode()) {
                console.log('{selectedProductsStoreReducer} ' + action.type + ' action being handled!');
            }

            const newState = Object.assign({}, initialSelectedProductsState);
            newState.selectedProducts = [];

            return newState;
        }

        case SelectedProductsActionTypes.REHYDRATE:{
            return {...state};
        }

        default: {
            return state;
        }
    }
}
