import { Component, Input } from '@angular/core';
import { Feature } from '../../state-management/state/selected-products.state';


@Component({
    moduleId: module.id,
    selector: 'feature-item',
    templateUrl: './feature-item.component.html',
    styleUrls: ['feature-item.component.scss']
})
export class FeatureItemComponent {
    @Input() feature: Feature;
    @Input() name: string;

    constructor() { }
}