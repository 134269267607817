<section class="home-form-newuser" [ngClass]="{ 'apply-margin': enablePersonaIdScanning }">
    <label class="home-header">
        <h1 *ngIf="!useNewUiForBank" class="home-form-header">Welcome</h1>

        <!-- TODO: RESKIN - update button ("Resume Application") when "Go Here" link is clicked to show active -->
        <!-- <div *ngIf="useNewUiForBank" class="home-header-text">
            <p>We’re very excited to have you! Tell us a little about yourself so we can get started.</p>
            <p>Need to finish signing up for accounts? <a [routerLink]="['/home', 'existingUser']" (click)="isNewView = false">Go here</a>.</p>
        </div> -->
    </label>

    <form (ngSubmit)="signUpNewUser()" #newUserForm="ngForm" class="form-home">
        <div class="form-home-container">
            <div *ngIf="!enablePersonaIdScanning" class="form-home-content">
                <div class="form-items form-home-firstname">
                    <input type="text" #firstName="ngModel" required [(ngModel)]="newUser.firstName" name="firstName" id="firstName" class="form-input"/>
                    <label for="firstName" class="form-label">
                        First Name
                        <span>(required)</span>
                    </label>
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="form-required-text">
                        First Name is required
                    </div>
                </div>

                <div class="form-items form-home-lastname">
                    <input type="text" #lastName="ngModel" required [(ngModel)]="newUser.lastName" name="lastName" id="lastName" class="form-input"/>
                    <label for="lastName" class="form-label">
                        Last Name
                        <span>(required)</span>
                    </label>
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="form-required-text">
                        Last Name is required
                    </div>
                </div>
            </div>

            <div *ngIf="enablePersonaIdScanning" class="form-home-content">
                <div class="form-items form-home-email-persona">
                    <input type="emailAddress" required [(ngModel)]="newUser.emailAddress" name="emailAddress" id="emailAddress" class="form-input" autocapitalize="none"
                    #emailAddress="ngModel" [required]="true" pattern="^.+@.+\..+$" />
                    <label for="emailAddress" class="form-label">
                        Email Address
                        <span>(required)</span>
                    </label>
                    <div *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)" class="form-required-text">
                        Invalid email address
                    </div>
                </div>
            </div>

            <div *ngIf="!enablePersonaIdScanning" class="form-home-content">
                <div class="form-items form-home-email">
                    <input type="emailAddress" required [(ngModel)]="newUser.emailAddress" name="emailAddress" id="emailAddress" class="form-input" autocapitalize="none"
                    #emailAddress="ngModel" [required]="true" pattern="^.+@.+\..+$" />
                    <label for="emailAddress" class="form-label">
                        Email Address
                        <span>(required)</span>
                    </label>
                    <div *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)" class="form-required-text">
                        Invalid email address
                    </div>
                </div>

                <!-- not the right class -->
                <div  class="form-items form-home-dob">
                    <input type="text" [(ngModel)]="newUser.dateOfBirth" name="dateOfBirth" id="dateOfBirth" class="form-input" #dateOfBirth="ngModel" [required]="true"
                    pattern="\d{2}\/\d{2}\/\d{4}" placeholder="mm/dd/yyyy" [textMask]="{mask: textMasks.dateMask}" />
                    <label for="dateOfBirth" class="form-label">
                        Date of Birth
                        <span>(required)</span>
                    </label>
                    <div *ngIf="dateOfBirth.invalid && (dateOfBirth.dirty || dateOfBirth.touched)" class="form-required-text">
                        Date of Birth is required
                    </div>
                </div>
            </div>

            <div *ngIf="!enablePersonaIdScanning" class="form-home-content">
                <div class="form-items form-home-phone">
                    <input type="tel" [textMask]="{mask: textMasks.phoneMask}" [(ngModel)]="newUser.phoneNumber" name="phoneNumber" id="phoneNumber" class="form-input"
                        #phoneNumber="ngModel" [required]="true" pattern="^\(\d{3}\)\s\d{3}-\d{4}$" />
                    <label for="phoneNumber" class="form-label">
                        Phone Number
                        <span>(required)</span>
                    </label>
                    <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="form-required-text">
                        Phone Number is required
                    </div>
                </div>

                <div class="form-items form-home-zip">
                    <input type="text" [(ngModel)]="newUser.zipCode" name="zipCode" id="zipCode" class="form-input" #zipCode="ngModel" [required]="true"
                    pattern="^\d{5}$" [textMask]="{mask: textMasks.zipMask}" />
                    <label for="zipCode" class="form-label">
                        Zip Code
                        <span>(required)</span>
                    </label>
                    <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="form-required-text">
                        Zip Code is required.
                    </div>
                </div>
            </div>

            <!-- esign -->
            <div class="esign-home-component">
                <csi-esign *ngIf="isEsignRequired()"
                            [verifyPDFOpened]="requireDemonstratedEsignConsent"
                            [disclosureURL]="getEsignLink()"
                            [disclosureDescription]="getEsignDescription()"
                            (esignValidChanged)="esignValid = ($event || !isEsignRequired())">                   
                </csi-esign>
            </div>
            
            <!-- /esign -->

            <!-- promo -->

            <home-promo-code [(promoCode)]="promoCode"></home-promo-code>


            <div class="form-home-content">
                <div class="form-items form-home-buttons">
                    <button type="submit" [disabled]="!newUserForm.form.valid || !validateDateOfBirth() || isLoading || !esignValid || !isPromoCodeValid()" name="signUp" class="login-form-buttonsubmit">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </form>
</section>
