import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { Subject } from 'rxjs';
import { SessionActionTypes } from '../state-management/actions/session.actions';

import { Store } from '@ngrx/store';

import { SessionState } from '../state-management/state/session.state';
import { InstitutionState } from '../state-management/state/institution.state';

import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './verification-error.component.html',
    styleUrls: ['verification-error.component.scss']
})
export class VerificationErrorComponent implements OnDestroy, OnInit {
    public institutionState: InstitutionState;
    public unsubscribe: Subject<void> = new Subject<void>();
    public pageName = 'verificationError';
    public isLoading = true;
    public errorMessage: string;

    constructor(private router: Router,
        private store: Store<CombinedState>,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institutionState = info;
            this.store.dispatch({ type: SessionActionTypes.LOG_OUT, payload: { bypassSendingEmail: true } });
            this.isLoading = false;
        });

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
            this.errorMessage = params.errorMessage;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public returnToLogin() {
        this.router.navigate(['home']);
    }
}
