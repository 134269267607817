import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DisclosuresRoutingModule } from './disclosures-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { ProgressModule } from '../progress/progress.module';

import { OrderByModule } from '../common/order-by-pipe.module';

import { DisclosuresComponent } from './disclosures.component';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        DisclosuresComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        DisclosuresRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        OrderByModule,
        FacebookPixelModule,
        LoadingModule
    ]
})
export class DisclosuresModule { }
