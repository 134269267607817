import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';

import { ProgressState, Step } from '../state-management/state/progress.state';

import { SessionState } from '../state-management/state/session.state';

import { ApplicantsState } from '../state-management/state/applicants.state';

import { InstitutionState } from '../state-management/state/institution.state';
import { SelectedProductsState, SelectedProductsUtil } from '../state-management/state/selected-products.state';
import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'wizard-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['progress.component.scss']
})
export class ProgressComponent implements OnDestroy {
    @Output() signOut: EventEmitter<Step> = new EventEmitter<Step>();
    public showSessionManagement = false;

    public applicantsState: ApplicantsState;
    public progressState: ProgressState;
    public sessionState: SessionState;

    public Step: typeof Step = Step; // hah!
    showAccountAssociation = false;
    isBusinessSession = false;
    personalInfoLabel = 'Information';
    personalInfoSubLabel = 'Information';
    public unsubscribe: Subject<void> = new Subject<void>();
    showFundingStep = true;
    enablePersona = false;

    useNewUiForBank = false;

    constructor(
        private store: Store<CombinedState>,
        private router: Router,
    ) {
        store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe(
            (info: SelectedProductsState) => {
                this.showFundingStep = !SelectedProductsUtil.hasDigitalFunding(info);
            }
        );

        store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            this.progressState = info;
        });

        store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ApplicantsState) => {
            this.applicantsState = info;
        });

        store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.sessionState = info;
        });

        store.select('institutionStoreReducer').subscribe((info: InstitutionState) => {
            this.showAccountAssociation = info.institution.digitalBankingBrandId !== 0;
            this.isBusinessSession = info.institution.isBusiness;
            this.personalInfoLabel = info.institution.isBusiness ? 'Business Information' : 'Personal Information';
            this.personalInfoSubLabel = info.institution.isBusiness ? 'Business & Signer Information' : 'Primary Applicant Information';
            this.useNewUiForBank = info.institution.useNewUiForBank;
            this.enablePersona = info.institution.enablePersonaIdScanning;

        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public hasSecondaryApplicant() {
        return this.applicantsState && this.applicantsState.applicants && this.applicantsState.applicants.length > 1;
    }

    public getProgressPercent() {
        if (this.progressState.currentStep !== undefined) {
            return Math.min(Math.floor(this.progressState.currentStep / Step.Completion * 10) * 10, 100);
        } else {
            return 0;
        }
    }

    public toggleShowSessionManagement() {
        this.showSessionManagement = !this.showSessionManagement;
    }

    public signOutClick() {
        if (!this.isBusinessSession || this.progressState.currentStep !== Step.PrimaryApplicantInformation) {
            this.router.navigate(['signout', this.sessionState.confirmationCode]);
        } else {
            this.signOut.emit(this.progressState.currentStep);
        }
    }

    public isCurrentStep(step: Step) {
        return this.progressState.currentStep === step;
    }

    public showPersonaCompleted() {
        if (this.applicantsState.applicants.length !== 1){
            return  this.isCurrentStep(Step.SecondaryApplicantInformation) ||               
                    this.isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                    this.isCurrentStep(Step.AcceptDisclosures) ||
                    this.isCurrentStep(Step.FundingSource) ||
                    this.isCurrentStep(Step.DigitalUserVerification) ||
                    this.isCurrentStep(Step.Completion);
        }
        else {
            return  this.isCurrentStep(Step.SelectProducts) ||
                    this.isCurrentStep(Step.CustomizeProducts) ||
                    this.isCurrentStep(Step.PrimaryApplicantInformation) ||
                    this.isCurrentStep(Step.SecondaryApplicantInformation) ||
                    this.isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                    this.isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                    this.isCurrentStep(Step.AcceptDisclosures) ||
                    this.isCurrentStep(Step.FundingSource) ||
                    this.isCurrentStep(Step.DigitalUserVerification) ||
                    this.isCurrentStep(Step.Completion);
        }
    }
}
