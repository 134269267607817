import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { InstitutionState, Institution } from '../state-management/state/institution.state';

import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['page-footer.component.scss']
})
export class PageFooterComponent implements OnDestroy {
    @Input() pageName: string;
    @Input() showText: boolean = true;

    public institution: Institution = null;
    public unsubscribe: Subject<void> = new Subject<void>();

    constructor(private store: Store<CombinedState>) {
        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institution = info.institution;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getHtml(): string {
        if (this.institution.pageContent !== null && this.pageContext !== null) {
            return this.pageContext.htmlFooter;
        } else {
            return '';
        }
    }

    get pageContext() {
        return this.institution.pageContent[this.pageName] ? this.institution.pageContent[this.pageName] : {
            htmlFooter: ''
        };
    }

    public hasFooterImage() {
        return this.institution.logoFooter !== '';
    }
}
