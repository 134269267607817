import { Component, Input } from '@angular/core';

import { ModalService } from './modal.service';
import { Button } from './button';

@Component({
    moduleId: module.id,
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['modal.component.scss']
})
export class ModalComponent<T> {
    constructor(public modalService: ModalService<T>) { }

    public getButtons() {
        return this.modalService.buttons;
    }

    public showModal() {
        return this.modalService.showModal;
    }

    public getBody() {
        return this.modalService.body;
    }

    public get isMultiBody() {
        return this.modalService.body instanceof Array;
    }

    public getClickAction(button: Button<T>) {
        this.modalService.click(button);
    }
}
