<!-- cart button -->
<div (click)="toggleShowCart()" class="products-cart" [ngClass]="showCart ? 'dropdown-cart-open' : 'dropdown-cart-closed'">
    <span class="icon icon-bag-2"></span>
    <span *ngIf="totalCartItems > 0" class="products-cart-total" [ngClass]="showCart ? 'dropdown-cart-open' : 'dropdown-cart-closed'">{{totalCartItems}}</span>
</div>

<!-- cart -->
<section *ngIf="showCart" class="dropdown-cart" [ngClass]="{'fade-out' : isRetracting}">
    <!-- cart header -->
    <div class="dropdown-cart-header" [ngClass]="{'dropdown-cart-header-empty' : totalCartItems === 0}">
        <div class="dropdown-cart-header-options">
            <div (click)="toggleShowCart()"><span class="icon icon-x"></span></div>
        </div>
        <div *ngIf="totalCartItems > 0" class="dropdown-cart-header-title">
            {{totalCartItems}} Item<span *ngIf="totalCartItems > 1">s</span> in Cart
        </div>
    </div>

    <!-- cart empty -->
    <div *ngIf="totalCartItems === 0" class="dropdown-cart-empty">
            <span class="icon icon-bag-2"></span>
            <p>Your bag is empty.</p>
    </div>

    <!-- cart items list -->
    <ul class="dropdown-cart-list" [ngClass]="{'dropdown-cart-empty' : totalCartItems === 0}">
        <li *ngFor="let bundle of selectedBundles" class="dropdown-cart-item">
            <div class="cart-item-icon"><span class="icon icon-bundle"></span></div>
            <div class="cart-item-info">
                <div class="cart-item-name">{{bundle.name}}</div>
                <ul class="dropdown-cart-bundle-list">
                    <li class="bundle-list-item"><strong>Bundle Contains:</strong></li>
                    <li *ngFor="let product of bundle.products" class="bundle-list-item">{{product.productName}}</li>
                </ul>
            </div>
            <div class="cart-item-remove"><span (click)="onBundleRemove(bundle)" class="cart-item-control-delete">&times;</span></div>
        </li>
        <li *ngFor="let product of selectedProducts" class="dropdown-cart-item">
            <div class="cart-item-icon"><span class="icon icon-cash-usd-outline -icon-file-o"></span></div>
            <div class="cart-item-info">{{product.productName}}</div>
            <div class="cart-item-remove"><span (click)="onProductRemove(product)" class="cart-item-control-delete">&times;</span></div>
        </li>
    </ul>
</section>