import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Level3Question } from '../../state-management/state/out-of-wallet.state';

@Component({
    moduleId: module.id,
    selector: 'out-of-wallet-question',
    templateUrl: './out-of-wallet-question.component.html',
    styleUrls: ['out-of-wallet-question.component.scss']
})
export class OutOfWalletQuestionComponent implements OnInit {
    public answer: number = null;

    @Input() question: Level3Question;
    @Input() name: string;
    @Input() autoFill: boolean;

    @Output() answerUpdated = new EventEmitter();

    ngOnInit() {
        if (this.autoFill) {
            this.answer = Math.floor(Math.random() * 4);
            this.setAnswer(this.answer);
        }
    }

    public setAnswer(answer: number) {
        this.answerUpdated.emit(answer);
    }
}
