import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DigitalUserComponent } from './digital-user.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';

const routes: Routes = [
    {
        path: 'digitaluser',
        component: DigitalUserComponent,
        canActivate: [
            CanActivateSessionGuard
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class DigitalUserRoutingModule { }
