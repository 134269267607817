import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VerificationErrorComponent } from './verification-error/verification-error.component';
import { SignoutComponent } from './signout/signout.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DissoComponent } from './disso/disso.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'signout/:confirmationCode',
        component: SignoutComponent
    },
    {
        path: 'verification-error/:errorMessage',
        component: VerificationErrorComponent
    },
    {
        path: 'verification-error',
        component: VerificationErrorComponent
    },
    {
        path: 'disso/dissoLogin.cfm', // pretend to be coldfusion 😢
        component: DissoComponent
    },    
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
