import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { ChallengeDepositState } from '../state-management/state/challenge-deposit.state';

@Injectable()
export class ChallengeDepositService {
    constructor( public http: HttpClient) {
    }

    tryChallengeDeposit(challengeAttempt: ChallengeDepositState): Observable<any> {
        return this.http.post<any>('Challenge', challengeAttempt);
    }
}
