import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { PromoCodeActionTypes, ApplyPromoCodeFailureAction } from '../actions/promo-code.actions';
import { ProductsService } from '../../services/products.service';

import { ProductsState } from '../state/products.state';
import { ProductsActionTypes } from '../actions/products.actions';

import { Store } from '@ngrx/store';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PromoCodeEffects {

    @Effect()
    appApplyPromoCode$ = this.action$.pipe(
        ofType(PromoCodeActionTypes.APPLY_PROMO_CODE),
        switchMap((action: NuFundAction) => {
            return this.service.applyPromoCode(action.payload.promotionalCode).pipe(
                map(() => {
                    // The promo code was successfully applied
                    return {
                        type: ProductsActionTypes.GET_PRODUCTS,
                        payload: {
                            instantAddCodes: action.payload.instantAddCodes
                        }
                    };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new ApplyPromoCodeFailureAction({ error: response }));
                })
            )
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: ProductsService, private store: Store<ProductsState>) { }
}
