import { Applicant } from 'src/app/state-management/state/applicants.state';
import { FormValidators } from './form-validators';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Product } from 'src/app/state-management/state/products.state';
import { ApplicationQuestion } from 'src/app/state-management/state/institution.state';

export class FormControlFactory {
    static getFormControls(
        isSSO: boolean,
        applicantIndex: number,
        applicant: Applicant,
        selectedProducts: Product[],
        questions: ApplicationQuestion[],
        requiredFields: OptionallyRequiredFields,
        isIdv: boolean
    ) {
        // all validators are in common here
        const defaultFormControls = this.getDefaultFormControls(applicant);

        // overrid based on sso and applicant index
        const ssoOptionalControls = this.getSSOOptionalFields(isSSO, applicantIndex, applicant, selectedProducts, questions, isIdv);

        // combine the above two and override based on bank settings
        const combinedControls = this.overrideSettingsBasedRequirements(
            isSSO, applicant, Object.assign(defaultFormControls, ssoOptionalControls), requiredFields
        );

        return combinedControls;
    }

    private static getDefaultFormControls(applicant: Applicant) {
        return {
            emailAddress: [
                applicant.emailAddress,
                [Validators.required, Validators.email]
            ],
            verifyEmailAddress: [
                applicant.verifyEmailAddress,
                [Validators.required, Validators.email]
            ],
            mailingAddress1: [],
            mailingAddress2: [],
            mailingCity: [],
            mailingState: [],
            mailingZipCode: [],
            employerOccupationTitle: [],
            employerPhone: [],
            identificationExpirationDate: [],
            identificationIssueDate: [],
            citizenType: []
        };
    }

    private static getSSOOptionalFields(
        isSSO: boolean,
        applicantIndex: number,
        applicant: Applicant,
        selectedProducts: Product[],
        questions: ApplicationQuestion[],
        isIdv: boolean
    ) {

        if (!isSSO || applicantIndex > 0) {
            return this.getNonSSOFields(applicantIndex, applicant, selectedProducts, questions, isIdv);
        } else {
            return this.getSSOAndNonPrimaryFields(applicant);
        }
    }

    private static getNonSSOFields(
        applicantIndex: number,
        applicant: Applicant,
        selectedProducts: Product[],
        questions: ApplicationQuestion[],
        isIdv: boolean
    ) {
        const formConfig =  {
            id: [applicant.id],
            salutation: [applicant.salutation],
            firstName: [applicant.firstName, [Validators.required]],
            middleInitial: [applicant.middleInitial],
            lastName: [applicant.lastName, [Validators.required]],
            phoneNumber: [applicant.phoneNumber, [Validators.required, FormValidators.validatePhoneNumber]],
            phoneType: [applicant.phoneType],
            identificationType: [applicant.identificationType, [Validators.required]],
            identificationNumber: [applicant.identificationNumber, [Validators.required, Validators.maxLength(20)]],
            identificationState: [applicant.identificationState, [Validators.required]],
            employerName: [applicant.employerName, [Validators.required, Validators.maxLength(25)]],
            employerOccupationTitle: [applicant.employerOccupationTitle, [Validators.maxLength(25)]],
            taxIdentificationNumber: [
                applicant.taxIdentificationNumber,
                [
                    Validators.required,
                    Validators.minLength(11),
                    Validators.maxLength(13)
                ]
            ],
            dateOfBirth: [applicant.dateOfBirth,
                [
                    Validators.required,
                    FormValidators.validateDateOfBirth,
                    FormValidators.validateProductAgeRangeCheck(selectedProducts, applicantIndex)
                ]
            ],
            address1: [applicant.address1, [Validators.required, FormValidators.validateNotPOBox, Validators.maxLength(40)]],
            address2: [applicant.address2, [Validators.maxLength(40)]],
            mailingAddress1: [applicant.mailingAddress1, [Validators.maxLength(40)]],
            mailingAddress2: [applicant.mailingAddress2, [Validators.maxLength(40)]],
            city: [applicant.city, [Validators.required]],
            state: [applicant.state, [Validators.required]],
            zipCode: [applicant.zipCode, [Validators.required, Validators.pattern(/\d{5}(-\d{4})?/)]],
            frontImage: [applicant.frontImage],
            backImage: [applicant.backImage],
            greenCardNumber: [applicant.greenCardNumber],
        };

        if (!isIdv) {
            (formConfig as any).applicationQuestions = this.getQuestionFormArray(questions)
        }

        return formConfig
    } 

    private static getQuestionFormArray(questions: ApplicationQuestion[]) {
        const formGroupArray: FormGroup[] = [];
        for (const question of questions) {
            if (!question.useTranslate) {
                formGroupArray.push(new FormGroup({
                    answerText: new FormControl(question.answerText, [Validators.required, Validators.maxLength(20)]),
                    questionText: new FormControl(question.questionText),
                    questionId: new FormControl(question.questionId),
                    fieldValue: new FormControl(null),
                    useTranslate: new FormControl(question.useTranslate)
                }));
            } else {
                formGroupArray.push(new FormGroup({
                    answerText: new FormControl(question.answerText, [Validators.required, Validators.maxLength(20)]),
                    questionText: new FormControl(question.answerText),
                    securityQuestion: new FormControl(question.securityQuestion, [Validators.required]),
                    questionId: new FormControl(question.questionId),
                    fieldValue: new FormControl(question.fieldValue),
                    useTranslate: new FormControl(question.useTranslate)
                }));
            }
        }
        return new FormArray(formGroupArray);
    }

    private static getSSOAndNonPrimaryFields(applicant: Applicant) {
        return {
            salutation: [applicant.salutation],
            firstName: [{ value: applicant.firstName, disabled: true }],
            middleInitial: [{ value: applicant.middleInitial, disabled: true }],
            lastName: [{ value: applicant.lastName, disabled: true }],
            phoneNumber: [{ value: applicant.phoneNumber, disabled: true }],
            identificationType: [applicant.identificationType],
            identificationNumber: [applicant.identificationNumber],
            identificationState: [applicant.identificationState],
            employerName: [applicant.employerName],
            taxIdentificationNumber: [applicant.taxIdentificationNumber],
            dateOfBirth: [applicant.dateOfBirth],
            address1: [{ value: applicant.address1, disabled: true }],
            address2: [{ value: applicant.address2, disabled: true }],
            city: [{ value: applicant.city, disabled: true }],
            state: [{ value: applicant.state, disabled: true }],
            zipCode: [{ value: applicant.zipCode, disabled: true }],
            contactPhoneNumber: [applicant.contactPhoneNumber]
        };
    }

    private static overrideSettingsBasedRequirements(
            isSSO: boolean,
            applicant: Applicant,
            formControls: any,
            requiredFields: OptionallyRequiredFields
        ) {

        // some fields are optional depending on if the user is a SSO user or a bank's preference, those follow
        if (requiredFields.employerNameRequired === true && !isSSO) {
            formControls.employerName = [applicant.employerName, [Validators.required, Validators.maxLength(25)]];
        } else {
            formControls.employerName = [ applicant.employerName, [Validators.maxLength(25)] ];
        }

        if (requiredFields.employerOccupationRequired === true && !isSSO) {
            formControls.employerOccupationTitle = [applicant.employerOccupationTitle, [Validators.required, Validators.maxLength(25)]];
        } else {
            formControls.employerOccupationTitle = [ applicant.employerOccupationTitle, [Validators.maxLength(25)] ];
        }

        if (requiredFields.employerPhoneRequired === true && !isSSO) {
            formControls.employerPhone = [applicant.employerPhone, [Validators.required]];
        } else {
            formControls.employerPhone = [applicant.employerPhone];
        }

        if (requiredFields.expirationDateRequired === true && !isSSO) {
            formControls.identificationExpirationDate = [
                applicant.identificationExpirationDate,
                [Validators.required, FormValidators.validateIdentificationExpirationDate]
            ];
        } else {
            formControls.identificationExpirationDate = [ applicant.identificationExpirationDate ];
        }

        if (requiredFields.issueDateRequired === true && !isSSO) {
            formControls.identificationIssueDate = [
                applicant.identificationIssueDate,
                [Validators.required, FormValidators.validateIdentificationIssueDate]
            ];
        } else {
            formControls.identificationIssueDate = [applicant.identificationIssueDate];
        }

        if (requiredFields.citizenTypeRequired === true && !isSSO) {
            formControls.citizenType = [
                applicant.citizenType,
                [Validators.required, Validators.pattern(/[^8]+/)]
            ];
        } else {
            formControls.citizenType = [applicant.citizenType];
        }

        return formControls;
    }
}

export interface OptionallyRequiredFields {
    employerNameRequired: boolean;
    employerOccupationRequired: boolean;
    employerPhoneRequired: boolean;
    expirationDateRequired: boolean;
    issueDateRequired: boolean;
    citizenTypeRequired: boolean;
}
