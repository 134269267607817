import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { last, takeUntil } from 'rxjs/operators';
import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { CombinedState } from '../state-management/state/combined.state';
import { InstitutionState } from '../state-management/state/institution.state';
import { Step, ProgressState } from '../state-management/state/progress.state';
import { Applicant, ApplicantsState, PhoneType, demoApplicant } from '../state-management/state/applicants.state';
import Persona, { ClientOptions } from 'persona';
import { SessionState } from '../state-management/state/session.state';
import { ApplicantsActionsTypes } from '../state-management/actions/applicants.actions';

@Component({
  selector: 'app-persona-id-collection',
  templateUrl: './persona-id-collection.component.html',
  styleUrls: ['./persona-id-collection.component.scss']
})
export class PersonaIdCollectionComponent implements OnInit, OnDestroy {
  useNewUiForBank = false;

  public currentStep: Step;
  public skipCustomizeProductsScreenFlag = false;
  public pageName = 'persona';
  public isLoading = true;
  public comments = '';
  public unsubscribe: Subject<void> = new Subject<void>();
  public isPanelVisible = false;
  public currentApplicant = 0;
  public currentApplicantID = '';
  private confimationCode = '';
  private templateID = '';
  private environmentId = '';
  public canContinue = true;
  private applicant; 
  allowAutoFill: boolean;

  constructor(
      private store: Store<CombinedState>,
      private router: Router
  ) {
  }
  ngOnInit(): void {
    this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((applicantsState: ApplicantsState) => {
      this.currentApplicant = applicantsState.currentApplicantIndex;
      this.currentApplicantID = applicantsState.applicants[this.currentApplicant].id; //save down the ID for Lauire.
      this.applicant = applicantsState.applicants[this.currentApplicant];
      if(applicantsState.applicants[this.currentApplicant].zipCode !== ''){       
          this.navigateNext();
      }
    });

    this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((progressState: ProgressState) => {
      this.currentStep = progressState.currentStep;
      // update the wizard panel
      if (this.currentApplicant === 0 && this.currentStep < Step.PersonaPrimaryApplicant) {
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaPrimaryApplicant});
      } else if (this.currentApplicant === 1 && this.currentStep < Step.PersonaSecondaryApplicant){
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PersonaSecondaryApplicant});
      }
    });

    this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((institutionState: InstitutionState) =>
    {
      this.useNewUiForBank = institutionState.institution.useNewUiForBank;
      this.allowAutoFill = institutionState.institution.allowAutoFill;
      this.templateID = institutionState.institution.personaTemplateId;
      this.environmentId = institutionState.institution.personaEnvironmentId;
    });

    this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((sessionState: SessionState) => 
    {
      this.confimationCode = sessionState.confirmationCode;
    });

    const clientOptions: ClientOptions = {
      templateId: this.templateID,
      referenceId: this.confimationCode,
      environmentId: this.environmentId,
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        console.log(fields);
        if(fields['current-selfie']['value'] === null || fields['identification-number']['value'] === null || fields['issue-date']['value'] === null){
          console.log('fail');
          this.saveApplicant(fields, ApplicantIDCollectionStatus.Failure);
          this.router.navigate(['verification-error']); //if something went wrong with the ID scanning end the session       
          return;
        }
        this.saveApplicant(fields, ApplicantIDCollectionStatus.Success);
        this.canContinue = false;
      },
      onCancel: ({ inquiryId, sessionToken }) => {this.router.navigate(['signout/' + this.confimationCode])},
      onError: (error) => {this.router.navigate(['verification-error'])}
    };
    
    const client = new Persona.Client(clientOptions);

    this.isLoading = false;
  }

  navigateNext() {
    if (this.currentApplicant > 0){
      this.router.navigate(['personal-information']);
    }
    else{
      this.router.navigate(['products']);
    }
  }

  ngOnDestroy() {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }

  saveApplicant(fields: object, personaStatus : ApplicantIDCollectionStatus) {
    // if (this.allowAutoFill){ 
    //   this.applicant = demoApplicant;
    //   this.applicant.id = this.currentApplicantID;
    //   this.applicant.index = this.currentApplicant;
    //   const applicant = this.applicant;
    //   this.store.dispatch({
    //       type: ApplicantsActionsTypes.SAVE_APPLICANT,
    //       payload: Object.assign(
    //         { applicant: applicant, 
    //           personaStatus: personaStatus}           
    //       )
    //   });
    //   this.store.dispatch({
    //     type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT,
    //     payload: applicant
    //   });
    // }
    // else {
      this.applicant.city = fields['address-city']['value'];
      this.applicant.zipCode = fields['address-postal-code']['value'];
      this.applicant.address1 = fields['address-street-1']['value'];
      this.applicant.address2 = fields['address-street-2']['value'];
      this.applicant.state = fields['address-subdivision']['value'];
      if(this.currentApplicant === 1){
        this.applicant.verifyEmailAddress = this.applicant.emailAddress;
      }
      
      this.applicant.taxIdentificationNumber = fields['social-security-number']['value'];
      this.applicant.dateOfBirth = fields['birthdate']['value'];
      this.applicant.phoneNumber = fields['phone-number']['value'].slice(-10);
      this.applicant.firstName = fields['name-first']['value'];
      this.applicant.lastName = fields['name-last']['value'];
      if (fields['name-middle']['value'] === null){
        this.applicant.middleInitial = null
      }else{
        this.applicant.middleInitial = fields['name-middle']['value'].charAt(0);
      }     

      if(fields['identification-class']['value']=== 'dl'){
        this.applicant.identificationType = '5';
      }
      else{
        this.applicant.identificationType = '8';
      }

      this.applicant.identificationExpirationDate = fields['expiration-date']['value'];
      this.applicant.identificationState = fields['address-subdivision']['value'];
      this.applicant.identificationNumber = fields['identification-number']['value'];
      this.applicant.identificationIssueDate = fields['issue-date']['value'];
      
      const applicant = this.applicant;
      this.store.dispatch({
        type: ApplicantsActionsTypes.SAVE_APPLICANT,
        payload: Object.assign(
          { applicant: applicant, 
            personaStatus: personaStatus}           
        )
      });
  
      this.store.dispatch({
        type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT,
        payload: applicant
      });
    
    // }
  }
}
export enum ApplicantIDCollectionStatus{
    Success = 0,
    Failure = 1,
    Timeout = 2
}
