import { Component, Input } from '@angular/core';
import { Element, Field } from '../../../state-management/state/selected-products.state';

@Component({
    selector: 'product-question-element',
    templateUrl: './product-question-element.component.html',
    styleUrls: ['product-question-element.component.scss']
})
export class ProductQuestionElementComponent {
    @Input() element: Element;

    constructor() { }

    get elementName() {
        return this.hasFormGUIName ? this.element.field.formGui : this.hasDDN ? 'ddn_' + this.element.field.ddn : 'input';
    }

    get hasFormGUIName() {
        return this.element.field !== null && this.element.field.formGui !== null && this.element.field.formGui !== '';
    }

    get hasDDN() {
        return this.element.field !== null && this.element.field.ddn !== null && !isNaN(this.element.field.ddn);
    }

    fieldHasOptions(field: Field) {
        return field.options && field.options.length > 0;
    }

    getFieldType(element: Element) {
        if (element.field && element.field.asteriskMasked) {
            return 'password';
        } else {
            return 'text';
        }
    }

    getPattern(element: Element) {
        return !element.field.validationPattern ? '' : element.field.validationPattern;
    }

    getTextMask(element: Element) {
        if (!element.field.inputMasks || element.field.inputMasks.length === 0) {
            return { mask: false };
        } else {
            return {
                mask: element.field.inputMasks.map(inputMask => inputMask.isRegEx ? new RegExp(inputMask.value) : inputMask.value),
                guide: true
            };
        }
    }
}
