import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DisclosuresComponent } from './disclosures.component';
import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';

const routes: Routes = [
    {
        path: 'disclosures',
        component: DisclosuresComponent,
        canActivate: [
            CanActivateSessionGuard
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class DisclosuresRoutingModule { }
