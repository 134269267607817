import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { Address } from '../models/address';
import { UspsResponse } from '../models/address-validate-response';
import { Settings } from '../settings';
import { IdentityService } from './identity.service';

@Injectable({
    providedIn: 'root',
})
export class USPSService {
    constructor(private http: HttpClient, private identityService: IdentityService) { }

    validateAddress(address: Address): Observable<UspsResponse> {
        
        return this.http.post<UspsResponse>('USPSService/ValidateAddress', address).pipe(
        timeout(5000)
            )
                .pipe(
                catchError(this.handleUspsError)
            );               
    }

    private handleUspsError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error(`An error occurred: ${error.error.message}`);
        } else {
            console.error(`Address lookup returned: ${error.error}`);
        }
        return throwError('Error validating address.');
    }
}
