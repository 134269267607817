import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProgressModule } from '../progress/progress.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { BusinessInformationRoutingModule } from './business-information-routing.module';

import { BusinessInformationComponent } from './business-information.component';
import { LoadingModule } from '../loading/loading.module';
import { BusinessApplicantComponent } from './business-applicant/business-applicant.component';
import { SignerApplicantComponent } from './signer-applicant/signer-applicant.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ValidationIndicatorComponent } from './validation-indicator/validation-indicator.component';

@NgModule({
    declarations: [
        BusinessInformationComponent,
        BusinessApplicantComponent,
        SignerApplicantComponent,
        ValidationIndicatorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        ProgressModule,
        BusinessInformationRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        LoadingModule
    ]
})
export class BusinessInformationModule { }
