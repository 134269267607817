import { NuFundAction } from 'src/app/common/nufund-action';

export const RoutingLookupActionTypes = {
    SUBMIT_SUGGESTION: '[Products] SUBMIT_SUGGESTION',
    SUBMIT_SUGGESTION_SUCCESS: '[Products] SUBMIT_SUGGESTION_SUCCESS',
    SUBMIT_SUGGESTION_FAILURE: '[Products] SUBMIT_SUGGESTION_FAILURE'
};

export class RoutingLookupFailureAction implements NuFundAction {
    type = RoutingLookupActionTypes.SUBMIT_SUGGESTION_FAILURE;
    constructor(public payload: { error: any }) { }
}
