<section [hidden]="isLoading" class="router-section" [class.nufund-update]="useNewUiForBank">
    <router-outlet></router-outlet>
    <csi-revation-chat *ngIf="revationURL" [chatURL]="revationURL"></csi-revation-chat>
</section>
<section [hidden]="!isLoading">
    <!-- LOADER -->
    <div class="loader-container">
        <div class="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
    <!-- / LOADER -->
</section>

<modal></modal>
