import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationHeaderManager } from 'src/app/authentication-header-manager';
import { Util } from 'src/app/common/util';
import { Settings } from 'src/app/settings';
import { ApplicantsActionsTypes } from 'src/app/state-management/actions/applicants.actions';
import { ProgressActionTypes } from 'src/app/state-management/actions/progress.actions';
import { SessionActionTypes } from 'src/app/state-management/actions/session.actions';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { InstitutionState } from 'src/app/state-management/state/institution.state';
import { Step } from 'src/app/state-management/state/progress.state';
import { ModalService } from 'src/app/modal/modal.service';
import { state } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-digital-user-home',
  templateUrl: './digital-user-home.component.html',
  styleUrls: ['./digital-user-home.component.scss']
})
export class DigitalUserHomeComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isInvalidCredentials: boolean = false;
  disableContinue : boolean =false;
  welcomeDisclosureId: string = '';
  
  public unsubscribe: Subject<void> = new Subject<void>();
  welcomeDisclosureDescription: string;

  hasESigned: boolean = false;
  isLoading: boolean = false;
  requireDemonstratedEsignConsent = false;

  instantAddCode: string = undefined
  skipProductSelection: boolean;
  constructor(
    private store: Store<CombinedState>,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: ModalService<boolean>
  ) {

    store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SignUp });
    this.loginForm = this.fb.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }
  ngOnDestroy(): void {
    
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe(info => {
      if (info.confirmationCode && info.confirmationCode.length) {
        if ((info as any).applicants[0]) {
          const applicant = (info as any).applicants[0]
          this.store.dispatch({
            type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT,
            payload: applicant
          })
        }

      }
      if (info.isError && info.error) {
        this.disableContinue =false;
        if (info.error.status === 400) {
          this.isInvalidCredentials = true;
        }
        else if (info.error.status === 500){
          this.modalService.presentError(<HttpErrorResponse> {error: 'Digital Banking is currently unavailable.'}, false, 'Error', 'Ok').subscribe(null, null, () => { });
        }
        else{     
          this.modalService.presentError(info.error, false, 'Error', 'Ok').subscribe(null, null, () => { });
        }
      } else {
        this.isInvalidCredentials = false;
      }
    })

    this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
      this.requireDemonstratedEsignConsent = info.institution.requireDemonstratedEsignConsent;
      this.welcomeDisclosureId = info.institution.welcomeDisclosureId;
      this.welcomeDisclosureDescription = info.institution.welcomeDisclosureDescription;
    })

    this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe(info => {
      if (info && info.applicants.length && info.applicants[0].id) {
        this.router.navigate(['products']);
      }
      this.setIsLoading(false)
    })

    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.instantAddCode !== undefined && params.instantAddCode !== 'none') {
        if (params.instantAddCode !== undefined) {
            this.instantAddCode = params.instantAddCode;
        }

        if (params.skipProductSelection) {
            this.skipProductSelection = params.skipProductSelection !== '';
        }
    }
    })
  }

  public getEsignLink(): string {
    const esignLink = Settings.getAPIBaseURL() + 'Disclosures/' + encodeURIComponent(this.welcomeDisclosureId) + '/' +
      Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    return esignLink;
  }

  public getEsignDescription(): string {
    return (this.welcomeDisclosureDescription);
  }

  public submit(): void {
    this.disableContinue =true;
    this.setIsLoading(true)
    this.loginForm.markAsTouched();
    if (this.loginForm.valid) {
      
      const userName = this.loginForm.value.userName;
      const password = this.loginForm.value.password;

      this.store.dispatch({
        type: SessionActionTypes.DIGITAL_SIGN_ON,
        payload: {
          userName: userName,
          password: password,
          isBusiness: false,
        }
      });
      this.store.dispatch( {
        type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT,
        payload: {isSSO: true, isVerified: true}
      });
    } else {
      this.setIsLoading(false);
    }
  }

  setIsLoading(isLoading: boolean): void {
    setTimeout(() => {this.isLoading = isLoading;})
  }

}
