import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { InstitutionState, Institution } from '../state-management/state/institution.state';
import { SessionState } from '../state-management/state/session.state';
import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil, skip } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['page-header.component.scss']
})
export class PageHeaderComponent implements OnDestroy {
    @Input() pageName: string;
    @Input() showText = true;
    @Input() hideLogo = false;

    public institution: Institution = null;
    public isInstitutionLoading = true;
    public unsubscribe: Subject<void> = new Subject<void>();
    public confirmationCode = '';
    public confirmationLinkCorrected = false;

    useNewUiForBank = false;
    
    constructor(
        private store: Store<CombinedState>
    ) {
        store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institution = info.institution;
            this.isInstitutionLoading = info.isLoading;
            this.useNewUiForBank = info.institution.useNewUiForBank;
        });

        if (this.confirmationCode === '') {
            store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
                this.confirmationCode = info.confirmationCode;
                this.hideLogo = this.hideLogo || (info.isSSO && !info.digitalBankingSSOKey);
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getHtml(): string {
        if (this.institution.pageContent !== null && this.pageContext !== null) {
            if (this.pageName === 'finalized' && !this.confirmationLinkCorrected && this.confirmationCode !== '') {
                this.pageContext.htmlHeader = this.pageContext.htmlHeader.replace(/\/@@ConfirmationCode@@/, '/' + this.confirmationCode);
                this.confirmationLinkCorrected = true;
            }
            return this.pageContext.htmlHeader;
        } else {
            return '';
        }
    }

    get pageContext() {
        return this.institution.pageContent[this.pageName] ? this.institution.pageContent[this.pageName] : {
            htmlHeader: ''
        };
    }

    public hasHeaderImage() {
        return !this.isInstitutionLoading && this.institution.logo !== '' && !this.hideLogo;
    }
}
