import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
 /*
 * The goal of this class is to allow child components to listen to the event of the layouts button click event.
 * Additionally, the goal of this class is to allow child components to control aspects of the layout page.
 * */
export class IdvLayoutService {
    /** This subject represents the internal state of the observable and lets the class encapsulate the behavior of the public facing observable. */
    private _buttonClick$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    /** Public facing observable that children subscribe to. Emits when the layout has recieved a click event. */
    public buttonClick$ = this._buttonClick$.asObservable();

    /** This subject represents the state of the `diabled` state of the layout `Continue` button. Is private to enforce encapsulation. */
    private _buttonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /** The public facing observable to emit to the layout page about the `disabled` state. */
    public buttonDisabled$ = this._buttonDisabled$.asObservable();


    /** Empty constructor in case we ever need to inject dependencies here. */
    constructor() {
    }

    /** Emits the `buttonClick$` observable to its subscribers. */
    public clickButton(): void {
        this._buttonClick$.next();
    }
    
    /** Emits the `buttonDisabled$` observable to the `idv.component.ts` */
    public setDisabled(disabld: boolean): void {
        this._buttonDisabled$.next(disabld);
    }
}