import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { SessionState } from '../../state-management/state/session.state';
import { SessionActionTypes } from '../../state-management/actions/session.actions';
import { InstitutionState } from '../../state-management/state/institution.state';

import { Applicant, ApplicantsState, initialApplicantsState } from '../../state-management/state/applicants.state';
import { ApplicantsActionsTypes } from '../../state-management/actions/applicants.actions';

import { AuthenticationHeaderManager } from '../../authentication-header-manager';

import { ProgressActionTypes } from '../../state-management/actions/progress.actions';
import { ProgressState, Step } from '../../state-management/state/progress.state';

import { Util } from 'src/app/common/util';
import { FormGroup, FormBuilder, AbstractControl, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { TextMasks } from 'src/app/business-information/business-validators';
import { Settings } from 'src/app/settings';
import { CombinedState } from 'src/app/state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';
import { isPromoCodeValid } from 'src/app/common/functions';

@Component({
    moduleId: module.id,
    templateUrl: './new-business.component.html',
    styleUrls: ['new-business.component.scss']
})
export class NewBusinessComponent implements OnDestroy {
    public isLoading: boolean;
    public unsubscribe: Subject<void> = new Subject<void>();
    public welcomeDisclosureId = '';
    public welcomeDisclosureDescription = '';
    // public routedPromoCode = '';
    // hidePromoCode = false;
    private readonly blankRouteMask = 'none';

    esignValid = false;
    requireDemonstratedEsignConsent = false;
    requireEsignConsentFromSingleSignOn = false;
    // isPromoInputVisible = false;
    useNewUiForBank = false;

    textMasks: TextMasks = new TextMasks();

    public applicantsState: ApplicantsState = JSON.parse(JSON.stringify(initialApplicantsState));
    business: Applicant = this.applicantsState.applicants[0];

    instantAddCode = '';
    skipProductSelection = false;
    isSSO = false;

    businessForm: FormGroup;
    lastName: FormControl;
    zipCode: FormControl;
    gambling: FormControl;
    cashChecks: FormControl;
    campaign: FormControl;
    other: FormControl;
    promoCode: string;
    emailAddress: FormControl;

    campaignOptions = [
        'Bank Employee',
        'Friend/Relative',
        'LinkedIn',
        'Bankrate',
        'Money-Rates',
        'Facebook',
        'Other'
    ];

    constructor(
        private store: Store<CombinedState>,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder
    ) {

        this.lastName = new FormControl(this.business.lastName, [Validators.required]);
        this.zipCode = new FormControl(this.business.zipCode, [Validators.required, Validators.minLength(5)]);
        this.emailAddress = new FormControl(this.business.emailAddress, [Validators.required]);
        this.gambling = new FormControl(this.applicantsState.gambling, [Validators.required, this.mustBeFalse()]);
        this.cashChecks = new FormControl(this.applicantsState.cashChecks, [Validators.required, this.mustBeFalse()]);
        this.campaign = new FormControl(this.applicantsState.campaign);
        this.other = new FormControl(this.applicantsState.other);
        this.promoCode = '';

        this.businessForm = new FormGroup({
            lastName: this.lastName,
            zipCode: this.zipCode,
            gambling: this.gambling,
            cashChecks: this.cashChecks,
            campaign: this.campaign,
            other: this.other,
            emailAddress: this.emailAddress
        });

        this.business.applicationQuestions = [];
        this.applicantsState.isBusiness = true;

        store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SignUp });
        store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.isLoading = info.isLoading;
            this.isSSO = info.isSSO;

            if (info.confirmationCode && info.confirmationCode.length) {
                this.store.dispatch({ type: ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT, payload: info });
                this.router.navigate(['products']);
            }
        });

        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {

            if (params.lastName !== undefined && params.lastName !== this.blankRouteMask) {
                this.lastName.setValue(params.lastName);
            }

            if (params.emailAddress !== undefined && params.emailAddress !== this.blankRouteMask) {
                this.business.emailAddress = params.emailAddress;
            }

            if (params.zipCode !== undefined && params.zipCode !== this.blankRouteMask) {
                this.business.zipCode = params.zipCode;
            }

            if (params.instantAddCode !== undefined && params.instantAddCode !== this.blankRouteMask) {
                if (params.instantAddCode !== undefined) {
                    this.instantAddCode = params.instantAddCode;
                }

                if (params.skipProductSelection) {
                    this.skipProductSelection = params.skipProductSelection !== '';
                }
                
                this.store.dispatch({
                    type: SessionActionTypes.UPDATE_INSTANT_ADD_CODE,
                    payload: {
                        instantAddCode: this.instantAddCode,
                        skipProductSelection: this.skipProductSelection,
                    }
                })
            }
        });

        store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.isLoading = info.isLoading;
            this.welcomeDisclosureId = info.institution.welcomeDisclosureId;
            this.welcomeDisclosureDescription = info.institution.welcomeDisclosureDescription;
            this.requireDemonstratedEsignConsent = info.institution.requireDemonstratedEsignConsent;
            this.requireEsignConsentFromSingleSignOn = info.institution.requireEsignConsentFromSingleSignOn;
            // this.hidePromoCode = info.institution.hidePromoCode;
            this.useNewUiForBank = info.institution.useNewUiForBank;
        });
    }

    mustBeFalse(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => control.value ? {mustBeFalse: {value: control.value}} : null;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public signUpNewUser(): void {
        if (this.promoCode !== '') {
            this.applicantsState.promotionalCodes.push(this.promoCode);
        }

        // copy the form values in to the models
        this.business.lastName = this.businessForm.value.lastName;
        this.business.zipCode = this.businessForm.value.zipCode;
        this.business.emailAddress = this.businessForm.value.emailAddress;
        this.applicantsState.gambling = this.businessForm.value.gambling;
        this.applicantsState.cashChecks = this.businessForm.value.cashChecks;
        this.applicantsState.campaign = this.businessForm.value.campaign;
        this.applicantsState.other = this.businessForm.value.other;

        this.store.dispatch({
            type: SessionActionTypes.ESTABLISH_SESSION,
            payload: Object.assign(
                {
                    instantAddCode: this.instantAddCode,
                    skipProductSelection: this.skipProductSelection
                },
                this.applicantsState
            )
        });
    }

    public getEsignLink(): string {
        return Settings.getAPIBaseURL() + 'Disclosures/' + encodeURIComponent(this.welcomeDisclosureId) + '/' +
            Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    }

    public getEsignDescription(): string {
        return (this.welcomeDisclosureDescription);
    }

    public isEsignRequired(): boolean {
        return this.welcomeDisclosureId.length > 0 &&
        !this.isSSO ||
        this.isSSO &&
        this.requireEsignConsentFromSingleSignOn;
    }
    public isPromoCodeValid():boolean {
        return isPromoCodeValid(this.promoCode);
    }
}
