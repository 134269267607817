import { NgModule, OnInit, Type } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';

import { HomeComponent } from './home.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ReturningUserComponent } from './returning-user/returning-user.component';
import { NewBusinessComponent } from './new-business/new-business.component';
import { DigitalUserHomeComponent } from './digital-user-home/digital-user-home.component';
import { CanSignOnDigitallyGuard } from './guard/can-digital-sign-on-guard.service';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        children: [
            // new business routes
            ...createHomeRoute('newBusiness', NewBusinessComponent),

            // new user routes
            ...createHomeRoute('newUser', NewUserComponent),

            // digital sign in routes
            ...createHomeRoute('digitalSignIn', DigitalUserHomeComponent).map(r => {return {...r, canActivate: [CanSignOnDigitallyGuard]}}),

            {
                path: 'existingUser',
                component: ReturningUserComponent
            },
            {
                path: 'existingUser/:confirmationCode/:emailAddress',
                component: ReturningUserComponent
            },
            {
                path: '',
                redirectTo: 'newUser',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'home/:reason',
        component: HomeComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class HomeRoutingModule { }

/** Creates a route configuration for the home component including the `promoCode`,  `instantAddCode`, `skipProductSelection` as well as some personal information */
function createHomeRoute(path: string, component: Type<any>): Route[] {
    return [
        {
            path: `${path}`,
            component: component
        },
        {
            path: `${path}/:promoCode`,
            component: component
        },
        {
            path: `${path}/:promoCode/:instantAddCode`,
            component: component
        },
        {
            path: `${path}/:promoCode/:instantAddCode/:skipProductSelection`,
            component: component
        },
        {
            path: `${path}/:firstName/:lastName/:emailAddress/:phoneNumber/:zipCode`,
            component: component
        },
        {
            path: `${path}/:firstName/:lastName/:emailAddress/:phoneNumber/:zipCode/:promoCode`,
            component: component
        },
        {
            path: `${path}/:firstName/:lastName/:emailAddress/:phoneNumber/:zipCode/:promoCode/:instantAddCode`,
            component: component
        },
        {
            // tslint:disable-next-line:max-line-length
            path: `${path}/:firstName/:lastName/:emailAddress/:phoneNumber/:zipCode/:promoCode/:instantAddCode/:skipProductSelection`,
            component: component
        }
    ]
}