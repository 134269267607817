import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SSOComponent } from './sso.component';

const routes: Routes = [
    {
        path: 'sso/:code',
        component: SSOComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SSORoutingModule { }
