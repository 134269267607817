import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonaIdCollectionRoutingModule } from './persona-id-collection-routing.module';
import { PersonaIdCollectionComponent } from './persona-id-collection.component';
import { LoadingModule } from '../loading/loading.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { ProgressModule } from '../progress/progress.module';


@NgModule({
  declarations: [
    PersonaIdCollectionComponent
  ],
  imports: [
    CommonModule,    
    ProgressModule,
    PersonaIdCollectionRoutingModule,
    PageHeaderModule,
    PageFooterModule,
    LoadingModule
  ]
})
export class PersonaIdCollectionModule { }
