import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ModalService } from '../modal/modal.service';

import { Store } from '@ngrx/store';

import { ProductsState, Product, Bundle } from '../state-management/state/products.state';
import { SessionState } from '../state-management/state/session.state';
import { SelectedProductsState } from '../state-management/state/selected-products.state';
import { ProductsActionTypes } from '../state-management/actions/products.actions';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState, Step } from '../state-management/state/progress.state';

import { AuthenticationHeaderManager } from '../authentication-header-manager';
import { InstitutionState } from '../state-management/state/institution.state';
import { Util } from '../common/util';
import { SelectedProductsActionTypes } from '../state-management/actions/selected-products.actions';
import { ProductsCartComponent } from './products-cart/products-cart.component';
import { LocationStrategy } from '@angular/common';
import { Settings } from '../settings';
import { CombinedState } from '../state-management/state/combined.state';

import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './products.component.html',
    styleUrls: ['products.component.scss']
})
export class ProductsComponent implements OnDestroy {
    currentStep: Step;
    pageName = 'products';
    isLoading: boolean;
    products: Product[];
    selectedProducts: Product[];
    selectedBundles: Bundle[];
    unsubscribe: Subject<void> = new Subject<void>();
    isPanelVisible = false;
    welcomeDisclosureId = '';
    welcomeDisclosureDescription = '';
    requireDemonstratedEsignConsent = false;
    requireEsignConsentFromSingleSignOn = false;
    isSSO = false;
    esignValid = false;
    pixelId = 0;
    pageEventName = 'Start';
    instantAddCode = '';
    useNewUiForBank = false;
    hidePromoCode = false;
    private skipProductSelection = false;
    private age = 0;
    private autosubmitted = false;
    nuFundStatus ='';

    @ViewChild(ProductsCartComponent) productsCart: ProductsCartComponent;

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<void>,
        private router: Router,
        private selectedProductsStore: Store<SelectedProductsState>,
        locationStrategy: LocationStrategy
    ) {

        // prevent back
        history.pushState(null, null, window.location.href);
        locationStrategy.onPopState(() => {
            history.pushState(null, null, location.href);
        });

        store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.age = this.getAge(info.dateOfBirth);
            this.isSSO = info.isSSO && !info.digitalBankingSSOKey;
            this.instantAddCode = info.instantAddCode;
            this.skipProductSelection = info.skipProductSelection;
        });

        store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            this.currentStep = info.currentStep;
            // update the wizard panel

            if (this.currentStep < Step.SelectProducts) {
                store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SelectProducts });
            } else if (this.currentStep > Step.SelectProducts) {
                this.router.navigate(['features']);
            }
        });

        store.select('productsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProductsState) => {
            if (info.isError) {
                this.handleLoadingError(info.error);
            } else {
                if (!info.productsLoaded && !info.isLoading && this.nuFundStatus !== 'Confirmation Package Sent') {
                    // load the initial products
                    store.dispatch({
                        type: ProductsActionTypes.GET_PRODUCTS,
                        payload: {
                            instantAddCodes: this.instantAddCode === '' ? [] : [this.instantAddCode]
                        }
                    });
                }
                this.store.dispatch({
                    type: SelectedProductsActionTypes.REHYDRATE
                });
                this.products = info.products;
                
                this.isLoading = info.isLoading || !info.productsLoaded;
            }

            if (info.productsSelected && (this.currentStep === Step.SelectProducts || this.currentStep > Step.SelectProducts)) {
                this.router.navigate(['features']);
            }
        });

        store.select('selectedProductsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SelectedProductsState) => {
            this.selectedProducts = info.selectedProducts;
            this.selectedBundles = info.selectedBundles;

            if (this.skipProductSelection && info.selectedProducts.length && !this.esignIsInvalid) {
                // don't auto-submit twice
                if (!this.autosubmitted) {
                    this.autosubmitted = true;
                    setTimeout(() => { this.confirmProductSelection(); });
                }
            }
        });

        store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.pixelId = info.institution.pixelId;
            this.welcomeDisclosureId = info.institution.welcomeDisclosureId;
            this.welcomeDisclosureDescription = info.institution.welcomeDisclosureDescription;
            this.requireDemonstratedEsignConsent = info.institution.requireDemonstratedEsignConsent;
            this.requireEsignConsentFromSingleSignOn = info.institution.requireEsignConsentFromSingleSignOn;
            this.useNewUiForBank = info.institution.useNewUiForBank;
            this.hidePromoCode = info.institution.hidePromoCode;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public handleLoadingError(error: any) {
        this.modalService.presentError(error, null, 'Error', 'Ok').pipe(takeUntil(this.unsubscribe)).subscribe(null, null, () => {
            this.store.dispatch({
                type: ProductsActionTypes.GET_PRODUCTS,
                payload: {
                    instantAddCodes: this.instantAddCode === '' ? [] : [this.instantAddCode]
                }
            });
        });
    }

    getAge(dateOfBirth: string) {
        const now = new Date();
        const birthDate = new Date(dateOfBirth);
        if (isNaN(birthDate.getTime()) || dateOfBirth == null) {
            return 0;
        }
        let age = now.getFullYear() - birthDate.getFullYear();
        const monthDiff = now.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    getProductMinimumAge(product: Product) {
        return product.minimumAge == null ? 0 : product.minimumAge;
    }

    get hideSelectorForInstantAddSkip() {
        return this.instantAddCode !== '' && this.skipProductSelection && this.hasValidAutoAddProduct;
    }

    get hasValidAutoAddProduct() {
        const codes = this.instantAddCode.split(',');
        // if there are products
        return this.products.length > 0 &&
            // and at least some of them match the auto add codes we have entered
            this.products.some(
                product =>
                    product.instantAddKey !== null &&
                    codes.some((code) => code.toLocaleLowerCase() === product.instantAddKey.toLocaleLowerCase())
            );
    }

    public confirmProductSelection() {
        this.store.dispatch({
            type: ProductsActionTypes.SUBMIT_SELECTED_PRODUCTS,
            payload: {
                products: this.selectedProducts,
                bundles: this.selectedBundles
            }
        });
    }

    hasSelectedProducts() {
        return this.selectedProducts.length > 0 || this.selectedBundles.length > 0;
    }

    getEsignLink() {
        return Settings.getAPIBaseURL() + 'Disclosures/' + encodeURIComponent(this.welcomeDisclosureId) + '/' +
            Util.getHostName() + '/' + encodeURIComponent(AuthenticationHeaderManager.getAuthenticationHeader());
    }

    handleEsignValidChanged(event: boolean) {
        this.esignValid = (event || !this.isEsignRequired())
    }

    getEsignDescription() {
        return (this.welcomeDisclosureDescription);
    }

    isEsignRequired() {
        return this.welcomeDisclosureId.length > 0 && this.requireEsignConsentFromSingleSignOn && this.isSSO;
    }

    get esignIsInvalid() {
        return this.isEsignRequired() && !this.esignValid;
    }

    onProductAdd(product: Product) {
        // if the product is being auto-added and it is already in the cart, don't add it again
        if (product.autoAdded && this.selectedProducts.some(selectedProduct => selectedProduct.productId === product.productId)) {
            return;
        }
        // don't open the cart if the cart doesn't exist or if it is an auto-add
        if (this.productsCart && !product.autoAdded) {
            this.productsCart.open();
        }
        this.selectedProductsStore.dispatch({ type: SelectedProductsActionTypes.INCREMENT_SELECTED_PRODUCT, payload: product });
    }

    onBundleAdd(bundle: Bundle) {
        if (this.productsCart) {
            this.productsCart.open();
        }
        this.selectedProductsStore.dispatch({ type: SelectedProductsActionTypes.INCREMENT_SELECTED_BUNDLE, payload: bundle });
    }

    onProductRemove(product: Product) {
        this.store.dispatch({ type: SelectedProductsActionTypes.DECREMENT_SELECTED_PRODUCT, payload: product });
    }

    onBundleRemove(bundle: Bundle) {
        this.store.dispatch({ type: SelectedProductsActionTypes.DECREMENT_SELECTED_BUNDLE, payload: bundle });
    }
}
