<div class="product-outer-container" [class.product-selected]="isProductSelected">
    <!-- icons container -->
    <ul class="product-icons-container">
        <li class="product-icon-item product-icon-promo" *ngIf="bundleHasPromoCode">
            <span class="icon icon-certificate"></span>
            <!-- RESKIN version-->
            <div class="product-icon-promo-badge">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" class="product-promo-icon"><path class="product-promo-icon-star" d="M9.71 12L6 9.491 2.29 12l1.119-4.495L0 4.582l4.4-.283L6-.001l1.6 4.3 4.4.283-3.41 2.923z" fill="#fff"/></svg>Promo
            </div>
        </li>
        <li class="product-icon-item product-bundle-notation">
            <span class="icon icon-bundle"></span>
            <span class="product-bundle-name">{{bundle.products[0].bundleName}}</span>
        </li>
    </ul>
    <!-- /icons container -->

    <!-- inner content -->
    <div class="product-inner-container">
        <ng-container *ngFor="let product of bundle.products; index as i">
            <ng-container *ngIf="i === currentProductBundleIndex">
                <!-- image style -->
                <img src="{{product.descriptionImageURI}}" class="product-type-image" *ngIf="product.isImageProduct">
                <!-- /image style -->

                <!-- text style -->
                <div class="product-item-details" *ngIf="!product.isImageProduct" #productDetails>
                    <div class="product-item-header">
                        <h5>{{ product.productName }}</h5>
                        <h6>{{ product.applicationCode }}</h6>
                    </div>
                    <div class="product-item-description" [innerHTML]="product.description"></div>
                </div>
                <!-- /text style -->
            </ng-container>
        </ng-container>

        <!-- dots -->
        <ul class="product-bundle-carousel-indicators">
            <li *ngFor="let product of bundle.products; index as i" [ngClass]="{'notCurrent': i !== currentProductBundleIndex, 'current': i === currentProductBundleIndex}"></li>
        </ul>
        <!-- /dots -->

        <!-- carousel arrows -->
        <a (click)="incrementProduct(-1)" class="product-button-bundle-carousel-prev">
            <span class="icon icon-angle-left"></span>
        </a>
        <a (click)="incrementProduct(1)" class="product-button-bundle-carousel-next">
            <span class="icon icon-angle-right"></span>
        </a>
        <!-- /carousel arrows -->

        <!-- buttons -->
        <ul class="product-buttons">
            <li *ngIf="isMoreInfoNeeded" class="product-button">
                <a *ngIf="!isProductSelected" (click)="showMoreInfo($event)" class="product-button-link">More Info</a>
                <a *ngIf="isProductSelected" (click)="showMoreInfoRemove($event)" class="product-button-link">More Info</a>
            </li>
            <li class="product-button">
                <a *ngIf="!isProductSelected" (click)="addBundle()" class="product-button-link">Add Bundle</a>
                <a *ngIf="isProductSelected" (click)="onBundleRemove()" class="product-button-link">Remove Bundle</a>
            </li>
        </ul>
        <!-- /buttons -->

    </div>

</div>
