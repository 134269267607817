import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { ProductsModule } from './products/products.module';
import { PersonalInformationModule } from './personal-information/personal-information.module';
import { ApplicantModule } from './personal-information/applicant/applicant.module';
import { OutOfWalletModule } from './out-of-wallet/out-of-wallet.module';
import { FeaturesModule } from './features/features.module';
import { DisclosuresModule } from './disclosures/disclosures.module';
import { FundingModule } from './funding/funding.module';
import { CompletionModule } from './completion/completion.module';
import { ChallengeDepositModule } from './challenge-deposit/challenge-deposit.module';
import { SSOModule } from './sso/sso.module';
import { PageHeaderModule } from './page-header/page-header.module';
import { PageFooterModule } from './page-footer/page-footer.module';

import { institutionStoreReducer } from './state-management/reducers/institution.reducer';
import { productsStoreReducer } from './state-management/reducers/products.reducer';
import { selectedProductsStoreReducer } from './state-management/reducers/selected-products.reducer';
import { promoCodeStoreReducer } from './state-management/reducers/promo-code.reducer';
import { sessionStoreReducer } from './state-management/reducers/session.reducer';
import { applicantsStoreReducer } from './state-management/reducers/applicants.reducer';
import { progressStoreReducer } from './state-management/reducers/progress.reducer';
import { outOfWalletStoreReducer } from './state-management/reducers/out-of-wallet.reducer';
import { routingLookupStoreReducer } from './state-management/reducers/routing-lookup.reducer';
import { challengeDepositStoreReducer } from './state-management/reducers/challenge-deposit.reducer';

import { InstitutionEffects } from './state-management/effects/institution.effects';
import { ProductsEffects } from './state-management/effects/products.effects';
import { PromoCodeEffects } from './state-management/effects/promo-code.effects';
import { SessionEffects } from './state-management/effects/session.effects';
import { ApplicantsEffects } from './state-management/effects/applicants.effects';
import { OutOfWalletEffects } from './state-management/effects/out-of-wallet.effects';
import { RoutingLookupEffects } from './state-management/effects/routing-lookup.effects';
import { ChallengeDepositEffects } from './state-management/effects/challenge-deposit.effects';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignoutComponent } from './signout/signout.component';
import { VerificationErrorComponent } from './verification-error/verification-error.component';
import { ModalComponent } from './modal/modal.component';

import { InstitutionService } from './services/institution.service';
import { ProductsService } from './services/products.service';
import { HttpInterceptorService } from './http-interceptor.service';
import { ModalService } from './modal/modal.service';
import { SessionService } from './services/session.service';
import { VerificationService } from './services/verification.service';
import { OutOfWalletService } from './services/out-of-wallet.service';
import { ChallengeDepositService } from './services/challenge-deposit.service';
import { RoutingLookupService } from './services/routing-lookup.service';
import { CanActivateSessionGuard } from './services/can-activate-session-guard.service';
import { SessionNotificationService } from './services/session-notification.service';
import { DissoComponent } from './disso/disso.component';
import { DigitalUserModule } from './digital-user/digital-user.module';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BusinessInformationModule } from './business-information/business-information.module';
import { RevationChatModule } from './revation-chat/revation-chat.module';
import { IdvModule } from './idv/idv.module';
import { CssService } from './services/css.service';
import { NewApplicantModule } from 'src/new-applicant/new-applicant.module';
import { PersonaConsentModule } from './persona-consent/persona-consent.module';
import { PersonaIdCollectionModule } from './persona-id-collection/persona-id-collection.module';

let dev = [
    // StoreDevtoolsModule.instrumentOnlyWithExtension()
];
if (environment.production) {
    dev = []; // If production mode, environment.ts gets switched out for environment.prod.ts
    enableProdMode();
} else {
    StoreDevtoolsModule.instrument();
}

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        ModalComponent,
        SignoutComponent,
        VerificationErrorComponent,
        DissoComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HomeModule,
        ProductsModule,
        NewApplicantModule,
        PersonalInformationModule,
        BusinessInformationModule,
        ApplicantModule,
        OutOfWalletModule,
        FeaturesModule,
        DisclosuresModule,
        FundingModule,
        DigitalUserModule,
        CompletionModule,
        ChallengeDepositModule,
        SSOModule,
        IdvModule,
        PersonaConsentModule,
        PersonaIdCollectionModule,
        AppRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        RevationChatModule,

        StoreModule.forRoot({
            institutionStoreReducer,
            productsStoreReducer,
            promoCodeStoreReducer,
            selectedProductsStoreReducer,
            sessionStoreReducer,
            applicantsStoreReducer,
            progressStoreReducer,
            outOfWalletStoreReducer,
            routingLookupStoreReducer,
            challengeDepositStoreReducer
        }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        EffectsModule.forRoot([
            InstitutionEffects,
            ProductsEffects,
            PromoCodeEffects,
            SessionEffects,
            ApplicantsEffects,
            OutOfWalletEffects,
            RoutingLookupEffects,
            ChallengeDepositEffects
        ]),
        ...dev
    ],
    providers: [
        InstitutionService,
        Title,
        ModalService,
        SessionService,
        ProductsService,
        CanActivateSessionGuard,
        VerificationService,
        OutOfWalletService,
        ChallengeDepositService,
        RoutingLookupService,
        SessionNotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        CssService,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
