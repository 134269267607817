import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { CombinedState } from '../state-management/state/combined.state';
import { SessionState } from '../state-management/state/session.state';

@Injectable()
export class CanActivateSessionGuard implements CanActivate {
    public session: SessionState = null;
    constructor(store: Store<CombinedState>, private router: Router) {
        store.select('sessionStoreReducer').subscribe((info: SessionState) => {
            this.session = info;
        });
    }

    canActivate() {
        if (this.session.confirmationCode && this.session.confirmationCode.length > 0 || this.router.url.indexOf('/signout/') != -1) {
            return true;
        } else {
            this.router.navigate(['home']);
            return false;
        }
    }
}