import { NuFundAction } from '../../common/nufund-action';

export const ApplicantsActionsTypes = {
    LOAD_DEMO_APPLICANT: '[Applicants] LOAD_DEMO_APPLICANT',
    LOAD_DEMO_BUSINESS: '[Applicants] LOAD_DEMO_BUSINESS',

    LOAD_DEMO_EXPERIANAPPLICANT: '[Applicants] LOAD_DEMO_EXPERIANAPPLICANT',
    LOAD_DEMO_EXPERIANBUSINESS: '[Applicants] LOAD_DEMO_EXPERIANBUSINESS',
    DEFAULT_BUSINESS_APPLICANTS: '[Applicants] DEFAULT_BUSINESS_APPLICANTS',
    ADD_BUSINESS_APPLICANT: '[Applicants] ADD_BUSINESS_APPLICANT',
    TOGGLE_ID_FILL: '[Applicants] TOGGLE_ID_FILL',

    UPDATE_CURRENT_APPLICANT: '[Applicants] UPDATE_CURRENT_APPLICANT',

    /** 
     * Adds an applicant to the `applicants` array in the state object.
     * @payload No Payload
     */
    ADD_APPLICANT: '[Applicants] ADD_APPLICANT',
    ADD_APPLICANT_SUCCESS: '[Applicants] ADD_APPLICANT_SUCCESS',

    REMOVE_APPLICANT: '[Applicants] REMOVE_APPLICANT',
    REMOVE_APPLICANT_SUCCESS: '[Applicants] REMOVE_APPLICANT_SUCCESS',

    SAVE_APPLICANT: '[Applicants] SAVE_APPLICANT',
    SAVE_APPLICANT_SUCCESS: '[Applicants] SAVE_APPLICANT_SUCCESS',
    SAVE_APPLICANT_FAILURE: '[Applicants] SAVE_APPLICANT_FAILURE',

    /** 
     * Updates the current applicant index. Should be called at the end of verification processes if there are multiple applicants.
     * @payload `number`: The applicant index you wish up update to.
     */
    SET_CURRENT_APPLICANT: '[Applicants] SET_CURRENT_APPLICANT',

    SUBMIT_IDENTIFICATION_DOCUMENT: '[Applicants] SUBMIT_IDENTIFICATION_DOCUMENT',
    SUBMIT_IDENTIFICATION_DOCUMENT_SUCCESS: '[Applicants] SUBMIT_IDENTIFICATION_DOCUMENT_SUCCESS',
    SUBMIT_IDENTIFICATION_DOCUMENT_FAILURE: '[Applicants] SUBMIT_IDENTIFICATION_DOCUMENT_FAILURE',

    SUBMIT_APPLICANT: '[Applicants] SUBMIT_APPLICANT',
    SUBMIT_APPLICANT_SUCCESS: '[Applicants] SUBMIT_APPLICANT_SUCCESS',
    SUBMIT_APPLICANT_FAILURE: '[Applicants] SUBMIT_APPLICANT_FAILURE',

    SUBMIT_BUSINESS_APPLICANTS: '[Applicants] SUBMIT_BUSINESS_APPLICANTS',
    SUBMIT_BUSINESS_APPLICANTS_SUCCESS: '[Applicants] SUBMIT_BUSINESS_APPLICANTS_SUCCESS',
    SUBMIT_BUSINESS_APPLICANTS_FAILURE: '[Applicants] SUBMIT_BUSINESS_APPLICANTS_FAILURE',

    /** Changes the route in the effects */
    SUBMIT_IDV_FLOW: '[Applicants] SUBMIT_IDV_FLOW',
    SUBMIT_IDV_FLOW_SUCCESS: '[Applicants] SUBMIT_IDV_FLOW_SUCCESS',
    SUBMIT_IDV_FLOW_FAILURE: '[Applicants] SUBMIT_IDV_FLOW_FAILURE',

    /** Changes the route in the effects */
    SUBMIT_IDV_FLOW_WITH_STEP_UP: '[Applicants] SUBMIT_IDV_FLOW_WITH_STEP_UP',
    SUBMIT_IDV_FLOW_WITH_STEP_UP_SUCCESS: '[Applicants] SUBMIT_IDV_FLOW_WITH_STEP_UP_SUCCESS',
    SUBMIT_IDV_FLOW_WITH_STEP_UP_FAILURE: '[Applicants] SUBMIT_IDV_FLOW_WITH_STEP_UP_FAILURE',

    /** 
     * Changes the route in the effects on success.
     * @payload `IdvBusinessRequest` model
     * @returns `IdvResponse` model
    */
    SUBMIT_BUSINESS_IDV: '[Applicants] SUBMIT_BUSINESS_IDV',
    SUBMIT_BUSINESS_IDV_SUCCESS: '[Applicants] SUBMIT_BUSINESS_IDV_SUCCESS',
    SUBMIT_BUSINESS_IDV_FAILURE: '[Applicants] SUBMIT_BUSINESS_IDV_FAILURE',



    SUBMIT_OTP_CODE: '[Applicants] SUBMIT_OTP_CODE',
    SUBMIT_OTP_CODE_SUCCESS: '[Applicants] SUBMIT_OTP_CODE_SUCCESS',

    DID_NOT_RECEIVE_CODE: '[Applicants] DID_NOT_RECEIVE_CODE',
    DID_NOT_RECEIVE_CODE_SUCCESS: '[Applicants] DID_NOT_RECEIVE_CODE_SUCCESS',
    CAN_NOT_RECEIVE_CODE: '[Applicants] CAN_NOT_RECEIVE_CODE',
    CAN_NOT_RECEIVE_CODE_SUCCESS: '[Applicants] CAN_NOT_RECEIVE_CODE_SUCCESS',

    /**
     * Submits the kiq questions to the api.
     * @payload A `IdvRequest` populated with a `number[]` in the kiqAnswers property
     */
    SUBMIT_KIQ_QUESTIONS: '[Applicants] SUBMIT_KIQ_QUESTIONS',
    SUBMIT_KIQ_QUESTIONS_SUCCESS: '[Applicants] SUBMIT_KIQ_QUESTIONS_SUCCESS',
};

export class SubmitApplicantSuccessAction implements NuFundAction {
    type = ApplicantsActionsTypes.SUBMIT_APPLICANT_SUCCESS;
    constructor(public payload: { result: any }) { }
}

export class SubmitApplicantFailureAction implements NuFundAction {
    type = ApplicantsActionsTypes.SUBMIT_APPLICANT_FAILURE;
    constructor(public payload: any) { }
}

export class SubmitIdentificationDocumentFailureAction implements NuFundAction {
    type = ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT_FAILURE;
    constructor(public payload: any) { }
}

export class SaveApplicantSuccessAction implements NuFundAction {
    type = ApplicantsActionsTypes.SAVE_APPLICANT_SUCCESS;
    constructor(public payload: { result: any }) { }
}

export class SaveApplicantFailureAction implements NuFundAction {
    type = ApplicantsActionsTypes.SAVE_APPLICANT_FAILURE;
    constructor(public payload: any) { }
}
