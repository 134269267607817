import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';

import { ProgressState, initialProgressState, Step } from '../state/progress.state';

import { ProgressActionTypes } from '../actions/progress.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const progressStoreReducer: ActionReducer<ProgressState> = reducer;

export function reducer(state = initialProgressState, action: NuFundAction) {
    switch (action.type) {
        case ProgressActionTypes.GET_PROGRESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state);
        }

        case ProgressActionTypes.SET_PROGRESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { currentStep: action.payload });
        }

        case SessionActionTypes.RESUME_SESSION_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const data = action.payload.resumeData;
            let newStep: Step;

            switch (data.nuFundStatus) {
                case 'Established Session': {
                    newStep = Step.SelectProducts;
                    break;
                }
                case 'Selected Products': {
                    newStep = Step.CustomizeProducts;
                    break;
                }
                case 'Business Applicant Collection':
                case 'Selected Features': {
                    newStep = Step.PrimaryApplicantInformation;
                    break;
                }
                case 'Applicant Verification': {
                    if (data.totalVerifiedApplicants === 0) {
                        newStep = Step.PrimaryApplicantInformation;
                    } else if (data.totalApplicants === 2 && data.totalVerifiedApplicants === 1) {
                        newStep = Step.SecondaryApplicantInformation;
                    } else if (data.totalApplicants === data.totalVerifiedApplicants) {
                        newStep = Step.AcceptDisclosures;
                    }
                    break;
                }
                case 'Business Applicants Saved': {
                    newStep = Step.AcceptDisclosures;
                    break;
                }
                case 'Accepted Disclosures': {
                    newStep = Step.FundingSource;
                    break;
                }
                case 'Saved Funding Sources': {
                    newStep = Step.DigitalUserVerification;
                    break;
                }
                case 'Digital Banking User Created':
                case 'Digital Banking Accounts Created':
                case 'Digital Banking User Authenticated': {
                    newStep = Step.Completion;
                    break;
                }
                default: {
                    newStep = Step.Completion;
                    break;
                }
            }
            return Object.assign({}, state, { currentStep: newStep });
        }

        case SessionActionTypes.LOG_OUT: {
            if (isDevMode()) {
                console.log('{progressStoreReducer} ' + action.type + ' action being handled!');
            }

            return Object.assign({}, initialProgressState);
        }

        default: {
            return state;
        }
    }
}
