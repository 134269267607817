import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsignComponent } from './esign.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TextMaskModule,
    FormsModule
  ],
  declarations: [
    EsignComponent
  ],
  exports: [
    EsignComponent
  ]
})
export class EsignModule { }
