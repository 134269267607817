import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationHeaderManager {
    public static readonly authenticationHeaderName: string = 'sessionid';

    constructor() { }

    public static storeAuthenticationHeader(authenticationHeader: string) {
        localStorage.setItem(AuthenticationHeaderManager.authenticationHeaderName, authenticationHeader);
    }

    public static getAuthenticationHeader() {
        return this.authenticationHeaderValue;
    }

    public static get authenticationHeaderValue() {
        return localStorage.getItem(AuthenticationHeaderManager.authenticationHeaderName);
    }

    public static get authenticationHeaders(): HttpHeaders {
        return new HttpHeaders({
            [AuthenticationHeaderManager.authenticationHeaderName]: AuthenticationHeaderManager.authenticationHeaderValue
        });
    }
}
