import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'csi-facebook-pixel',
  template: ''
})
export class FacebookPixelComponent implements OnInit {
  @Input() pageEventName = 'PageView';
  @Input() pixelId = 0;
  @Input() init = false;

  constructor() { }

  ngOnInit() {
    if (this.pixelId !== 0) {
      this.registerPageView();
    }
  }

  registerPageView() {
    const iifi = function(windowScope, document, tagType, url, n, newTag, firstTagOfType) {
      if (windowScope.fbq) {
        return;
      }
      n = windowScope.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!windowScope._fbq) {
        windowScope._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
      }
    n.queue = [];
    newTag = document.createElement(tagType);
    newTag.async = !0;
    newTag.src = url;
    firstTagOfType = document.getElementsByTagName(tagType)[0];
    firstTagOfType.parentNode.insertBefore(newTag, firstTagOfType);
  }((<any>window), (<any>document), 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    if (this.init) {
      (<any>window).fbq('init', this.pixelId.toString());
    }
    (<any>window).fbq('trackCustom', this.pageEventName);
  }
}
