import { Component, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { ModalService } from '../modal/modal.service';
import { Store } from '@ngrx/store';

import { SessionState } from '../state-management/state/session.state';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState } from '../state-management/state/progress.state';
import { Step } from '../state-management/state/progress.state';

import { ApplicantsActionsTypes } from '../state-management/actions/applicants.actions';
import { ApplicantsState, Applicant } from '../state-management/state/applicants.state';

import { InstitutionState, Institution } from '../state-management/state/institution.state';

import { Router } from '@angular/router';

import { CombinedState } from '../state-management/state/combined.state';

import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdvRequest, IdvStrategy } from '../models/idv.model';
import { isIdvFlow, truncateInstitutionName } from '../common/functions';

@Component({
    moduleId: module.id,
    templateUrl: './personal-information.component.html',
    styleUrls: ['personal-information.component.scss']
})
export class PersonalInformationComponent implements OnDestroy {
    pageName = 'personalInformation';
    isLoading = false;
    isAddingApplicant = false;
    applicantsState: ApplicantsState;
    currentStep: Step;
    applicantIsValid: boolean;
    unsubscribe: Subject<any> = new Subject<any>();
    institution: Institution = null;
    dataConcat$: Observable<any>;
    isPanelVisible = false;
    useNewUiForBank = false;

    sessionState: SessionState;

    hasSubmitted: boolean = false;

    Step = Step;

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<boolean>,
        private router: Router) {
        store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            this.currentStep = info.currentStep;
        });

        store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institution = info.institution;
            this.useNewUiForBank = info.institution.useNewUiForBank;
        });

        store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            this.sessionState = info;
        });

        store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ApplicantsState) => {
            this.isLoading = info.applicants[info.currentApplicantIndex].isLoading;
            
            this.isAddingApplicant = info.applicants[info.currentApplicantIndex].isAddingApplicant;

            if (info.applicants[info.currentApplicantIndex].isError) {
                if (info.canContinue) {
                    this.modalService.presentError(
                        info.applicants[info.currentApplicantIndex].error, true, 'Verification Error', 'Ok'
                    ).pipe(takeUntil(this.unsubscribe)).subscribe(null, null, () => { });
                }
            } else {
                // Meant to manage state for resuming applications
                if (
                    info.currentApplicantIndex === 0 &&
                    this.currentStep < Step.VerifyPrimaryApplicantInformation &&
                    this.currentStep !== Step.SignUp && this.currentStep !== Step.PrimaryApplicantInformation
                ) {
                    store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PrimaryApplicantInformation });
                } else if (
                    info.currentApplicantIndex === 1 &&
                    this.currentStep < Step.SecondaryApplicantInformation &&
                    this.currentStep !== Step.SignUp &&
                    this.currentStep !== Step.SecondaryApplicantInformation
                ) {
                    store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.SecondaryApplicantInformation });
                } else if (this.currentStep >= Step.AcceptDisclosures) {
                    this.router.navigate(['disclosures']);
                }

                if (
                    info.applicants[info.currentApplicantIndex].isVerified &&
                    (this.currentStep === Step.PrimaryApplicantInformation || this.currentStep === Step.SecondaryApplicantInformation)
                ) {
                    if (
                        this.sessionState && this.sessionState.isSSO &&
                        info.currentApplicantIndex === 0 && info.applicants.length === 1
                    ) { // sso session with only one applicant
                        this.router.navigate(['disclosures']);
                    } else if (
                        info.currentApplicantIndex > 0 ||
                        !this.sessionState.isSSO
                    ) { // non sso session or sso session with more than one applicant
                        this.router.navigate(['out-of-wallet', info.currentApplicantIndex]);
                    } else { // sso session with more than one applicant
                        this.store.dispatch({ type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT, payload: 1 });
                        this.router.navigate(['personal-information']);
                    }
                }
                this.applicantsState = info;
            }
        });

    }

    onAutofillClick() {
        if (isIdvFlow(this.institution)) {
            this.store.dispatch({ type: ApplicantsActionsTypes.LOAD_DEMO_EXPERIANAPPLICANT, payload: this.institution });

        } else {
            this.store.dispatch({ type: ApplicantsActionsTypes.LOAD_DEMO_APPLICANT, payload: this.institution });

        }
        return false;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    addApplicant() {
        this.store.dispatch({ type: ApplicantsActionsTypes.ADD_APPLICANT });
        return false;
    }

    removeApplicant(index: number) {
        this.applicantsState.applicants.forEach((applicant, currentIndex) => applicant.index = currentIndex);

        if (this.applicantsState.currentApplicantIndex === index) {
            this.modalService.openModal(
                'Confirm removal',
                'Are you sure you want to remove this person?  It will not be possible to add them back after this.',
                [
                    {
                        label: 'Remove this person',
                        result: true,
                        closesModal: true,
                        class: 'modal-button'
                    },
                    {
                        label: 'Cancel',
                        result: false,
                        closesModal: true,
                        class: 'modal-error-button'
                    }
                ], 'modal-alert').pipe(takeUntil(this.unsubscribe)).subscribe(buttonResult => {
                    if (buttonResult) {
                        this.store.dispatch({ type: ApplicantsActionsTypes.REMOVE_APPLICANT, payload: this.applicantsState.applicants[index] });
                        this.router.navigate(['disclosures']);
                    }
                });
        } else {
            this.store.dispatch({ type: ApplicantsActionsTypes.REMOVE_APPLICANT, payload: this.applicantsState.applicants[index] });
        }
        return false;
    }

    handleFormStateChanged(event: boolean) {
        this.applicantIsValid = event;
    }

    applicantChanged(event: Applicant) {
        if (!this.hasSubmitted) {
            this.applicantsState.applicants[this.applicantIndex] = event;
        }
    }

    applicantInformationIsValid(applicant: Applicant): boolean {
        return applicant && applicant.taxIdentificationNumber && applicant.taxIdentificationNumber.replace(/[^0-9]/, '').length === 9;
    }

    submitCurrentApplicant() {
        this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].index = this.applicantsState.currentApplicantIndex;
        if (this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].citizenType === '2.1' ||
            this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].citizenType === '2.2') {
            this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].citizenType = '2';
        }

        if (!this.institution.collectUSResidency) {
            this.applicant.citizenType = '0';
        }
        
        if (this.sessionState.isSSO && this.applicantIndex === 0) {
            // there is logic in the api method to snuff out sso requests
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_APPLICANT,
                payload: this.applicantsState.applicants[this.applicantsState.currentApplicantIndex]
            });
            // short circuit the rest
            return;
        }
        // Watchdog IDV applicant validation
        if (isIdvFlow(this.institution)) {
            const idvRequest: IdvRequest = <IdvRequest>{
                applicant: this.applicantsState.applicants[this.applicantIndex],
                clientReferenceId: null,
                idvSessionId: null,
                kiqAnswers: null,
                bankName: truncateInstitutionName(this.institution.institutionName),
                oneTimePasscode: null,
            }
            if (this.institution.idvStrategy === IdvStrategy.IdScreening) {
                this.store.dispatch({
                    type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW,
                    payload: {...idvRequest, currentApplicantIndex: this.applicantIndex, length: this.applicantsState.applicants.length}
                });
            }
            if (this.institution.idvStrategy === IdvStrategy.IdScreeningWithStepUp) {
                this.store.dispatch({
                    type: ApplicantsActionsTypes.SUBMIT_IDV_FLOW_WITH_STEP_UP,
                    payload: idvRequest
                });
            }
        } else {
            // Normal KBA validation validation
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_APPLICANT,
                payload: this.applicantsState.applicants[this.applicantsState.currentApplicantIndex]
            });
        }
    }

    getApplicantPrefix(prefix: number): string {
        switch (prefix) {
            case 0:
                return 'Primary';
            case 1:
                return 'Secondary';
            default:
                return 'Additional';
        }
    }

    get applicant() {
        return this.applicantsState.applicants[this.applicantIndex];
    }

    get applicantIndex() {
        return this.applicantsState.currentApplicantIndex;
    }

    get showAdminAutoFill() {
        return this.institution.allowAutoFill && (!this.sessionState.isSSO || this.applicantIndex > 0);
    }

    public isContinueDisabled() {
        return this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].isLoading
            || this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].isAddingApplicant
    }
}

