import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateSessionGuard } from '../services/can-activate-session-guard.service';
import { IdvComponent } from './idv.component';
import { KiqComponent } from './kiq/kiq.component';
import { OtpComponent } from './otp/otp.component';

const routes: Routes = [
    {
        path: 'idv',
        component: IdvComponent,
        canActivate: [CanActivateSessionGuard],
        children: [
            {path: 'otp', component: OtpComponent},
            {path: 'kiq', component: KiqComponent}
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class IdvRoutingModule { }
