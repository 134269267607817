import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FeatureItemComponent } from './feature-item.component';

import { RemoveSpaces } from './remove-spaces.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        FeatureItemComponent,
        RemoveSpaces
    ],
    exports: [
        FeatureItemComponent
    ]
})
export class FeatureItemModule { }