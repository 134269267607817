
export interface ProductsState {
    products: Product[];
    productsLoaded: boolean;
    isLoading: boolean;
    isError: boolean;
    error: any;
    featuresSelected: boolean;
    productsSelected: boolean;
    disclosuresSubmitted: boolean;
    fundingSubmitted: boolean;
    bundles: Bundle[];
    filesSubmitted: boolean;
}

export class Product {
    id: string;
    openAmount: string;
    applicationCode: string;
    features: Feature[];
    disclosures: Disclosure[];
    availableFundingMethods: any;
    description: string;
    maximumDeposit: any;
    minimumAge: any;
    minimumDeposit: number;
    minimumOutOfWalletScore: number;
    productId: string;
    productName: string;
    bundleId: number;
    bundleName: string;
    promotionalCodeMask: string;
    useChallengeDeposits: boolean;
    selectedFundingMethods: SelectedFundingMethods[];
    hasPromoCode: boolean;
    institutionProductDefinedData: ProductQuestion[];
    instantAddKey: string;
    autoAdded?: boolean;
    relatedAccountId: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get descriptionWithoutImage(): string {
        return this.description.replace(/(.|\r|\n)+=!END_IMAGE!=.+(\r|\n)/i, '');
    }

    get descriptionImageURI(): string {
        if (this.isImageProduct) {
            // I think this regex would be better like this /<img\s+[^>]*src="([^"]*)"[^>]*>/
            // I'm not changing it for now since it's not failing anywhere I know of; /<img[^>]*>/ is even more simple
            return this.description.match(/<img.+src="(.+)(?=".+>)/i)[1];
        } else {
            return '';
        }
    }

    get isImageProduct(): boolean {
        return this.description.indexOf('=!END_IMAGE!=') !== -1 && this.description.match(/<img.+src="(.+)(?=".+>)/i) !== null;
    }
}

export class Bundle {
    products: Product[];
    name: string;
    hasPromoCode: boolean;
    isBundle: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface SelectedFundingMethods {
    // fundingSources: FundingSource[];
    id: number;
    type: string;
    routingNumber: string;
    accountNumber: string;
    accountType: string;
    challengeDeposit1: string;
    challengeDeposit2: string;
    challengeDepositConfirmation: boolean;
    challengeAttempts: number;
    bankName: string;
}

/*export interface FundingSource {
    type: string;
    routingNumber: string;
    accountNumber: string;
    fundingBankName: string;
    accountType: string;
    productId: string;
}
*/

export interface Disclosure {
    acceptanceRequired: boolean;
    description: string;
    disclosureId: string;
    disclosureName: string;
    disclosureResponse: string;
    displayOrder: number;
    displayText: string;
    documentType: string;
    documentAvailable: boolean;
    isAccepted: boolean;
    disclosureany: string;
    questions: DisclosureQuestion[];
}

export interface DisclosureQuestion {
    id: number;
    disclosreId: string;
    question: string;
    appCode: string;
    ddn: number;
    yesAnswer: string;
    noAnswer: string;
    displayOrder: number;
    answer: string;
}

export class Feature {
    featureId: string;
    featureName: string;
    featureProductId: string;
}

export interface ProductQuestion {
    questionId: string;
    questionText: string;
    answerText: string;
    productId: string;
    dataDefintionId: string;
    isRequired: boolean;
}

export const initialProductsState = {
    products: [],
    disclosures: [],
    selectedFundingMethods: [],
    productsLoaded: false,
    isLoading: false,
    isError: false,
    error: null,
    featuresSelected: false,
    productsSelected: false,
    disclosuresSubmitted: false,
    fundingSubmitted: false,
    hasPromoCode: false,
    bundles: [],
    filesSubmitted: false
};

export const initialFundingSourceState = {
    id: 0,
    type: '',
    routingNumber: '',
    accountNumber: '',
    accountType: '',
    challengeDeposit1: '',
    challengeDeposit2: '',
    challengeDepositConfirmation: false,
    challengeAttempts: 0,
    // openAmount: '',
    bankName: ''
};

export const fundingSources = {
    ACH: 'ACH',
    Check: 'Personal Check',
    Other: 'Other'
};

export const accountTypes = {
    Checking: 'Checking',
    Savings: 'Savings'
};
