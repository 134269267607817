<section class="wizard-main">
    <!-- top: PROGRESS -->
    <section class="wizard-upper">

        <div *ngIf="!useNewUiForBank" class="wizard-session">
            <div class="wizard-user-code">
                <h5 class="wizard-confirmation-title">Confirmation Code:</h5>
                <h1 class="wizard-confirmation-code">{{ sessionState.confirmationCode }}</h1>
                <p class="wizard-confirmation-info">This code will allow you to resume the application process at a later date and time. Please store it in a safe, secure location.</p>
            </div>
        </div>
        <div *ngIf="!useNewUiForBank" class="wizard-user-progress">
            <progress max="100" value="{{getProgressPercent()}}" class="wizard-user-progressBar">
                <!-- fallback -->
                <div class="progress-bar">
                    <span class="progress-bar-value" [style.width]="getProgressPercent() + '%'">{{getProgressPercent()}}%</span>
                </div>
                <!-- /fallback -->
            </progress>
            <p class="wizard-user-progress-text">{{getProgressPercent()}}% Complete</p>
        </div>

        <!-- TODO: RESKIN - look into moving this into page-header component so we don't have to position it in hacky way -->
        <div class="sign-out" (click)="signOutClick()">
            Save &amp; Resume Later<i class="icon icon-chevron-right" aria-hidden="true"></i>
        </div>

    </section>
    <!-- /top: PROGRESS -->

    <!-- bottom: NAVIGATION -->
    <nav class="wizard-navigation wizard-lower">
        <ul class="wizard-navigation-list">
            <!-- WELCOME -->
            <li *ngIf="!useNewUiForBank" [ngClass]="{'current-progress': progressState.currentStep == Step.SignUp }" class="wizard-navigation-item wizard-navigation-item-welcome">
                <div class="nav-list-item nav-section-title nav-section-title-welcome">
                    <span class="nav-stepnumber">01</span><span class="nav-stepname">Welcome</span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.SelectProducts) ||
                        isCurrentStep(Step.CustomizeProducts) ||
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                </div>
            </li>

            <!-- Persona -->
            <li *ngIf="enablePersona" [ngClass]="{ 'nav-section-active':isCurrentStep(Step.PersonaPrimaryApplicantConsent) || 
                                                                        isCurrentStep(Step.PersonaPrimaryApplicant) ||
                                                                        isCurrentStep(Step.PersonaSecondaryApplicantConsent) || 
                                                                        isCurrentStep(Step.PersonaSecondaryApplicant) }" class="wizard-navigation-item wizard-navigation-item-persona">
                <div class="nav-list-item nav-section-title nav-section-title-persona">
                    <span class="nav-stepname">Persona ID Collection</span>
                    <span class="icon icon-check process-step-complete" *ngIf="                     
                    showPersonaCompleted()"></span>
                </div>
            </li>

            <!-- PRODUCTS -->
            <li [ngClass]="{ 'nav-section-active':isCurrentStep(Step.SelectProducts) || isCurrentStep(Step.CustomizeProducts) }" class="wizard-navigation-item wizard-navigation-item-products">
                <div class="nav-list-item nav-section-title nav-section-title-products">
                    <span class="nav-stepnumber">02</span><span class="nav-stepname">Products</span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.PersonaSecondaryApplicantConsent) || 
                        isCurrentStep(Step.PersonaSecondaryApplicant) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                </div>
                <ul *ngIf="!useNewUiForBank && (isCurrentStep(Step.SelectProducts) || isCurrentStep(Step.CustomizeProducts))" class="wizard-navigation-sublist">
                    <li [ngClass]="{ 'current-progress':isCurrentStep(Step.SelectProducts) }" class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.SelectProducts) }">
                            <span class="nav-stepname">Select Products</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.CustomizeProducts) ||
                                isCurrentStep(Step.PrimaryApplicantInformation) ||
                                isCurrentStep(Step.SecondaryApplicantInformation) ||
                                isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                                isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress':isCurrentStep(Step.CustomizeProducts) }" class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.CustomizeProducts) }">
                            <span class="nav-stepname">Customize Products</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.PrimaryApplicantInformation) ||
                                isCurrentStep(Step.SecondaryApplicantInformation) ||
                                isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                                isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                </ul>
            </li>

            <!-- PERSONAL INFO -->
            <li [ngClass]="{ 'nav-section-active':isCurrentStep(Step.PrimaryApplicantInformation) || isCurrentStep(Step.SecondaryApplicantInformation) || isCurrentStep(Step.VerifyPrimaryApplicantInformation) || isCurrentStep(Step.VerifySecondaryApplicantInformation) }" class="wizard-navigation-item wizard-navigation-item-info">
                <div class="nav-list-item nav-section-title nav-section-title-personalInfo"><span class="nav-stepnumber">
                    03</span><span class="nav-stepname">{{personalInfoLabel}}</span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                </div>
                <ul *ngIf="!useNewUiForBank && (isCurrentStep(Step.PrimaryApplicantInformation) || isCurrentStep(Step.SecondaryApplicantInformation) || isCurrentStep(Step.VerifyPrimaryApplicantInformation) || isCurrentStep(Step.VerifySecondaryApplicantInformation))" class="wizard-navigation-sublist">
                    <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PrimaryApplicantInformation) } " class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PrimaryApplicantInformation) }">
                            <span class="nav-stepname">{{personalInfoSubLabel}}</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.SecondaryApplicantInformation) ||
                                isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                                isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.VerifyPrimaryApplicantInformation) } " *ngIf="!sessionState.isSSO && !isBusinessSession" class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.VerifyPrimaryApplicantInformation) }">
                            <span class="nav-stepname">Verify Primary Information</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.SecondaryApplicantInformation) ||
                                isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.SecondaryApplicantInformation) }" *ngIf="hasSecondaryApplicant() && !isBusinessSession" class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.SecondaryApplicantInformation) }">
                            <span class="nav-stepname">Secondary Applicant Information</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.VerifySecondaryApplicantInformation) } " *ngIf="hasSecondaryApplicant() && !isBusinessSession" class="wizard-navigation-subitem">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.VerifySecondaryApplicantInformation) }">
                            <span class="nav-stepname">Verify Secondary Information</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.AcceptDisclosures) ||
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                </ul>
            </li>

            <!-- DISCLOSURES/FUNDING -->
            <li [ngClass]="{ 'nav-section-active':isCurrentStep(Step.AcceptDisclosures) || isCurrentStep(Step.FundingSource) || isCurrentStep(Step.DigitalUserVerification) }" class="wizard-navigation-item wizard-navigation-item-disclosuresfunding">
                <div class="nav-list-item nav-section-title nav-section-title-disclosures-funding"><span class="nav-stepnumber">04</span><span class="nav-stepname">Disclosures &amp; Funding</span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.Completion)"></span>
                </div>
                <ul *ngIf="!useNewUiForBank && (isCurrentStep(Step.AcceptDisclosures) || isCurrentStep(Step.FundingSource) || isCurrentStep(Step.DigitalUserVerification))" class="wizard-navigation-sublist">
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.AcceptDisclosures) } " class="wizard-navigation-subitem nav-item-inactive">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.AcceptDisclosures) }">
                            <span class="nav-stepname">Accept Disclosures</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.FundingSource) ||
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.FundingSource) } " class="wizard-navigation-subitem nav-item-inactive" *ngIf="showFundingStep">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.FundingSource) }">
                            <span class="nav-stepname">Funding Source</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.DigitalUserVerification) ||
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.DigitalUserVerification) } " class="wizard-navigation-subitem nav-item-inactive" *ngIf="showAccountAssociation">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.DigitalUserVerification) }">
                            <span class="nav-stepname">Account Association</span>
                            <span class="icon icon-check process-step-complete" *ngIf="
                                isCurrentStep(Step.Completion)"></span>
                        </div>
                    </li>
                </ul>
            </li>

            <!-- REVIEW/SUBMIT 
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.ReviewAndSubmit) } " class="wizard-navigation-item wizard-navigation-item-review">
                <div class="nav-list-item nav-section-title nav-section-title-review"><span class="nav-stepnumber">05</span><span class="nav-stepname">Review &amp; Submit</span></div>
            </li>
            -->

            <!-- COMPLETION -->
            <li *ngIf="!useNewUiForBank" [ngClass]="{ 'nav-section-active':isCurrentStep(Step.Completion) }" class="wizard-navigation-item wizard-navigation-item-complete">
                <!--isCurrentStep(Step.ConfirmationEmail) || isCurrentStep(Step.MicroDeposits) ||-->
                <div class="nav-list-item nav-section-title nav-section-title-confirmation"><span class="nav-stepnumber">05</span><span class="nav-stepname">Confirmation</span></div>
                <!--
                <ul *ngIf="isCurrentStep(Step.ConfirmationEmail) || isCurrentStep(Step.MicroDeposits) || isCurrentStep(Step.Completion)" class="wizard-navigation-sublist">
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.ConfirmationEmail) }" class="wizard-navigation-subitem nav-item-inactive">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.ConfirmationEmail) }">
                            Confirmation Email
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.MicroDeposits) }" class="wizard-navigation-subitem nav-item-inactive">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.MicroDeposits) }">
                            Micro Deposits
                        </div>
                    </li>
                    <li [ngClass]="{ 'current-progress': isCurrentStep(Step.Completion) }" class="wizard-navigation-subitem nav-item-inactive">
                        <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.Completion) }">
                            Completion
                        </div>
                    </li>
                </ul>
                -->
            </li>
        </ul>

        <!-- reskin: SUB-NAVS -->
        <!-- breaking into individual <ul> elements for more modularity -->
        <!-- PERSONA -->
        <ul *ngIf="useNewUiForBank &&   (isCurrentStep(Step.PersonaPrimaryApplicantConsent) || 
                                        isCurrentStep(Step.PersonaPrimaryApplicant) )" class="wizard-subnavigation-list">
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PersonaPrimaryApplicantConsent)}" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PersonaPrimaryApplicantConsent)}">
                    <span *ngIf="isCurrentStep(Step.PersonaPrimaryApplicantConsent)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PersonaPrimaryApplicant) ||
                        isCurrentStep(Step.PersonaSecondaryApplicant) ||
                        isCurrentStep(Step.PersonaSecondaryApplicantConsent)"></span>
                    <span class="nav-stepname">Primary Applicant ID Collection Consent</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PersonaPrimaryApplicant)}" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PersonaPrimaryApplicant)}">
                    <span *ngIf="isCurrentStep(Step.PersonaPrimaryApplicant)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion) ||
                        isCurrentStep(Step.PersonaSecondaryApplicant) ||
                        isCurrentStep(Step.PersonaSecondaryApplicantConsent)"></span>
                    <span class="nav-stepname">Primary Applicant ID Scanning</span>
                </div>
            </li>
        </ul>
        <ul *ngIf="useNewUiForBank &&   (isCurrentStep(Step.PersonaSecondaryApplicantConsent) ||
                                        isCurrentStep(Step.PersonaSecondaryApplicant) )" class="wizard-subnavigation-list">
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PersonaSecondaryApplicantConsent)}" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PersonaSecondaryApplicantConsent) }">
                    <span *ngIf="isCurrentStep(Step.PersonaSecondaryApplicantConsent)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PersonaSecondaryApplicant)"></span>
                    <span class="nav-stepname">Secondary Applicant ID Collection Consent</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PersonaSecondaryApplicant) }" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PersonaSecondaryApplicant)}">
                    <span *ngIf="isCurrentStep(Step.PersonaSecondaryApplicant)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Secondary Applicant ID Scanning</span>
                </div>
            </li>
        </ul>

        <!-- PRODUCTS -->
        <ul *ngIf="useNewUiForBank && (isCurrentStep(Step.SelectProducts) || isCurrentStep(Step.CustomizeProducts))" class="wizard-subnavigation-list">
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.SelectProducts) }" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.SelectProducts) }">
                    <span *ngIf="isCurrentStep(Step.SelectProducts)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.CustomizeProducts) ||
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Select Products</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.CustomizeProducts) }" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.CustomizeProducts) }">
                    <span *ngIf="isCurrentStep(Step.CustomizeProducts)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.PrimaryApplicantInformation) ||
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Customize Products</span>
                </div>
            </li>
        </ul>

        <!-- PERSONAL INFO -->
        <ul *ngIf="useNewUiForBank && (isCurrentStep(Step.PrimaryApplicantInformation) || isCurrentStep(Step.SecondaryApplicantInformation) || isCurrentStep(Step.VerifyPrimaryApplicantInformation) || isCurrentStep(Step.VerifySecondaryApplicantInformation))" class="wizard-subnavigation-list">
            <li [ngClass]="{ 'current-progress':isCurrentStep(Step.PrimaryApplicantInformation) } " class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.PrimaryApplicantInformation) }">
                    <span *ngIf="isCurrentStep(Step.PrimaryApplicantInformation)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifyPrimaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">{{personalInfoSubLabel}}</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.VerifyPrimaryApplicantInformation) } " *ngIf="!sessionState.isSSO && !isBusinessSession" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.VerifyPrimaryApplicantInformation) }">
                    <span *ngIf="isCurrentStep(Step.VerifyPrimaryApplicantInformation)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.SecondaryApplicantInformation) ||
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Verify Primary Information</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.SecondaryApplicantInformation) }" *ngIf="hasSecondaryApplicant() && !isBusinessSession" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.SecondaryApplicantInformation) }">
                    <span *ngIf="isCurrentStep(Step.SecondaryApplicantInformation)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.VerifySecondaryApplicantInformation) ||
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Secondary Applicant Information</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.VerifySecondaryApplicantInformation) } " *ngIf="hasSecondaryApplicant() && !isBusinessSession" class="wizard-navigation-subitem">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.VerifySecondaryApplicantInformation) }">
                    <span *ngIf="isCurrentStep(Step.VerifySecondaryApplicantInformation)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.AcceptDisclosures) ||
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Verify Secondary Information</span>
                </div>
            </li>
        </ul>

        <!-- DISCLOSURES/FUNDING -->
        <ul *ngIf="useNewUiForBank && (isCurrentStep(Step.AcceptDisclosures) || isCurrentStep(Step.FundingSource) || isCurrentStep(Step.DigitalUserVerification))" class="wizard-subnavigation-list">
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.AcceptDisclosures) } " class="wizard-navigation-subitem nav-item-inactive">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.AcceptDisclosures) }">
                    <span *ngIf="isCurrentStep(Step.AcceptDisclosures)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.FundingSource) ||
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Accept Disclosures</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.FundingSource) } " class="wizard-navigation-subitem nav-item-inactive" *ngIf="showFundingStep">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.FundingSource) }">
                    <span *ngIf="isCurrentStep(Step.FundingSource)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.DigitalUserVerification) ||
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Funding Source</span>
                </div>
            </li>
            <li [ngClass]="{ 'current-progress': isCurrentStep(Step.DigitalUserVerification) } " class="wizard-navigation-subitem nav-item-inactive" *ngIf="showAccountAssociation">
                <div class="nav-list-item" [ngClass]="{ 'nav-list-item-active':isCurrentStep(Step.DigitalUserVerification) }">
                    <span *ngIf="isCurrentStep(Step.DigitalUserVerification)" class="process-step-current"></span>
                    <span class="icon icon-check process-step-complete" *ngIf="
                        isCurrentStep(Step.Completion)"></span>
                    <span class="nav-stepname">Account Association</span>
                </div>
            </li>
        </ul>
        <!-- /reskin: SUB-NAVS -->
    </nav>
    <!-- /bottom: NAVIGATION -->

</section>