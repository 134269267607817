import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsSelectorComponent } from './products-selector.component';

import { OrderByModule } from '../../common/order-by-pipe.module';
import { ProductItemModule } from './product-item/product-item.module';
import { ProductBundleModule } from './product-bundle/product-bundle.module';

@NgModule({
    imports: [
        CommonModule,
        ProductItemModule,
        ProductBundleModule,
        OrderByModule
    ],
    declarations: [
        ProductsSelectorComponent
    ],
    exports: [
        ProductsSelectorComponent
    ]
})
export class ProductsSelectorModule { }
