import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacebookPixelComponent } from './facebook-pixel.component';

@NgModule({
    declarations: [
        FacebookPixelComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FacebookPixelComponent
    ]
})
export class FacebookPixelModule { }
