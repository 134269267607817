import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SSORoutingModule } from './sso-routing.module';
import { SSOComponent } from './sso.component';

@NgModule({
    declarations: [
        SSOComponent
    ],
    imports: [
        CommonModule,
        SSORoutingModule
    ]
})
export class SSOModule { }