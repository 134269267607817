import { NuFundAction } from 'src/app/common/nufund-action';
import { SessionState, ReturningUser } from '../state/session.state';

export const SessionActionTypes = {
    LOG_OUT: '[Session] LOG_OUT',
    LOG_OUT_SUCCESS: '[Session] LOG_OUT_SUCCESS',

    UPDATE_INSTANT_ADD_CODE: '[Session] UPDATE_INSTANT_ADD_CODE',

    ESTABLISH_SESSION: '[Session] ESTABLISH_SESSION',
    ESTABLISH_SESSION_SUCCESS: '[Session] ESTABLISH_SESSION_SUCCESS',
    ESTABLISH_SESSION_FAILURE: '[Session] ESTABLISH_SESSION_FAILURE',

    RETRIEVE_FORGOTTEN_CONFIRMATION_CODE: '[Session] RETRIEVE_FORGOTTEN_CONFIRMATION_CODE',
    RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_SUCCESS: '[Session] RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_SUCCESS',
    RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_FAILURE: '[Session] RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_FAILURE',

    RESUME_SESSION: '[Session] RESUME_SESSION',
    RESUME_SESSION_SUCCESS: '[Session] RESUME_SESSION_SUCCESS',

    ESTABLISH_SSO_SESSION: '[Session] ESTABLISH_SSO_SESSION',
    ESTABLISH_SSO_SESSION_SUCCESS: '[Session] ESTABLISH_SSO_SESSION_SUCCESS',

    DIGITAL_SIGN_ON: '[Session] DIGITAL_SIGN_ON',
    DIGITAL_SIGN_ON_SUCCESS: '[Session] DIGITAL_SIGN_ON_SUCCESS',
    DIGITAL_SIGN_ON_FAILURE: '[Session] DIGITAL_SIGN_ON_FAILURE',

    GET_DIGITAL_USER_EXISTS: '[Session] GET_DIGITAL_USER_EXISTS',
    GET_DIGITAL_USER_EXISTS_SUCCESS: '[Session] GET_DIGITAL_USER_EXISTS_SUCCESS',
    GET_DIGITAL_USER_EXISTS_FAILURE: '[Session] GET_DIGITAL_USER_EXISTS_FAILURE',

    SUBMIT_DIGITAL_CREDENTIALS: '[Session] SUBMIT_DIGITAL_CREDENTIALS',
    SUBMIT_DIGITAL_CREDENTIALS_SUCCESS: '[Session] SUBMIT_DIGITAL_CREDENTIALS_SUCCESS',
    SUBMIT_DIGITAL_CREDENTIALS_FAILURE: '[Session] SUBMIT_DIGITAL_CREDENTIALS_FAILURE',

    /** Makes a call to AO to apply business rules of the session
     * @sideeffect calls `SelectedProductsActionTypes.GET_SERVER_SELECTED_PRODUCTS`
     * @payload no response
     */
    APPLY_BUSINESS_RULES: '[Session] APPLY_BUSINESS_RULES',
    APPLY_BUSINESS_RULES_SUCCESS: '[Session] APPLY_BUSINESS_RULES_SUCCESS',
    APPLY_BUSINESS_RULES_FAILURE: '[Session] APPLY_BUSINESS_RULES_FAILURE',

    ADD_HISTORY_ENTRY: '[Session] ADD_HISTORY_ENTRY',
    ADD_HISTORY_ENTRY_SUCCESS: '[Session] ADD_HISTORY_ENTRY_SUCCESS',
    ADD_HISTORY_ENTRY_FAILURE: '[Session] ADD_HISTORY_ENTRY_FAILURE'
};

export class ResumeSessionSuccessAction implements NuFundAction {
    type = SessionActionTypes.RESUME_SESSION_SUCCESS;
    constructor(public payload: { resumeData: SessionState }) { }
}

export class EstablishSSOSessionSuccessAction implements NuFundAction {
    type = SessionActionTypes.ESTABLISH_SSO_SESSION_SUCCESS;
    constructor(public payload: { resumeData: SessionState }) { }
}

export class EstablishSessionSuccessAction implements NuFundAction {
    type = SessionActionTypes.ESTABLISH_SESSION_SUCCESS;
    constructor(public payload: ReturningUser) { }
}

export class EstablishSessionFailureAction implements NuFundAction {
    type = SessionActionTypes.ESTABLISH_SESSION_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class RetrieveForgottenConfirmationCodeFailureAction implements NuFundAction {
    type = SessionActionTypes.RETRIEVE_FORGOTTEN_CONFIRMATION_CODE_FAILURE;
    constructor(public payload: { error: any }) { }
}

export class AddHistoryEntryFailureAction implements NuFundAction {
    type = SessionActionTypes.ADD_HISTORY_ENTRY_FAILURE;
    constructor(public payload: { error: any }) { }
}