import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class BusinessValidators {
    static phoneNumber(control: AbstractControl): ValidationErrors {
        return control.value && control.value.match(/\d/g) && control.value.match(/\d/g).length === 10 ? null : {
            invalidEmail: 'Phone number is invalid.'
        };
    }

    static date(control: AbstractControl): ValidationErrors {
        return new Date(control.value) < new Date() || new Date(control.value) > new Date() ? null : {
            invalidDate: 'Invalid Date'
        };
    }

    static pastDate(control: AbstractControl): ValidationErrors {
        return new Date(control.value) < new Date() ? null : {
            invalidDate: 'Date must be in the past'
        };
    }

    static futureDate(control: AbstractControl): ValidationErrors {
        return new Date(control.value) > new Date() ? null : {
            invalidDate: 'Date must be in the future'
        };
    }

    static email(control: AbstractControl): ValidationErrors {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(control.value) ? null : {
            invalidEmail: 'Email address is invalid.'
        };
    }

    static valueMatches(otherControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => otherControl.value === control.value ? null : {
            valueMismatch: 'Values must match.'
        };
    }
}

export class TextMasks {
    public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    public readonly ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public readonly einMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    public readonly phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public readonly zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
}
