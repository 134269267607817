import { NuFundAction } from 'src/app/common/nufund-action';

export const ChallengeDepositActionTypes = {
    TRY_CHALLANGE: '[ChallengeDeposit] TRY_CHALLANGE',
    TRY_CHALLANGE_SUCCESS: '[ChallengeDeposit] TRY_CHALLANGE_SUCCESS',
    TRY_CHALLANGE_FAILURE: '[ChallengeDeposit] TRY_CHALLANGE_FAILURE'
};

export class TryChallengeFailureAction implements NuFundAction {
    type = ChallengeDepositActionTypes.TRY_CHALLANGE_FAILURE;
    constructor(public payload: { error: any }) { }
}
