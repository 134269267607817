import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewApplicantComponent } from './new-applicant.component';
import { ApplicantLayoutService } from './services/applicant-layout.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NewApplicantRoutingModule } from './new-applicant-routing.module';
import { ApplicantFormComponent } from './components/applicant-form/applicant-form.component';
import { PageHeaderModule } from 'src/app/page-header/page-header.module';
import { PageFooterModule } from 'src/app/page-footer/page-footer.module';
import { LoadingModule } from 'src/app/loading/loading.module';
import { ProgressModule } from 'src/app/progress/progress.module';

@NgModule({
  imports: [
    CommonModule, ReactiveFormsModule,
    TextMaskModule,
    NewApplicantRoutingModule,
    FormsModule,
    ProgressModule,
    PageHeaderModule,
    PageFooterModule,
    LoadingModule
  ],
  declarations: [NewApplicantComponent, ApplicantFormComponent],
  providers: [
    ApplicantLayoutService
  ]
})
export class NewApplicantModule { }
