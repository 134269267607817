import { Component, ViewEncapsulation } from '@angular/core';

import { ModalService } from './modal/modal.service';

import { Store } from '@ngrx/store';
import { InstitutionState } from './state-management/state/institution.state';
import { InstitutionActionTypes } from './state-management/actions/institution.actions';
import { ScriptInject } from './common/script-injection';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { CombinedState } from './state-management/state/combined.state';
import { CssService } from './services/css.service';

@Component({
    moduleId: module.id,
    selector: 'app-root',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public isLoading = true;
    alphaRankLoaded = false;
    googleAnalyticsLoaded = false;
    revationURL = '';
    baseURLRegExp = /^(https?:\/\/[^/]+\/)/;
    groupRegExp = /group=([^&]+)/;
    personaRegExp = /rev-dst-pid=([^&]+)/;
    useNewUiForBank = false;

    constructor(private store: Store<CombinedState>,
                private modalService: ModalService<void>,
                router: Router,
                private cssService: CssService,
    ) {
        store.dispatch({ type: InstitutionActionTypes.GET_INSTITUTION });
        this.cssService.getCss().subscribe(res => {});

        store.select('institutionStoreReducer').subscribe((info: InstitutionState) => {
            if (info.isError) {
                this.handleLoadingInstitutionError(info.error);
            } else {
                this.useNewUiForBank = info.institution.useNewUiForBank;
                this.isLoading = info.isLoading;
                // they are using revation but we haven't checked to see if anyone is online yet
                if (info.institution.revationURL && info.institution.revationStatus === null) {
                    // parse the parts of the revation URL we need to get their online status
                    const baseURL = this.baseURLRegExp.exec(info.institution.revationURL)[1];
                    const group = this.groupRegExp.exec(info.institution.revationURL)[1];
                    const persona = this.personaRegExp.exec(info.institution.revationURL)[1];
                    const revationStatusURL = `${baseURL}status/json/?im=${persona}&group=${group}`;

                    // check if they are online
                    store.dispatch({ type: InstitutionActionTypes.GET_REVATION_STATUS, payload: { revationStatusURL } });
                } else if (info.institution.revationURL && info.institution.revationStatus.status === 'online') {
                    // if they are onilne then set the URL so the component loads
                    this.revationURL = info.institution.revationURL;
                }

                // Alpha Rank pixel wrapper
                if (!this.alphaRankLoaded && info.institution.alphaRankId !== '') {
                    this.alphaRankLoaded = true;
                    ScriptInject.injectScript(environment.alphaRankBaseURL + info.institution.alphaRankId);
                }

                // Google Analytics integration
                if (!this.googleAnalyticsLoaded && info.institution.googleTagId !== '') {
                    this.googleAnalyticsLoaded = true;
                    ScriptInject.injectScript(environment.googleAnalyticsBaseURL + info.institution.googleTagId);

                    ScriptInject.injectScriptCode('\
                        window.dataLayer = window.dataLayer || [];\
                        function gtag() { window.dataLayer.push(arguments); }\
                        gtag(\'js\', new Date());\
                        gtag(\'config\', \'' + info.institution.googleTagId + '\');\
                    ', false);                    
                }
                //Google Tag Manager Code
                if (info.institution.googleTagManagerId !== '') {
                    ScriptInject.injectScript('https://www.googletagmanager.com/ns.html?id=' + info.institution.googleTagManagerId);
                    ScriptInject.injectScriptCode('\
                       (function (w, d, s, l, i) {\
                           w[l] = w[l] || []; w[l].push({ \'gtm.start\':\
                           new Date().getTime(), event: \'gtm.js\'\
                           }); var f = d.getElementsByTagName(s)[0],\
                           j = d.createElement(s), dl = l != \'dataLayer\' ? \'&l=\' + l : \'\'; j.async = true; j.src =\
                           \'https://www.googletagmanager.com/gtm.js?id=\' + i + dl; f.parentNode.insertBefore(j, f);\
                           })(window, document, \'script\', \'dataLayer\', \'' + info.institution.googleTagManagerId + '\'); ');
                }
            }
        });
    }

    public handleLoadingInstitutionError(error: any) {
        this.modalService.presentError(error, null, 'Error', 'Retry').subscribe(null, null, () => {
            this.store.dispatch({ type: InstitutionActionTypes.GET_INSTITUTION });
        });
    }
}
