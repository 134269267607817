import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ChallengeDepositRoutingModule } from './challenge-deposit-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';

import { ChallengeDepositComponent } from './challenge-deposit.component';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';

@NgModule({
    declarations: [
        ChallengeDepositComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ChallengeDepositRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        FacebookPixelModule
    ]
})
export class ChallengeDepositModule { }
