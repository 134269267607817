import { Applicant } from "../state-management/state/applicants.state";

export interface IdvRequest {
    /** * Applicant that we are sending to WatchdogIDV service to authenticate. You can only send one applicant at a time. */
    applicant: Applicant;
    /** The field RCG uses to know which app made the IDV request */
    clientReferenceId: string;
    /** The Guid that RCG uses to track the session through the Watchdog IDV Process. Will come back truthy if there were no errors in the initial request. Set by API. */
    idvSessionId: string;
    /** An array of the indexes the choose for their kiq answers. Maps to `KIQQuestion.questionChoices.key`. */
    kiqAnswers: number[];
    /** The matching property in the app would be `InstitutionState.Institution.InstitutionName`. Needs to be trimmed to be length `30 - {rcg.bankname.append}` */
    bankName: string;
    /** A string of 6 characters which will be authenticated on the API. They enter this information on the `otp.component`. */
    oneTimePasscode: string;
}

export interface IdvBusinessRequest {
    /** The business on the application. This field is ignored on a `WatchDogIDV/PrincipalIDScreening` call. */
    businessApplicant: Applicant;
    /** The signers on the applicantion */
    applicants: Applicant[];
    /** The field IDV uses to know which app made the IDV request */
    clientReferenceId: string;
    /** The Guid that RCG uses to track the session through the Watchdog IDV Process. Will come back truthy if there were no errors in the initial request. Set by API. */
    idvSessionId: string;
    /** The matching property in the app would be `InstitutionState.Institution.InstitutionName`. Needs to be trimmed to be length `30 - {rcg.bankname.append}` */
    bankName: string;
    /** A form of auditing for RCG */
    ipAddress: string;
}
export interface IdvResponse {
    /** HTTP Status Code. Will be > 400 if there was a server error */
    statusCode: number // TODO: what is this?
    /** Will be true if the next request should be OTP */
    otpRequest: boolean;
    /** KIQ Questions for Step-Up. If these are truthy then the user has successfully submitted a valid OTP*/
    kiqQuestions: KiqQuestion[];
    /** The field IDV uses to know which app made the IDV request. Will come back truthy if there were no errors in the initial request. */
    clientReferenceId: string;
    /** The Guid that RCG uses to track the session through the Watchdog IDV Process. Will come back truthy if there were no errors in the initial request. */
    sessionId: string;
    /** The score that Watchdog IDV returns to us. Will pass according to specific business rules. */
    score: number;
    /**
     * Describes the the result RCG returns from their request. Usually a duplicate of the status code. The following list is not exhaustive.
     * @ACCEPT The request was good and continue to disclosures. A positive response.
     * @REFER The request was rejected. A negative response.
     * @ERROR There was an error in building the request, either on the front end or the back end.
     * @Alert This list is not exhaustive!
     */
    exceptionMessage: string;
}

export interface KiqQuestion {
    /** The question text. Used for HTML. */
    questionText: string;
    /** 
     * The KIQ question choices. We send this over as a map to preserve the order since it matters. 
     * @number The index of the question as it were when API gets it. 
     * @string The answer text. Just used for displaying in the HTML.
     */
    questionChoices: KiqAnswer;
}
/**
 * The key will be the index. The string is the question text. The object will look like:
 * ```
 * {
 *   1: 'First Answer',
 *   2: 'Second Answer',
 *   ...
 *   5: 'Fifth Answer'
 * }
 * ```
 */
export interface KiqAnswer {

    [key: number]: string
}


export enum IdvStrategy {
    IdScreening = 0,
    IdScreeningWithStepUp = 1,
}
