import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicantsState, Applicant } from '../state-management/state/applicants.state';
import { ProgressState, Step } from '../state-management/state/progress.state';
import { InstitutionState, Institution } from '../state-management/state/institution.state';
import { ModalService } from '../modal/modal.service';
import { Router } from '@angular/router';
import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ApplicantsActionsTypes } from '../state-management/actions/applicants.actions';
import { Subject, Observable } from 'rxjs';
import { SessionState } from '../state-management/state/session.state';
import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';
import copy from "fast-copy";
import { getIpAddressAsync, isIdvFlow, trimPhoneNumber, truncateInstitutionName } from '../common/functions';
import { IdvBusinessRequest } from '../models/idv.model';
@Component({
    selector: 'app-business-information',
    templateUrl: './business-information.component.html',
    styleUrls: ['./business-information.component.scss']
})
export class BusinessInformationComponent {
    pageName = 'personalInformation';
    isLoadingApplicants = false;
    applicants: Applicant[];
    currentStep: Step;
    unsubscribe: Subject<any> = new Subject<any>();
    institution: Institution = null;
    dataConcat$: Observable<any>;
    isPanelVisible = false;
    confirmationCode = '';
    continueSession = true;
    useNewUiForBank = false;
    isApplicationValid = false;

    constructor(
        private store: Store<CombinedState>,
        private modalService: ModalService<boolean>,
        private router: Router
    ) {

        this.isLoadingApplicants = true;
        this.store.dispatch({ type: ApplicantsActionsTypes.DEFAULT_BUSINESS_APPLICANTS });

        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ProgressState) => {
            this.currentStep = info.currentStep;
        });

        this.store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: ApplicantsState) => {
            // if the currentStep isn't up to this point, point it there
            if (
                this.currentStep < Step.VerifyPrimaryApplicantInformation &&
                this.currentStep !== Step.SignUp && this.currentStep !== Step.PrimaryApplicantInformation
            ) {
                this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: Step.PrimaryApplicantInformation });
                // if we are past this step, move on to the next one
            } else if (this.currentStep >= Step.AcceptDisclosures || info.applicants[0].submitted && this.continueSession) {
                this.router.navigate(['disclosures']);
            }

            // only bother looking at the business applicant for errors, it should be duplicated all around since these are handled in batch
            if (info.applicants[0].isError) {
                if (info.canContinue) {
                    this.modalService.presentError(
                        info.applicants[0].error, true, 'Error saving information', 'Ok'
                    ).pipe(takeUntil(this.unsubscribe)).subscribe(null, null, () => { });
                } else {
                    if (info.applicants[0].error.status !== 401) {
                        this.router.navigate([
                            'home',
                            'This application is unable to be completed online.  Contact us for more information.'
                        ]);
                    } else {
                        // the 401 coming back from saving the applicant
                        this.router.navigate(['signout', this.confirmationCode]);
                    }
                }
            }
            this.applicants = null;
            this.applicants = copy(info.applicants);
            this.isLoadingApplicants = this.applicants[0].isLoading;
            this.updateApplicationValid();
        });

        this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
            this.institution = info.institution;
            this.useNewUiForBank = info.institution.useNewUiForBank;
        });

        this.store.select('sessionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: SessionState) => {
            if (info.confirmationCode !== '') {
                this.confirmationCode = info.confirmationCode;
            }
        });
    }

    ngDoCheck() {
        // this.updateApplicationValid();
    }

    onAutofillClick() {

        if (isIdvFlow(this.institution)) {
            this.store.dispatch( {type: ApplicantsActionsTypes.LOAD_DEMO_EXPERIANBUSINESS})
        } else {
            this.store.dispatch({ type: ApplicantsActionsTypes.LOAD_DEMO_BUSINESS, payload: this.institution });
        }
        return false;
    }

    addSigner() {
        this.store.dispatch({ type: ApplicantsActionsTypes.ADD_BUSINESS_APPLICANT });
    }

    get showAdminAutoFill() {
        return this.institution.allowAutoFill;
    }

    async submitApplicants(continueSession: boolean) {
        this.continueSession = continueSession;
        this.isLoadingApplicants = true;

        this.indexApplicants();
        if (isIdvFlow(this.institution)) {
            const signers = this.applicants.slice(1).map(s => {
                return {...s, phoneNumber: trimPhoneNumber(s.phoneNumber)}
            })
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_BUSINESS_IDV,
                payload: <IdvBusinessRequest> {
                    businessApplicant: this.applicants[0],
                    applicants: signers,
                    clientReferenceId: null,
                    idvSessionId: null,
                    bankName: truncateInstitutionName(this.institution.institutionName),
                    ipAddress: await getIpAddressAsync()
                }
            });
        } else {
            
            this.store.dispatch({
                type: ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS,
                payload: this.applicants,
                continueSession
            });

            return

        }
        
    }

    get invalidErrorMessage() {
        if (!this.applicants[0].completeOnServer && !this.applicants[0].isValid) {
            return 'The business information entered is incomplete or invalid.  It is still possible to continue\
             this session later, but some of the details may not be saved.';
        } else if (!this.isApplicationValid && !this.isApplictionCompletelySaved) {
            return 'One of the signer\'s information entered incomplete or invalid.  It is still possible to continue\
             this session later, but some of the details may not be saved.';
        }
        return '';
    }

    private presentLogOutErrorModal(errorMessage: string) {
        this.modalService.openModal(
            'Confirm sign out',
            errorMessage,
            [
                {
                    label: 'Sign out',
                    result: true,
                    closesModal: true,
                    class: 'modal-button'
                },
                {
                    label: 'Cancel',
                    result: false,
                    closesModal: true,
                    class: 'modal-error-button'
                }
            ], 'modal-alert').pipe(takeUntil(this.unsubscribe)).subscribe(buttonResult => {
                if (!this.applicants[0].completeOnServer && !this.applicants[0].isValid) {
                    // the business isn't valid so we can't save anything
                    this.router.navigate(['signout', this.confirmationCode]);
                } else {
                    // at least some applicants may be valid
                    this.removeInvalidApplicants();
                    this.submitApplicants(false);
                }
            }
            );
    }

    handleSignOutClick() {
        const errorMessage = this.invalidErrorMessage;
        if (errorMessage !== '') {
            this.presentLogOutErrorModal(errorMessage);
        } else {
            // all applicants are valid
            this.submitApplicants(false);
        }
    }

    private removeInvalidApplicants() {
        this.applicants = this.applicants.filter(applicant => {
            return applicant.isValid || applicant.completeOnServer;
        });
    }

    handleApplicantValidationChange(index: number, isValid: boolean) {
        this.applicants[index].isValid = isValid;
        setTimeout(() => {
            this.updateApplicationValid();
        })
        // this.updateApplicationValid();
    }


    updateApplicationValid() {
        this.isApplicationValid = this.applicants.every((applicant, index) => applicant.isValid || applicant.id && applicant.completeOnServer);
    }


    get isApplictionCompletelySaved() {
        return this.applicants.every(applicant => applicant.completeOnServer);
    }

    deleteApplicant(applicant: Applicant) {
        this.indexApplicants();
        this.store.dispatch({
            type: ApplicantsActionsTypes.REMOVE_APPLICANT,
            payload: this.applicants[applicant.index]
        });
    }

    private indexApplicants() {
        this.applicants.forEach((applicant, index) => {
            applicant.index = index;
            return applicant;
        });
    }
}
