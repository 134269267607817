<!-- error -->
<div *ngIf="isInvalid">
  <div class="indicator-error"></div>
  <div *ngIf="firstError" class="alert alert-danger">
    {{firstError}}
  </div>
</div>
<!-- /error -->

<!-- valid -->
<div *ngIf="isIndicatedValid" class="indicator-valid">
    <div class="icon icon-check"></div>
</div>
<!-- /valid -->