<main>
    <!-- header -->
    <section class="page-header-content">
        <div class="xl-container">
            <page-header [showText]="!validationSuccessful" [pageName]="pageName"></page-header>
        </div>
    </section>
    <!-- /header -->

    <!-- body content -->
    <section class="page-body-content">
        <!-- xl container -->
        <div class="xl-container">
            <section class="page-body-challenge">
                <!-- challenge form -->
                <form *ngIf="!validationSuccessful" class="challenge-form">

                    <!-- confirmation code -->
                    <div class="challenge-form-container">
                        <div class="form-items challenge-form-confirmationcode">
                            <label for="confirmationCode" class="required">
                                Confirmation Code
                            </label>
                            <input type="text" [readonly]="confirmationCodePrefilled" name="confirmationCode" id="confirmationCode" [(ngModel)]="challengeDepositAttempt.confirmationCode"
                                class="form-input form-size-lg challenge-form-code" />
                        </div>
                    </div>
                    <!-- /confirmation code -->

                    <!-- deposit amounts -->
                    <div class="challenge-form-container challenge-form-amounts">
                        <div class="form-items challenge-form-amount-1">
                            <label for="challengeDepositAmount1" class="required">
                                Deposit 1 Amount
                            </label>
                            <!-- user is allowed to enter only 2 numeric characters with decimal -->
                            <input type="number" name="challengeDepositAmount1" id="challengeDepositAmount1" [(ngModel)]="challengeDepositAttempt.amount1"
                            (blur)="formatAmount($event, 'challengeDepositAmount1')"
                            (keydown)="formatAmount($event, 'challengeDepositAmount1')"
                            (input)="limitDigits($event)"
                            class="form-input"/>
                        </div>
                        <div class="form-items challenge-form-amount-2">
                            <label for="challengeDepositAmount2" class="required">
                                Deposit 2 Amount
                            </label>
                            <input type="number" name="challengeDepositAmount2" id="challengeDepositAmount2" [(ngModel)]="challengeDepositAttempt.amount2"
                            (input)="limitDigits($event)"
                            (blur)="formatAmount($event, 'challengeDepositAmount2')"
                            (keydown)="formatAmount($event, 'challengeDepositAmount2')"
                            class="form-input" />
                        </div>
                    </div>
                    <!-- /deposit amounts -->

                    <!-- submit/notification -->
                    <div class="challenge-form-container">
                        <div class="challenge-form-buttons">
                            <div class="challenge-form-button">
                                <button (click)="submitChallengeDeposit()" [disabled]="!canSubmit()" class="challenge-form-submit">Submit</button>
                            </div>
                        </div>
                    </div>
                    <!-- /submit/notification -->
                </form>
                <!-- /challenge form -->

                <!-- challenge success -->
                <div *ngIf="validationSuccessful" class="challenge-success">
                    <csi-facebook-pixel *ngIf="pixelId" [init]="true" [pixelId]="pixelId" [pageEventName]="pageEventName"></csi-facebook-pixel>
                    <img src="./assets/images/success-coin@2x.png" alt="Success!" class="success-image">
                    <h2 class="success-title">Success!</h2>
                    <div class="success-text">
                        <p>The amounts entered are correct. In the next few business days, upon final approval, we will make a withdrawal from this account to fund your new account(s).</p>
                        <p class="success-text-thanks">Thank you!</p>
                    </div>
                </div>
                <!-- /challenge success -->
            </section>

            <page-footer [showText]="!validationSuccessful" [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>
    <!-- /body content -->
</main>