import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import {
    ApplicantsState, initialApplicantsState, demoApplicant, defaultApplicant, Applicant, demoBusiness, demoExperianBusiness, demoExperianApplicant, demoExperianBusinessSigner
} from '../state/applicants.state';
import { ApplicantsActionsTypes } from '../actions/applicants.actions';
import { SessionActionTypes } from '../actions/session.actions';
import { NuFundAction } from 'src/app/common/nufund-action';
import { Institution, ApplicationQuestion } from '../state/institution.state';
import { IdvResponse } from 'src/app/models/idv.model';

export const applicantsStoreReducer: ActionReducer<ApplicantsState> = reducer;

export function reducer(state = initialApplicantsState, action: NuFundAction) {

    switch (action.type) {
        case ApplicantsActionsTypes.DEFAULT_BUSINESS_APPLICANTS: {

            const newState = Object.assign({}, state);
            newState.applicants[0] = Object.assign(
                {},
                Object.assign({}, defaultApplicant),
                newState.applicants[0],
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true,  autoFill: false }
            );
            newState.applicants[1] = Object.assign(
                {},
                Object.assign({}, defaultApplicant),
                newState.applicants[1],
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true,  autoFill: false }
            );
            return newState;
        }
        case ApplicantsActionsTypes.ADD_BUSINESS_APPLICANT: {

            const newState = Object.assign({}, state);
            newState.applicants.push(Object.assign(
                {},
                Object.assign({}, defaultApplicant),
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true }
            ));

            // re-index applicants
            newState.applicants.forEach((applicant, index) => applicant.index = index);
            return newState;
        }

        case ApplicantsActionsTypes.LOAD_DEMO_BUSINESS: {

            // we have to preserve the origional ID since the server expects the session to be "real"
            const origionalId = state.applicants[0].id;
            const newState = Object.assign({}, state);
            // the business
            newState.applicants[0] = Object.assign({}, demoBusiness, { id: origionalId });

            newState.applicants[0].applicationQuestions[0] =
                assignQuestion('Anderson', newState.applicants[0], 0, action.payload);

            newState.applicants[0].applicationQuestions[1] =
                assignQuestion('Anderson', newState.applicants[0], 1, action.payload);

            newState.applicants[0].applicationQuestions[2] =
                assignQuestion('Anderson', newState.applicants[0], 2, action.payload);

            // one signer
            newState.applicants[1] = Object.assign(
                {},
                demoApplicant,
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true }
            );
            return newState;
        }
        case ApplicantsActionsTypes.LOAD_DEMO_EXPERIANBUSINESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            // we have to preserve the origional ID since the server expects the session to be "real"
            const origionalId = state.applicants[0].id;

            const newState = Object.assign({}, state);

            newState.applicants[0] = Object.assign({}, demoExperianBusiness, { id: origionalId });


            // one signer
            newState.applicants[1] = Object.assign(
                {},
                demoExperianBusinessSigner,
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true }
            );
            return newState;
        }

        case ApplicantsActionsTypes.LOAD_DEMO_EXPERIANAPPLICANT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            // we have to preserve the origional ID since the server expects the session to be "real"
            const origionalId = state.applicants[state.currentApplicantIndex].id;
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign({}, demoExperianApplicant, { id: origionalId }, {autoFill:true});

            return newState;
        }

        case ApplicantsActionsTypes.LOAD_DEMO_APPLICANT: {

            // we have to preserve the origional ID since the server expects the session to be "real"
            const origionalId = state.applicants[state.currentApplicantIndex].id;
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign({}, demoApplicant, { id: origionalId }, {autoFill: true});

            newState.applicants[newState.currentApplicantIndex].applicationQuestions[0] =
                assignQuestion('Anderson', newState.applicants[newState.currentApplicantIndex], 0, action.payload);

            newState.applicants[newState.currentApplicantIndex].applicationQuestions[1] =
                assignQuestion('Anderson', newState.applicants[newState.currentApplicantIndex], 1, action.payload);

            newState.applicants[newState.currentApplicantIndex].applicationQuestions[2] =
                assignQuestion('Anderson', newState.applicants[newState.currentApplicantIndex], 2, action.payload);

            return newState;
        }

        case ApplicantsActionsTypes.LOAD_DEMO_EXPERIANAPPLICANT: {

            // we have to preserve the origional ID since the server expects the session to be "real"
            const originalId = state.applicants[state.currentApplicantIndex].id;
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign({}, demoExperianApplicant, { id: originalId });


            return newState;
        }

        case ApplicantsActionsTypes.TOGGLE_ID_FILL: {

            const newState = Object.assign({}, state);
            newState.applicants[state.currentApplicantIndex].showIdFill = !newState.applicants[state.currentApplicantIndex].showIdFill;

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT: {

            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign({}, newState.applicants[newState.currentApplicantIndex],
                { isLoading: true, isError: false, error: null, idResultsFilled: false,  autoFill:false });

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT_SUCCESS: {

            const newState = Object.assign({ }, state);
            const oldZip = newState.applicants[newState.currentApplicantIndex].zipCode;

            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                action.payload,
                {
                    isLoading: false, isError: false, error: null, idResultsFilled: true, autoFill:false
                }
            );

            // don't overwrite the zip code of the primary applicant;
            if (newState.currentApplicantIndex === 0) {
                newState.applicants[newState.currentApplicantIndex].zipCode = oldZip;
            }

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_IDENTIFICATION_DOCUMENT_FAILURE: {
          const newState = Object.assign({}, state);

            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: false, isError: true, error: action.payload,
                    idResultsFilled: false || newState.applicants[newState.currentApplicantIndex].idResultsFilled,
                    autoFill:false
                }
            );

            return newState;
        }

        case ApplicantsActionsTypes.UPDATE_CURRENT_APPLICANT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const applicantIndex = initialApplicantsState.currentApplicantIndex ?? 0;
            const newState = Object.assign({}, state);
            newState.applicants[applicantIndex] = Object.assign({}, initialApplicantsState.applicants[applicantIndex], action.payload);
        
            return newState;
        }

        case ApplicantsActionsTypes.ADD_APPLICANT: {

            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                {},
                newState.applicants[newState.currentApplicantIndex],
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true , autoFill: false,idResultsFilled:false }
            );

            return newState;
        }

        case ApplicantsActionsTypes.ADD_APPLICANT_SUCCESS: {
            // this is how the reducer methods should look.
            return {
                ...state,
                applicants: [

                    ...state.applicants, // current state
                    {
                        ...defaultApplicant, // first assign the default applicant
                        ...action.payload, // the payload is an applicant with an id and defaults
                        applicationQuestions: [], // the rest is misc
                        isLoading: false,
                        isError: false,
                        error: null,
                        isVerified: false,
                        isAddingApplicant: false,
                    },
                ].map((applicant, index) => index === state.currentApplicantIndex ? {...applicant, isAddingApplicant: false, autoFill:false, idResultsFilled:false} : applicant),
            };
        }

        case SessionActionTypes.RESUME_SESSION_SUCCESS: {

            action.payload.resumeData.totalApplicants = action.payload.resumeData.applicants.length;
            action.payload.resumeData.totalVerifiedApplicants =
                action.payload.resumeData.applicants.filter(applicant => applicant.isVerified).length;

            const newState = Object.assign({}, state);
            if (!action.payload.resumeData.isBusiness) {
                state.applicants[0] = Object.assign({}, state.applicants[0], action.payload.resumeData.applicants[0]);

                if (action.payload.resumeData.totalApplicants > state.applicants.length) {
                    newState.applicants.push(Object.assign({}, defaultApplicant, action.payload.resumeData.applicants[1]));
                }

                if (action.payload.resumeData.totalApplicants === 1 || action.payload.resumeData.totalVerifiedApplicants === 0) {
                    newState.currentApplicantIndex = 0;
                } else {
                    newState.currentApplicantIndex = 1;
                }
            } else {
                newState.currentApplicantIndex = 0;
                action.payload.resumeData.applicants.forEach((applicant: Applicant, index, applicants: Applicant[]) => {
                    // if address1 comes back on resume, the user submitted a complete applicant previously and it's on the server
                    if (applicants[index].address1) {
                        applicants[index].completeOnServer = true;
                    }
                    newState.applicants[index] = Object.assign({ }, applicants[index]);
                });
            }

            // re-index applicants
            newState.applicants.forEach((applicant, index) => applicant.index = index);

            return newState;
        }

        case ApplicantsActionsTypes.REMOVE_APPLICANT: {

            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                {},
                newState.applicants[newState.currentApplicantIndex],
                { isLoading: false, isError: false, error: null, isVerified: false, isAddingApplicant: true,  autoFill: false, idResultsFilled:false }
            );

            return newState;
        }

        case ApplicantsActionsTypes.REMOVE_APPLICANT_SUCCESS: {

            const newState = Object.assign({}, state);
            newState.applicants.splice(action.payload.index, 1);
            newState.currentApplicantIndex = 0;

            newState.applicants.forEach((applicant, index, applicants) => applicants[index] = Object.assign(
                { },
                applicant,
                {
                    isLoading: false,
                    isError: false,
                    error: null,
                    isVerified: false,
                    isAddingApplicant: false,
                    autoFill: false,
                    idResultsFilled:false,
                    lastDeletedApplicantId: action.payload.id
                })
            );

            return newState;
        }

        case ApplicantsActionsTypes.SET_CURRENT_APPLICANT: {

            return Object.assign({}, state, { currentApplicantIndex: action.payload });
        }

        case ApplicantsActionsTypes.SUBMIT_APPLICANT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: true, isError: false, error: null, isVerified: false,  autoFill: false, idResultsFilled:false
                }
            );

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_APPLICANT_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: false, isError: false, error: null, isVerified: true,  autoFill: false, idResultsFilled:false
                }
            );

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_APPLICANT_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: false, isError: true, error: action.payload, isVerified: false,  autoFill: false, idResultsFilled:false
                }
            );
            newState.canContinue = action.payload.status !== 401;

            return newState;
        }

        case SessionActionTypes.LOG_OUT: {

            const newState = Object.assign({}, initialApplicantsState);
            while (newState.applicants.length > 1) {
                newState.applicants.splice(1);
            }

            newState.applicants.length = 0;
            newState.applicants.push(Object.assign({}, defaultApplicant));

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS: {

            const newState = Object.assign({ }, state);
            newState.applicants.forEach((applicant, index, array) => {
                array[index] = Object.assign({ }, applicant, { isLoading: true, isError: false, error: null, isVerified: false,  autoFill: false });
            });

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS_SUCCESS: {

            const newState = Object.assign({}, state, {applicants: action.payload});

            newState.applicants.forEach((applicant, index, array) => {
                array[index] = Object.assign(
                    { },
                    applicant,
                    { isLoading: false, isError: false, error: null, isVerified: false, submitted: true });
            });

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_BUSINESS_APPLICANTS_FAILURE: {

            const newState = Object.assign({}, state);
            newState.applicants.forEach((applicant, index, array) => {
                array[index] = Object.assign({ }, applicant, { isLoading: false, isError: true, error: action.payload, isVerified: false ,  autoFill: false});
            });
            newState.canContinue = action.payload.status !== 401;

            return newState;
        }

        case ApplicantsActionsTypes.SUBMIT_OTP_CODE_SUCCESS: {
            const idvResponse = (<IdvResponse>action.payload).statusCode >= 400
                ? state.idvResponse
                : action.payload;

            return Object.assign({}, state, {idvResponse: idvResponse})
        }
        case ApplicantsActionsTypes.SUBMIT_IDV_FLOW_SUCCESS:
        case ApplicantsActionsTypes.SUBMIT_IDV_FLOW_WITH_STEP_UP_SUCCESS:
        case ApplicantsActionsTypes.DID_NOT_RECEIVE_CODE_SUCCESS:
        case ApplicantsActionsTypes.CAN_NOT_RECEIVE_CODE_SUCCESS:
        case ApplicantsActionsTypes.SUBMIT_KIQ_QUESTIONS_SUCCESS: {
            return Object.assign({}, state, {idvResponse: action.payload});
        }
        case ApplicantsActionsTypes.SAVE_APPLICANT: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: true, isError: false, error: null, isVerified: false,  autoFill: false, idResultsFilled:false
                }
            );

            return newState;
        }

        case ApplicantsActionsTypes.SAVE_APPLICANT_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: false, isError: false, error: null, isVerified: false,  autoFill: false, idResultsFilled:false
                }
            );

            return newState;
        }

        case ApplicantsActionsTypes.SAVE_APPLICANT_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }
            const newState = Object.assign({}, state);
            newState.applicants[newState.currentApplicantIndex] = Object.assign(
                { },
                newState.applicants[newState.currentApplicantIndex],
                {
                    isLoading: false, isError: true, error: action.payload, isVerified: false,  autoFill: false, idResultsFilled:false
                }
            );
            newState.canContinue = action.payload.status !== 401;

            return newState;
        }
        default: {
            return state;
        }
    }
}

function assignQuestion(answer: string, applicant: Applicant, questionIndex: number, institution: Institution) {
    let question: ApplicationQuestion;
    question = Object.assign(
        { },
        institution.applicationQuestions[questionIndex]
    );
    question.answerText = answer;
    if (question.useTranslate) {
        const randomAnswer = institution.securityQuestions[Math.floor(Math.random() * institution.securityQuestions.length)];
        question.questionText = randomAnswer.questionText;
        question.fieldValue = randomAnswer.fieldValue;
        question.securityQuestion = randomAnswer;
    }

    return question;
}
