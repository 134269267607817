import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import {
    OutOfWalletActionTypes, GetOutOfWalletSuccessAction, GetOutOfWalletFailureAction, ScoreOutOfWalletFailureAction
} from '../actions/out-of-wallet.actions';
import { OutOfWalletService } from '../../services/out-of-wallet.service';
import { Level3Question } from '../state/out-of-wallet.state';

import { Router } from '@angular/router';
import { Applicant } from '../state/applicants.state';
import { NuFundAction } from 'src/app/common/nufund-action';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class OutOfWalletEffects {

    @Effect()
    appGetOutOfWalletQuestions$ = this.action$.pipe(
        ofType(OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS),
        switchMap((action: NuFundAction) => {
            return this.service.getOutOfWalletQuestions(action.payload as Applicant).pipe(
                map((info: Level3Question[]) => {
                    return new GetOutOfWalletSuccessAction({ questions: info });
                }),
                catchError((response: any) => {
                    return of(new GetOutOfWalletFailureAction({ error: response }));
                })
            )
        })
    )

    @Effect()
    appScoreGetOutOfWalletQuestions$ = this.action$.pipe(
        ofType(OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS),
        switchMap((action: NuFundAction) => {
            return this.service.scoreOutOfWalletAnswers(action.payload.answers, action.payload.applicant).pipe(
                map(() => {
                    
                    return { type: OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS_SUCCESS };
                }),
                catchError((response: any) => {
                    // The InstitutionService failed somewhere while making the Http request
                    return of(new ScoreOutOfWalletFailureAction({ error: response }));
                })
            )
        })
    )

    // actions$ with the dollar sign is naming convention for things that are observables
    constructor(private action$: Actions, private service: OutOfWalletService, private router: Router) { }
}
