import { Injectable } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { Router } from '@angular/router';
import { Settings } from '../settings';
import { AuthenticationHeaderManager } from '../authentication-header-manager';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SessionNotificationService {
    public static mainTimeout: any;
    private static warnTimeout: any;

    constructor(private modalService: ModalService<{ logout: boolean }>, private router: Router, private http: HttpClient) { }

    public startTimers(timeUntilSignout: number, timeUntilWarn: number, confirmationCode: string) {
        SessionNotificationService.mainTimeout = setTimeout(() => {this.signOut(confirmationCode); }, timeUntilSignout);
        SessionNotificationService.warnTimeout = setTimeout(() => {
            this.modalService.openModal('Session Timeout', 'Your session is about to time out.  Click below to extend your session.', [
                {
                    label: 'Extend',
                    result: { logout: false },
                    closesModal: true,
                    class: 'modal-button'
                }, {
                    label: 'Signout',
                    result: { logout: true },
                    closesModal: true,
                    class: 'modal-error-button'
                }
            ], 'modal-alert').subscribe(buttonResult => {
                if (!buttonResult.logout) {
                    this.extendSession(timeUntilSignout, timeUntilWarn, confirmationCode);
                } else {
                    this.signOut(confirmationCode);
                }
            });
        }, timeUntilWarn);
    }

    public stopTimers() {
        clearTimeout(SessionNotificationService.mainTimeout);
        clearTimeout(SessionNotificationService.warnTimeout);
    }

    public restartTimers(timeUntilSignout: number, timeUntilWarn: number, confirmationCode: string) {
        this.stopTimers();
        this.startTimers(timeUntilSignout, timeUntilWarn, confirmationCode);
    }

    public signOut(confirmationCode: string) {
        this.stopTimers();
        this.modalService.closeModal();
        this.router.navigate(['signout', confirmationCode]);
    }

    public extendSession(timeUntilSignout: number, timeUntilWarn: number, confirmationCode: string) {
        const headers: HttpHeaders = new HttpHeaders();
        headers.set(AuthenticationHeaderManager.authenticationHeaderName, AuthenticationHeaderManager.getAuthenticationHeader());

        this.http.post('Session/ping/', { }, { headers }).subscribe((response: any) => {
            if (response.status < 300) {
                AuthenticationHeaderManager.storeAuthenticationHeader(
                    response.headers.get(AuthenticationHeaderManager.authenticationHeaderName)
                );
                this.restartTimers(timeUntilSignout, timeUntilWarn, confirmationCode);
            } else {
                this.signOut(confirmationCode);
            }
        });
    }
}
