
export interface PromoCodeState {
    isLoading: boolean;
    isError: boolean;
    error: any;
}

export const initialPromoCodeState = {
    isLoading: false,
    isError: false,
    error: null
}