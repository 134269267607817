<div class="container view-error">
    <header class="error-header">
        <!-- xl container -->
        <div class="xl-container">
            <div class="signout-branding">
                <img *ngIf="institutionState.institution.logo" src="data:image/jpeg;base64,{{ institutionState.institution.logo }}" class="main-logo" />
            </div>
        </div>
        <!-- /xl container -->
    </header>

    <section class="error-body">
        <!-- xl container -->
        <div class="xl-container">
            <div class="error-content" *ngIf="!isLoading">
                <h2 class="error-title" *ngIf="institutionState.institution.pageContent">{{institutionState.institution.pageContent.verificationError.title}}</h2>

                <div class="error-text">
                    <page-header [pageName]="pageName" [hideLogo]="true" class="error-page-header"></page-header>

                    <p class="text-error">{{errorMessage}}</p>
                    <button (click)="returnToLogin()" class="error-button-return">
                        Return to Welcome Screen
                    </button>

                    <page-footer [pageName]="pageName" class="error-page-footer"></page-footer>
                </div>
            </div>
        </div>
        <!-- /xl container -->
    </section>
</div>