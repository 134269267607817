import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DigitalUserRoutingModule } from './digital-user-routing.module';
import { PageHeaderModule } from '../page-header/page-header.module';
import { PageFooterModule } from '../page-footer/page-footer.module';
import { ProgressModule } from '../progress/progress.module';

import { DigitalUserComponent } from './digital-user.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    declarations: [
        DigitalUserComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProgressModule,
        DigitalUserRoutingModule,
        PageHeaderModule,
        PageFooterModule,
        LoadingModule
    ]
})

export class DigitalUserModule { }
