<main id="view-persona-id-collection">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="persona-id-collection-content">
        <div class="inner-main-content">
            <!-- xl container -->
            <div class="xl-container">

                <div class="page-header-content">
                    <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>

                    <!-- RESKIN: NAV -->
                    <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                        <wizard-progress></wizard-progress>
                    </div>
                    <!-- /RESKIN: NAV -->

                </div>

                <csi-loading [isLoading]="isLoading"></csi-loading>

                <!-- RESKIN: PAGETEXT -->
                <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
                <!-- /RESKIN: PAGETEXT -->

                <div *ngIf="!isLoading" class="page-body-content">
                   <p style="text-align: center;"> Continue your application in Persona</p>
                </div>

                <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

            </div>
            <!-- /xl container -->
        </div>

    </section>
    <!-- /col 1 -->

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->

    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="isLoading || canContinue" (click)="navigateNext()" data-continue-page="persona" class="next-button">Continue</button>
    </div>
    <!-- /"contine" button -->

</main>