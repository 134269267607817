import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { RoutingLookupState, initialRoutingLookupState } from '../state/routing-lookup.state';
import { RoutingLookupActionTypes } from '../actions/routing-lookup.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const routingLookupStoreReducer: ActionReducer<RoutingLookupState> = reducer;

export function reducer(state = initialRoutingLookupState, action: NuFundAction) {
    switch (action.type) {
        case RoutingLookupActionTypes.SUBMIT_SUGGESTION: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true });
        }

        case RoutingLookupActionTypes.SUBMIT_SUGGESTION_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: false, error: null, suggestions: action.payload });
        }

        case RoutingLookupActionTypes.SUBMIT_SUGGESTION_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        default: {
            return state;
        }
    }
}
