import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, Observable, combineLatest } from 'rxjs';

import { Router } from '@angular/router';
import { ModalService } from '../modal/modal.service';

import { Store } from '@ngrx/store';

import { OutOfWalletActionTypes } from '../state-management/actions/out-of-wallet.actions';
import { OutOfWalletState, Level3Question } from '../state-management/state/out-of-wallet.state';

import { ProgressActionTypes } from '../state-management/actions/progress.actions';
import { ProgressState, Step } from '../state-management/state/progress.state';

import { ApplicantsActionsTypes } from '../state-management/actions/applicants.actions';
import { ApplicantsState, Applicant, demoApplicant } from '../state-management/state/applicants.state';

import { InstitutionState } from '../state-management/state/institution.state';

import { SessionState } from '../state-management/state/session.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';
import { CombinedState } from '../state-management/state/combined.state';
import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: './out-of-wallet.component.html',
    styleUrls: ['out-of-wallet.component.scss']
})
export class OutOfWalletComponent implements OnDestroy, OnInit {
    pageName = 'questions';
    isLoading = true;
    questions: Level3Question[] = [];
    answers: number[] = [];
    applicant: number;
    applicantsState: ApplicantsState;
    unsubscribe: Subject<any> = new Subject<any>();
    isPanelVisible = false;
    allowMultipleApplicants = false;
    autoFill = false;
    pixelId = 0;
    pageEventName = 'Verify';
    useNewUiForBank = false;
    canSubmitAnswers = false;
    enablePersona = false;

    progress: Step;

    constructor(
        private store: Store<CombinedState>,
        private route: ActivatedRoute,
        private outOfWalletStore: Store<OutOfWalletState>,
        private router: Router,
        private modalService: ModalService<boolean>,
    ) {

        store.select('outOfWalletStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: OutOfWalletState) => {
            // the 401 is handled in the interceptor
            if (!info.isError) {
                if (!isNaN(info.currentApplicantIndex)) {
                    this.questions = info.applicantOutOfWalletState[info.currentApplicantIndex].questions;
                }
                this.isLoading = info.isLoading;
            }

            this.setUpRoutingEvents(info);
        });

        const applicantsSource = store.select('applicantsStoreReducer').pipe(takeUntil(this.unsubscribe));
        const institutionSource = store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe));

        const dataConcat = combineLatest(applicantsSource, institutionSource).pipe(takeUntil(this.unsubscribe));
        dataConcat.subscribe((next) => {
            this.applicantsState = next[0] as ApplicantsState;
            const institutionState = next[1] as InstitutionState;

            this.useNewUiForBank = institutionState.institution.useNewUiForBank;
            this.pixelId = institutionState.institution.pixelId;
            this.allowMultipleApplicants = institutionState.institution.allowMultipleApplicants;
            this.enablePersona = institutionState.institution.enablePersonaIdScanning;

            if (
                this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].firstName === demoApplicant.firstName &&
                this.applicantsState.applicants[this.applicantsState.currentApplicantIndex].lastName === demoApplicant.lastName &&
                institutionState.institution.allowAutoFill) {
                this.autoFill = true;
            }
        });

        this.store.select('progressStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe(progressState => {
            this.progress = progressState.currentStep;
        })
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public setUpRoutingEvents(info: OutOfWalletState) {
        if (!this.applicantsState) {
            return;
        }

        const applicants = this.applicantsState.applicants;

        //make this check for sso && db login because they will be verified but have not answered any out of wallet questions
        if (applicants[0].isSSO) {
            info.applicantOutOfWalletState[0].questionsPassed = true;
        }

        if (applicants.length === 1) {
            if (info.applicantOutOfWalletState[0].questionsPassed) {
                this.router.navigate(['disclosures']);
                return;
            }
        }

        if (applicants.length === 2) {

                if (info.applicantOutOfWalletState[1].questionsPassed) {
                    this.router.navigate(['disclosures']);
                    return;
                }


                if (!info.applicantOutOfWalletState[1].questionsPassed &&
                    info.applicantOutOfWalletState[0].questionsPassed) {
                    if (this.progress === Step.VerifyPrimaryApplicantInformation) {
                        this.store.dispatch({ type: ApplicantsActionsTypes.SET_CURRENT_APPLICANT, payload: 1 });
                        if (this.applicantsState.applicants[1].zipCode !== ''){
                            this.router.navigate(['personal-information']);
                        }
                        else if(this.enablePersona){
                            this.router.navigate(['personaconsent']);
                        }
                        else{
                            this.router.navigate(['personal-information']);
                        }                     
                        return;
                    }

                    if (this.progress === Step.VerifySecondaryApplicantInformation) {
                        // stay on this page
                        return;
                    }
                }
            }

    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params: Params) => {
            this.applicant = +params.applicant;

            switch (this.applicant) {
                case 0:
                    this.setupApplicant(0, Step.VerifyPrimaryApplicantInformation);
                    break;
                case 1:
                    this.setupApplicant(1, Step.VerifySecondaryApplicantInformation);
                    break;
            }
        });
    }

    setupApplicant(applicantIndex: number, step: Step) {
        this.store.dispatch({ type: ProgressActionTypes.SET_PROGRESS, payload: step });
        this.store.dispatch({ type: OutOfWalletActionTypes.SET_CURRENT_APPLICANT, payload: applicantIndex });
        this.store.dispatch({
            type: OutOfWalletActionTypes.GET_OUT_OF_WALLET_QUESTIONS,
            payload: this.applicantsState.applicants[this.applicantsState.currentApplicantIndex]
        });
    }

    getApplicantPrefix(prefix: number): string {
        switch (prefix) {
            case 0:
                return 'Primary';
            case 1:
                return 'Secondary';
            default:
                return 'Additional';
        }
    }

    getCanSubmitAnswers(): boolean {
        // check for sparse array
        for (const answer in this.answers) {
            if (answer === undefined) {
                return false;
            }
        }
        // isn't a sparse array so check to see if they are all answered
        return this.answers.length > 3 && !this.isLoading;
    }

    handleUpdatedAnswer(question: number, value: number) {
        setTimeout(() => {
            this.canSubmitAnswers = this.getCanSubmitAnswers();
        }, 0)

        this.answers[question] = value;
    }


    addApplicant() {
        this.store.dispatch({ type: ApplicantsActionsTypes.ADD_APPLICANT });
        return false;
    }

    removeApplicant(index: number) {
        this.applicantsState.applicants.forEach((applicant, currentIndex) => applicant.index = currentIndex);

        if (this.applicantsState.currentApplicantIndex === index) {
            this.modalService.openModal(
            'Confirm removal',
            'Are you sure you want to remove this person?  It will not be possible to add them back after this.',
            [
                {
                    label: 'Remove this person',
                    result: true,
                    closesModal: true,
                    class: 'modal-button'
                },
                {
                    label: 'Cancel',
                    result: false,
                    closesModal: true,
                    class: 'modal-error-button'
                }
            ], 'modal-alert').pipe(takeUntil(this.unsubscribe)).subscribe(buttonResult => {
                if (buttonResult) {
                    this.store.dispatch({
                        type: ApplicantsActionsTypes.REMOVE_APPLICANT,
                        payload: this.applicantsState.applicants[index]
                    });
                    this.router.navigate(['disclosures']);
                }
            });
        } else {
            this.store.dispatch({
                type: ApplicantsActionsTypes.REMOVE_APPLICANT,
                payload: this.applicantsState.applicants[index]
            });
        }
        return false;
    }

    scoreAnswers() {
        this.outOfWalletStore.dispatch({
            type: OutOfWalletActionTypes.SCORE_OUT_OF_WALLET_ANSWERS,
            payload: {
                answers: this.answers,
                applicant: this.applicantsState.applicants[this.applicantsState.currentApplicantIndex]
            }
        });
    }
}
