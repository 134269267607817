<fieldset *ngFor="let question of questions; let i = index" class="verify-item">
  <div class="verify-question">{{question.questionText}}</div>

  <ul class="verify-answers">
    <li *ngFor="let answerId of getAnswerKeysByQuestionText(question.questionText)">
      <div class="form-radio">
        <input (click)="addSelectedAnswer(question.questionText, answerId)" type="radio" name="group_{{i}}" [value]="answerId" id="q-{{i}}_a-{{answerId}}">
        <label for="q-{{i}}_a-{{answerId}}">{{getAnswerTextById(question.questionText, answerId)}}</label>
      </div>
    </li>
  </ul>

  <!-- <div *ngFor="let answer of question.questionChoices">
    {{answer}}
  </div> -->

</fieldset>