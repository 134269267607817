import { isDevMode } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { InstitutionState, initialInstitutionState } from '../state/institution.state';
import { InstitutionActionTypes } from '../actions/institution.actions';
import { NuFundAction } from 'src/app/common/nufund-action';

export const institutionStoreReducer: ActionReducer<InstitutionState> = reducer;

export function reducer(state = initialInstitutionState, action: NuFundAction) {
    switch (action.type) {
        case InstitutionActionTypes.GET_INSTITUTION: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: true });
        }

        case InstitutionActionTypes.GET_INSTITUTION_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            // don't lose the revation info on a normal lookup
            const newState = state;
            const oldRevationStatus = state.institution.revationStatus;
            newState.institution = action.payload.institution;
            newState.institution.revationStatus = oldRevationStatus;
            if (newState.institution.isBusiness && newState.institution.businessTypes) {
                newState.institution.businessTypes = newState.institution.businessTypes.filter(bt => !(bt.value === "I" || bt.value === "B"))
            } 
            return Object.assign({}, state, newState, { isLoading: false, isError: false });
        }

        case InstitutionActionTypes.GET_INSTITUTION_FAILURE: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            return Object.assign({}, state, { isLoading: false, isError: true, error: action.payload.error });
        }

        case InstitutionActionTypes.GET_REVATION_STATUS_SUCCESS: {
            if (isDevMode()) {
                console.log(action.type + ' action being handled!');
            }

            const newState = state;
            newState.institution.revationStatus = action.payload;

            return Object.assign({}, state, newState, { isLoading: false, isError: false });
        }

        default: {
            return state;
        }
    }
}
