import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';

import { Store } from '@ngrx/store';
import { SessionState } from '../state-management/state/session.state';
import { SessionActionTypes } from '../state-management/actions/session.actions';
import { SessionNotificationService } from '../services/session-notification.service';

import { InstitutionState } from '../state-management/state/institution.state';

import { SelectedProductsState } from '../state-management/state/selected-products.state';
import { ProductsState } from '../state-management/state/products.state';
import { Observable, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CombinedState } from '../state-management/state/combined.state';

@Component({
    moduleId: module.id,
    templateUrl: './signout.component.html',
    styleUrls: ['signout.component.scss']
})
export class SignoutComponent implements OnInit {
    public confirmationCode: string;
    public institutionState: InstitutionState;
    public enablePersona: Boolean;
    useNewUiForBank = false;

    constructor(
        private router: Router,
        private store: Store<CombinedState>,
        private route: ActivatedRoute,
        private sessionNotificationService: SessionNotificationService,
    ) { }

    ngOnInit() {
        // if the user hit logout or directly navigated here somehow
        this.sessionNotificationService.stopTimers();

        this.store.select('institutionStoreReducer').subscribe((institutionState: InstitutionState) => {
            this.institutionState = institutionState;
            this.useNewUiForBank = this.institutionState.institution.useNewUiForBank;
        });

        const selectedProducts$ = this.store.select('selectedProductsStoreReducer')
            .pipe(take(1)) as Observable<SelectedProductsState>;
        const products$ = this.store.select('productsStoreReducer').pipe(take(1)) as Observable<ProductsState>;

        forkJoin([selectedProducts$, products$]).subscribe((result) => {
            // if there are no products selected or available then don't bother sending them an email to resume
            var bypassSendingEmail : boolean;
            bypassSendingEmail = (result[0] as SelectedProductsState).selectedProducts.length === 0 &&
                (result[1] as ProductsState).products.length === 0;
            
            if (this.enablePersona){
                bypassSendingEmail = false;
            }
            this.store.dispatch({
                type: SessionActionTypes.LOG_OUT,
                payload: {
                    bypassSendingEmail
                }
            });
        });

        this.route.params.pipe(take(1)).subscribe(params => {
            this.confirmationCode = params.confirmationCode;
        });
    }

    public returnToLogin() {
        this.router.navigate(['home']);
    }
}
