import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'csi-revation-chat',
  templateUrl: './revation-chat.component.html',
  styleUrls: ['./revation-chat.component.scss']
})
export class RevationChatComponent implements AfterViewInit {
  sanitizedChatURL: SafeResourceUrl;
  open = true;
  isLoading = true;

  @Input() set chatURL(value: string) {
    if (value) {
      this.sanitizedChatURL = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
  }

  @ViewChild('chatFrame', { static: true }) chatFrame;

  private chatFrameElement: HTMLIFrameElement = null;

  constructor(private sanitizer: DomSanitizer) { }

  ngAfterViewInit() {
    this.chatFrameElement = this.chatFrame.nativeElement as HTMLIFrameElement;
    // when the iframe is done loading, send them the message to minimize
    this.chatFrameElement.addEventListener('load', () =>
      this.chatFrameElement.contentWindow.postMessage({ event: 'minimize' }, '*')
    );

    // handle events that come back out of the iframe
    window.addEventListener('message', (event) => this.handleMessageEvent(event), false);
  }

  handleMessageEvent(event: MessageEvent) {
    switch (event.data.event) {
      case 'open':
        this.open = true;
        this.isLoading = false;
        break;
      case 'close':
        this.open = false;
        this.isLoading = false;
        break;
      case 'pending_msgs':
        break;
      default:
        break;
    }
  }
}
