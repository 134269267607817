<main id="view-personalInfo">
    <!-- mobile menu -->
    <div *ngIf="!useNewUiForBank" class="mobile-menu" [class.menu-on]="isPanelVisible">
        <div class="mobile-menu-toggle" (click)="isPanelVisible = !isPanelVisible;">
            <span class="mobile-menu-bars"></span>
        </div>
    </div>
    <!-- /mobile menu -->

    <!-- col 1 -->
    <section class="inner-main-content personalInfo-content">
        <!-- xl container -->
        <div class="xl-container">

            <div class="page-header-content">
                <page-header [pageName]="pageName" class="page-header-logo-title"></page-header>

                <!-- RESKIN: NAV -->
                <div *ngIf="useNewUiForBank" class="reskin-nav internal-nav-container">
                    <wizard-progress></wizard-progress>
                </div>
                <!-- /RESKIN: NAV -->
            </div>

            <csi-loading [isLoading]="applicant['isLoading']"></csi-loading>

            <!-- RESKIN: PAGETEXT -->
        <page-header *ngIf="!isLoading && useNewUiForBank" [pageName]="pageName" class="page-body-pagetext"></page-header>
            <!-- /RESKIN: PAGETEXT -->

            <div [hidden]="applicant.isLoading" class="page-body-content">
                <section class="applicant-body">

                    <!-- RESKIN: creating new container elements for column layout -->
                    <!-- RESKIN: col 1 -->
                    <div class="applicant-body-main">
                        <!-- header -->
                        <div class="applicant-header">
                            <div class="applicant-title">
                                <h1 *ngIf="applicantState.currentApplicantIndex === 0" class="subhead">
                                    Primary Applicant Information
                                </h1>
                                <h1 *ngIf="applicantState.currentApplicantIndex === 1" class="subhead">
                                    Secondary Applicant Information
                                </h1>
                            </div>
                        </div>

                        <!-- admin -->
                        <div class="admin-container" *ngIf="showAdminAutoFill">
                            <ul class="admin-items">
                                <li>
                                    <a href="#" (click)="onAutofillClick()" class="admin-button">
                                        <span class="icon icon-cog"></span>Auto Fill Content
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- /admin -->
                        <!-- /header -->

                         <!--form-->
                         <ng-container *ngIf="institution !== null">
                            <app-applicant-form></app-applicant-form>
                        </ng-container>
                        <!-- ends -->


                    </div>
                    <!-- /RESKIN: col 1 -->

                    <!-- RESKIN: col 2 -->
                    <div class="applicant-body-aside">
                        <!-- applicant controls -->
                        <div class="applicant-controls" *ngIf="institution.allowMultipleApplicants">
                            <!-- <div class="applicant-control-items"> -->
                                <!-- add applicant -->
                                <div *ngIf="applicantState?.applicants.length < 2" class="applicant-control applicant-control-add">
                                    <div class="applicant-control-info">
                                        <h3>Add another person?</h3>
                                        <p>If you want to add another person, click the box below. After verifying your personal information, you will be able to enter their information.</p>
                                    </div>
                                    <div class="applicant-control-action" >

                                        <button (click)="addApplicant()" [disabled]="!isEnabled" [ngClass]="isEnabled ? 'applicant-control-button' : 'applicant-control-button disabled'">
                                            <span class="icon icon-user-plus"></span> Add Another Person
                                        </button>
                                    </div>
                                </div>
                                <!-- /add applicant -->

                                <!-- remove applicant -->
                                <ng-container *ngIf="applicantState?.applicants.length >= 2">
                                    <div class="applicant-control applicant-control-remove">
                                        <div class="applicant-control-info">
                                            <h3>Additional Person Added</h3>
                                            <p>If you want to remove this additional person, click the box below.</p>
                                        </div>
                                        <div class="applicant-control-action" [ngClass]="{'disabled': isAddingApplicant}">
                                            <button (click)="removeApplicant(1)" [disabled]='!isEnabled' [ngClass]="isEnabled ? 'applicant-control-button remove-item' : 'applicant-control-button remove-item disabled'">
                                                <span class="icon icon-user-times"></span> Remove Additional Person
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- /remove applicant -->
                            <!-- </div> -->
                        </div>
                        <!-- /applicant controls -->
                    </div>
                    <!-- /RESKIN: col 2 -->

                </section>
            </div>

            <page-footer [pageName]="pageName" class="page-footer-content"></page-footer>

        </div>
        <!-- /xl container -->

    </section>

    <!-- col 2 -->
    <!-- NAV -->
    <div *ngIf="!useNewUiForBank" class="internal-nav-container" [class.hidden]="!isPanelVisible">
        <wizard-progress></wizard-progress>
    </div>
    <!-- /NAV -->
    <!-- col 2 -->
    <!-- "continue" button -->
    <div class="next-button-container">
        <button [disabled]="!continueButtonEnable" (click)="submitCurrentApplicant()"
            data-continue-page="personalInformation" class="next-button">Continue</button>
    </div>
    <!-- /"continue" button -->

</main>
