import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CombinedState } from 'src/app/state-management/state/combined.state';
import { InstitutionState } from 'src/app/state-management/state/institution.state';

@Component({
  selector: 'home-promo-code',
  templateUrl: './home-promo-code.component.html',
  styleUrls: ['./home-promo-code.component.scss']
})
export class HomePromoCodeComponent implements OnInit, OnDestroy {
  /** Reusable two-way binding pattern to commuicate with parent components */
  @Input() promoCode: string;
  @Output() promoCodeChange: EventEmitter<string> = new EventEmitter<string>();

  /** Pattern for preventing observable memory leaks */
  public unsubscribe: Subject<void> = new Subject<void>();
  /** Promo code take from the route */
  public routedPromoCode = '';
  /** Controls the visibility of the entire promo code component. Particularly from the instituion store. */
  public hidePromoCode = false;
  /** Controls the visibility of the input specifically. Controlled by the toggle */
  public isPromoInputVisible = false;
  
  public promoForm: FormGroup = null;

  constructor(
    
    private store: Store<CombinedState>,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.promoCode !== undefined && params.promoCode !== 'none') {
          this.routedPromoCode = params.promoCode;
          this.promoCode = this.routedPromoCode;
          this.promoCodeChange.emit(this.promoCode);
      }

      this.promoForm = this.fb.group({promoCode: this.promoCode})
      this.promoForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((vc: {promoCode: string} )=> {
        this.promoCodeChange.emit(vc.promoCode);
      })
    });

    
    this.store.select('institutionStoreReducer').pipe(takeUntil(this.unsubscribe)).subscribe((info: InstitutionState) => {
      this.hidePromoCode = info.institution.hidePromoCode;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** Toggles the visibility of the input while retaining the visibility of the entire promo code component */
  public togglePromoCodeVisible() {
    this.isPromoInputVisible = !this.isPromoInputVisible;
  }

}
